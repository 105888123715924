import * as React from 'react';

const IconCoin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.33 80" {...props}>
    <g data-name="Calque 2">
      <g data-name="Calque 1">
        <path
          d="M137.33 42.2v9.92c0 7.57-16 14-34.92 14s-34.91-6.4-34.91-14V42.2z"
          fill="#ffbe0d"
        />
        <path
          d="M102.41 56.9c-18.27 0-34.91-7-34.91-14.7 0-8 18.12-12.86 34.91-12.86s34.92 4.91 34.92 12.86c0 7.69-16.65 14.7-34.92 14.7z"
          fill="#ffd11d"
        />
        <path
          d="M89.35 56.11V66c0 7.58-16 14-34.92 14s-34.91-6.39-34.91-14v-9.89z"
          fill="#ffbe0d"
        />
        <path
          d="M89.25 55.13a4.66 4.66 0 01.1 1c0 7.7-16.65 14.7-34.92 14.7s-34.91-7-34.91-14.7c0-2.74 2.16-5.13 5.69-7.07z"
          fill="#ffd11d"
        />
        <path
          d="M94.86 37.74v9.92c0 7.57-16 14-34.91 14S25 55.23 25 47.66v-9.92z"
          fill="#ffbe0d"
        />
        <path
          d="M90.68 31.58c2.62 1.76 4.18 3.83 4.18 6.16 0 7.69-16.64 14.7-34.91 14.7S25 45.43 25 37.74a5.21 5.21 0 01.41-2z"
          fill="#ffd11d"
        />
        <path
          d="M91.18 19.36v9.92c0 7.57-16 14-34.91 14s-34.91-6.4-34.91-14v-9.92z"
          fill="#ffbe0d"
        />
        <path
          d="M56.27 34.06c-18.27 0-34.91-7-34.91-14.7 0-7.94 18.12-12.86 34.91-12.86s34.91 4.92 34.91 12.86c0 7.7-16.64 14.7-34.91 14.7z"
          fill="#ffd11d"
        />
        <circle cx={7.26} cy={7.26} r={7.26} fill="#ffd11d" />
        <circle cx={111.24} cy={18.61} r={4.01} fill="#ffd11d" />
        <circle cx={10.51} cy={59.77} r={2.93} fill="#ffd11d" />
      </g>
    </g>
  </svg>
);

export default IconCoin;
