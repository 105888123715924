import React, { useState, useEffect } from 'react';
import classes from 'assets/style/passwordStrengthBar.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';

const PasswordStrengthBar = ({ className, password = '' }) => {
  const { t } = useTranslation('passwordInput');

  const [score, setScore] = useState(0);

  useEffect(() => {
    import('zxcvbn').then(({ default: zxcvbn }) => {
      setScore(zxcvbn(password).score);
    });
  }, [password]);

  classes.active = score > 2 ? classes.strong : classes.weak;
  classes.active = score > 3 ? classes.excellent : classes.active;

  return (
    <div className={className}>
      <OverlayTrigger
        placement="right"
        overlay={(
          <Popover content className={classes.popover}>
            {!!password && (
              <strong>
                {t(`pwdStrength.strength.${score}`)}
                <br />
              </strong>
            )}
            {t('pwdStrength.hint')}
          </Popover>
        )}
      >
        <div className={classes.bar}>
          <div className={score > 3 ? classes.active : classes.dot} />
          <div className={score > 2 ? classes.active : classes.dot} />
          <div className={score > 1 ? classes.active : classes.dot} />
          <div className={score > 0 ? classes.active : classes.dot} />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default PasswordStrengthBar;
