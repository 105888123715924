import iconChecked from 'assets/images/icon-checked.svg';
import iconNotChecked from 'assets/images/icon-not-checked.svg';
import { DELIVERY_METHOD_IN_PERSON } from 'helpers/constants';
import useWindowSize from 'helpers/useWindowSize';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EVENTS_IN_PERSON = [
  'event_transaction_accept',
  'event_transaction_pay',
  'event_transaction_complete',
];

const EVENTS_REMOTE = [
  'event_transaction_accept',
  'event_transaction_pay',
  'event_transaction_ship',
  'event_transaction_delivered',
  'event_transaction_complete',
];

const TransactionTimeline = ({
  deliveryMethod = DELIVERY_METHOD_IN_PERSON,
  events = [],
}) => {
  const { t } = useTranslation('transactionTimeline');
  const { isNarrow } = useWindowSize();

  const defaultSteps = deliveryMethod === DELIVERY_METHOD_IN_PERSON
    ? EVENTS_IN_PERSON
    : EVENTS_REMOTE;

  const filteredEvents = events.filter(({ slug }) => defaultSteps.includes(slug));

  let formatedEvents = filteredEvents.concat(
    defaultSteps.map((slug) => ({ slug })).slice(filteredEvents.length),
  );

  if (isNarrow) {
    let nextEventIndex = formatedEvents.findIndex(({ date }) => !date);
    if (nextEventIndex < 0) {
      nextEventIndex = formatedEvents.length - 1;
    }

    formatedEvents = formatedEvents.slice(
      Math.min(Math.max(nextEventIndex - 2, 0), formatedEvents.length - 2),
      Math.min(Math.max(nextEventIndex + 1, 3), formatedEvents.length),
    );
  }

  return (
    <div className="transaction-reference-progress">
      {formatedEvents.map(({ slug, date }) => (
        <div key={slug}>
          <img width="22px" src={date ? iconChecked : iconNotChecked} alt="" />
          <p className="transaction-reference-progress__title">{t(slug)}</p>
        </div>
      ))}
    </div>
  );
};

export default TransactionTimeline;
