/**
 * Function that allows to resize an image file before using it in browser.
 * Large images can affect browser behavior
 * @param {Object} file - File object from input with type "file"
 * @param maxWidth - Maximum horizontal dimension
 * @param maxHeight - maximum vertical dimension
 * @returns {Promise} - Promise that resolves with image url (base64)
 *
 * @example
 * // returns data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...
 * image has width 2400px and height 1350px
 * resizeImage(image, 600, 500);
 * @returns {string} Returns promise that resolves with resized image url (width 600px, height 337px)
 *
 *  * @example
 * // returns data:image/png;base64,9i0AAioQSkJRgABAQAAAQABAAD...
 * image has width 1500px and height 1800px
 * resizeImage(image, 500, 500);
 * @returns {string} Returns promise that resolves with resized image url (width 416px, height 500px)
 *
 */
const resizeImage = (file, maxWidth, maxHeight) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = () => {
    const img = new Image();
    img.src = reader.result;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      let { width, height } = img;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      const resizedImageUrl = canvas.toDataURL(file.type);

      if (!resizedImageUrl || resizedImageUrl.length < 7) {
        const error = new Error('Can not resize image');
        reject(error);
      }

      resolve(resizedImageUrl);
    };
  };
});

export default resizeImage;
