import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ConfirmCancelModal = ({
  show, onHide, disabled, onCancel,
}) => {
  const [isCanceling, setCanceling] = useState(false);

  const { t } = useTranslation('transactionActionPanel');

  const handleCancel = useCallback(() => {
    setCanceling(true);
    onCancel()
      .catch(() => toast.error(t('confirmCancelModal.error')))
      .finally(() => {
        setCanceling(false);
        onHide();
      });
  }, [
    onCancel,
    onHide,
    t,
  ]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>{t('confirmCancelModal.title')}</Modal.Header>

      <Modal.Body>{t('confirmCancelModal.areYouSure')}</Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          {t('confirmCancelModal.close')}
        </Button>
        <Button
          variant="danger"
          isLoading={isCanceling}
          disabled={disabled}
          onClick={handleCancel}
        >
          {t('confirmCancelModal.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmCancelModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmCancelModal;
