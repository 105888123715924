import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import DisputeResolutionRadioButton from 'components/DisputeResolutionRadioButton';

export const SOLUTION_RETURN = 'solution_return';
export const SOLUTION_PARTIAL = 'solution_partial';

const ChooseSolution = ({ proposedSolution, back, next }) => {
  const { t } = useTranslation('disputeResolution');

  const [selected, setSelected] = useState(proposedSolution);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>{t('chooseSolution.title')}</p>
      <div className={classes.form}>
        <DisputeResolutionRadioButton
          id={SOLUTION_RETURN}
          name="solution"
          title={t('chooseSolution.solutionReturn')}
          onChange={() => {
            setSelected(SOLUTION_RETURN);
          }}
          defaultChecked={selected === SOLUTION_RETURN}
        />
        <DisputeResolutionRadioButton
          id={SOLUTION_PARTIAL}
          name="solution"
          title={t('chooseSolution.solutionPartial')}
          onChange={() => {
            setSelected(SOLUTION_PARTIAL);
          }}
          defaultChecked={selected === SOLUTION_PARTIAL}
        />
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(selected);
        }}
        disabled={!selected}
      >
        {t('chooseSolution.next')}
      </Button>
    </Row>
  );
};

export default ChooseSolution;
