import React, { useEffect } from 'react';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import TakePicturesFirst from 'pages/takePictures/TakePicturesFirst';
import TakePicturesSecond from 'pages/takePictures/TakePicturesSecond';
import TakePicturesForItems from 'pages/takePictures/TakePicturesForItems';
import {
  PICTURES_UPLOAD_STATUS_KEY,
  TAKE_PICTURES_ROUTES,
} from 'helpers/constants';
import MediaContainer from 'components/takePictures/MediaContainer';
import TakePicturesForParcel from 'pages/takePictures/TakePicturesForParcel';
import TakePicturesResult from 'pages/takePictures/TakePicturesResult';
import localStorage from 'helpers/localStorage';

const TakePicturesRoutes = () => {
  const history = useHistory();

  const { pathname, state: { transactionId } = {} } = useLocation();

  const status = localStorage.get(PICTURES_UPLOAD_STATUS_KEY);

  useEffect(() => {
    if (!transactionId) {
      history.push('/');
    }
  }, [
    history,
    transactionId,
  ]);

  useEffect(() => {
    if (status === 'success') {
      if (pathname.includes(TAKE_PICTURES_ROUTES.FIRST_SCREEN)) {
        localStorage.delete(PICTURES_UPLOAD_STATUS_KEY);
      } else {
        history.push('/');
      }
    }
  }, [
    status,
    history,
    pathname,
  ]);

  return (
    <div className="absolute-container">
      <Switch>
        <Route
          exact
          path={TAKE_PICTURES_ROUTES.FIRST_SCREEN}
          component={TakePicturesFirst}
        />
        <Route
          exact
          path={TAKE_PICTURES_ROUTES.SECOND_SCREEN}
          component={TakePicturesSecond}
        />
        <MediaContainer>
          <Route
            exact
            path={TAKE_PICTURES_ROUTES.RESULT_SCREEN}
            component={TakePicturesResult}
          />
          <Route
            exact
            path={TAKE_PICTURES_ROUTES.ITEMS_SCREEN}
            component={TakePicturesForItems}
          />
          <Route
            exact
            path={TAKE_PICTURES_ROUTES.PARCEL_SCREEN}
            component={TakePicturesForParcel}
          />
        </MediaContainer>
      </Switch>
    </div>
  );
};

export default TakePicturesRoutes;
