import React from 'react';
import LogoTripartie from 'assets/svg/LogoTripartie';
import { useTranslation } from 'react-i18next';

const CheckoutHeader = () => {
  const { t } = useTranslation('checkoutHeader');

  return (
    <div className="checkout-container__header p-lg-2 fixed-top d-flex justify-content-center align-items-center bg-primary">
      <div className="mr-3 mr-lg-0">
        <LogoTripartie className="checkout-container__logo" />
      </div>
      <p className="checkout-header__slogan m-0 ml-lg-3 d-block">
        {t('title')}
      </p>
    </div>
  );
};

export default CheckoutHeader;
