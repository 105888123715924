import React, { useState, useEffect, useRef } from 'react';
import 'assets/style/transactionListItem.scss';
import { CURRENCY_EUR } from 'helpers/constants';
import SpanWithColon from 'helpers/SpanWithColon';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import defaultTransactionImage from 'assets/images/defaultTransactionImage.svg';
import { fetchTransactionPicture } from 'logic/actions/transactionsActions';
import Button from 'react-bootstrap/esm/Button';
import { getBase64FromBlob } from 'helpers/decode';
import { selectCurrentUserId } from 'logic/actions/meActions';
import { useInView } from 'react-intersection-observer';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';

const { CancelToken } = axios;

const TransactionListItem = ({ transaction = {}, isCurrentTab }) => {
  const cancel = useRef(null);

  const {
    id,
    title,
    sellerDisplayName = '',
    buyerId,
    buyerDisplayName = '',
    client,
    sellerTotal,
    buyerTotal,
    currency = CURRENCY_EUR,
    hasPicture,
  } = transaction;

  const dispatch = useDispatch();

  const { t } = useTranslation('transactionListItem');

  const history = useHistory();

  const [picture, setPicture] = useState(false);
  const [loading, setLoading] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: !!picture,
  });

  useEffect(() => {
    if (inView) {
      if (hasPicture && !picture) {
        setLoading(true);
        dispatch(
          fetchTransactionPicture(
            id,
            new CancelToken((cancellationCallback) => {
              cancel.current = cancellationCallback;
            }),
          ),
        )
          .then(({ payload: { data } = {} }) => {
            getBase64FromBlob(data).then(setPicture);
          })
          .catch(() => Promise.resolve())
          .finally(() => setLoading(false));
      }
    }
  }, [
    inView,
    hasPicture,
    picture,
    dispatch,
    id,
  ]);

  useEffect(() => {
    if (cancel.current && !isCurrentTab) {
      cancel.current();
    }
  }, [isCurrentTab, cancel]);

  const currentUserId = useSelector(selectCurrentUserId);

  const isPurchase = currentUserId === buyerId;
  const total = isPurchase ? -buyerTotal : sellerTotal;

  return (
    <div
      role="button"
      tabIndex={0}
      className="transaction-list-item clickable"
      onClick={() => history.push(`/transactions/${id}`, { transaction })}
      onKeyDown={() => history.push(`/transactions/${id}`, { transaction })}
      ref={ref}
    >
      <div className="transaction-list-item__infos-wrapper">
        {loading ? (
          <Skeleton className="picture-skeleton" />
        ) : (
          <div className="transaction-list-item__image">
            <img
              className="transaction-list-item__image__inner"
              src={picture || defaultTransactionImage}
              alt={title}
            />
          </div>
        )}

        <div className="transaction-list-item__infos">
          <div>
            <h5 className="transaction-list-item__text-title">
              {loading ? <Skeleton /> : title}
            </h5>
            <h5 className="transaction-list-item__price--mobile text-overflow-any">
              {loading ? (
                <Skeleton />
              ) : (
                <FormattedNumber
                  value={total / 100}
                  style="currency"
                  currency={currency}
                />
              )}
            </h5>
          </div>
          <div className="transaction-list-item__infos-body">
            <p className="m-0 text-overflow-any">
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <SpanWithColon>
                    {isPurchase ? t('purchase') : t('sale')}
                  </SpanWithColon>
                  <span className="ml-1">
                    {isPurchase ? sellerDisplayName : buyerDisplayName}
                  </span>
                </>
              )}
            </p>
            <div>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="text-overflow-any">
                  <SpanWithColon>{t('platform')}</SpanWithColon>
                  <span className="ml-1">{client}</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <h5 className="transaction-list-item__price--desktop">
        {loading ? (
          <Skeleton />
        ) : (
          <FormattedNumber
            value={total / 100}
            style="currency"
            currency={currency}
          />
        )}
      </h5>
      {!loading && (
        <Button className="transaction-list-item__button--placeholder">
          {t('details')}
        </Button>
      )}
    </div>
  );
};

export default TransactionListItem;
