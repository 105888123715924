import * as React from 'react';

const IconDeliveryMobile1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70.024 68.5"
    {...props}
  >
    <defs>
      <style>
        {'.icon-delivery-mobile-1_svg__a,.icon-delivery-mobile-1_svg__b{fill:none;stroke:#050038;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.5px}.icon-delivery-mobile-1_svg__b{fill-rule:evenodd}'}
      </style>
    </defs>
    <g transform="translate(-2151.251 -620.75)">
      <path
        className="icon-delivery-mobile-1_svg__a"
        d="M2161.408 665.294a37.793 37.793 0 0110.7-1.528c6.059 0 8.277 3.928 11.564 3.928h7a3.957 3.957 0 110 7.913h-6.838a9.4 9.4 0 00-5.029 2.456"
      />
      <rect
        className="icon-delivery-mobile-1_svg__a"
        width={8.908}
        height={28.063}
        rx={1.742}
        transform="translate(2152.501 659.937)"
      />
      <path
        className="icon-delivery-mobile-1_svg__a"
        d="M2194.623 671.651l22.086-6.31a2.733 2.733 0 013.279 2.623 2.524 2.524 0 01-1.705 2.623l-26.494 14.3a15.372 15.372 0 01-9.05.787l-9.838-2.886a20.237 20.237 0 00-11.493-.127"
      />
      <path
        className="icon-delivery-mobile-1_svg__b"
        d="M2164.517 664.523a81.345 81.345 0 01-1.2-15.036 74.64 74.64 0 011.482-16.283v.012A76.4 76.4 0 012189.988 622v.014a76.4 76.4 0 0125.193 11.216M2215.181 633.2a90.183 90.183 0 010 32.566"
      />
      <path
        className="icon-delivery-mobile-1_svg__b"
        d="M2204.718 649.881a62.294 62.294 0 00-1.377-11.412 80.978 80.978 0 00-26.707-11.89"
      />
      <path
        className="icon-delivery-mobile-1_svg__a"
        d="M2189.988 667.694v-23.3l-25.188-11.178 25.193 11.177 25.193-11.177"
      />
    </g>
  </svg>
);

export default IconDeliveryMobile1;
