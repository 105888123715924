const copyToClipboard = (text) => {
  let textarea;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.className = 'copy-to-clipboard-textarea';
    textarea.value = text;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, 999999);
    document.execCommand('copy');
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
  } finally {
    document.body.removeChild(textarea);
  }
};

export default copyToClipboard;
