import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import classes from 'assets/style/profilePicture.module.scss';
import profileIcon from 'assets/images/profile-icon.svg';
import AvatarMask from 'assets/svg/AvatarMask';
import { avatarUrl } from 'logic/actions/meActions';
import { Spinner } from 'react-bootstrap';

const ProfilePicture = ({
  className, loading,
}) => {
  const avatar = useSelector(avatarUrl);

  return (
    <div
      className={classNames(classes.profilePicture, className)}
    >
      <AvatarMask
        fill="#fff"
        className={classes.profilePicture__mask}
      />
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <img
          className={classes.profilePicture__image}
          src={avatar || profileIcon}
          alt="user-avatar"
        />
      )}
    </div>
  );
};

ProfilePicture.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
};

ProfilePicture.defaultProps = {
  className: '',
  loading: false,
};

export default ProfilePicture;
