import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { Redirect } from 'react-router-dom';
import { resetPassword } from 'logic/actions/resetPasswordActions';
import { loggedIn } from 'logic/actions/meActions';
import classes from 'assets/style/resetPassword.module.scss';
import LogoTripartie from 'assets/svg/LogoTripartie';
import PasswordInput, {
  usePasswordStrengthTest,
} from 'components/PasswordInput';
import { ERRORS_FROM_SERVER } from 'helpers/constants';
import { toast } from 'react-toastify';
import Footer from 'components/Footer';

const ResetPassword = ({
  match: { params: { token } = {} } = {},
  history,
  location: { state: { referer } = '/' } = {},
}) => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(loggedIn);

  const { t } = useTranslation('resetPassword');

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t('emptyPassword'))
      .min(10, t('invalidPassword'))
      .test(usePasswordStrengthTest()),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('passwordsDontMatch'))
      .required(t('emptyConfirmPassword')),
  });

  const handleReset = useCallback(
    ({ password }, { setFieldError, setSubmitting }) => {
      dispatch(resetPassword(token, password))
        .then(({ payload: { data: { returnTo = '/' } = {} } = {} }) => {
          window.location.replace(returnTo);
        })
        .catch(({ error }) => {
          if (error.response.status === 429) {
            toast.error(t('tooManyRequests'));
            setSubmitting(false);
          }
          switch (error.response.data?.errors) {
            case ERRORS_FROM_SERVER.PASSWORD_ERROR:
              setFieldError('password', t('samePasswordError'));
              setSubmitting(false);
              break;

            case ERRORS_FROM_SERVER.NO_TOKEN:
              toast.error(t('incorrectTokenError'));
              history.push('/');
              break;

            default:
              setSubmitting(false);
          }
        });
    },
    [
      dispatch,
      token,
      history,
      t,
    ],
  );

  if (isAuthenticated) {
    return <Redirect to={referer} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <LogoTripartie fill="#004BB4" className={classes.logo} />
          <p className={classes.title}>{t('title')}</p>
          <p className={classes.description}>{t('description')}</p>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={handleReset}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group
                    md="4"
                    controlId="password"
                    className={classes.formGroup}
                  >
                    <Form.Label className={classes.label}>
                      {t('passwordLabel')}
                    </Form.Label>
                    <PasswordInput
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.password && !!errors.password}
                      placeholder={t('passwordPlaceholder')}
                      showStrenghtBar
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </PasswordInput>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    md="4"
                    controlId="confirmPassword"
                    className={classes.formGroup}
                  >
                    <Form.Label className={classes.label}>
                      {t('confirmPasswordLabel')}
                    </Form.Label>
                    <PasswordInput
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                      placeholder={t('confirmPasswordPlaceholder')}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </PasswordInput>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Button
                    type="submit"
                    className="btn-block mt-4"
                    isLoading={isSubmitting}
                  >
                    {t('submit')}
                  </Button>
                </Form.Row>
              </Form>
            )}
          </Formik>
        </div>
        <Footer className={classes.footer} />
      </div>
    </>
  );
};

export default ResetPassword;
