import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import DisputeResolutionRadioButton from 'components/DisputeResolutionRadioButton';

export const DOES_NOT_MATCH = 'does_not_match';
export const DAMAGED = 'damaged';
export const INCOMPLETE = 'incomplete';
export const COUNTERFEIT = 'counterfeit';
export const NOT_RECEIVED = 'not_received';

const IssueType = ({ type, back, next }) => {
  const { t } = useTranslation('disputeResolution');

  const [selected, setSelected] = useState(type);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>{t('issueType.title')}</p>
      <div className={classes.form}>
        <DisputeResolutionRadioButton
          id={DOES_NOT_MATCH}
          name="issueType"
          title={t('issueType.doesNotMatch')}
          onChange={() => {
            setSelected(DOES_NOT_MATCH);
          }}
          defaultChecked={selected === DOES_NOT_MATCH}
        />
        <DisputeResolutionRadioButton
          id={DAMAGED}
          name="issueType"
          title={t('issueType.damaged')}
          onChange={() => {
            setSelected(DAMAGED);
          }}
          defaultChecked={selected === DAMAGED}
        />
        <DisputeResolutionRadioButton
          id={INCOMPLETE}
          name="issueType"
          title={t('issueType.incomplete')}
          onChange={() => {
            setSelected(INCOMPLETE);
          }}
          defaultChecked={selected === INCOMPLETE}
        />
        <DisputeResolutionRadioButton
          id={COUNTERFEIT}
          name="issueType"
          title={t('issueType.counterfeit')}
          onChange={() => {
            setSelected(COUNTERFEIT);
          }}
          defaultChecked={selected === COUNTERFEIT}
        />
        <DisputeResolutionRadioButton
          id={NOT_RECEIVED}
          name="issueType"
          title={t('issueType.notReceived')}
          onChange={() => {
            setSelected(NOT_RECEIVED);
          }}
          defaultChecked={selected === NOT_RECEIVED}
        />
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(selected);
        }}
        disabled={!selected}
      >
        {t('issueType.next')}
      </Button>
    </Row>
  );
};

export default IssueType;
