import React from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/takePictures/pageTitle.module.scss';
import ArrowLeftIcon from 'assets/svg/ArrowLeftIcon';

const PageTitle = ({
  title, goBack, step, children,
}) => (
  <div className={classes.pageTitle__header}>
    <button
      type="button"
      className={classes.pageTitle__headerArrowIcon}
      onClick={goBack}
    >
      <ArrowLeftIcon strokeWidth={3} />
    </button>
    <div className={classes.pageTitle__headerTitle}>
      {children || (
        <>
          <div className={classes.pageTitle__numberIcon}>{step}</div>
          {title}
        </>
      )}
    </div>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  step: PropTypes.number,
};

PageTitle.defaultProps = {
  title: '',
  step: 0,
};

export default PageTitle;
