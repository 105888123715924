// DO

import React from 'react';
import defaultTransactionImage from 'assets/images/defaultTransactionImage.svg';
import TransactionRecap from 'components/TransactionRecap';
import classes from 'assets/style/TransactionRecapCard.module.scss';

const TransactionRecapCard = ({ transaction }) => (
  <div className={classes.TransactionRecapCard}>
    <div className={classes.image}>
      <img
        className={classes.image__inner}
        src={transaction.picture || defaultTransactionImage}
        alt={transaction.title}
      />
    </div>
    <TransactionRecap transaction={transaction} />
  </div>
);

export default TransactionRecapCard;
