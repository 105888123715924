import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCount,
  selectItems,
  selectLastLoadedPage,
  selectLoadedPages,
  selectNbPages,
} from 'logic/actions/transactionsActions';
import 'intersection-observer';

const useDynamicPagination = (
  getElementsFromServer,
  transactionType,
  isCurrentTab,
) => {
  const dispatch = useDispatch();

  const loader = useRef(null);

  const lastLoadedPage = useSelector(selectLastLoadedPage(transactionType));
  const numberOfPages = useSelector(selectNbPages(transactionType));
  const currentPages = useSelector(selectLoadedPages(transactionType));
  const itemsCount = useSelector(selectCount(transactionType));
  const items = useSelector(selectItems(transactionType));

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(lastLoadedPage);

  const isSinglePage = useMemo(
    () => itemsCount !== undefined && numberOfPages === 0,
    [itemsCount, numberOfPages],
  );
  const isPaginationFinished = useMemo(
    () => numberOfPages && lastLoadedPage === numberOfPages,
    [lastLoadedPage, numberOfPages],
  );

  const isFetchingNewPage = useMemo(() => (
    page > 0
      && !currentPages.includes(page)
      && !currentPages.includes(numberOfPages)
  ), [
    page,
    currentPages,
    numberOfPages,
  ]);

  const getPage = useCallback((page) => {
    setLoading(true);
    dispatch(getElementsFromServer(page)).finally(() => {
      setLoading(false);
    });
  }, [
    dispatch,
    getElementsFromServer,
  ]);

  const onObserve = (entries) => {
    const target = entries[0];

    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  };

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
  };

  const observer = useRef(new IntersectionObserver(onObserve, options));

  useEffect(() => {
    const loaderCurrent = loader?.current;
    const observerCurrent = observer?.current;

    if (loaderCurrent) {
      observerCurrent.observe(loaderCurrent);
    }

    return () => {
      if (loaderCurrent) {
        observerCurrent.unobserve(loaderCurrent);
      }
    };
  }, []);

  useEffect(() => {
    if (!isFetchingNewPage && isCurrentTab && page <= 1) {
      getPage(1);
    }
    if (isFetchingNewPage && isCurrentTab && page > 0) {
      getPage(page);
    }
  }, [
    isFetchingNewPage,
    getPage,
    isCurrentTab,
    page,
  ]);

  useEffect(() => {
    if (isPaginationFinished || isSinglePage) {
      observer.current.unobserve(loader.current);
      loader.current.className = 'd-none';
    }
  }, [isPaginationFinished, isSinglePage]);

  return {
    loading,
    loader,
    numberOfPages,
    items,
  };
};

export default useDynamicPagination;
