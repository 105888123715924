import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CURRENCY_EUR } from 'helpers/constants';
import Button from 'components/Button';
import useCurrencyHelpers from 'helpers/useCurrencyHelpers';
import IconCoin from 'assets/svg/IconCoin';
import classes from 'assets/style/checkoutCounerOfferModal.module.scss';
import CheckoutPromocodesContext from 'pages/checkout/CheckoutPromocodesContext';

const CheckoutCounterOfferModal = ({
  visible,
  transactionTemplate: { currency = CURRENCY_EUR } = {},
  onClose,
}) => {
  const { t } = useTranslation('checkoutCounterOfferModal');
  const currencyHelper = useCurrencyHelpers();
  const { symbol, before } = currencyHelper(currency);

  const { applyNewOfferPrice } = useContext(CheckoutPromocodesContext);

  const sendCounterOffer = (values, formikBag) => {
    applyNewOfferPrice(values, formikBag).then(onClose).catch(() => { formikBag.setFieldError('subTotal', t('incorrectValue')); formikBag.setSubmitting(false); });
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        subTotal: yup
          .number()
          .positive(t('incorrectValue'))
          .typeError(t('incorrectValue'))
          .required(t('emptySubTotal')),
      })}
      onSubmit={sendCounterOffer}
      initialValues={{ subTotal: '' }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onClose();
        };

        return (
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={visible}
            onHide={resetAndHide}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header
                className={classes.counterOfferModal__header}
                closeButton
              />
              <Modal.Body>
                <div className={classes.counterOfferModal__top}>
                  <div>
                    <IconCoin className={classes.counterOfferModal__icon} />
                  </div>
                  <h4 className={classes.counterOfferModal__title}>
                    {t('title')}
                  </h4>
                  <p className={classes.counterOfferModal__subTitle}>
                    {t('subTitle')}
                  </p>
                </div>
                <div className={classes.counterOfferModal__bottom}>
                  <Form.Label htmlFor="subTotal">{t('label')}</Form.Label>
                  <InputGroup className={classes.counterOfferModal__inputGroup}>
                    {before && (
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon2">
                          {symbol}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    )}

                    <FormControl
                      id="subTotal"
                      type="number"
                      placeholder={t('placeholder')}
                      name="subTotal"
                      value={values.subTotal}
                      onChange={handleChange}
                      onBlur={(e) => {
                        if (!Number.isNaN(values.subTotal)) {
                          setFieldValue(
                            'subTotal',
                            Math.trunc(values.subTotal * 100) / 100,
                          );
                        }
                        handleBlur(e);
                      }}
                      isInvalid={touched.subTotal && !!errors.subTotal}
                    />
                    {!before && (
                      <InputGroup.Append>
                        <InputGroup.Text
                          className={
                            classes['counterOfferModal__currency--append']
                          }
                          id="basic-addon2"
                        >
                          {symbol}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.subTotal}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <Button type="submit" isLoading={isSubmitting}>
                    {t('submit')}
                  </Button>
                </div>
              </Modal.Body>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CheckoutCounterOfferModal;
