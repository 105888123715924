import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import IconNum from 'assets/svg/IconNum';
import classes from 'assets/style/addNote.module.scss';
import classNames from 'classnames';
import { CHECKOUT_STEPS } from 'helpers/constants';
import Button from 'components/Button';
import MessageForm from 'components/MessageForm';

const AddNoteComponent = ({
  setActiveKey, activeKey, message, setMessage,
}) => {
  const { t } = useTranslation('checkout');

  const editNote = useCallback(() => {
    setActiveKey(CHECKOUT_STEPS.message);
  }, [setActiveKey]);

  const showEditButton = activeKey === CHECKOUT_STEPS.payment || message;

  return (
    <Card id={classes.addNote}>
      <Card.Header className={classes.addNote__cardHeader}>
        <Accordion.Toggle
          eventKey={CHECKOUT_STEPS.message}
          className={classNames(classes.addNote__toggler, {
            [classes['addNote__toggler--disabled']]: ![
              CHECKOUT_STEPS.message,
              CHECKOUT_STEPS.payment,
            ].includes(activeKey),
          })}
        >
          <div className={classes.addNote__togglerContent}>
            <IconNum className={classes.addNote__togglerContentIcon} step={3} />
            <p className="m-0 text-left">
              <span
                className={classNames('checkout__title', classes.addNote__title)}
              >
                {t('message.title')}
              </span>
              {showEditButton && (
                <Button
                  type="button"
                  variant="link"
                  className={classes.addNote__togglerEditBtn}
                  onClick={editNote}
                >
                  {t('deliveryMethod.desktopChange')}
                </Button>
              )}
            </p>
          </div>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={CHECKOUT_STEPS.message}>
        <Card.Body className={classes.addNote__cardBody}>
          <MessageForm
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            setMessage={setMessage}
          />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

AddNoteComponent.propTypes = {
  setActiveKey: PropTypes.func.isRequired,
  activeKey: PropTypes.string.isRequired,
  message: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
};

AddNoteComponent.defaultProps = {
  message: undefined,
};

export default AddNoteComponent;
