import { createReducer } from '@reduxjs/toolkit';
import { success, shouldResetTransactionsState } from 'logic/reducers/helpers';
import {
  GET_OWN_ACTIVE_TRANSACTIONS,
  GET_OWN_ENDED_TRANSACTIONS,
  GET_TRANSACTION,
  POST_PROCESS_PREAUTH,
  ACCEPT_TRANSACTION,
  REJECT_TRANSACTION,
  SHIP_TRANSACTION,
  COMPLETE_TRANSACTION,
  REQUEST_CANCEL_TRANSACTION,
  REJECT_CANCEL_TRANSACTION,
  CANCEL_TRANSACTION,
  EVALUATE_TRANSACTION,
  FETCH_TRANSACTION_VERIFICATION_CODE,
  OPEN_ISSUE,
  GENERATE_SHIPPING_LABEL,
  CREATE_DISPUTE,
} from 'logic/actions/transactionsActions';

const initialState = {
  activeTransactions: {
    items: {},
    nbPages: 0,
    lastLoadedPage: 0,
    count: undefined,
  },
  endedTransactions: {
    items: {},
    nbPages: 0,
    lastLoadedPage: 0,
    count: undefined,
  },
  transactions: [],
  discount: {},
};

const handleGetOwnActiveTransactions = (
  state,
  {
    payload: {
      data: {
        page, nbPages = 0, items = [], count = 0,
      } = {},
    } = {},
  },
) => {
  const { activeTransactions } = state;
  if (page) {
    activeTransactions.items[page] = items;
    activeTransactions.lastLoadedPage = page;
  }
  activeTransactions.nbPages = nbPages;
  activeTransactions.count = count;
};

const handleGetOwnEndedTransactions = (
  state,
  {
    payload: {
      data: {
        page, nbPages = 0, items = [], count = 0,
      } = {},
    } = {},
  },
) => {
  const { endedTransactions } = state;
  if (page) {
    endedTransactions.items[page] = items;
    endedTransactions.lastLoadedPage = page;
  }
  endedTransactions.nbPages = nbPages;
  endedTransactions.count = count;
};

const handleGetTransaction = (
  state,
  {
    payload: { data = {} } = {},
    meta: { previousAction: { transactionId = 0 } = {} } = {},
  },
) => {
  const { transactions } = state;
  if (transactionId) {
    transactions[transactionId] = {
      ...transactions[transactionId],
      ...data,
    };
  }
};

export default createReducer(
  initialState,
  {
    [success(GET_OWN_ACTIVE_TRANSACTIONS)]: handleGetOwnActiveTransactions,
    [success(GET_OWN_ENDED_TRANSACTIONS)]: handleGetOwnEndedTransactions,
    [success(GET_TRANSACTION)]: handleGetTransaction,
    [success(POST_PROCESS_PREAUTH)]: handleGetTransaction,
    [success(ACCEPT_TRANSACTION)]: handleGetTransaction,
    [success(REJECT_TRANSACTION)]: handleGetTransaction,
    [success(SHIP_TRANSACTION)]: handleGetTransaction,
    [success(COMPLETE_TRANSACTION)]: handleGetTransaction,
    [success(REQUEST_CANCEL_TRANSACTION)]: handleGetTransaction,
    [success(REJECT_CANCEL_TRANSACTION)]: handleGetTransaction,
    [success(CANCEL_TRANSACTION)]: handleGetTransaction,
    [success(EVALUATE_TRANSACTION)]: handleGetTransaction,
    [success(FETCH_TRANSACTION_VERIFICATION_CODE)]: handleGetTransaction,
    [success(OPEN_ISSUE)]: handleGetTransaction,
    [success(GENERATE_SHIPPING_LABEL)]: handleGetTransaction,
    [success(CREATE_DISPUTE)]: handleGetTransaction,
  },
  [
    {
      matcher: (action) => shouldResetTransactionsState(action),
      reducer: (state) => ({
        ...state,
        discount: state.discount,
        activeTransactions: initialState.activeTransactions,
        endedTransactions: initialState.endedTransactions,
      }),
    },
  ],
);
