import React, { forwardRef } from 'react';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { useCookies } from 'react-cookie';
import { debug } from 'helpers/env';
import { LANG_COOKIE_NAME, LANG_COOKIE_OPT } from 'helpers/constants';
import gearIcon from 'assets/images/gear_icon.svg';
import frFlag from 'assets/images/fr.svg';
import enFlag from 'assets/images/gb.svg';
import esFlag from 'assets/images/es.svg';
import deFlag from 'assets/images/de.svg';
import upArrow from 'assets/images/up-arrow.svg';
import downArrow from 'assets/images/down-arrow.svg';
import 'assets/style/language-selector.scss';

// Fetched flags from https://github.com/ekwonye-richard/react-flags-select/tree/master/flags

const COUNTRIES = [
  {
    lang: 'en',
    icon: enFlag,
  },
  {
    lang: 'fr',
    icon: frFlag,
  },
  {
    lang: 'de',
    icon: deFlag,
  },
];

const DEV_COUNTRIES = [
  ...COUNTRIES,
  {
    lang: 'dev',
    icon: gearIcon,
  },
  {
    lang: 'es',
    icon: esFlag,
  },
];

const CustomToggle = forwardRef(
  ({
    children, onClick, onKeyPress, up,
  }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
      className="d-flex align-items-center"
    >
      {children}
      {up ? (
        <img
          className="language-selector__arrow"
          src={upArrow}
          alt="expand language selection"
        />
      ) : (
        <img
          className="language-selector__arrow"
          src={downArrow}
          alt="collapse language selection"
        />
      )}
    </div>
  ),
);

const LanguageSelector = ({
  up = false,
  small = false,
  align = 'left',
  selectedLang,
  onSelect,
  className,
}) => {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies([LANG_COOKIE_NAME]);

  const cookieLang = cookies[LANG_COOKIE_NAME];
  const browserLang = cookieLang || i18n.language?.replace(/^([a-z]+)-?.*/, '$1');
  const currentLang = selectedLang || browserLang || 'en';

  const countries = debug ? DEV_COUNTRIES : COUNTRIES;

  const selected = find(countries, ({ lang }) => lang === currentLang) || COUNTRIES[0];

  const setBrowserLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCookie(LANG_COOKIE_NAME, lang, LANG_COOKIE_OPT);
  };

  const onSelectLanguage = (lang) => (onSelect ? onSelect(lang) : setBrowserLanguage(lang));

  return (
    <Dropdown
      className={`language-selector ${small && 'small'} ${className}`}
      drop={up ? 'up' : 'down'}
    >
      <Dropdown.Toggle id="language-selector-toggle" as={CustomToggle} up={up}>
        <img src={selected.icon} alt="" className="language-selector__flag" />
        <span className="language-selector__label">
          {capitalize(selected.lang)}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu align={align}>
        {countries.map(({ lang, icon }) => (
          <Dropdown.Item
            key={lang}
            className="d-flex align-items-center"
            onClick={() => {
              onSelectLanguage(lang);
            }}
            onKeyPress={() => {
              onSelectLanguage(lang);
            }}
          >
            <img src={icon} alt="" className="language-selector__flag" />
            <span className="language-selector__label">
              {capitalize(lang)}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
