import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import AddressField from 'components/AddressField';
import ManualAddressFormCheckout from 'components/ManualAddressFormCheckout';
import classes from 'assets/style/checkoutAddressForm.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountries,
  selectAllCountries,
} from 'logic/actions/countriesActions';
import { trimObjectValues } from 'helpers/stringHelpers';
import validateGoogleAddress from 'helpers/validateGoogleAddress';
import { AVAILABLE_COUNTRIES_ISO_FOR_PARCEL } from 'helpers/constants';
import countriesMapper from 'helpers/countriesMapper';

const CheckoutAddressForm = ({
  deliveryAddress = {},
  onSubmit,
  isPhoneFieldVisible,
  allowedCountries,
  validateCarrier,
  translation = 'checkoutAddressForm',
  loading = false,
  needsPickupPoint = false,
}) => {
  const { t } = useTranslation(translation);

  const dispatch = useDispatch();

  const [isValidateGoogleAddress, setIsValidateGoogleAddress] = useState(false);

  const allCountries = useSelector(selectAllCountries);

  const { isoCodes } = useMemo(() => countriesMapper(allCountries), [
    allCountries,
  ]);

  const availableCountries = useMemo(
    () => allowedCountries || AVAILABLE_COUNTRIES_ISO_FOR_PARCEL,
    [allowedCountries],
  );

  const { validateCountry, validateFields, validateEmptyCountry } = useMemo(
    () => validateGoogleAddress(availableCountries, isoCodes),
    [availableCountries, isoCodes],
  );

  useEffect(() => {
    if (!allCountries.length) {
      dispatch(getCountries());
    }
  }, [
    allCountries.length,
    dispatch,
  ]);

  const validationSchema = yup.object({
    recipientName: yup
      .string()
      .trim()
      .required(t('emptyRecipientName')),
    phoneNumber: isPhoneFieldVisible
      ? yup.string().required(t('emptyPhoneNumber'))
      : yup.string(),
    address: yup.mixed().when('isManualAddress', {
      is: false,
      then: yup
        .mixed()
        .test({
          name: 'address',
          message: t('requiredField'),
          test: validateEmptyCountry,
          exclusive: false,
        })
        .test({
          name: 'address',
          message: t('invalidCountry'),
          test: validateCountry,
          exclusive: false,
        })
        .test({
          name: 'address',
          message: t('invalidAddress'),
          test: validateFields,
          exclusive: false,
        }),
    }),
    isManualAddress: yup.boolean(),
    manualAddress: yup.mixed().when('isManualAddress', {
      is: true,
      then: yup.object({
        addressLine1: yup
          .string()
          .trim()
          .min(5, t('minError'))
          .max(95, t('maxError'))
          .required(t('requiredField')),
        addressLine2: yup
          .string()
          .min(5, t('minError'))
          .max(95, t('maxError')),
        city: yup
          .string()
          .trim()
          .min(2, t('minError'))
          .max(35, t('maxError'))
          .required(t('requiredField')),
        postcode: yup
          .string()
          .trim()
          .min(2, t('minError'))
          .max(10, t('maxError'))
          .required(t('requiredField')),
        countryIso2: yup.string().required(t('requiredField')),
        country: yup.string().required(t('requiredField')),
      }),
    }),
  });

  const handleSubmit = useCallback(
    ({
      isManualAddress,
      manualAddress,
      recipientName,
      address,
      phoneNumber,
    }) => {
      if (isManualAddress) {
        const {
          addressLine1,
          addressLine2,
          city,
          postcode,
          country,
          countryIso2,
        } = trimObjectValues(manualAddress);

        onSubmit({
          addressLine1,
          addressLine2,
          city,
          postcode,
          recipientName: recipientName.trim(),
          phoneNumber,
          countryIso2,
          country,
          isManualAddress,
          formattedAddress: `${addressLine1},${addressLine2
            ? ` ${addressLine2},` : ''} ${postcode} ${city}, ${country}`,
        });
      } else {
        onSubmit({
          ...address,
          recipientName: recipientName.trim(),
          phoneNumber,
          isManualAddress,
        });
      }
    },
    [onSubmit],
  );

  const initialValues = {
    recipientName: deliveryAddress?.recipientName || '',
    phoneNumber: deliveryAddress?.phoneNumber || '',
    address: { ...deliveryAddress },
    isManualAddress: deliveryAddress?.isManualAddress || false,
    manualAddress: {
      addressLine1: deliveryAddress?.addressLine1 || '',
      addressLine2: deliveryAddress?.addressLine2 || '',
      city: deliveryAddress?.city || '',
      postcode: deliveryAddress?.postcode || '',
      country: deliveryAddress?.country || '',
      countryIso2: deliveryAddress?.countryIso2 || '',
    },
  };

  return (
    <div className={classes.checkoutAddressForm}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        validate={validateCarrier}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          errors,
        }) => {
          const { recipientName, isManualAddress, phoneNumber } = values;

          const isManualFormVisible = isManualAddress;

          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <Form.Group
                  controlId="recipientName"
                  className={classes.formGroup}
                >
                  <Form.Label>{t('recipientName')}</Form.Label>
                  <Form.Control
                    name="recipientName"
                    className={classes.input}
                    value={recipientName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.recipientName && !!errors.recipientName}
                    placeholder={t('recipientNamePlaceholder')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.recipientName}
                  </Form.Control.Feedback>
                </Form.Group>
                {isPhoneFieldVisible && (
                  <Form.Group
                    controlId="phoneNumber"
                    className={classes.formGroup}
                  >
                    <Form.Label>{t('phone')}</Form.Label>
                    <Form.Control
                      name="phoneNumber"
                      className={classes.input}
                      value={phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.recipientName && !!errors.phoneNumber}
                      placeholder={t('phonePlaceholder')}
                    />
                    <div className="invalid-feedback d-block">
                      {errors.phoneNumber}
                    </div>
                  </Form.Group>
                )}
                <Form.Group
                  controlId="address"
                  className={classes.formGroup}
                >
                  {window?.google?.maps && (
                    <Form.Label>{t('address')}</Form.Label>
                  )}
                  <Form.Control
                    className={classes.input}
                    as={AddressField}
                    type="text"
                    name="address"
                    onChange={(value) => {
                      setFieldValue('address', value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.address}
                    placeholder={t('addressPlaceholder')}
                    formattedAddress={deliveryAddress?.formattedAddress}
                    isValidateGoogleAddress={isValidateGoogleAddress}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className={classes.toggler}>
                  <input
                    className={classes.radio}
                    type="checkbox"
                    id="manual-address"
                    name="isManualAddress"
                    checked={values.isManualAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    htmlFor="manual-address"
                    className={classes.label}
                  >
                    {t('manualAddressToggle')}
                  </label>
                </div>
                <div className={classes.manualAddressFormContainer}>
                  {isManualFormVisible && (
                    <ManualAddressFormCheckout
                      isValidateGoogleAddress={isValidateGoogleAddress}
                      setIsValidateGoogleAddress={setIsValidateGoogleAddress}
                      allowedCountries={allowedCountries}
                    />
                  )}
                </div>

                <Button
                  type="submit"
                  className={classes.button}
                  isLoading={loading}
                >
                  {needsPickupPoint ? t('selectPickupPoint') : t('submit')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CheckoutAddressForm;
