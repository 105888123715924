import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';

const IssueMessage = ({ message, back, next }) => {
  const { t } = useTranslation('disputeResolution');

  const [value, setValue] = useState(message);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>{t('issueMessage.title')}</p>
      <div className={classes.form}>
        <Form.Label className={classes.formLabel} htmlFor="textareaMessage">
          {t('issueMessage.textareaTitle')}
        </Form.Label>
        <Form.Control
          id="textareaMessage"
          className={classes.textarea}
          as="textarea"
          placeholder={t('issueMessage.textareaPlaceholder')}
          onChange={({ target: { value } = {} }) => {
            setValue(value);
          }}
          value={value}
        />
        <label className={classes.formBottomLabel} htmlFor="textareaTitle">
          {t('issueMessage.textareaBottomLabel', {
            count: value?.length || 0,
          })}
        </label>
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(value);
        }}
        disabled={!value}
      >
        {t('issueMessage.next')}
      </Button>
    </Row>
  );
};

export default IssueMessage;
