import React, { useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import {
  CHECKOUT_STEPS,
  CURRENCY_EUR,
  DELIVERY_METHOD_REMOTE,
  PICKUP_PROVIDERS,
} from 'helpers/constants';
import IconInfo from 'assets/svg/IconInfo';
import classNames from 'classnames';
import shippingProvidersConfig from 'helpers/shippingProvidersConfig';
import SpanWithColon from 'helpers/SpanWithColon';
import classes from 'assets/style/checkoutRecapDesktop.module.scss';
import AddPromocode from 'components/AddPromocode';

const CheckoutRecapDesktop = ({
  transactionTemplate: {
    title,
    description,
    subTotal = 0,
    currency = CURRENCY_EUR,
    shippingFees = 0,
    client,
  } = {},
  transaction: {
    subTotal: newPrice = 0,
    buyerFees = 0,
    buyerTotal = 0,
    buyerDiscount = 0,
    deliveryAddress: postProcessDeliveryAddress,
    shippingFees: shippingFeesTransaction,
  } = {},
  deliveryAddress: checkoutDeliveryAddress,
  deliveryMethod,
  showCounterOfferModal,
  allowPriceChange,
  activeKey,
  isPostProcess,
  currentProvider,
}) => {
  const { t } = useTranslation('checkoutRecap');
  const hasCounterOffer = newPrice !== subTotal;

  const needsPickupPoint = useMemo(() => (PICKUP_PROVIDERS.includes(currentProvider?.name)), [currentProvider]);

  const deliveryAddress = isPostProcess
    ? postProcessDeliveryAddress
    : checkoutDeliveryAddress;

  const showDeliveryAddress = deliveryAddress
    && (isPostProcess
      || (activeKey !== CHECKOUT_STEPS.delivery
        && deliveryMethod === DELIVERY_METHOD_REMOTE));

  const calculatedShippingFees = useMemo(() => {
    // user on checkout for parcel with provider
    if (currentProvider && currentProvider.shippingFees && !isPostProcess) {
      return currentProvider.shippingFees;
    }

    // user on checkout without provider
    if (!currentProvider && shippingFees && !isPostProcess) {
      return shippingFees;
    }

    // user on post process with provider
    if (isPostProcess && shippingFeesTransaction) {
      return shippingFeesTransaction;
    }

    return 0;
  }, [currentProvider, isPostProcess, shippingFeesTransaction, shippingFees]);

  const calculatedTotal = useMemo(() => {
    // user on checkout with pre auth error
    if (!isPostProcess && shippingFeesTransaction && !deliveryAddress) {
      return buyerTotal;
    }

    if (!isPostProcess && shippingFeesTransaction && deliveryAddress) {
      return buyerTotal + calculatedShippingFees;
    }

    // user on checkout
    if (!isPostProcess && !shippingFeesTransaction) {
      return buyerTotal + calculatedShippingFees;
    }

    // user on post-process
    if (isPostProcess) {
      return buyerTotal;
    }

    return 0;
  }, [
    isPostProcess,
    shippingFeesTransaction,
    buyerTotal,
    calculatedShippingFees,
    deliveryAddress,
  ]);

  const displayedBuyerTotal = useMemo(
    () => (showDeliveryAddress ? calculatedTotal : buyerTotal),
    [showDeliveryAddress, buyerTotal, calculatedTotal],
  );

  return (
    <div className="checkout-detail">
      <div className="checkout-detail-row">
        <div className="checkout-detail-image">
          <div className="checkout-detail-image__inner" />
        </div>
        <div>
          <h5 className="checkout__title checkout__title--small">{title}</h5>
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Popover id="popover-description">
                <Popover.Content>{description}</Popover.Content>
              </Popover>
            )}
          >
            <p className="m-0 checkout-detail__desc">{description}</p>
          </OverlayTrigger>
        </div>
      </div>
      <div className="border-top py-4">
        <div className="d-flex justify-content-between mb-0">
          <p className="m-0 p-0">{t('subTotal')}</p>
          <p className="mb-0 text-right">
            {hasCounterOffer ? (
              <p className="text-primary mb-0">
                <del className="text-dark mr-2">
                  <FormattedNumber
                    value={subTotal / 100}
                    style="currency"
                    currency={currency}
                  />
                </del>
                <FormattedNumber
                  value={newPrice / 100}
                  style="currency"
                  currency={currency}
                />
              </p>
            ) : (
              <FormattedNumber
                value={subTotal / 100}
                style="currency"
                currency={currency}
              />
            )}
          </p>
        </div>
        {allowPriceChange && (
          <div
            onClick={showCounterOfferModal}
            onKeyPress={showCounterOfferModal}
            role="button"
            tabIndex={0}
          >
            <p className="checkout-detail__other clickable text-primary text-small mt-2">
              {t('counterPrice')}
            </p>
          </div>
        )}
        <div className="d-flex justify-content-between mt-2">
          <p className="m-0">{t('fees')}</p>
          <OverlayTrigger
            placement="right"
            overlay={(
              <Popover
                content
                className="checkout-recap__tooltipIcon shadow border-0 ml-2"
              >
                {buyerFees ? t('feesTooltip') : t('freeFeesTooltip', { client })}
              </Popover>
            )}
          >
            <IconInfo className="ml-2 mr-auto" width="14px" />
          </OverlayTrigger>
          <p className="m-0 text-right">
            {buyerFees ? (
              <FormattedNumber
                value={buyerFees / 100}
                style="currency"
                currency={currency}
              />
            ) : (
              t('free')
            )}

          </p>
        </div>
        {!!Number(buyerDiscount) && isPostProcess && (
          <div className="d-flex justify-content-between mt-2">
            <p className="m-0">{t('discount')}</p>
            <p className="m-0 text-right">
              <FormattedNumber
                value={-buyerDiscount / 100}
                style="currency"
                currency={currency}
              />
            </p>
          </div>
        )}
      </div>

      {showDeliveryAddress && (
        <div
          className={classNames(
            'border-top',
            classes.checkoutRecapDesktop__deliveryInfo,
          )}
        >
          <div className={classes.checkoutRecapDesktop__deliveryFees}>
            <span>{t('deliveryFees')}</span>
            <FormattedNumber
              value={calculatedShippingFees / 100}
              style="currency"
              currency={currency}
            />
          </div>
          <div className={classes.checkoutRecapDesktop__deliveryContact}>
            {currentProvider && (
              <div className="text-overflow-any">
                <SpanWithColon className="delivery-method__param">
                  {t('carrier')}
                </SpanWithColon>
                <span className="delivery-method__value">
                  {shippingProvidersConfig[currentProvider.name]?.name}
                </span>
              </div>
            )}
            <div className="text-overflow-any">
              <SpanWithColon className="delivery-method__param">
                {t('name')}
              </SpanWithColon>
              <span className="delivery-method__value">
                {deliveryAddress?.recipientName}
              </span>
            </div>
            {deliveryAddress?.phoneNumber && (
              <div className="text-overflow-any">
                <SpanWithColon className="delivery-method__param">
                  {t('phone')}
                </SpanWithColon>
                <span className="delivery-method__value">{deliveryAddress?.phoneNumber}</span>
              </div>
            )}
            <div className="text-overflow-any">
              <SpanWithColon className="delivery-method__param">
                {t('deliveryAddress')}
              </SpanWithColon>
              <span className="delivery-method__value">
                {deliveryAddress?.formattedAddress}
              </span>
            </div>
            {needsPickupPoint && deliveryAddress?.pickupPointAddress && (
              <div className="text-overflow-any">
                <SpanWithColon className="delivery-method__param">
                  {t('pickupPointAddress')}
                </SpanWithColon>
                <span className="delivery-method__value">
                  {deliveryAddress?.pickupPointAddress}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      {!isPostProcess && (
        <AddPromocode currency={currency} discount={buyerDiscount} />
      )}

      <div className="border-top py-4">
        <div className="d-flex justify-content-between mb-0">
          <p className="m-0">{t('total')}</p>
          <p className="m-0 text-right checkout__title-total">
            <FormattedNumber
              value={displayedBuyerTotal / 100}
              style="currency"
              currency={currency}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutRecapDesktop;
