import React from 'react';
import sortBy from 'lodash/sortBy';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { isValidIBAN } from 'ibantools';
import 'assets/style/modal-payment.scss';
import ErrorMessagesBlock from 'components/ErrorMessagesBlock';

const BankAccountForm = ({ onSubmit, countries = [], initialValues = {} }) => {
  const { t } = useTranslation(['bankAccountForm', '_countries']);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        iban: yup
          .string()
          .required(t('emptyIban'))
          .test('testIban', t('invalidIban'), (iban = '') => isValidIBAN(iban.replace(/\s/g, ''))),
        addressLine1: yup.string().required(t('emptyAddressLine1')),
        addressLine2: yup.string(),
        postcode: yup.string().required(t('emptyPostcode')),
        city: yup.string().required(t('emptyCity')),
        region: yup.string(),
        country: yup.string().required(t('emptyCountry')),
      })}
      onSubmit={(
        {
          iban = '',
          addressLine1,
          addressLine2 = null,
          postcode,
          city,
          region = null,
          country,
        },
        formikBag,
      ) => {
        onSubmit(
          {
            iban: iban.replace(/\s/g, ''),
            address: {
              addressLine1,
              addressLine2,
              postcode,
              city,
              region,
              country,
            },
          },
          formikBag,
        );
      }}
      initialValues={{
        iban: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        city: '',
        region: '',
        country: '',
        ...initialValues,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        dirty,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <span className="modal-add-payment__text-holder-address">
            {t('iban')}
          </span>
          <div className="bank-form__row">
            <div className="form-group-top">
              <Form.Group controlId="iban">
                <Form.Control
                  type="text"
                  name="iban"
                  value={values.iban}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      /([^ ]{4}) *(?=[^ ])/g,
                      '$1 ',
                    );
                    handleChange(e);
                  }}
                  placeholder="FR76 XXXX XXXX XXXX XXXX"
                  onBlur={handleBlur}
                  isInvalid={touched.iban && !!errors.iban}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.iban}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <span className="modal-add-payment__text-holder-address">
            {t('addressLine1')}
          </span>
          <div className="groupedForm">
            <Row className="bank-form__row">
              <Col className="form-group-border-top">
                <Form.Group controlId="addressLine1">
                  <Form.Control
                    type="text"
                    name="addressLine1"
                    value={values.addressLine1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.addressLine1 && !!errors.addressLine1}
                    placeholder={t('addressLine1')}
                  />
                  <div className="line line-bottom" />
                </Form.Group>
              </Col>
            </Row>
            <Row className="groupedForm-inline">
              <Col className="form-group-border-center-left">
                <Form.Group controlId="postcode">
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={values.postcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.postcode && !!errors.postcode}
                    placeholder={t('postcode')}
                  />
                  <div className="line line-top" />
                  <div className="line line-bottom" />
                  <div className="line-vertical line-right" />
                </Form.Group>
              </Col>
              <Col className="form-group-border-center-right">
                <Form.Group controlId="city">
                  <Form.Control
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.city && !!errors.city}
                    placeholder={t('city')}
                  />
                  <div className="line line-top" />
                  <div className="line line-bottom" />
                  <div className="line-vertical line-left" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="form-group-border-bottom">
                <Form.Group controlId="country">
                  <Form.Control
                    as="select"
                    custom
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.country && !!errors.country}
                  >
                    <option disabled value="">
                      {t('country')}
                    </option>
                    {sortBy(countries, ({ slug }) => t(`_countries:country.${slug}`)).map(({ slug }) => (
                      <option key={slug} value={slug} className="color-input">
                        {t(`_countries:country.${slug}`)}
                      </option>
                    ))}
                  </Form.Control>
                  <div className="line line-top" />
                </Form.Group>
              </Col>
            </Row>
          </div>
          {!!Object.keys(errors).length && (
            <ErrorMessagesBlock skipErrors={['iban']} />
          )}
          <Row>
            <Col className="form-group-border-bottom">
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={!dirty}
                className="modal-add-payment__button-submit"
              >
                {t('submit')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default BankAccountForm;
