import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Header from 'components/Header';
import 'assets/style/transactions.scss';
import 'assets/style/footer.scss';
import useWindowSize from 'helpers/useWindowSize';
import Footer from 'components/Footer';
import localStorage from 'helpers/localStorage';
import { HOMEPAGE_TAB_KEY, HOMEPAGE_TABS } from 'helpers/constants';
import FinishedTransactionsTab from 'components/FinishedTransactionsTab';
import ActiveTransactionsTab from 'components/ActiveTransactionsTab';
import PaymentLinksTab from 'components/PaymentLinksTab';
import { useSelector } from 'react-redux';
import { selectCurrentTransactionsCount } from 'logic/actions/transactionsActions';
import TransactionsNavbarMobile from 'components/TransactionsNavbarMobile';

const Transactions = ({ location: { state } = {} }) => {
  const { t } = useTranslation('transactions');
  const [key, setKey] = useState(
    localStorage.get(HOMEPAGE_TAB_KEY) || HOMEPAGE_TABS.IN_PROGRESS,
  );

  const tabKeyAfterRedirect = state?.[HOMEPAGE_TAB_KEY];

  const { isNarrow, isMobile } = useWindowSize();

  const currentTransactionsCount = useSelector(selectCurrentTransactionsCount);

  const changeTab = useCallback((tabName) => {
    setKey(tabName);
    localStorage.set(HOMEPAGE_TAB_KEY, tabName);
  }, [setKey]);

  useEffect(() => {
    if (tabKeyAfterRedirect) {
      setKey(tabKeyAfterRedirect);
    }
  }, [tabKeyAfterRedirect]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Header />
      <div className="w-100 transactions">
        {isMobile && (
          <TransactionsNavbarMobile onChangeTab={changeTab} currentTab={key} />
        )}
        <Tabs
          activeKey={key}
          onSelect={changeTab}
          className="transactions-tab border-0"
          mountOnEnter
        >
          <Tab
            tabClassName="text-cta"
            eventKey={HOMEPAGE_TABS.IN_PROGRESS}
            title={t('active', {
              count:
                currentTransactionsCount >= 0
                  ? `(${currentTransactionsCount})`
                  : '',
            })}
          >
            <ActiveTransactionsTab isCurrentTab={key === HOMEPAGE_TABS.IN_PROGRESS} />
          </Tab>
          <Tab
            tabClassName="text-cta"
            eventKey={HOMEPAGE_TABS.FINISHED}
            title={t('ended')}
          >
            <FinishedTransactionsTab isCurrentTab={key === HOMEPAGE_TABS.FINISHED} />
          </Tab>
          <Tab
            tabClassName="text-cta"
            eventKey={HOMEPAGE_TABS.LINKS}
            title={isNarrow ? t('links.mobile') : t('links.desktop')}
          >
            <PaymentLinksTab isCurrentTab={key === HOMEPAGE_TABS.LINKS} template={state?.template} />
          </Tab>
        </Tabs>
        <Footer className="webapp__footer" />
      </div>
    </>
  );
};

export default Transactions;
