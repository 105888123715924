import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/takePictures/previewItem.module.scss';
import startVideoIcon from 'assets/images/startVideoIcon.svg';
import MediaContext from 'components/takePictures/mediaContext';
import { getBase64FromBlob } from 'helpers/decode';
import { isSafari } from 'react-device-detect';

const PreviewItem = ({ media, disabled }) => {
  const {
    setRecording,
    setPhotoPreview,
    showDeleteBtn,
    setCurrentPreviewElementId,
  } = useContext(MediaContext);

  const {
    file, type, preview, id,
  } = media || {};

  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  useEffect(() => {
    if (type === 'image' && file) {
      getBase64FromBlob(file).then(setImagePreviewUrl);
    }
  }, [
    type,
    file,
  ]);

  const image = imagePreviewUrl ? (
    <img src={imagePreviewUrl} alt="photoPreview" />
  ) : (
    '+'
  );

  const video = file ? (
    <div className={classes.previewItem__videoPreview}>
      <img src={startVideoIcon} alt="videoIcon" />
      <img
        src={preview}
        alt="preview"
        className={classes.previewItem__preview}
      />
    </div>
  ) : (
    '+'
  );

  const handleClick = useCallback(() => {
    if (type === 'image') {
      setPhotoPreview(imagePreviewUrl);
    } else {
      if (isSafari) {
        getBase64FromBlob(file).then(setRecording);
      }
      if (!isSafari) {
        setRecording(window.URL.createObjectURL(file));
      }
    }
    setCurrentPreviewElementId(id);
    showDeleteBtn();
  }, [
    type,
    file,
    id,
    imagePreviewUrl,
    setCurrentPreviewElementId,
    setPhotoPreview,
    setRecording,
    showDeleteBtn,
  ]);

  if (!file) {
    return (
      <button
        type="button"
        className={classes.previewItem}
        disabled={!file || disabled}
      >
        +
      </button>
    );
  }

  return (
    <button
      type="button"
      className={classes.previewItem}
      onClick={handleClick}
      disabled={!file || disabled}
    >
      {type === 'image' ? image : video}
    </button>
  );
};

PreviewItem.propTypes = {
  disabled: PropTypes.bool,
};

PreviewItem.defaultProps = {
  disabled: false,
};

export default PreviewItem;
