import React from 'react';
import iconArrowLeft from 'assets/images/iconmonstr-angel-left-thin.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TransactionGoBackLink = () => {
  const history = useHistory();
  const { t } = useTranslation('transaction');

  return (
    <div className="justify-content-between mb-3 transaction__breadcrumb">
      <div
        onClick={() => history.push('/transactions')}
        onKeyPress={() => history.push('/transactions')}
        role="link"
        tabIndex={0}
      >
        <p className="m-0 d-flex align-items-center">
          <img src={iconArrowLeft} alt="" width="12px" />
          <span className="pl-1 clickable">{t('goBack')}</span>
        </p>
      </div>
    </div>
  );
};

export default TransactionGoBackLink;
