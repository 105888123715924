import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import classes from 'assets/style/addProfilePicture.module.scss';
import { toast } from 'react-toastify';
import ProfilePicture from 'components/ProfilePicture';
import Button from 'components/Button';
import { AVATAR_MAX_WEIGHT, ERRORS_FROM_SERVER } from 'helpers/constants';
import {
  addAvatar,
  getAvatar,
  deleteAvatar,
  avatarUrl,
} from 'logic/actions/meActions';
import { loadSingleMedia } from 'logic/actions/mediaActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const AddProfilePicture = () => {
  const { t } = useTranslation(['profile']);

  const dispatch = useDispatch();

  const avatar = useSelector(avatarUrl);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const selectImage = useCallback((e) => {
    const {
      target: { files: [picture = null] = [] },
    } = e;
    if (picture?.size < AVATAR_MAX_WEIGHT) {
      setLoading(true);
      dispatch(loadSingleMedia(picture))
        .then(({ payload: { data: { id } = {} } = {} }) => dispatch(addAvatar(id)))
        .then(() => dispatch(getAvatar()))
        .catch(({ error }) => {
          if (
            error.response.data.errors === ERRORS_FROM_SERVER.WRONG_IMAGE_TYPE
          ) {
            return toast.error(t('addProfilePicture.fileTypeError'));
          }
          return toast.error(t('addProfilePicture.addError'));
        })
        .finally(() => setLoading(false));
    } else {
      toast.error(t('addProfilePicture.sizeError'));
    }
    e.target.value = '';
  }, [
    dispatch,
    t,
  ]);

  const removeAvatar = useCallback(() => {
    setDeleting(true);
    dispatch(deleteAvatar())
      .catch(() => toast.error(t('addProfilePicture.deleteError')))
      .finally(() => setDeleting(false));
  }, [
    dispatch,
    t,
  ]);

  return (
    <div className={classNames('block-profile', classes.addProfilePicture)}>
      <div>
        <div className={classes.addProfilePicture__info}>
          <p
            className={classNames(
              'block-profile__title',
              classes.addProfilePicture__title,
            )}
          >
            {t('addProfilePicture.title')}
          </p>
          <p className={classes.addProfilePicture__warning}>
            {t('addProfilePicture.warning')}
          </p>
          {avatar && (
            <div className={classes.addProfilePicture__removeContainer}>
              <button
                type="button"
                onClick={removeAvatar}
                className={classes.addProfilePicture__removeButton}
              >
                {t('addProfilePicture.remove')}
              </button>
              {deleting && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className={classes.addProfilePicture__deleteLoader}
                />
              )}
            </div>
          )}
        </div>
        <div>
          <Button
            isLoading={false}
            variant="outline-primary"
            className={classes.addProfilePicture__button}
          >
            <label htmlFor="avatar">{t('addProfilePicture.upload')}</label>
          </Button>
          <input
            type="file"
            id="avatar"
            disabled={loading}
            accept="image/jpeg, image/png"
            onChange={selectImage}
            className={classes.addProfilePicture__input}
          />
        </div>
      </div>
      <ProfilePicture className="profile-pic" loading={loading} />
    </div>
  );
};

export default AddProfilePicture;
