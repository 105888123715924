/* eslint-disable import/no-unused-modules */
export const UPLOAD_KYC_DOCUMENT = 'app/kyc/UPLOAD_KYC_DOCUMENT';
/* eslint-enable import/no-unused-modules */

export const uploadKycDocument = (pages) => (dispatch) => {
  const data = new FormData();

  pages.forEach((page) => {
    data.append('pages[]', page);
  });

  return dispatch({
    type: UPLOAD_KYC_DOCUMENT,
    payload: {
      request: {
        method: 'POST',
        url: '/api/webapp/kyc-documents/',
        data,
      },
    },
  });
};
