import React from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/roundIcon.module.scss';
import classNames from 'classnames';

const RoundIcon = ({ children, className }) => (
  <div className={classNames(classes.roundIcon, className)}>
    <span>{children}</span>
  </div>
);

RoundIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

RoundIcon.defaultProps = {
  className: '',
};

export default RoundIcon;
