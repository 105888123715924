/**
 * Function that allows to convert Blob to base64 format
 * @param {Blob} blob - Blob object
 * @returns {Promise} - Promise that resolves with image url (base64)
 */
export const getBase64FromBlob = (blob) => {
  const reader = new FileReader();

  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

/**
 * Function that allows to convert PDF File to base64 thumbnail
 * @param {File} file - File object
 * @returns {Promise} - Promise that resolves with image url (base64)
 */
export const getBase64FromPdfFile = (file) => {
  const reader = new FileReader();

  reader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      import('react-pdf/dist/esm/entry.webpack').then(({ pdfjs }) => (
        pdfjs
          .getDocument(new Uint8Array(reader.result))
          .promise.then((pdf) => {
            pdf.getPage(1).then((page) => {
              const outputScale = window.devicePixelRatio || 1;
              const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
              const viewport = page.getViewport({ scale: 1 });
              const scale = 310 / viewport.width; // 310 seems to be working best, after trials and errors (don't know why thought)
              const scaledViewport = page.getViewport({ scale });
              const canvas = document.createElement('canvas');
              page
                .render({
                  canvasContext: canvas.getContext('2d'),
                  transform,
                  viewport: scaledViewport,
                })
                .promise.then(() => {
                  resolve(canvas.toDataURL());
                });
            });
          })
      )).catch((err) => {
        reject(err);
      });
    };
  });
};
