import React, { useState, useEffect, useCallback } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import classes from 'assets/style/cropImageModal.module.scss';
import resizeImage from 'helpers/resizeImage';
import useCropper from 'hooks/useCropper';
import { IMAGE_MAX_SIZE_FOR_CROPPER } from 'helpers/constants';
import Button from 'components/Button';

const CropImageModal = () => {
  const { t } = useTranslation('cropImageModal');

  const {
    values: { picture },
    setFieldValue,
  } = useFormikContext();

  const { initCropper, cropImage } = useCropper(picture);

  const [show, setShow] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    if (picture && !image) {
      resizeImage(
        picture,
        IMAGE_MAX_SIZE_FOR_CROPPER,
        IMAGE_MAX_SIZE_FOR_CROPPER,
      )
        .then((url) => {
          setShow(true);
          setImage(url);
        })
        .catch((e) => {
          toast.error(e.message);
          setShow(false);
        });
    }
  }, [
    picture,
    image,
  ]);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      centered
      className={classes.cropImageModal}
      contentClassName={classes.cropImageModal__content}
    >
      <Modal.Header closeButton>
        <div className={classes.cropImageModal__title}>{t('title')}</div>
      </Modal.Header>

      <Modal.Body className={classes.cropImageModal__body}>
        <Cropper
          className={classes.cropImageModal__cropper}
          zoomable={false}
          src={image}
          viewMode={0}
          minCropBoxHeight={64}
          minCropBoxWidth={110}
          background={false}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={initCropper}
          guides
          aspectRatio={220 / 128}
        />
        <Button
          className={classes.cropImageModal__saveBtn}
          onClick={() => {
            cropImage().then((cropData) => {
              setFieldValue('picture', cropData);
              setShow(false);
            });
          }}
        >
          {t('save')}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CropImageModal;
