import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/documentsPicker.module.scss';
import PlusIcon from 'assets/svg/PlusIcon';
import DocumentPreview from 'components/DocumentPreview';
import { toast } from 'react-toastify';
import Button from 'components/Button';

const DocumentsPicker = ({
  acceptFileTypes,
  documents,
  errorMsg,
  selectDocument,
  removeDocument,
  clearErrors,
  addButtonText,
  formatNotSupportedMsg,
  maxDocuments,
}) => {
  const handleSelectDocument = useCallback(
    (e) => {
      const {
        target: { files: [doc = null] = [] },
      } = e;
      if (acceptFileTypes.includes(doc.type)) {
        selectDocument(doc);
      } else if (formatNotSupportedMsg) {
        toast.error(formatNotSupportedMsg);
      }
      e.target.value = '';
    },
    [
      selectDocument,
      acceptFileTypes,
      formatNotSupportedMsg,
    ],
  );

  const handleRemoveDocument = useCallback(
    (doc) => {
      removeDocument(doc);
      clearErrors();
    },
    [removeDocument, clearErrors],
  );

  return (
    <div className={classes.container}>
      {documents.map((doc) => (
        <DocumentPreview
          key={doc.name}
          file={doc}
          onDelete={handleRemoveDocument}
        />
      ))}
      {documents.length < maxDocuments && (
        <div className={classes.addButtonContainer}>
          <Button className={classes.addButton}>
            <Form.Group controlId="picture">
              <Form.Label>
                <PlusIcon />
                {!documents.length && <span>{addButtonText}</span>}
              </Form.Label>
              <Form.File>
                <Form.File.Input
                  onChange={handleSelectDocument}
                  accept={acceptFileTypes.toString()}
                  isInvalid={!!errorMsg}
                  disabled={documents.length >= maxDocuments}
                  className={classes.input}
                />
              </Form.File>
            </Form.Group>
          </Button>
        </div>
      )}
      {!!errorMsg && <div className="d-block invalid-feedback">{errorMsg}</div>}
    </div>
  );
};

DocumentsPicker.propTypes = {
  acceptFileTypes: PropTypes.arrayOf(PropTypes.string),
  documents: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  errorMsg: PropTypes.string,
  selectDocument: PropTypes.func,
  removeDocument: PropTypes.func,
  clearErrors: PropTypes.func,
  addButtonText: PropTypes.string,
  formatNotSupportedMsg: PropTypes.string,
  maxDocuments: PropTypes.number,
};

DocumentsPicker.defaultProps = {
  acceptFileTypes: ['image/png, image/jpeg'],
  documents: [],
  errorMsg: null,
  selectDocument: () => {},
  removeDocument: () => {},
  clearErrors: () => {},
  addButtonText: null,
  formatNotSupportedMsg: null,
  maxDocuments: 9,
};

export default DocumentsPicker;
