import React from 'react';

const ArrowLeftIcon = ({ stroke, strokeWidth, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.121"
    height="21.414"
    viewBox="0 0 12.121 21.414"
    {...restProps}
  >
    <path
      id="Tracé_5"
      data-name="Tracé 5"
      d="M-2970-5839l-10-10,10-10"
      transform="translate(2981.414 5859.707)"
      fill="none"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
    />
  </svg>
);

ArrowLeftIcon.defaultProps = {
  strokeWidth: 2,
};

export default ArrowLeftIcon;
