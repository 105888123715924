import queryString from 'query-string';

/* eslint-disable import/no-unused-modules */
export const GET_OWN_PAYMENT_CARDS = 'app/paymentCards/GET_OWN_PAYMENT_CARDS';
export const INIT_TOKENIZATION = 'app/paymentCards/INIT_TOKENIZATION';
export const POST_TOKENIZATION = 'app/paymentCards/POST_TOKENIZATION';
export const COMPLETE_TOKENIZATION = 'app/paymentCards/COMPLETE_TOKENIZATION';
export const DELETE_CARD = 'app/paymentCards/DELETE_CARD';
/* eslint-enable import/no-unused-modules */

export const getOwnPaymentCards = () => ({
  type: GET_OWN_PAYMENT_CARDS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/payment-cards/',
    },
  },
});

const initTokenization = (transactionId) => ({
  type: INIT_TOKENIZATION,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/payment-cards/tokenization/${transactionId}/`,
      data: {
        transactionId,
      },
    },
  },
});

const postTokenization = (url, data) => ({
  type: POST_TOKENIZATION,
  noAuth: true,
  payload: {
    request: {
      method: 'POST',
      url,
      data: queryString.stringify(data),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'text',
    },
  },
});

const completeTokenization = (id, data) => ({
  type: COMPLETE_TOKENIZATION,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/payment-cards/tokenization/${id}/`,
      data,
    },
  },
});

export const tokenizeCard = ({
  cardNumber, cardExpirationDate, cardCvx, transactionId,
}) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(initTokenization(transactionId))
    .then(
      ({
        payload: {
          data: {
            id, cardRegistrationURL, data, accessKeyRef,
          } = {},
        } = {},
      }) => {
        dispatch(
          postTokenization(cardRegistrationURL, {
            accessKeyRef,
            data,
            cardNumber,
            cardExpirationDate,
            cardCvx,
          }),
        )
          .then(({ payload: { data: postTokenizationData } = {} }) => {
            dispatch(completeTokenization(id, { postTokenizationData }))
              .then(({ payload: { data } = {} }) => {
                resolve({ ...data, transactionId });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      },
    )
    .catch((err) => {
      reject(err);
    });
});

export const deleteCard = (cardId) => ({
  type: DELETE_CARD,
  payload: {
    request: {
      method: 'DELETE',
      url: `/api/webapp/payment-cards/${cardId}/`,
    },
  },
});

export const selectPaymentCards = (state) => state.persistent.paymentCardsReducer.paymentCards;
