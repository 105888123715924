import * as React from 'react';

const IconDeliveryMobile2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 106.283 68.5"
    {...props}
  >
    <defs>
      <style>
        {'.icon-delivery-mobile-2_svg__a,.icon-delivery-mobile-2_svg__b{fill:none;stroke:#050038;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.5px}.icon-delivery-mobile-2_svg__a{fill-rule:evenodd}'}
      </style>
    </defs>
    <path
      className="icon-delivery-mobile-2_svg__a"
      d="M103.253 14.707c1.077 4.345 1.78 11.486 1.78 19.559s-.7 15.214-1.78 19.558A90.355 90.355 0 0172.994 67.25a90.355 90.355 0 01-30.259-13.426c-1.077-4.344-1.78-11.485-1.78-19.558s.7-15.214 1.78-19.559v.016A91.712 91.712 0 0172.992 1.25v.017a91.735 91.735 0 0130.261 13.472"
    />
    <path
      className="icon-delivery-mobile-2_svg__a"
      d="M90.685 34.74a74.8 74.8 0 00-1.652-13.708A97.234 97.234 0 0056.954 6.75"
    />
    <path
      className="icon-delivery-mobile-2_svg__b"
      d="M72.992 67.25v-39.1L42.733 14.723l30.261 13.426 30.261-13.426M1.25 52.758h30.791M30.544 34.273H11.731M32.04 15.791h-9.829"
    />
  </svg>
);

export default IconDeliveryMobile2;
