import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CheckoutRadioButton from 'components/CheckoutRadioButton';
import Button from 'components/Button';
import PaymentCardForm from 'components/PaymentCardForm';
import {
  DELIVERY_METHOD_IN_PERSON,
  DELIVERY_METHOD_REMOTE,
  CARD_PROVIDER_VISA,
  CARD_PROVIDER_MASTERCARD,
  CARD_PROVIDER_CB,
  PAYMENT_CREATED,
  PAYMENT_SUCCEEDED,
  ERRORS,
  MANGOPAY,
} from 'helpers/constants';
import { ReactComponent as IconPaymentMethodCard } from 'assets/images/icon-payment-method-card.svg';
import { ReactComponent as IconPaymentMethodCB } from 'assets/images/icon-payment-method-cb.svg';
import { ReactComponent as IconPaymentMethodVisa } from 'assets/images/icon-payment-method-visa.svg';
import { ReactComponent as IconPaymentMethodMastercard } from 'assets/images/icon-payment-method-mastercard.svg';
// import { ReactComponent as IconPaymentMethod3X } from 'assets/images/icon-payment-3x.svg';
// import { ReactComponent as IconPaymentMethod4X } from 'assets/images/icon-payment-4x.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  tokenizeCard,
  deleteCard,
  selectPaymentCards,
} from 'logic/actions/paymentCardsActions';
import { logout } from 'logic/actions/authActions';
import { loggedIn, refreshMe } from 'logic/actions/meActions';
import {
  doPreauth,
  cancelTransaction,
  doWebPreauth,
} from 'logic/actions/transactionsActions';
import { createTransactionFromTemplate } from 'logic/actions/transactionTemplatesActions';
import PaymentFormDisclaimer from 'components/PaymentFormDisclaimer';
import GoBackCheckoutLink from 'components/GoBackCheckoutLink';
import CheckoutPromocodesContext from 'pages/checkout/CheckoutPromocodesContext';
import useWindowSize from 'helpers/useWindowSize';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const NEW = 'new';

const CheckoutPaymentForm = ({
  transactionTemplate: {
    id,
    allowInPerson,
    allowDelivery,
    flow: { standardCategory, inPersonCategory, remoteCategory } = {},
    currency: transactionCurrency,
    pspSlug: transactionPspSlug,
  } = {},
  transaction: { subTotal } = {},
  deliveryMethod,
  deliveryAddress,
  showPaymentErrorModal,
  message,
  openSameUserModal,
  goBack,
  currentProvider,
}) => {
  const history = useHistory();

  const { isNarrow } = useWindowSize();

  const dispatch = useDispatch();

  const { promocode } = useContext(CheckoutPromocodesContext);

  const isLoggedIn = useSelector(loggedIn);
  const paymentCards = useSelector(selectPaymentCards);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const filteredPaymentCards = useMemo(() => (
    paymentCards.filter(({ currency, pspSlug }) => (
      currency === transactionCurrency
        && pspSlug === transactionPspSlug
    ))
  ), [paymentCards, transactionCurrency, transactionPspSlug]);

  const { t } = useTranslation(['checkoutPaymentForm', 'paymentCardForm']);
  const [paymentMethodId, setPaymentMethodId] = useState(
    filteredPaymentCards.length ? null : NEW,
  );

  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isLoggedIn && filteredPaymentCards) {
      setPaymentMethodId(filteredPaymentCards.length ? null : NEW);
    }
  }, [isLoggedIn, filteredPaymentCards, setPaymentMethodId]);

  let category = standardCategory;

  if (!standardCategory) {
    if (allowInPerson && deliveryMethod === DELIVERY_METHOD_IN_PERSON) {
      category = inPersonCategory;
    }
    if (allowDelivery && deliveryMethod === DELIVERY_METHOD_REMOTE) {
      category = remoteCategory;
    }
  }

  const getCardProviderLogo = (cardProvider) => {
    switch (cardProvider) {
      case CARD_PROVIDER_VISA:
        return IconPaymentMethodVisa;

      case CARD_PROVIDER_MASTERCARD:
        return IconPaymentMethodMastercard;

      case CARD_PROVIDER_CB:
        return IconPaymentMethodCB;

      default:
        return '';
    }
  };

  const handlePreauth = useCallback(
    (transactionId, cardId) => dispatch(doPreauth(transactionId, cardId))
      .then(
        ({
          payload: {
            data: { status, secureModeIsNeeded, secureModeRedirectUrl } = {},
          } = {},
        }) => {
          switch (status) {
            case PAYMENT_CREATED:
              if (secureModeIsNeeded) {
                window.location.assign(secureModeRedirectUrl);
              } else {
                history.push(
                  `/transactions/${transactionId}/post-process-preauth`,
                );
              }
              return Promise.resolve();

            case PAYMENT_SUCCEEDED:
              history.push(
                `/transactions/${transactionId}/post-process-preauth`,
              );
              return Promise.resolve();

            default:
              return Promise.reject();
          }
        },
      ),
    [
      dispatch,
      history,
    ],
  );

  const handleWebPreauth = useCallback(
    (transactionId) => dispatch(doWebPreauth(transactionId))
      .then(
        ({
          payload: {
            data: { webPaymentUrl } = {},
          } = {},
        }) => {
          window.location.assign(webPaymentUrl);
        },
      ),
    [
      dispatch,
    ],
  );

  const handleCreateTransaction = useCallback(async () => {
    const deliveryWithProviderRequest = currentProvider && deliveryMethod === DELIVERY_METHOD_REMOTE
      ? { shippingProvider: currentProvider.name }
      : {};

    const token = await executeRecaptcha('transaction');

    return dispatch(
      createTransactionFromTemplate(id, {
        subTotal,
        category,
        deliveryAddress:
              deliveryMethod === DELIVERY_METHOD_REMOTE
                ? deliveryAddress
                : undefined,
        captcha: token,
        message,
        promocode,
        ...deliveryWithProviderRequest,
      }),
    ).then((response) => {
      dispatch(refreshMe());

      return Promise.resolve(response);
    }).catch(({ error: { response: { data: { errors } = {} } = {} } = {} }) => {
      if (errors === ERRORS.sameUser) {
        openSameUserModal();
        dispatch(logout());
      }
      return Promise.reject();
    });
  }, [
    dispatch,
    id,
    subTotal,
    category,
    currentProvider,
    deliveryMethod,
    deliveryAddress,
    message,
    promocode,
    openSameUserModal,
    executeRecaptcha,
  ]);

  const handleWebPayment = useCallback(() => {
    setSubmitting(true);
    handleCreateTransaction().then((response = {}) => {
      const { payload: { data: { id: transactionId } = {} } = {} } = response;
      return handleWebPreauth(transactionId).catch(() => {
        dispatch(cancelTransaction(transactionId));
        showPaymentErrorModal();
        setSubmitting(false);
      });
    });
  }, [
    handleCreateTransaction,
    handleWebPreauth,
    dispatch,
    showPaymentErrorModal,
  ]);

  const handlePayWithCurrentCard = useCallback(() => {
    setSubmitting(true);
    handleCreateTransaction().then((response = {}) => {
      const { payload: { data: { id: transactionId } = {} } = {} } = response;
      return handlePreauth(transactionId, paymentMethodId).catch(() => {
        dispatch(cancelTransaction(transactionId));
        showPaymentErrorModal();
        setSubmitting(false);
      });
    });
  }, [
    handleCreateTransaction,
    paymentMethodId,
    handlePreauth,
    dispatch,
    showPaymentErrorModal,
  ]);

  const handlePayWithNewCard = useCallback((cardData, { resetForm }) => {
    const { cardNumber, cardExpirationDate, cardCvx } = cardData;
    let transactionId;
    let paymentMethodId;

    setSubmitting(true);
    handleCreateTransaction()
      .then(({ payload: { data: { id, currency } = {} } = {} } = {}) => {
        transactionId = id;
        return dispatch(tokenizeCard({
          cardNumber,
          cardExpirationDate,
          cardCvx,
          currency,
          transactionId,
        }));
      })
      .then(({ id } = {}) => {
        paymentMethodId = id;
        return handlePreauth(transactionId, paymentMethodId);
      })
      .catch(() => {
        if (paymentMethodId) {
          dispatch(deleteCard(paymentMethodId));
        }
        dispatch(cancelTransaction(transactionId));
        showPaymentErrorModal();
        setSubmitting(false);
        resetForm();
      });
  }, [
    handleCreateTransaction,
    handlePreauth,
    dispatch,
    showPaymentErrorModal,
  ]);

  return (
    <div>
      <div>
        {filteredPaymentCards.map(
          ({
            id, obfuscatedNumber, expirationDate, cardProvider,
          }) => (
            <CheckoutRadioButton
              id="method-payment"
              name="method-payment"
              key={id}
              Icon={getCardProviderLogo(cardProvider)}
              title={obfuscatedNumber.replace(/.*([0-9]{4})$/g, '•••• $1')}
              subTitle={t('expirationDate', {
                expirationDate: expirationDate.replace(
                  /(.{2})(.{2})/g,
                  '$1/$2',
                ),
              })}
              checked={paymentMethodId === id}
              onClick={() => {
                setPaymentMethodId(id);
              }}
              className="radio-payment-method"
            />
          ),
        )}
        <CheckoutRadioButton
          id="method-payment"
          name="method-payment"
          Icon={IconPaymentMethodCard}
          title={filteredPaymentCards.length ? t('newCard') : t('card')}
          checked={paymentMethodId === NEW}
          onClick={() => {
            setPaymentMethodId(NEW);
          }}
          className="radio-payment-method"
        />
      </div>

      <div className="modal-add-payment">
        {paymentMethodId === NEW && transactionPspSlug === MANGOPAY ? (
          <div className="form-content">
            <PaymentCardForm
              isCheckoutPage
              onSubmit={handlePayWithNewCard}
            />
          </div>
        ) : (
          <div>
            <PaymentFormDisclaimer />
            <Button
              className="payment-form__submit mt-2 btn-block"
              isLoading={isSubmitting}
              onClick={transactionPspSlug === MANGOPAY ? handlePayWithCurrentCard : handleWebPayment}
            >
              {t('paymentCardForm:checkoutSubmit')}
            </Button>
          </div>
        )}
      </div>
      {!isSubmitting && isNarrow && (
        <GoBackCheckoutLink handleClick={goBack} disabled={isSubmitting} />
      )}

      {/*
        <div className="mt-3">
          <CheckoutRadioButton
            id="method-payment-other1"
            name="method-payment-other"
            Icon={IconPaymentMethod3X}
            title={t('3xcb')}
          />

          <CheckoutRadioButton
            id="method-payment-other2"
            name="method-payment-other"
            Icon={IconPaymentMethod4X}
            title={t('4xcb')}
          />
        </div>
      */}
    </div>
  );
};

export default CheckoutPaymentForm;
