import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import classes from 'assets/style/checkoutRecapMobile.module.scss';
import IconInfo from 'assets/svg/IconInfo';
import { CURRENCY_EUR, DELIVERY_METHOD_REMOTE, PICKUP_PROVIDERS } from 'helpers/constants';
import AddPromocode from 'components/AddPromocode';
import shippingProvidersConfig from 'helpers/shippingProvidersConfig';
import SpanWithColon from 'helpers/SpanWithColon';

const CheckoutRecapMobile = ({
  isDropdown,
  transactionTemplate: {
    title,
    client,
    subTotal = 0,
    currency = CURRENCY_EUR,
    shippingFees = 0,
  } = {},
  transaction: {
    subTotal: newPrice = 0,
    buyerFees = 0,
    buyerDiscount = 0,
  } = {},
  deliveryAddress,
  deliveryMethod,
  displayedBuyerTotal,
  currentProvider,
}) => {
  const { t } = useTranslation('checkoutRecap');
  const hasCounterOffer = newPrice !== subTotal;

  const needsPickupPoint = useMemo(() => (PICKUP_PROVIDERS.includes(currentProvider?.name)), [currentProvider]);

  const showDeliveryAddress = deliveryMethod === DELIVERY_METHOD_REMOTE;

  return (
    <div className={classes.checkoutRecapMobile}>
      {!isDropdown && (
        <>
          <div className="checkout-detail-image">
            <div className="checkout-detail-image__inner" />
          </div>
          <h4 className="recapmobile__title my-3 py-1">{t('title')}</h4>
        </>
      )}
      <div className={classes.checkoutRecapMobile__itemsContainer}>
        <div>
          <div className="d-flex justify-content-between">
            <p className="m-0">{t('purchase')}</p>
            <p className="m-0 pl-5 text-right checkout-checkout__title">
              {title}
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p className="m-0">{t('client')}</p>
            <p className="m-0 text-right">{client}</p>
          </div>
        </div>

        <div className="border-top">
          <div className="d-flex justify-content-between">
            <p className="m-0">{t('subTotal')}</p>
            {hasCounterOffer ? (
              <p className="m-0 text-right text-primary">
                <del className="text-dark mr-2">
                  <FormattedNumber
                    value={subTotal / 100}
                    style="currency"
                    currency={currency}
                  />
                </del>
                <FormattedNumber
                  value={newPrice / 100}
                  style="currency"
                  currency={currency}
                />
              </p>
            ) : (
              <p className="m-0 text-right">
                <FormattedNumber
                  value={subTotal / 100}
                  style="currency"
                  currency={currency}
                />
              </p>
            )}
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex max-height-content">
              <p className="m-0">{t('fees')}</p>
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Popover content className="shadow border-0">
                    {buyerFees ? t('feesTooltip') : t('freeFeesTooltip', { client })}
                  </Popover>
                )}
              >
                <IconInfo className="ml-2" width="14px" />
              </OverlayTrigger>
            </div>
            <p className="m-0 text-right">
              {buyerFees ? (
                <FormattedNumber
                  value={buyerFees / 100}
                  style="currency"
                  currency={currency}
                />
              ) : (
                t('free')
              )}
            </p>
          </div>
        </div>

        {showDeliveryAddress && (
          <div className="border-top">
            <div className="d-flex justify-content-between">
              <span>{t('deliveryFees')}</span>
              <FormattedNumber
                value={
                  (currentProvider
                    ? currentProvider.shippingFees
                    : shippingFees) / 100
                }
                style="currency"
                currency={currency}
              />
            </div>

            <div>
              {currentProvider && (
                <div className="text-overflow-any">
                  <SpanWithColon className="delivery-method__param">
                    {t('carrier')}
                  </SpanWithColon>
                  <span className="delivery-method__value">
                    {shippingProvidersConfig[currentProvider.name].name}
                  </span>
                </div>
              )}
              <div className="text-overflow-any">
                <SpanWithColon className="delivery-method__param">
                  {t('name')}
                </SpanWithColon>
                <span className="delivery-method__value">
                  {deliveryAddress?.recipientName}
                </span>
              </div>
              {deliveryAddress?.phoneNumber && (
                <div className="text-overflow-any">
                  <SpanWithColon className="delivery-method__param">
                    {t('phone')}
                  </SpanWithColon>
                  <span className="delivery-method__value">
                    {deliveryAddress?.phoneNumber}
                  </span>
                </div>
              )}
              <div className="text-overflow-any">
                <SpanWithColon className="delivery-method__param">
                  {t('deliveryAddress')}
                </SpanWithColon>
                <span className="delivery-method__value">
                  {deliveryAddress?.formattedAddress}
                </span>
              </div>
              {needsPickupPoint && deliveryAddress?.pickupPointAddress && (
                <div className="text-overflow-any">
                  <SpanWithColon className="delivery-method__param">
                    {t('pickupPointAddress')}
                  </SpanWithColon>
                  <span className="delivery-method__value">
                    {deliveryAddress?.pickupPointAddress}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        <AddPromocode currency={currency} discount={buyerDiscount} />

        <div className="border-top">
          <div className="d-flex justify-content-between">
            <p className="m-0 checkout__title">{t('total')}</p>
            <p className="m-0 text-right checkout__title">
              <FormattedNumber
                value={displayedBuyerTotal / 100}
                style="currency"
                currency={currency}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutRecapMobile;
