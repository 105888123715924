import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'helpers/useWindowSize';
import {
  CHECKOUT_STEPS,
  CURRENCY_EUR,
  DELIVERY_METHOD_IN_PERSON,
  DELIVERY_METHOD_REMOTE,
  PICKUP_PROVIDERS,
} from 'helpers/constants';
import IconDelivery1 from 'assets/svg/IconDelivery1';
import IconDelivery2 from 'assets/svg/IconDelivery2';
import IconDeliveryMobile1 from 'assets/svg/IconDeliveryMobile1';
import IconDeliveryMobile2 from 'assets/svg/IconDeliveryMobile2';
import Button from 'components/Button';
import CheckoutRadioButton from 'components/CheckoutRadioButton';
import CheckoutAddressModal from 'components/CheckoutAddressModal';
import CheckoutAddressForm from 'components/CheckoutAddressForm';
import { useSelector } from 'react-redux';
import { loggedIn, selectCurrentUserAddress } from 'logic/actions/meActions';
import { useIntl } from 'react-intl';
import useParseUserAddress from 'hooks/useParseUserAddress';
import SpanWithColon from 'helpers/SpanWithColon';
import DeliveryProviders from 'components/DeliveryProviders';
import DeliveryProviderInfo from 'components/DeliveryProviderInfo';
import MondialRelayForm from 'components/MondialRelayForm';
import isEmpty from 'lodash/isEmpty';

const CheckoutDeliveryMethod = ({
  transactionTemplate: {
    allowInPerson,
    allowDelivery,
    flow: { inPersonCategory, remoteCategory } = {},
    shippingFees,
    currency = CURRENCY_EUR,
    shippingProviders,
  } = {},
  deliveryMethod,
  setDeliveryMethod,
  deliveryAddress,
  setDeliveryAddress,
  setActiveKey,
  showAddressModal,
  setShowAddressModal,
  message,
  currentProvider,
  setCurrentProvider,
  selectingPickupPointOnMobile = false,
  setSelectingPickupPointOnMobile,
}) => {
  const { t } = useTranslation('checkoutDeliveryMethod');
  const { parseUserAddress } = useParseUserAddress();

  const { isNarrow } = useWindowSize();
  const [internalDeliveryMethod, setInternalDeliveryMethod] = useState(
    deliveryMethod,
  );
  const [internalDeliveryAddress, setInternalDeliveryAddress] = useState(
    deliveryAddress,
  );
  const inPersonAllowed = allowInPerson && inPersonCategory;
  const deliveryAllowed = allowDelivery && remoteCategory;
  const showMobileAddressForm = isNarrow
    && internalDeliveryMethod === DELIVERY_METHOD_REMOTE
    && !deliveryAddress;
  const [isEditingMobileAddress, setIsEditingMobileAddress] = useState(false);
  const [carrierError, setCarrierError] = useState(false);

  const isDeliveryWithProvider = useMemo(
    () => !shippingFees && !!shippingProviders?.length,
    [shippingFees, shippingProviders],
  );

  const needsPickupPoint = useMemo(() => (PICKUP_PROVIDERS.includes(currentProvider?.name)), [currentProvider]);

  const validateCarrier = useCallback(() => {
    if (currentProvider && isDeliveryWithProvider) {
      setCarrierError(false);
    }

    if (!currentProvider && isDeliveryWithProvider) {
      setCarrierError(true);
    }
  }, [currentProvider, isDeliveryWithProvider]);

  const isDeliveryProviderValid = useMemo(
    () => currentProvider || !isDeliveryWithProvider,
    [isDeliveryWithProvider, currentProvider],
  );

  const showAddressRecap = internalDeliveryMethod === DELIVERY_METHOD_REMOTE
    && internalDeliveryAddress
    && isDeliveryProviderValid
    && !isEditingMobileAddress;

  const intl = useIntl();

  const isLoggedIn = useSelector(loggedIn);
  const currentUserAddress = useSelector(selectCurrentUserAddress);

  useEffect(() => {
    if (currentUserAddress && shippingFees) {
      setInternalDeliveryAddress(parseUserAddress(currentUserAddress));
    }
  }, [
    currentUserAddress,
    shippingFees,
    setInternalDeliveryAddress,
    parseUserAddress,
  ]);

  const resetDeliveryParams = useCallback(() => {
    setInternalDeliveryAddress(undefined);
    setInternalDeliveryMethod(undefined);
    setDeliveryMethod(undefined);
  }, [
    setInternalDeliveryAddress,
    setInternalDeliveryMethod,
    setDeliveryMethod,
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      resetDeliveryParams();
    }
  }, [isLoggedIn, resetDeliveryParams]);

  const goFromDeliveryTab = useCallback(
    (message) => {
      if (message && !isNarrow) {
        setActiveKey(CHECKOUT_STEPS.payment);
      } else {
        setActiveKey(CHECKOUT_STEPS.message);
      }
    },
    [setActiveKey, isNarrow],
  );

  const openAddressModal = useCallback(() => {
    setShowAddressModal(true);
  }, [setShowAddressModal]);

  const closeAddressModal = useCallback(() => {
    setShowAddressModal(false);
  }, [setShowAddressModal]);

  const handleEditAddress = useCallback(() => {
    if (isNarrow) {
      setIsEditingMobileAddress(true);
    } else {
      openAddressModal();
    }
  }, [
    isNarrow,
    openAddressModal,
  ]);

  const onChangeProvider = useCallback((e) => {
    if (shippingProviders) {
      const selectedProvider = shippingProviders.find(
        (provider) => provider.name === e.target.value,
      );
      setCurrentProvider(selectedProvider);
    }
  }, [
    shippingProviders,
    setCurrentProvider,
  ]);

  const onSubmitAddress = useCallback((deliveryAddress) => {
    setInternalDeliveryAddress(deliveryAddress);

    if (needsPickupPoint) {
      setSelectingPickupPointOnMobile(true);
    } else {
      setDeliveryAddress(deliveryAddress);
      setDeliveryMethod(internalDeliveryMethod);
      setIsEditingMobileAddress(false);
    }
  }, [
    needsPickupPoint,
    internalDeliveryMethod,
    setDeliveryAddress,
    setDeliveryMethod,
    setSelectingPickupPointOnMobile,
  ]);

  const parcelDeliveryLabel = useMemo(
    () => (
      <span>
        <span>{t('delivery')}</span>
        {isDeliveryWithProvider ? null : (
          <span className="parcelDelivery-label">
            {`: ${intl.formatNumber((shippingFees || 0) / 100, {
              style: 'currency',
              currency,
            })}`}
          </span>
        )}
      </span>
    ),
    [
      isDeliveryWithProvider,
      shippingFees,
      currency,
      t,
      intl,
    ],
  );

  if (selectingPickupPointOnMobile) {
    return (
      <MondialRelayForm
        allowedCountries={currentProvider?.allowedCountries}
        onSubmit={({ pickupPointCode, formattedAddress: pickupPointAddress }) => {
          const deliveryAddress = {
            ...internalDeliveryAddress,
            pickupPointCode,
            pickupPointAddress,
          };

          setSelectingPickupPointOnMobile(false);
          setInternalDeliveryAddress(deliveryAddress);
          setDeliveryAddress(deliveryAddress);
          setDeliveryMethod(internalDeliveryMethod);
          setIsEditingMobileAddress(false);
        }}
        deliveryAddress={internalDeliveryAddress}
      />
    );
  }

  return (
    <>
      <CheckoutAddressModal
        resetDeliveryParams={resetDeliveryParams}
        isDeliveryWithProvider={isDeliveryWithProvider}
        providers={shippingProviders}
        currency={currency}
        setCurrentProvider={setCurrentProvider}
        currentProvider={currentProvider}
        needsPickupPoint={needsPickupPoint}
        deliveryAddress={internalDeliveryAddress}
        visible={showAddressModal}
        onHide={() => {
          if (!internalDeliveryAddress || !isDeliveryProviderValid) {
            setInternalDeliveryMethod(deliveryMethod);
          }
          closeAddressModal();
        }}
        onSubmit={(deliveryAddress) => {
          setInternalDeliveryAddress(deliveryAddress);
          setDeliveryAddress(deliveryAddress);
          setInternalDeliveryMethod(DELIVERY_METHOD_REMOTE);
          setDeliveryMethod(DELIVERY_METHOD_REMOTE);
          closeAddressModal();
        }}
      />

      <div className="delivery-method">
        <CheckoutRadioButton
          name="method-delivery"
          Icon={isNarrow ? IconDeliveryMobile1 : IconDelivery1}
          title={inPersonAllowed ? t('handover') : t('handover-disabled')}
          disabled={!inPersonAllowed}
          checked={internalDeliveryMethod === DELIVERY_METHOD_IN_PERSON}
          onChange={() => {
            setInternalDeliveryMethod(DELIVERY_METHOD_IN_PERSON);
            setIsEditingMobileAddress(false);
          }}
          bottomPanel={
            internalDeliveryMethod === DELIVERY_METHOD_IN_PERSON && (
              <span className="delivery-method__bottom-panel">
                {t('handoverNotice')}
              </span>
            )
          }
        />
        <CheckoutRadioButton
          name="method-delivery"
          Icon={isNarrow ? IconDeliveryMobile2 : IconDelivery2}
          className={showAddressRecap && 'mb-0'}
          title={deliveryAllowed ? parcelDeliveryLabel : t('delivery-disabled')}
          disabled={!deliveryAllowed}
          checked={internalDeliveryMethod === DELIVERY_METHOD_REMOTE}
          onChange={() => {
            setInternalDeliveryMethod(DELIVERY_METHOD_REMOTE);
            if (!internalDeliveryAddress || !isDeliveryProviderValid) {
              handleEditAddress();
            }
          }}
          bottomPanel={
            showAddressRecap && (
              <div className="delivery-method__bottom-panel">
                <DeliveryProviderInfo
                  provider={currentProvider}
                  currency={currency}
                  onEdit={handleEditAddress}
                />
                <div>
                  <SpanWithColon className="delivery-method__param">
                    {t('name')}
                  </SpanWithColon>
                  <span className="delivery-method__value">
                    {internalDeliveryAddress.recipientName}
                  </span>
                </div>
                {internalDeliveryAddress?.phoneNumber && (
                  <div>
                    <SpanWithColon className="delivery-method__param">
                      {t('phone')}
                    </SpanWithColon>
                    <span className="delivery-method__value">
                      {internalDeliveryAddress.phoneNumber}
                    </span>
                  </div>
                )}
                <div>
                  <SpanWithColon className="delivery-method__param">
                    {t('deliveryAddress')}
                  </SpanWithColon>
                  <span className="delivery-method__value">
                    {internalDeliveryAddress.formattedAddress}
                  </span>
                </div>
                {needsPickupPoint && internalDeliveryAddress?.pickupPointAddress && (
                  <div>
                    <SpanWithColon className="delivery-method__param">
                      {t('pickupPointAddress')}
                    </SpanWithColon>
                    <span className="delivery-method__value">
                      {internalDeliveryAddress.pickupPointAddress}
                    </span>
                  </div>
                )}
              </div>
            )
          }
        />
        {(showMobileAddressForm || isEditingMobileAddress) && (
          <>
            {isDeliveryWithProvider && (
              <DeliveryProviders
                onChangeProvider={onChangeProvider}
                currentProvider={currentProvider}
                providers={shippingProviders}
                currency={currency}
                carrierError={carrierError}
              />
            )}
            {(!isDeliveryWithProvider || currentProvider) && (
              <CheckoutAddressForm
                isPhoneFieldVisible={isDeliveryWithProvider}
                allowedCountries={currentProvider?.allowedCountries}
                validateCarrier={validateCarrier}
                deliveryAddress={
                  currentUserAddress && isEmpty(deliveryAddress)
                    ? parseUserAddress(currentUserAddress)
                    : deliveryAddress
                }
                onSubmit={onSubmitAddress}
                needsPickupPoint={needsPickupPoint}
              />
            )}
          </>
        )}
        {!showMobileAddressForm && !isEditingMobileAddress && (
          <div>
            <Button
              className="btn-block checkout-delivery-btn"
              onClick={() => {
                setDeliveryAddress(internalDeliveryAddress);
                setDeliveryMethod(internalDeliveryMethod);
                goFromDeliveryTab(message);
              }}
              disabled={!internalDeliveryMethod}
            >
              {t('submit')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CheckoutDeliveryMethod;
