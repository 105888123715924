import React, { useState, useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import {
  DELIVERY_METHOD_IN_PERSON,
  DELIVERY_METHOD_REMOTE,
  CLIENT_TRIPARTIE,
  CHECKOUT_STEPS,
} from 'helpers/constants';
import { getToken, logout } from 'logic/actions/authActions';
import { createUser, checkEmail } from 'logic/actions/usersActions';
import { getOwnPaymentCards } from 'logic/actions/paymentCardsActions';
import CheckoutRecapDesktop from 'components/CheckoutRecapDesktop';
import CheckoutBreadcrumbBar from 'components/CheckoutBreadcrumbBar';
import Button from 'components/Button';
import LoginForm from 'components/LoginForm';
import SignupForm from 'components/SignupForm';
import Footer from 'components/Footer';
import IconNum from 'assets/svg/IconNum';
import CheckoutDeliveryMethod from 'components/CheckoutDeliveryMethod';
import CheckoutPaymentForm from 'components/CheckoutPaymentForm';
import AddNoteComponent from 'components/AddNoteComponent';
import { getExternalId } from 'helpers/transactionTemplateReturnUrl';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const CheckoutDesktop = ({
  transactionTemplate = {},
  transaction,
  picture,
  loggedIn,
  me: { userProfile: { firstName } = {} } = {},
  showCounterOfferModal,
  showPaymentErrorModal,
  showAddressModal,
  setShowAddressModal,
  deliveryAddress,
  setDeliveryAddress,
  showSignup,
  setShowSignup,
  deliveryMethod,
  setDeliveryMethod,
  activeKey,
  setActiveKey,
  getToken,
  createUser,
  checkEmail,
  getOwnPaymentCards,
  logout,
  showTuto,
  message,
  setMessage,
  openSameUserModal,
  currentProvider,
  setCurrentProvider,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation('checkout');
  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';
  const loginErrorToast = useCallback(() => toast.error(t('loginError')), [t]);
  const signupErrorToast = useCallback(
    () => toast.error(t('signupError')),
    [t],
  );
  const [loggingOut, setLoggingOut] = useState(false);

  const {
    id, client, adUrl, allowPriceChange, clientId,
  } = transactionTemplate;

  const externalId = getExternalId(window.location.href);

  const authPayloadWithExternalId = externalId
    ? {
      externalId,
      transactionTemplateId: externalId ? id : undefined,
    }
    : {};

  return (
    <div className="checkout">
      <Row className="checkout__content">
        <Col lg="5">
          {client && client !== CLIENT_TRIPARTIE && (
            <CheckoutBreadcrumbBar
              showLogo
              client={client}
              adUrl={adUrl}
              clientId={clientId}
            />
          )}
          <CheckoutRecapDesktop
            transactionTemplate={transactionTemplate}
            transaction={transaction}
            picture={picture}
            showCounterOfferModal={showCounterOfferModal}
            deliveryAddress={deliveryAddress}
            setShowAddressModal={setShowAddressModal}
            deliveryMethod={deliveryMethod}
            activeKey={activeKey}
            allowPriceChange={allowPriceChange}
            currentProvider={currentProvider}
          />
        </Col>
        <Col lg="2">
          <div className="h-100 m-auto checkout__separator" />
        </Col>
        <Col lg="5">
          <Accordion activeKey={activeKey}>
            <Card className="border-0">
              <Card.Header className="bg-white border-0 pl-0 pr-0 pt-0 pb-0">
                <Accordion.Toggle
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  eventKey={CHECKOUT_STEPS.sign}
                  className="pl-0 pr-0 checkout-btn-header-accordion"
                >
                  <div className="m-0 d-flex align-items-center">
                    <IconNum className="mr-2" width="24px" step={1} />
                    <p className="m-0 text-left">
                      <span className="checkout__title">
                        {loggedIn
                          && firstName
                          && t('hello', { name: firstName })}
                        {!(loggedIn && firstName) && showSignup && t('signup')}
                        {!(loggedIn && firstName) && !showSignup && t('login')}
                      </span>
                      {loggedIn && firstName && (
                        <Button
                          variant="link"
                          className="checkout__changeBtn"
                          isLoading={loggingOut}
                          onClick={() => {
                            setLoggingOut(true);
                            logout().finally(() => {
                              setActiveKey(CHECKOUT_STEPS.sign);
                              setDeliveryAddress(null);
                              setDeliveryMethod(null);
                              setLoggingOut(false);
                            });
                          }}
                        >
                          {t('switchAccount')}
                        </Button>
                      )}
                    </p>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={CHECKOUT_STEPS.sign}>
                <Card.Body className="p-0 pb-1">
                  <div className="pt-4 pb-0">
                    {showSignup ? (
                      <SignupForm
                        isCheckoutPage
                        setShowSignup={setShowSignup}
                        checkEmail={checkEmail}
                        onSubmit={(
                          {
                            email, firstName, lastName, password,
                          },
                          { setSubmitting },
                        ) => {
                          executeRecaptcha('signup').then(
                            (token) => {
                              createUser({
                                email,
                                captcha: token,
                                plainPassword: password,
                                userProfile: {
                                  firstName,
                                  lastName,
                                },
                                lang: currentLang,
                                ...authPayloadWithExternalId,
                              })
                                .then(() => {
                                  getToken({
                                    email,
                                    password,
                                  })
                                    .then(() => {
                                      getOwnPaymentCards();
                                      setActiveKey(CHECKOUT_STEPS.delivery);
                                    })
                                    .catch(() => {
                                      setShowSignup(false);
                                    })
                                    .finally(() => {
                                      setSubmitting(false);
                                    });
                                })
                                .catch(() => {
                                  signupErrorToast();
                                  setSubmitting(false);
                                });
                            },
                          ).catch(
                            () => {
                              signupErrorToast();
                              setSubmitting(false);
                            },
                          );
                        }}
                      />
                    ) : (
                      <LoginForm
                        isCheckoutPage
                        setShowSignup={setShowSignup}
                        onSubmit={(values, { resetForm }) => {
                          getToken({ ...values, ...authPayloadWithExternalId })
                            .then(() => {
                              getOwnPaymentCards();
                              setActiveKey(CHECKOUT_STEPS.delivery);
                            })
                            .catch(() => {
                              loginErrorToast();
                            })
                            .finally(() => {
                              resetForm({
                                values: {
                                  ...values,
                                  password: '',
                                },
                              });
                            });
                        }}
                      />
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className="border-0">
              <Card.Header className="bg-white border-0 pl-0 pr-0 pt-4 pb-0">
                <Accordion.Toggle
                  eventKey={CHECKOUT_STEPS.delivery}
                  className={classNames(
                    'pl-0 pr-0 checkout-btn-header-accordion',
                    {
                      disable: !loggedIn || activeKey === CHECKOUT_STEPS.sign,
                    },
                  )}
                >
                  <div className="m-0 d-flex align-items-center">
                    <IconNum className="mr-2" width="24px" step={2} />
                    <p className="m-0 text-left">
                      <span className="checkout__title">
                        {(!loggedIn
                          || activeKey === CHECKOUT_STEPS.delivery
                          || !deliveryMethod)
                          && t('deliveryMethod.desktop')}
                        {loggedIn
                          && activeKey !== CHECKOUT_STEPS.delivery
                          && deliveryMethod === DELIVERY_METHOD_IN_PERSON
                          && t('deliveryMethod.desktopInPerson')}
                        {loggedIn
                          && activeKey !== CHECKOUT_STEPS.delivery
                          && deliveryMethod === DELIVERY_METHOD_REMOTE
                          && t('deliveryMethod.desktopRemote')}
                      </span>
                      {loggedIn
                        && activeKey !== CHECKOUT_STEPS.delivery
                        && deliveryMethod && (
                          <Button
                            variant="link"
                            className="checkout__changeBtn"
                            onClick={() => {
                              setActiveKey(CHECKOUT_STEPS.delivery);
                            }}
                          >
                            {t('deliveryMethod.desktopChange')}
                          </Button>
                      )}
                    </p>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={CHECKOUT_STEPS.delivery}>
                <Card.Body className="p-0 pb-1">
                  <CheckoutDeliveryMethod
                    transactionTemplate={transactionTemplate}
                    deliveryMethod={deliveryMethod}
                    setDeliveryMethod={setDeliveryMethod}
                    setActiveKey={setActiveKey}
                    showAddressModal={showAddressModal}
                    setShowAddressModal={setShowAddressModal}
                    deliveryAddress={deliveryAddress}
                    setDeliveryAddress={setDeliveryAddress}
                    message={message}
                    currentProvider={currentProvider}
                    setCurrentProvider={setCurrentProvider}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <AddNoteComponent
              deliveryMethod={deliveryMethod}
              loggedIn={loggedIn}
              setActiveKey={setActiveKey}
              activeKey={activeKey}
              message={message}
              setMessage={setMessage}
            />
            <Card className="border-0">
              <Card.Header className="bg-white border-0 pl-0 pr-0 pt-4 pb-0">
                <Accordion.Toggle
                  eventKey={CHECKOUT_STEPS.payment}
                  className={classNames(
                    'pl-0 pr-0 checkout-btn-header-accordion',
                    {
                      disable: activeKey !== CHECKOUT_STEPS.payment,
                    },
                  )}
                >
                  <p className="checkout__payment-choice m-0">
                    <IconNum className="mr-2" width="24px" step={4} />
                    <p className="mb-0 text-left">
                      <span className="checkout__title">
                        {t('paymentMethod.desktop')}
                      </span>
                    </p>
                  </p>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={CHECKOUT_STEPS.payment}>
                <Card.Body className="p-0 pt-4 pb-1">
                  <CheckoutPaymentForm
                    transactionTemplate={transactionTemplate}
                    transaction={transaction}
                    deliveryMethod={deliveryMethod}
                    deliveryAddress={deliveryAddress}
                    showPaymentErrorModal={showPaymentErrorModal}
                    message={message}
                    openSameUserModal={openSameUserModal}
                    currentProvider={currentProvider}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Footer checkout showTuto={showTuto} className="checkout__footer" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  me: state.persistent.meReducer.me,
});

const actionCreators = {
  getToken,
  createUser,
  checkEmail,
  getOwnPaymentCards,
  logout,
};

export default connect(mapStateToProps, actionCreators)(CheckoutDesktop);
