import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import DisputeResolutionRadioButton from 'components/DisputeResolutionRadioButton';
import { snake2Camel } from 'helpers/caseConvert';

export const RESPONSE_OK = 'response_ok';
export const RESPONSE_NO = 'response_no';

const ChooseResponse = ({
  transaction: {
    currency = 'EUR',
    issue: {
      type,
      openedAt,
      messages: [{ proposedSolution, proposedRefund = 0 }] = [],
    } = {},
  } = {},
  response,
  back,
  next,
}) => {
  const { t } = useTranslation('disputeResolution');
  const intl = useIntl();

  const [selected, setSelected] = useState(response);

  const deadline = moment(openedAt)
    .add(3, 'days')
    .toDate();

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>
        {t('chooseResponse.title', {
          deadlineDate: intl.formatDate(deadline),
          deadlineTime: intl.formatTime(deadline),
        })}
      </p>
      <div className={classes.form}>
        <DisputeResolutionRadioButton
          id={RESPONSE_OK}
          name="response"
          title={t(
            `chooseResponse.responseOk.${snake2Camel(proposedSolution)}`,
            {
              proposedRefund: intl.formatNumber(proposedRefund / 100, {
                style: 'currency',
                currency,
              }),
            },
          )}
          onChange={() => {
            setSelected(RESPONSE_OK);
          }}
          defaultChecked={selected === RESPONSE_OK}
        />
        <DisputeResolutionRadioButton
          id={RESPONSE_NO}
          name="response"
          title={t(`chooseResponse.responseNo.${snake2Camel(type)}`)}
          onChange={() => {
            setSelected(RESPONSE_NO);
          }}
          defaultChecked={selected === RESPONSE_NO}
        />
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(selected);
        }}
        disabled={!selected}
      >
        {t('chooseResponse.next')}
      </Button>
    </Row>
  );
};

export default ChooseResponse;
