import React from 'react';
import { Helmet } from 'react-helmet-async';
import classes from 'assets/style/resetPassword.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import IconFly from 'assets/images/icon-fly.svg';
import Footer from 'components/Footer';

const MailBox = () => {
  const { t } = useTranslation('mailBox');
  const history = useHistory();

  /* eslint-disable jsx-a11y/control-has-associated-label */
  const goBackLink = (
    <span
      onClick={history.goBack}
      onKeyPress={history.goBack}
      role="button"
      tabIndex={0}
    />
  );
  /* eslint-enable jsx-a11y/control-has-associated-label */

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <img src={IconFly} alt="mail" className={classes.flyIcon} />
          <p className={classes.title}>{t('title')}</p>
          <p className={classes.description}>{t('description')}</p>
          <p className={classes.notReceived}>
            <Trans t={t} i18nKey="notReceived" components={{ a: goBackLink }} />
          </p>
        </div>
        <Footer className={classes.footer} />
      </div>
    </>
  );
};

export default MailBox;
