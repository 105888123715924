import iconHelpCenter from 'assets/images/icon-help-center_mobile.svg';
import iconLogout from 'assets/images/icon-logout_mobile.svg';
import iconMyTransactions from 'assets/images/icon-my-transactions_mobile.svg';
import iconPayments from 'assets/images/icon-payments_mobile.svg';
import iconProfile from 'assets/images/icon-profile_mobile.svg';
import iconVerifiedProfile from 'assets/images/icon-verified-profile_mobile.svg';
import iconWallet from 'assets/images/icon-wallet_mobile.svg';
import 'assets/style/menu-responsive.scss';
import Button from 'components/Button';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import { logout } from 'logic/actions/authActions';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import localStorage from 'helpers/localStorage';
import { HOMEPAGE_TAB_KEY, HOMEPAGE_TABS } from 'helpers/constants';
import iconAdd from 'assets/images/add-icon.svg';

const HeaderResponsive = ({
  hideMenu,
  onOpenTransactionTemplateModal,
  menuRef,
}) => {
  const { t } = useTranslation(['headerResponsive', '_links']);
  const setLoadingScreen = useLoadingScreen();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    setLoadingScreen(true);
    dispatch(logout()).finally(() => {
      setLoadingScreen(false);
      hideMenu();
    });
  }, [
    dispatch,
    setLoadingScreen,
    hideMenu,
  ]);

  const gotoNewTransactionTemplate = useCallback(() => {
    hideMenu();
    onOpenTransactionTemplateModal();
  }, [hideMenu, onOpenTransactionTemplateModal]);

  return (
    <div ref={menuRef} className="block-menu-responsive">
      <div
        className="block-menu-responsive__opacity"
        onClick={hideMenu}
        onKeyDown={hideMenu}
        role="button"
        tabIndex={0}
        aria-label="Close menu"
      />
      <ul className="block-menu-responsive__list-bar">
        <div className="block-menu-responsive__list-bar-top">
          <li>
            <NavLink
              to="/settings/wallets"
              onClick={hideMenu}
              onKeyDown={hideMenu}
            >
              <span className="text-list">
                <img className="icon-list" src={iconWallet} alt="" />
                {t('wallet')}
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/transactions"
              onClick={() => {
                localStorage.set(HOMEPAGE_TAB_KEY, HOMEPAGE_TABS.IN_PROGRESS);
                hideMenu();
              }}
              onKeyDown={() => {
                localStorage.set(HOMEPAGE_TAB_KEY, HOMEPAGE_TABS.IN_PROGRESS);
                hideMenu();
              }}
            >
              <span className="text-list">
                <img className="icon-list" src={iconMyTransactions} alt="" />
                {t('transactions')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings/profile"
              onClick={hideMenu}
              onKeyDown={hideMenu}
            >
              <span className="text-list">
                <img className="icon-list" src={iconProfile} alt="" />
                {t('profile')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings/payments"
              onClick={hideMenu}
              onKeyDown={hideMenu}
            >
              <span className="text-list">
                <img className="icon-list" src={iconPayments} alt="" />
                {t('payments')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings/profile-verification"
              onClick={hideMenu}
              onKeyDown={hideMenu}
            >
              <span className="text-list--last">
                <img className="icon-list" src={iconVerifiedProfile} alt="" />
                {t('kyc')}
              </span>
            </NavLink>
          </li>
          <li className="margin-top-list">
            <a
              href={t('_links:supportCenter')}
              target="_blank"
              rel="noopener noreferrer"
              onClick={hideMenu}
              onKeyDown={hideMenu}
            >
              <span className="text-list">
                <img className="icon-list" src={iconHelpCenter} alt="" />
                {t('helpCenter')}
              </span>
            </a>
          </li>
          <li>
            <Button as="a" variant="link" onClick={handleLogout}>
              <span className="text-list">
                <img className="icon-list" src={iconLogout} alt="" />
                {t('logout')}
              </span>
            </Button>
          </li>
        </div>

        <div className="block-menu-responsive__list-bar-bottom">
          <Button onClick={gotoNewTransactionTemplate}>
            <img
              src={iconAdd}
              alt="add"
              className="header_new-transaction__icon"
            />
            {t('newTransaction')}
          </Button>
        </div>
      </ul>
    </div>
  );
};

export default HeaderResponsive;
