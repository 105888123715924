import { useCallback, useState } from 'react';

const useModal = (state) => {
  const [modalState, setModalState] = useState(state);

  const openModal = useCallback(() => {
    setModalState(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalState(false);
  }, []);

  return {
    modalState,
    openModal,
    closeModal,
  };
};

export default useModal;
