import React from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/deliveryProviderItem.module.scss';
import shippingProvidersConfig from 'helpers/shippingProvidersConfig';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import useWindowSize from 'helpers/useWindowSize';
import classNames from 'classnames';
import SpanWithColon from 'helpers/SpanWithColon';

const DeliveryProviderItem = ({
  provider,
  onChange,
  currentProvider,
  currency,
  tempDisabled,
}) => {
  const { t } = useTranslation('deliveryProviders');

  const { isNarrow } = useWindowSize();

  const { name: slug, shippingFees } = provider;

  const { icon, name } = shippingProvidersConfig[slug];

  const subTitles = Object.values(
    t(`providers.${slug}.subTitles`, { returnObjects: true }) || {},
  );

  if (isNarrow) {
    return (
      <label
        htmlFor={slug}
        className={classNames(
          classes.deliveryProviderItemMobile__container,
          { [classes.deliveryProviderItem__tempDisabled]: tempDisabled },
        )}
      >
        <div className={classes.deliveryProviderItemMobile__description}>
          <input
            type="radio"
            id={slug}
            name="DeliveryProviderItem"
            value={slug}
            checked={slug === currentProvider?.name}
            onChange={onChange}
            className={classNames(
              classes.deliveryProviderItem__input,
              classes.deliveryProviderItemMobile__input,
            )}
            disabled={tempDisabled}
          />
          <div className={classes.deliveryProviderItemMobile__info}>
            <span className={classes.deliveryProviderItemMobile__title}>
              {tempDisabled ? (
                <span>{name}</span>
              ) : (
                <>
                  <SpanWithColon>{name}</SpanWithColon>
                  <span className={classes.deliveryProviderItemMobile__price}>
                    <FormattedNumber
                      value={shippingFees / 100}
                      style="currency"
                      currency={currency}
                    />
                  </span>
                </>
              )}
            </span>
            {tempDisabled ? (
              <span className={classes.deliveryProviderItemMobile__subtitle}>
                {t('tempDisabled')}
              </span>
            ) : (!!subTitles.length && (
              <span className={classes.deliveryProviderItemMobile__subtitle}>
                {subTitles[0]}
              </span>
            ))}
          </div>
        </div>
        <div>
          <img
            src={icon}
            alt={slug}
            className={classes.deliveryProviderItemMobile__icon}
          />
        </div>
      </label>
    );
  }

  return (
    <div className={classes.deliveryProviderItem__container}>
      <input
        type="radio"
        id={slug}
        name="DeliveryProviderItem"
        value={slug}
        checked={slug === currentProvider?.name}
        onChange={onChange}
        className={classes.deliveryProviderItem__input}
        disabled={tempDisabled}
      />
      <label
        htmlFor={slug}
        className={classNames(
          classes.deliveryProviderItem__label,
          { [classes.deliveryProviderItem__tempDisabled]: tempDisabled },
        )}
      >
        <img
          src={icon}
          alt={slug}
          className={classes.deliveryProviderItem__icon}
        />
        <span className={classes.deliveryProviderItem__title}>
          {tempDisabled ? (
            <span>{name}</span>
          ) : (
            <>
              <SpanWithColon>{name}</SpanWithColon>
              <span className={classes.deliveryProviderItem__fees}>
                <FormattedNumber
                  value={shippingFees / 100}
                  style="currency"
                  currency={currency}
                />
              </span>
            </>
          )}
        </span>
        {tempDisabled ? (
          <p className={classes.deliveryProviderItem__subTitle}>
            {t('tempDisabled')}
          </p>
        ) : (
          subTitles.map((subTitle, index) => (
            <p
              key={index.toString()}  // eslint-disable-line
              className={classes.deliveryProviderItem__subTitle}
            >
              {subTitle}
            </p>
          ))
        )}
      </label>
    </div>
  );
};

DeliveryProviderItem.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    shippingFees: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  currentProvider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    shippingFees: PropTypes.number.isRequired,
  }),
  currency: PropTypes.string.isRequired,
  tempDisabled: PropTypes.bool,
};

DeliveryProviderItem.defaultProps = {
  provider: undefined,
  currentProvider: undefined,
  tempDisabled: false,
};

export default DeliveryProviderItem;
