/* eslint-disable import/no-unused-modules */
export const GET_OWN_WALLETS = 'app/wallets/GET_OWN_WALLETS';
export const CASHOUT = 'app/wallets/CASHOUT';
/* eslint-enable import/no-unused-modules */

export const getOwnWallets = () => ({
  type: GET_OWN_WALLETS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/wallets/',
    },
  },
});

export const cashout = (walletId, bankAccountId) => ({
  type: CASHOUT,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/wallets/${walletId}/cashout/`,
      data: { bankAccountId },
    },
  },
});
