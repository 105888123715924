import preauthorizedIcon from 'assets/images/preauthorized-round-icon.svg';
import acceptedIcon from 'assets/images/accepted-round-icon.svg';
import ShippedIcon from 'assets/images/shipped-round-icon.svg';
import DeliveredIcon from 'assets/images/delivered-round-icon.svg';
import CompletedIcon from 'assets/images/completed-round-icon.svg';
import CancelIcon from 'assets/images/canceled-round-icon.svg';
import ClockIcon from 'assets/images/cancel-requested-round-icon.svg';
import { TRANSACTION_STATUSES } from 'helpers/constants';

const {
  PREAUTHORIZED,
  ACCEPTED,
  ACCEPTED_LABEL_GENERATION,
  SHIPPED,
  DELIVERED,
  NEED_REVIEW,
  COMPLETED,
  REJECTED,
  EXPIRED,
  CANCEL_BUYER_BEFORE_PAYMENT,
  CANCEL_BUYER_AFTER_PAYMENT,
  CANCEL_SELLER_BEFORE_PAYMENT,
  CANCEL_SELLER_AFTER_PAYMENT,
  CANCEL_ADMIN_BEFORE_PAYMENT,
  CANCEL_ADMIN_AFTER_PAYMENT,
  CANCEL_AUTO_BEFORE_PAYMENT,
  CANCEL_AUTO_AFTER_PAYMENT,
  CANCEL_REQUESTED,
  ISSUE_OPENED,
  ISSUE_IN_ARBITRATION,
  ISSUE_CLOSED,
  LABEL_GENERATED,
} = TRANSACTION_STATUSES;

const images = {
  [PREAUTHORIZED]: preauthorizedIcon,
  [ACCEPTED]: acceptedIcon,
  [ACCEPTED_LABEL_GENERATION]: acceptedIcon,
  [SHIPPED]: ShippedIcon,
  [DELIVERED]: DeliveredIcon,
  [NEED_REVIEW]: CompletedIcon,
  [COMPLETED]: CompletedIcon,
  [REJECTED]: CancelIcon,
  [EXPIRED]: CancelIcon,
  [CANCEL_BUYER_BEFORE_PAYMENT]: CancelIcon,
  [CANCEL_BUYER_AFTER_PAYMENT]: CancelIcon,
  [CANCEL_SELLER_BEFORE_PAYMENT]: CancelIcon,
  [CANCEL_SELLER_AFTER_PAYMENT]: CancelIcon,
  [CANCEL_ADMIN_BEFORE_PAYMENT]: CancelIcon,
  [CANCEL_ADMIN_AFTER_PAYMENT]: CancelIcon,
  [CANCEL_AUTO_BEFORE_PAYMENT]: CancelIcon,
  [CANCEL_AUTO_AFTER_PAYMENT]: CancelIcon,
  [CANCEL_REQUESTED]: ClockIcon,
  [ISSUE_OPENED]: ClockIcon,
  [ISSUE_IN_ARBITRATION]: ClockIcon,
  [ISSUE_CLOSED]: CompletedIcon,
  [LABEL_GENERATED]: acceptedIcon,
};

export default images;
