import React from 'react';

const CheckedRoundedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79.996"
    height="80"
    viewBox="0 0 79.996 80"
    {...props}
  >
    <defs>
      <style>
        {'.a{fill:#004bb4;fill-rule:evenodd;}.b{fill:none;stroke:#fff;stroke-width:3px;'}
      </style>
    </defs>
    <g transform="translate(-30 -641.664)">
      <g transform="translate(30 641.664)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M-6440.309-1473.771c-32,0-40,8-40,40s8,40,40,40,40-8,40-40S-6408.309-1473.771-6440.309-1473.771Z"
            transform="translate(6480.309 1473.771)"
          />
        </g>
      </g>
      <g transform="translate(52.628 667.338)">
        <path
          className="b"
          d="M-6475.782-1451.728l11.266,14.089,25.357-31"
          transform="translate(6475.782 1468.636)"
        />
      </g>
    </g>
  </svg>
);

export default CheckedRoundedIcon;
