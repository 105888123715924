import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SELLER, TAKE_PICTURES_ROUTES } from 'helpers/constants';
import { useTranslation } from 'react-i18next';
import itemsStorage from 'helpers/itemsStorage';
import parcelStorage from 'helpers/parcelStorage';
import ConfirmModal from 'components/takePictures/ConfirmModal';
import useModal from 'hooks/useModal';
import OrderInHands from 'assets/svg/OrderInHands';
import classes from 'assets/style/takePictures/takePicturesSecond.module.scss';

const TakePicturesSecond = () => {
  const location = useLocation();
  const history = useHistory();

  const { modalState: readyToProceedModal } = useModal(true);

  const { t } = useTranslation('takePictures');

  const goNext = useCallback(() => {
    if (location?.state?.role === SELLER) {
      history.push({
        pathname: TAKE_PICTURES_ROUTES.ITEMS_SCREEN,
        state: location.state,
      });
    } else {
      history.push({
        pathname: TAKE_PICTURES_ROUTES.PARCEL_SCREEN,
        state: location.state,
      });
    }
  }, [
    history,
    location.state,
  ]);

  const goBack = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    itemsStorage.clear();
    parcelStorage.clear();
  }, []);

  const image = (
    <div className={classes.takePicturesSecond__image}>
      <OrderInHands />
    </div>
  );

  const text = (
    <p className={classes.takePicturesSecond__text}>
      {t('readyToProceedModal.text')}
    </p>
  );

  return (
    <div className={classes.takePicturesSecond}>
      <ConfirmModal
        show={readyToProceedModal}
        onConfirm={goNext}
        onReject={goBack}
        title={t('readyToProceedModal.title')}
        text={text}
        img={image}
        confirmText={t('readyToProceedModal.confirm')}
        rejectText={t('readyToProceedModal.reject')}
      />
    </div>
  );
};

export default TakePicturesSecond;
