import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import { BUYER, SELLER } from 'helpers/constants';

const Welcome = ({
  me: { userProfile: { firstName = '' } = {} } = {},
  transaction: { title, issue: { openedAt } = {} } = {},
  role,
  back,
  next,
}) => {
  const { t } = useTranslation('disputeResolution');
  const intl = useIntl();

  const deadline = moment(openedAt)
    .add(3, 'days')
    .toDate();

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('backToTransaction')}
      </Button>
      {role === BUYER && (
        <p className={classes.title}>
          {t('welcome.buyerTitle', { firstName })}
        </p>
      )}
      {role === SELLER && (
        <p className={classes.title}>
          {t('welcome.sellerTitle', {
            firstName,
            title,
            deadlineDate: intl.formatDate(deadline),
            deadlineTime: intl.formatTime(deadline),
          })}
        </p>
      )}
      <Button className={classes.nextButton} onClick={next}>
        {t('welcome.next')}
      </Button>
    </Row>
  );
};

export default Welcome;
