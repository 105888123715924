import React from 'react';
import Button from 'components/Button';

const CancelButton = ({ content, isDisabled, handleClick }) => (
  <div className="transaction__cta">
    <Button
      variant="secondary"
      className="cancelBtn transaction__button"
      disabled={isDisabled}
      onClick={handleClick}
    >
      {content}
    </Button>
  </div>
);

export default CancelButton;
