import { useEffect, useState } from 'react';

const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState(null);

  useEffect(() => {
    if (!geolocation) {
      window.navigator.geolocation.getCurrentPosition(setGeolocation, () => {
        setGeolocation(null);
      });
    }
  }, [geolocation, setGeolocation]);

  return {
    geolocation,
    longitude: geolocation?.coords.longitude,
    latitude: geolocation?.coords.latitude,
  };
};

export default useGeolocation;
