import { useEffect, useRef } from 'react';

/**
 * Trigger modal only if status has changed from false to true
 * @param {(boolean|undefined)} transactionStatus
 * @param {function} openModal
 * @param {boolean} [extraCondition]
 */
const useTriggerModalOnTransactionStatusChange = (
  transactionStatus,
  openModal,
  extraCondition = true,
) => {
  const status = useRef();

  useEffect(() => {
    if (transactionStatus === false) {
      status.current = transactionStatus;
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (status.current === false && extraCondition && transactionStatus) {
      openModal();
      status.current = true;
    }
  }, [
    transactionStatus,
    extraCondition,
    openModal,
  ]);
};

export default useTriggerModalOnTransactionStatusChange;
