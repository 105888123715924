import 'assets/style/transaction.scss';
import CancelModal from 'components/CancelModal';
import Header from 'components/Header';
import TransactionActionPanel from 'components/TransactionActionPanel';
import {
  cancelTransaction,
  fetchTransactionPicture,
  fetchTransactionVerificationCode,
  getTransaction,
  requestCancelTransaction,
  createDisputeLoginLink,
} from 'logic/actions/transactionsActions';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';
import CancelButton from 'components/CancelButton';
import TransactionDetailsCard from 'components/TransactionDetailsCard';
import Footer from 'components/Footer';
import TransactionGoBackLink from 'components/TransactionGoBackLink';
import TransactionTimelineCard from 'components/TransactionTimelineCard';
import TransactionRecapCard from 'components/TransactionRecapCard';
import useTransaction from 'hooks/useTransaction';
import TransactionTimeline from 'components/TransactionTimeline';
import { Button } from 'react-bootstrap';
import Loader from 'components/Loader';
import useWindowSize from 'helpers/useWindowSize';
import GotoTakePicturesBlock from 'components/takePictures/GotoTakePicturesBlock';
import {
  BUYER, SELLER, isBetaTester,
} from 'helpers/constants';
import { isProd } from 'helpers/env';

const Transaction = ({
  transactions,
  getTransaction,
  requestCancelTransaction,
  cancelTransaction,
  fetchTransactionPicture,
  fetchTransactionVerificationCode,
  createDisputeLoginLink,
  me,
  match: { params: { transactionId } = {} } = {},
  location,
}) => {
  const { t } = useTranslation('transaction');

  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

  const { isMobile } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const extendedTransaction = useTransaction({
    me,
    transactionId,
    transactions,
    getTransaction,
    fetchTransactionPicture,
    fetchTransactionVerificationCode,
  });

  const {
    id,
    title,
    buyerTotal,
    sellerTotal,
    currency,
    isPurchase,
    showTimeline,
    deliveryMethod,
    events,
    canBeCanceled,
    isAccepted,
    loading,
    isShipped,
  } = extendedTransaction;

  useEffect(() => {
    const { query: { dispute, user } } = queryString.parseUrl(location.search);

    if (
      id && parseInt(dispute) === id
      && me?.id && parseInt(user) === me?.id
    ) {
      createDisputeLoginLink(id)
        .then(({ payload: { data: { link } = {} } = {} }) => {
          window.location = link;
        });
    }
  }, [
    location,
    id,
    me,
    createDisputeLoginLink,
  ]);

  const gotoHelp = useCallback(() => {
    window.open('https://tripartie.freshdesk.com/', '_blank');
  }, []);

  const role = isPurchase ? BUYER : SELLER;

  return (
    <>
      <Helmet>
        <title>{t('pageTitle', { id: extendedTransaction.id })}</title>
      </Helmet>
      <CancelModal
        transaction={extendedTransaction}
        showCancelModal={showConfirmCancelModal}
        setShowCancelModal={setShowConfirmCancelModal}
        cancelTransaction={cancelTransaction}
        requestCancelTransaction={requestCancelTransaction}
      />
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="transaction">
          <TransactionGoBackLink />
          {!isProd && isBetaTester(me?.email) && (
            <GotoTakePicturesBlock transactionId={transactionId} role={role} />
          )}
          <TransactionActionPanel transaction={extendedTransaction} />
          <TransactionTimelineCard
            title={title}
            sellerTotal={sellerTotal}
            buyerTotal={buyerTotal}
            isPurchase={isPurchase}
            currency={currency}
          >
            {showTimeline && (
              <TransactionTimeline
                deliveryMethod={deliveryMethod}
                events={events}
              />
            )}
          </TransactionTimelineCard>
          <div className="transaction__row">
            <TransactionDetailsCard transaction={extendedTransaction} />
            <TransactionRecapCard transaction={extendedTransaction} />
          </div>
          {isMobile && (
            <Button
              variant="outline-secondary"
              className="mb-3"
              onClick={gotoHelp}
            >
              {t('help')}
            </Button>
          )}
          {!isShipped && (
            <CancelButton
              content={
                isPurchase && isAccepted ? t('requestCancel') : t('cancel')
              }
              isDisabled={!canBeCanceled}
              handleClick={() => {
                setShowConfirmCancelModal(true);
              }}
            />
          )}
          <Footer />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  transactions: state.persistent.transactionsReducer.transactions,
});

const actionsCreators = {
  getTransaction,
  requestCancelTransaction,
  cancelTransaction,
  fetchTransactionPicture,
  fetchTransactionVerificationCode,
  createDisputeLoginLink,
};

export default connect(
  mapStateToProps,
  actionsCreators,
)(Transaction);
