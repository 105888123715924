import sortBy from 'lodash/sortBy';

export const removeAccents = (str) => str
  .replace(/[áàãâä]/gi, 'a')
  .replace(/[éèëê]/gi, 'e')
  .replace(/[íìïî]/gi, 'i')
  .replace(/[óòöôõ]/gi, 'o')
  .replace(/[úùüû]/gi, 'u')
  .replace(/[ç]/gi, 'c')
  .replace(/[ñ]/gi, 'n')
  .replace(/[^a-zA-Z0-9]/g, ' ');

export const sort = (array, fn) => sortBy(array, (item) => removeAccents(fn(item)).toLowerCase());

export const trimObjectValues = (object) => {
  const newEntries = [];
  Object.entries(object).forEach(([key, value]) => newEntries.push([key, value.trim()]));
  return Object.fromEntries(newEntries);
};
