import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import CheckIcon from 'assets/images/check-rounded-icon.svg';
import classes from 'assets/style/confirmRejectModal.module.scss';
import RadioCheckOutlined from 'components/RadioCheckOutlined';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { REJECT_OFFER_REASONS_KEYS } from 'helpers/constants';

const ConfirmRejectModal = ({ show, onHide, onReject }) => {
  const { t } = useTranslation('transactionActionPanel');

  const [isRejecting, setRejecting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentReason, setCurrentReason] = useState('');

  const handleReject = useCallback(() => {
    setRejecting(true);
    onReject(currentReason)
      .then(() => {
        setRejecting(false);
        setSuccess(true);
      })
      .catch(() => {
        setRejecting(false);
        toast.error(t('confirmRejectModal.error'));
      });
  }, [
    currentReason,
    onReject,
    t,
  ]);

  const handleReason = useCallback((e) => {
    setCurrentReason(e.target.value);
  }, []);

  useEffect(() => {
    setCurrentReason('');
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={classes.confirmRejectModal}
      dialogClassName={classes.confirmRejectModal__dialog}
      contentClassName={classes.confirmRejectModal__content}
      centered
    >
      {success && (
        <div className={classes.confirmRejectModal__success}>
          <Modal.Header closeButton>
            <img src={CheckIcon} alt="check-icon" />
          </Modal.Header>
          <p className={classes.confirmRejectModal__header}>
            {t('confirmRejectModal.choiceTaken')}
          </p>
        </div>
      )}

      <div
        className={classNames(classes.confirmRejectModal__container, {
          [classes['confirmRejectModal__container--hidden']]: success,
        })}
      >
        <Modal.Header closeButton>
          <p className={classes.confirmRejectModal__header}>
            {t('confirmRejectModal.title')}
          </p>
        </Modal.Header>
        {REJECT_OFFER_REASONS_KEYS.map((reasonKey) => (
          <RadioCheckOutlined
            key={reasonKey}
            label={t(`confirmRejectModal.reasons.${reasonKey}`)}
            onChange={handleReason}
            value={reasonKey}
            name="reason"
            id={reasonKey}
            isChecked={reasonKey === currentReason}
          />
        ))}
        <Button
          variant="primary"
          isLoading={isRejecting}
          onClick={handleReject}
          className={classes.confirmRejectModal__button}
          disabled={!currentReason}
        >
          {t('confirmRejectModal.confirm')}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmRejectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ConfirmRejectModal;
