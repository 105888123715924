import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import useTransaction from 'hooks/useTransaction';
import classes from 'assets/style/disputeResolution.module.scss';
import Welcome from 'pages/disputeResolution/Welcome';
import IssueRecap from 'pages/disputeResolution/IssueRecap';
import ChooseTransaction from 'pages/disputeResolution/ChooseTransaction';
import IssueType, { NOT_RECEIVED } from 'pages/disputeResolution/IssueType';
import IssueMessage from 'pages/disputeResolution/IssueMessage';
import IssueAddProof from 'pages/disputeResolution/IssueAddProof';
import ChooseSolution, {
  SOLUTION_PARTIAL,
  SOLUTION_RETURN,
} from 'pages/disputeResolution/ChooseSolution';
import ChooseRefund from 'pages/disputeResolution/ChooseRefund';
import ConfirmDispute from 'pages/disputeResolution/ConfirmDispute';
import ChooseResponse, {
  RESPONSE_OK,
  RESPONSE_NO,
} from 'pages/disputeResolution/ChooseResponse';
import DisputeResolutionHeader from 'components/DisputeResolutionHeader';
import Footer from 'components/Footer';
import {
  getTransaction,
  openIssue,
  updateIssue,
} from 'logic/actions/transactionsActions';
import { loadSingleMedia } from 'logic/actions/mediaActions';
import {
  EVENT_TRANSACTION_SHIP,
  EVENT_TRANSACTION_DELIVERED,
  EVENT_TRANSACTION_OPEN_ISSUE,
  EVENT_TRANSACTION_UPDATE_ISSUE,
  EVENT_TRANSACTION_CLOSE_ISSUE,
  BUYER,
  SELLER,
} from 'helpers/constants';
import useModal from 'hooks/useModal';
import SimpleModal from 'components/SimpleModal';
import envelope from 'assets/images/disputeResolution/envelope.png';

const STEP_WELCOME = 0;

const STEP_BUYER_CHOOSE_TRANSACTION = 1;
const STEP_BUYER_ISSUE_TYPE = 2;
const STEP_BUYER_ISSUE_MESSAGE = 3;
const STEP_BUYER_ADD_PROOF = 4;
const STEP_BUYER_CHOOSE_SOLUTION = 5;
const STEP_BUYER_CHOOSE_REFUND = 6;
const STEP_BUYER_CONFIRM = 7;
const NB_STEPS_BUYER = 7;

const STEP_SELLER_REVIEW = 1;
const STEP_SELLER_RESPONSE = 2;
const STEP_SELLER_ADD_PROOF = 3;
const STEP_SELLER_CONFIRM = 4;
const NB_STEPS_SELLER = 4;

const DisputeResolution = ({
  me = {},
  transactions,
  openIssue,
  updateIssue,
  getTransaction,
  loadSingleMedia,
  location: { state: { currentStep = STEP_WELCOME } = {} } = {},
  history,
  match: { params: { transactionId } = {} } = {},
}) => {
  const { t } = useTranslation('disputeResolution');
  const extendedTransaction = useTransaction({
    me,
    transactionId,
    transactions,
    getTransaction,
  });

  const {
    id,
    lastEventSlug,
    buyerId,
    sellerId,
    subTotal,
    issue: { openedAt, arbitrationStartedAt } = {},
  } = extendedTransaction;

  let role;
  let nbSteps = 0;
  if (buyerId === me.id) {
    role = BUYER;
    nbSteps = NB_STEPS_BUYER;
  }
  if (sellerId === me.id) {
    role = SELLER;
    nbSteps = NB_STEPS_SELLER;
  }

  const [type, setType] = useState();
  const [message, setMessage] = useState();
  const [buyerProofs, setBuyerProofs] = useState([]);
  const [proposedSolution, setProposedSolution] = useState();
  const [proposedRefund, setProposedRefund] = useState();
  const [response, setResponse] = useState();
  const [sellerProofs, setSellerProofs] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    history.replace('#', { currentStep: STEP_WELCOME });
  }, [history]);

  useEffect(() => {
    const allowedStatuses = [
      EVENT_TRANSACTION_SHIP,
      EVENT_TRANSACTION_DELIVERED,
      EVENT_TRANSACTION_OPEN_ISSUE,
      EVENT_TRANSACTION_UPDATE_ISSUE,
      EVENT_TRANSACTION_CLOSE_ISSUE,
    ];

    if (lastEventSlug && !allowedStatuses.includes(lastEventSlug)) {
      history.push('/');
    }
  }, [
    history,
    lastEventSlug,
  ]);

  const {
    openModal: openBadTransactionModal,
    closeModal: hideBadTransactionModal,
    modalState: badTransactionModal,
  } = useModal(false);

  const {
    openModal: openNotReceivedModal,
    closeModal: hideNotReceivedModal,
    modalState: notReceivedModal,
  } = useModal(false);

  const {
    openModal: openConfirmModal,
    closeModal: hideConfirmModal,
    modalState: confirmModal,
  } = useModal(false);

  // No flow = only show recap
  // Don't show if confirmModal is being displayed
  const noFlow = !confirmModal && ((role === BUYER && openedAt) || arbitrationStartedAt);

  return (
    <div className={classes.container}>
      <SimpleModal
        show={badTransactionModal}
        onHide={hideBadTransactionModal}
        text={(
          <Trans t={t} i18nKey="badTransactionModal.text">
            Please go back to your
            <a href="/transactions">transactions list</a>
            and select the transaction you want to open a dispute about.
          </Trans>
        )}
        buttonLabel={t('badTransactionModal.buttonLabel')}
      />
      <SimpleModal
        show={notReceivedModal}
        onHide={hideNotReceivedModal}
        text={(
          <Trans t={t} i18nKey="notReceivedModal.text">
            If you have not received your order, please contact
            <a href="mailto:support@tripartie.com">our support team</a>
            .
          </Trans>
        )}
        buttonLabel={t('notReceivedModal.buttonLabel')}
      />
      <SimpleModal
        show={confirmModal}
        onHide={() => {
          hideConfirmModal();
          history.push(`/transactions/${id}`);
        }}
        image={
          <img className={classes.envelopeIcon} src={envelope} alt="envelope" />
        }
        title={t('confirmModal.title')}
        text={t(`confirmModal.text.${role}`)}
        buttonLabel={t('confirmModal.buttonLabel')}
      />

      <DisputeResolutionHeader
        nbSteps={nbSteps}
        currentStep={currentStep}
        showProgress={currentStep !== STEP_WELCOME}
      />

      {/* No flow (recap only) ;  */}
      {noFlow && (
        <IssueRecap
          transaction={extendedTransaction}
          role={role}
          back={() => {
            history.goBack();
          }}
        />
      )}

      {/* Buyer flow */}
      {!noFlow && role === BUYER && (
        <>
          {currentStep === STEP_WELCOME && (
            <Welcome
              me={me}
              transaction={extendedTransaction}
              role={role}
              back={() => {
                history.goBack();
              }}
              next={() => {
                history.push('#', {
                  currentStep: STEP_BUYER_CHOOSE_TRANSACTION,
                });
              }}
            />
          )}
          {currentStep === STEP_BUYER_CHOOSE_TRANSACTION && (
            <ChooseTransaction
              transaction={extendedTransaction}
              back={() => {
                history.goBack();
              }}
              next={(selected) => {
                if (selected) {
                  history.push('#', { currentStep: STEP_BUYER_ISSUE_TYPE });
                } else {
                  openBadTransactionModal();
                }
              }}
            />
          )}
          {currentStep === STEP_BUYER_ISSUE_TYPE && (
            <IssueType
              type={type}
              back={() => {
                history.goBack();
              }}
              next={(type) => {
                if (type !== NOT_RECEIVED) {
                  setType(type);
                  history.push('#', { currentStep: STEP_BUYER_ISSUE_MESSAGE });
                } else {
                  openNotReceivedModal();
                }
              }}
            />
          )}
          {currentStep === STEP_BUYER_ISSUE_MESSAGE && (
            <IssueMessage
              message={message}
              back={() => {
                history.goBack();
              }}
              next={(message) => {
                setMessage(message);
                history.push('#', { currentStep: STEP_BUYER_ADD_PROOF });
              }}
            />
          )}
          {currentStep === STEP_BUYER_ADD_PROOF && (
            <IssueAddProof
              proofs={buyerProofs}
              back={() => {
                history.goBack();
              }}
              next={(proofs) => {
                setBuyerProofs(proofs);
                history.push('#', { currentStep: STEP_BUYER_CHOOSE_SOLUTION });
              }}
            />
          )}
          {currentStep === STEP_BUYER_CHOOSE_SOLUTION && (
            <ChooseSolution
              proposedSolution={proposedSolution}
              back={() => {
                history.goBack();
              }}
              next={(proposedSolution) => {
                setProposedSolution(proposedSolution);
                switch (proposedSolution) {
                  case SOLUTION_PARTIAL:
                    history.push('#', {
                      currentStep: STEP_BUYER_CHOOSE_REFUND,
                    });
                    break;
                  case SOLUTION_RETURN:
                    history.push('#', { currentStep: STEP_BUYER_CONFIRM });
                    break;
                  default:
                }
              }}
            />
          )}
          {currentStep === STEP_BUYER_CHOOSE_REFUND && (
            <ChooseRefund
              transaction={extendedTransaction}
              proposedRefund={proposedRefund}
              back={() => {
                history.goBack();
              }}
              next={(proposedRefund) => {
                setProposedRefund(proposedRefund);
                history.push('#', { currentStep: STEP_BUYER_CONFIRM });
              }}
            />
          )}
          {currentStep === STEP_BUYER_CONFIRM && (
            <ConfirmDispute
              role={role}
              proposedSolution={proposedSolution}
              transaction={extendedTransaction}
              proposedRefund={proposedRefund}
              me={me}
              back={() => {
                history.goBack();
              }}
              submitting={submitting}
              confirm={() => {
                const mediaPromises = [];
                const medias = [];

                setSubmitting(true);
                buyerProofs.forEach((proof) => {
                  mediaPromises.push(
                    loadSingleMedia(proof).then(
                      ({ payload: { data: { id } = [] } = {} }) => {
                        medias.push(id);
                      },
                    ),
                  );
                });

                Promise.all(mediaPromises)
                  .then(() => openIssue(id, {
                    type,
                    message,
                    proposedSolution,
                    proposedRefund:
                        proposedSolution === SOLUTION_PARTIAL
                          ? proposedRefund
                          : subTotal,
                    medias,
                  }).then(() => {
                    setSubmitting(false);
                    openConfirmModal();
                  }))
                  .catch(() => {
                    setSubmitting(false);
                    toast.error(t('error'));
                  });
              }}
            />
          )}
        </>
      )}

      {/* Seller flow */}
      {!noFlow && role === SELLER && (
        <>
          {currentStep === STEP_WELCOME && (
            <Welcome
              me={me}
              transaction={extendedTransaction}
              role={role}
              back={() => {
                history.goBack();
              }}
              next={() => {
                history.push('#', { currentStep: STEP_SELLER_REVIEW });
              }}
            />
          )}
          {currentStep === STEP_SELLER_REVIEW && (
            <IssueRecap
              transaction={extendedTransaction}
              role={role}
              back={() => {
                history.goBack();
              }}
              next={() => {
                history.push('#', { currentStep: STEP_SELLER_RESPONSE });
              }}
            />
          )}
          {currentStep === STEP_SELLER_RESPONSE && (
            <ChooseResponse
              response={response}
              transaction={extendedTransaction}
              back={() => {
                history.goBack();
              }}
              next={(response) => {
                setResponse(response);
                if (response === RESPONSE_OK) {
                  history.push('#', { currentStep: STEP_SELLER_CONFIRM });
                }
                if (response === RESPONSE_NO) {
                  history.push('#', { currentStep: STEP_SELLER_ADD_PROOF });
                }
              }}
            />
          )}
          {currentStep === STEP_SELLER_ADD_PROOF && (
            <IssueAddProof
              proofs={sellerProofs}
              back={() => {
                history.goBack();
              }}
              next={(proofs) => {
                setSellerProofs(proofs);
                history.push('#', { currentStep: STEP_SELLER_CONFIRM });
              }}
            />
          )}
          {currentStep === STEP_SELLER_CONFIRM && (
            <ConfirmDispute
              role={role}
              transaction={extendedTransaction}
              response={response}
              me={me}
              back={() => {
                history.goBack();
              }}
              submitting={submitting}
              confirm={() => {
                const mediaPromises = [];
                const medias = [];

                setSubmitting(true);
                sellerProofs.forEach((proof) => {
                  mediaPromises.push(
                    loadSingleMedia(proof).then(
                      ({ payload: { data: { id } = [] } = {} }) => {
                        medias.push(id);
                      },
                    ),
                  );
                });

                Promise.all(mediaPromises)
                  .then(() => updateIssue(id, {
                    response,
                    medias,
                  }).then(() => {
                    setSubmitting(false);
                    openConfirmModal();
                  }))
                  .catch(() => {
                    setSubmitting(false);
                    toast.error(t('error'));
                  });
              }}
            />
          )}
        </>
      )}

      <Footer className={classes.footer} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  transactions: state.persistent.transactionsReducer.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  getTransaction: bindActionCreators(getTransaction, dispatch),
  openIssue: bindActionCreators(openIssue, dispatch),
  updateIssue: bindActionCreators(updateIssue, dispatch),
  loadSingleMedia: bindActionCreators(loadSingleMedia, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisputeResolution);
