import React, { useCallback, useEffect, useState } from 'react';
import {
  deleteTransactionTemplate,
  getOwnTransactionTemplates,
  selectTemplatesCount,
  selectTemplatesItems,
  selectTemplatesLoading,
} from 'logic/actions/transactionTemplatesActions';
import { useDispatch, useSelector } from 'react-redux';
import TransactionTemplateListItem from 'components/TransactionTemplateListItem';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/paymentLinksTab.module.scss';
import useWindowSize from 'helpers/useWindowSize';
import Loader from 'components/Loader';
import parseObjectValuesToArray from 'helpers/parseObjectValuesToArray';
import PermalinkModal from 'components/PermalinkModal';
import { CURRENCY_EUR } from 'helpers/constants';
import useModal from 'hooks/useModal';
import DeleteTemplateModal from 'components/DeleteTemplateModal';
import { toast } from 'react-toastify';

const PaymentLinksTab = ({
  template: newTemplate,
  isCurrentTab,
}) => {
  const { isMobile } = useWindowSize();

  const { t } = useTranslation('transactions');

  const dispatch = useDispatch();

  const [template, setTemplate] = useState();

  const {
    id,
    title,
    subTotal,
    currency = CURRENCY_EUR,
    permalink,
  } = template || {};

  const {
    modalState: permalinkModal,
    openModal: openPermalinkModal,
    closeModal: closePermalinkModal,
  } = useModal(false);

  const {
    modalState: deleteTemplateModal,
    openModal: openDeleteTemplateModal,
    closeModal: closeDeleteTemplateModal,
  } = useModal(false);

  const [deleting, setDeleting] = useState(false);

  const templates = useSelector(selectTemplatesItems);
  const count = useSelector(selectTemplatesCount);
  const loading = useSelector(selectTemplatesLoading);

  const deleteTemplate = useCallback(() => {
    setDeleting(true);
    dispatch(deleteTransactionTemplate(id))
      .then(() => {
        closeDeleteTemplateModal();
      })
      .catch(() => {
        toast.error(t('deleteError'));
      })
      .finally(() => {
        setDeleting(false);
      });
  }, [
    id,
    closeDeleteTemplateModal,
    dispatch,
    t,
  ]);

  useEffect(() => {
    if (count === undefined || isCurrentTab) {
      dispatch(getOwnTransactionTemplates());
    }
  }, [dispatch, count, isCurrentTab]);

  useEffect(() => {
    if (newTemplate) {
      setTemplate(newTemplate);
      openPermalinkModal();
    }
  }, [
    newTemplate,
    openPermalinkModal,
  ]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PermalinkModal
        permalink={permalink}
        show={permalinkModal}
        currency={currency}
        onHide={closePermalinkModal}
        subTotal={subTotal}
        title={title}
      />
      <DeleteTemplateModal
        show={deleteTemplateModal}
        onHide={closeDeleteTemplateModal}
        onDelete={deleteTemplate}
        loading={deleting}
      />
      {!isMobile && (
        <h2 className={classes.paymentLinksTab__title}>{t('myTemplates')}</h2>
      )}
      {count === 0 && (
        <p className={classes.paymentLinksTab__message}>{t('noTemplates')}</p>
      )}
      <div className={classes.paymentLinksTab__container}>
        {parseObjectValuesToArray(templates).map((template) => (
          <TransactionTemplateListItem
            key={template.id}
            template={template}
            setTemplate={setTemplate}
            openPermalinkModal={openPermalinkModal}
            openDeleteTemplateModal={openDeleteTemplateModal}
          />
        ))}
      </div>
    </>
  );
};

export default PaymentLinksTab;
