import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { loggedIn } from 'logic/actions/meActions';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const PrivateMobileOnlyRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(loggedIn);
  const { state: { previousPath = '/' } = {} } = useLocation();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referer: rest.location },
        }}
      />
    );
  }

  if (!isMobile) {
    return <Redirect to={previousPath} />;
  }

  return <Route component={Component} {...rest} />;
};

PrivateMobileOnlyRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateMobileOnlyRoute;
