const parseUserDevice = () => {
  const { userAgent, language } = window.navigator;

  const {
    screen: {
      colorDepth,
      width: screenWidth,
      height: screenHeight,
    },
  } = window;

  const timeZoneOffset = (new Date().getTimezoneOffset() || 0).toString();

  return {
    userAgent,
    javaEnabled: window.navigator.javaEnabled(),
    language,
    colorDepth,
    screenHeight,
    screenWidth,
    timeZoneOffset,
    javascriptEnabled: true,
  };
};

export default parseUserDevice;
