import React from 'react';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/disputeResolutionRadioButton.module.scss';
import { ReactComponent as CheckMark } from 'assets/images/disputeResolution/checkMark.svg';

/**
 * To use this you MUST povide a unique id
 * id must satisfy the condition (id != false)
 */
const DisputeResolutionRadioButton = ({ id, title, ...props }) => (
  <div>
    <Form.Check.Input
      id={id}
      type="radio"
      className={classes.radio}
      {...props}
    />
    <label htmlFor={id} className={classes.label}>
      <span className={classes.title}>{title}</span>
      <span className={classes.checkmark}>
        <CheckMark />
      </span>
    </label>
  </div>
);

export default DisputeResolutionRadioButton;
