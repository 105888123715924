import React from 'react';

const Cat = (props) => (
  <svg viewBox="0 0 534.2 457" {...props}>
    <g data-name="Calque 2">
      <g data-name="Calque 1">
        <path
          d="M534.2 264s-38.37-13.49-91.83-30.29c-4.73 18.75-13.37 38.45-21.15 67.21C483.7 283.94 534.2 264 534.2 264zM80.3 225.77q-3.87 2.34-7.71 4.71h-.07l-2.21 1.37q-4.55 2.84-9 5.66l-2.1 1.34-.31.2-1.81 1.16-.37.24-1.75 1.13-.4.25-1.83 1.2-.22.14-4.19 2.83-.21.14-1.8 1.18-.42.28-1.52 1-.58.38-1.35.89-.61.41-1.34.89-.58.39-1.37.92-.51.34-3.66 2.46-.49.33-1.22.82-.78.53-.94.64-.76.52-1 .66-.74.5-.91.63-.74.5-.93.64-.68.46-1.48 1-.71.49-.86.6-.71.49-.74.51-.77.54-.65.45-.85.58-.48.34-.83.58-.67.47-.65.44c-.25.19-.51.36-.76.54l-1.15.81-.54.37-.74.53-.45.31-.68.48-.48.34-.65.46-.42.29-.67.48-.25.19c-.24.16-.47.33-.69.49l-.33.23-.6.43-.33.23-1 .71-.41.3-.41.29-.37.26-.42.3-.34.25-.4.29-.27.18L3 277l-.26.19-1.65 1.19h-.06l-.26.19-.07.06-.18.12-.14.1-.28.21-.14.1c89.37 34 174.84 44.53 277.74 45 31 .15 66.52-5.25 101.1-12.88.58-2.34 1.2-4.64 1.84-6.89-22.64-.82-77.64-10.48-184.05-11.54C85.36 291.73 78.1 245.92 80.3 225.77z"
          fill="#e5f4ff"
        />
        <path
          d="M286 265.64c-27.36-10.86-71.67-40.83-113.8-44.74s-46.93-.75-46.93-.75-24 5.67-20.57-8.41c-6.32 3.43-12.78 7.13-19.24 11-1.71 1-3.41 2-5.12 3.07-2.2 20.15 5.06 66 116.29 67.08C303 293.91 358 303.57 380.68 304.46a212.14 212.14 0 0121.7-50.54c-53 27.36-89.05 22.58-116.38 11.72z"
          fill="#e5f4ff"
        />
        <path
          d="M277.74 324.23c-102.9-.47-188.37-11-277.74-45 0 0 84.58 99.21 207.32 108.11 75.47 5.48 130-5.88 167.47-19.43-2.51-20-.17-39.47 4-56.56-34.53 7.65-70.08 13.03-101.05 12.88zM416.46 349c3.59-2 6.81-3.86 9.63-5.56C454.71 326.29 524 267.15 534.2 264c0 0-50.5 20-113 36.92-1 3.66-2 7.43-2.91 11.4a95 95 0 00-1.83 36.68z"
          fill="#d8eeff"
        />
        <path
          d="M142.92 197.13c46.67 18.59 57-9.89 111.55 13.84S387.21 257.42 435 158.26c-25.59-64-173.71-40.84-173.71-40.84s-9.81-44.29-48.19-59.54c0 0-.1-45.51-24-57.88 0 0-23.92 13.75-23.92 40.14 0 0-25.57-2.48-40.41 17.86 0 0-23.92-18.41-48.11-12.92 0 0-6.87 25.57 15.12 56.08.03 0 4.46 77.38 51.14 95.97z"
          fill="#004bb4"
        />
        <path
          d="M102 178c6.59 9.05 5.49 22.65 2.43 34.6a12.11 12.11 0 0015.4 14.63c13.85-4.43 32.29-8.15 52.3-6.3 42.13 3.91 86.44 33.88 113.8 44.74s63.42 15.64 116.42-11.72c0 0-75.6 118.9 18.88 194.19 0 0 19.68-38.88 46.25-27.56 0 0-66.43-36.42-49.2-108.26s42.31-90.05 16.73-154c-47.83 99.16-126 76.45-180.57 52.71s-64.88 4.75-111.55-13.84-51.11-96-51.11-96S79.71 147.33 102 178z"
          fill="#0045ae"
        />
        <path
          d="M467.51 420.55c-26.57-11.32-46.25 27.56-46.25 27.56S445 463.65 460 453.64s7.51-33.09 7.51-33.09z"
          fill="#69d9af"
        />
        <path
          d="M188.25 25.09s-8.2 4-11.43 17.4A28.43 28.43 0 00196 44.73s.21-11.93-7.75-19.64zM88.39 60.87s-.72 9.13 9.23 18.67a28.5 28.5 0 0011.61-15.38s-10.16-6.26-20.84-3.29z"
          fill="#039"
        />
        <ellipse
          cx={185.52}
          cy={120.96}
          rx={28.13}
          ry={29.8}
          transform="rotate(-30 185.526 120.971)"
          fill="#69d9af"
        />
        <path
          d="M172.23 98.87a23.37 23.37 0 0017.91 8.79s5.65-4 6-14.36c-.05 0-18.14-7.4-23.91 5.57z"
          fill="#039"
        />
        <path
          d="M185.52 100.27l4.62 7.39s6 13.3-1.65 19.71M127.81 100.38a13.75 13.75 0 0113.75-13.75M184.36 72.68a13.75 13.75 0 0119.45 0M201.64 117.07a20.13 20.13 0 01-27.17 8.58M204.21 104.2s6.45-11.2 16.13-12.7M208.81 114.08a19.78 19.78 0 0116.13-5.88M211.85 123.72s5.51-3.48 17.08-1.65M160.46 117.71s-13.31-2.6-22.9-1M162.46 128.44s-15.27-1.57-20.75 4.11M167 139.27s-13.12 1.17-17 10"
          fill="none"
          stroke="#039"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
        />
      </g>
    </g>
  </svg>
);

export default Cat;
