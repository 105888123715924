import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import classes from 'assets/style/newTransactionTemplateModal.module.scss';
import { useTranslation } from 'react-i18next';
import useCurrencyHelpers from 'helpers/useCurrencyHelpers';
import * as yup from 'yup';
import {
  CURRENCIES,
  // DELIVERY_METHOD_IN_PERSON,
  DELIVERY_METHOD_REMOTE,
  FLOW_OBJECTS,
  FLOW_SERVICES,
  HOMEPAGE_TAB_KEY,
  HOMEPAGE_TABS,
  PRODUCT_CATEGORIES,
  // TRANSACTION_DELIVERY_METHODS,
  TRANSACTION_TEMPLATE_IMAGE_FORMATS,
  TRANSACTION_TEMPLATE_IMAGE_MAX_SIZE,
} from 'helpers/constants';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import CropImageModal from 'components/CropImageModal';
import InputGroup from 'react-bootstrap/InputGroup';
import DocumentsPicker from 'components/DocumentsPicker';
import { Formik } from 'formik';
import { createTransactionTemplate } from 'logic/actions/transactionTemplatesActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RadioCheckbox from 'components/RadioCheckbox';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import localStorage from 'helpers/localStorage';
import Button from 'components/Button';

const FILE_SIZE = TRANSACTION_TEMPLATE_IMAGE_MAX_SIZE;
const SUPPORTED_FORMATS = TRANSACTION_TEMPLATE_IMAGE_FORMATS;

const NewTransactionTemplateModal = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { t } = useTranslation(['newTransaction', '_productCategories']);

  const currencyHelper = useCurrencyHelpers();

  const validationSchema = useMemo(
    () => yup.object({
      flow: yup.string().required(t('form.emptyFlow')),
      title: yup
        .string()
        .trim()
        .max(150, t('form.longString'))
        .required(t('form.emptyTitle')),
      productCategory: yup.string().required(t('form.emptyProductCategory')),
      description: yup
        .string()
        .trim()
        .required(t('form.emptyDescription')),
      subTotal: yup
        .number()
        .typeError(t('form.notNumber'))
        .min(1, t('form.smallNumber', { number: '1' }))
        .max(2000, t('form.largeNumber', { number: '2 000' }))
        .required(t('form.emptySubTotal')),
      currency: yup.string().required(t('form.emptyCurrency')),
      delivery: yup.object({
        value: yup.string(),
        shippingCosts: yup.boolean(),
        requestValues: yup.object({
          allowInPerson: yup.boolean(),
          allowDelivery: yup.boolean(),
        }),
      }),
      shippingFees: yup
        .number()
        .typeError(t('form.notNumber'))
        .positive(t('form.positiveNumber'))
        .max(100, t('form.largeNumber', { number: '100' })),
      picture: yup
        .mixed()
        .test(
          'fileSize',
          t('form.fileTooBig'),
          (file) => !file || file.size <= FILE_SIZE,
        )
        .test(
          'fileFormat',
          t('form.formatNotSupported'),
          (file) => !file || SUPPORTED_FORMATS.includes(file.type),
        ),
    }),
    [t],
  );

  const initialValues = useMemo(
    () => ({
      flow: FLOW_OBJECTS,
      title: '',
      productCategory: '',
      description: '',
      currency: 'EUR',
      subTotal: '',
      picture: '',
      shippingFees: '',
      delivery: {
        value: DELIVERY_METHOD_REMOTE,
        shippingCosts: true,
        requestValues: {
          allowInPerson: false,
          allowDelivery: true,
        },
      },
    }),
    [],
  );

  const handleCreateTransactionTemplate = useCallback(
    ({
      subTotal, shippingFees, delivery, ...data
    }, { setSubmitting }) => {
      const { requestValues } = delivery;

      dispatch(
        createTransactionTemplate({
          subTotal: subTotal * 100,
          shippingFees: shippingFees ? shippingFees * 100 : undefined,
          ...requestValues,
          ...data,
        }),
      )
        .then(({ payload: { data: template } = {} }) => {
          onHide();
          localStorage.set(HOMEPAGE_TAB_KEY, HOMEPAGE_TABS.LINKS);
          history.push('/transactions', {
            template,
            [HOMEPAGE_TAB_KEY]: HOMEPAGE_TABS.LINKS,
          });
        })
        .catch(({ error }) => {
          toast.error(error.response?.data?.errors?.main?.[0]);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [
      dispatch,
      history,
      onHide,
    ],
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={classes.newTransactionTemplateModal}
      dialogClassName={classes.newTransactionTemplateModal__dialog}
      contentClassName={classes.newTransactionTemplateModal__content}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={handleCreateTransactionTemplate}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            errors,
            isSubmitting,
            setFieldError,
          }) => {
            const { symbol, before } = currencyHelper(values.currency);

            const CurrencySelector = ({ as, className }) => (
              <DropdownButton
                title={symbol}
                as={as}
                variant="outline-secondary"
                className={className}
              >
                {CURRENCIES.map((currency) => (
                  <Dropdown.Item
                    key={currency}
                    onClick={() => {
                      setFieldValue('currency', currency);
                    }}
                    onKeyPress={() => {
                      setFieldValue('currency', currency);
                    }}
                  >
                    {currencyHelper(currency).symbol}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            );

            return (
              <Form noValidate onSubmit={handleSubmit}>
                {values.picture && <CropImageModal />}
                <div className={classes.newTransactionTemplateModal__block}>
                  <p className={classes.newTransactionTemplateModal__title}>
                    {t('block1Title')}
                  </p>
                  <p className={classes.newTransactionTemplateModal__subTitle}>
                    {t('block1SubTitle')}
                  </p>
                  <div className="mb-3">
                    <div
                      className={
                        classes.newTransactionTemplateModal__checkboxGroup
                      }
                    >
                      <RadioCheckbox
                        id={FLOW_OBJECTS}
                        value={FLOW_OBJECTS}
                        checked={values.flow === FLOW_OBJECTS}
                        onChange={(e) => {
                          setFieldValue('flow', e.target.value);
                        }}
                      >
                        {t('form.object')}
                      </RadioCheckbox>
                      <RadioCheckbox
                        disabled
                        id={FLOW_SERVICES}
                        value={FLOW_SERVICES}
                        checked={values.flow === FLOW_SERVICES}
                        onChange={(e) => {
                          setFieldValue('flow', e.target.value);
                        }}
                      >
                        {t('form.service')}
                      </RadioCheckbox>
                    </div>
                    <Form.Control.Feedback
                      type="invalid"
                      className={errors.flow && 'd-block'}
                    >
                      {errors.flow}
                    </Form.Control.Feedback>
                  </div>
                  <Form.Group controlId="title">
                    <Form.Label
                      className={classes.newTransactionTemplateModal__label}
                    >
                      {t('form.title')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('form.titlePlaceholder')}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.title}
                      className={classes.newTransactionTemplateModal__input}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="productCategory">
                    <Form.Label
                      className={classes.newTransactionTemplateModal__label}
                    >
                      {t('form.productCategory')}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={values.productCategory}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.productCategory}
                      className={classes.newTransactionTemplateModal__select}
                      required
                    >
                      <option value="">{t('form.categoryPlaceholder')}</option>
                      {PRODUCT_CATEGORIES.map((productCategory) => (
                        <option key={productCategory} value={productCategory}>
                          {t(`_productCategories:${productCategory}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.productCategory}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="description">
                    <Form.Label
                      className={classes.newTransactionTemplateModal__label}
                    >
                      {t('form.description')}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength={300}
                      placeholder={t('form.descriptionPlaceholder')}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.description}
                      className={
                        classes.newTransactionTemplateModal__description
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className={classes.newTransactionTemplateModal__block}>
                  <p className={classes.newTransactionTemplateModal__title}>
                    {t('block2Title')}
                  </p>
                  <p className={classes.newTransactionTemplateModal__subTitle}>
                    {t('block2SubTitle')}
                  </p>
                  <DocumentsPicker
                    acceptFileTypes={SUPPORTED_FORMATS}
                    documents={values.picture ? [values.picture] : []}
                    errorMsg={errors.picture}
                    selectDocument={(doc) => {
                      setFieldValue('picture', doc);
                    }}
                    removeDocument={() => {
                      setFieldValue('picture', null);
                    }}
                    clearErrors={() => {
                      setFieldError('picture', '');
                    }}
                    formatNotSupportedMsg={t('form.formatNotSupported')}
                    addButtonText={t('form.picture.label')}
                    maxDocuments={1}
                  />
                </div>

                <div className={classes.newTransactionTemplateModal__block}>
                  <p className={classes.newTransactionTemplateModal__title}>
                    {t('block3Title')}
                  </p>
                  <p className={classes.newTransactionTemplateModal__subTitle}>
                    {t('block3SubTitle')}
                  </p>
                  <Form.Group controlId="subTotal">
                    <Form.Label
                      className={classes.newTransactionTemplateModal__label}
                    >
                      {t('form.subTotal')}
                    </Form.Label>
                    <InputGroup>
                      {before && (
                        <CurrencySelector
                          as={InputGroup.Prepend}
                          className={classNames(
                            classes.newTransactionTemplateModal__currencySelector,
                            {
                              [classes[
                                'newTransactionTemplateModal__currencySelector-invalid'
                              ]]: !!errors.subTotal,
                            },
                          )}
                        />
                      )}
                      <Form.Control
                        placeholder={t('form.pricePlaceholder')}
                        value={values.subTotal}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (!Number.isNaN(values.subTotal)) {
                            setFieldValue(
                              'subTotal',
                              Math.trunc(values.subTotal * 100) / 100,
                            );
                          }
                          handleBlur(e);
                        }}
                        isInvalid={!!errors.subTotal}
                        className={classes.newTransactionTemplateModal__input}
                      />
                      {!before && <CurrencySelector as={InputGroup.Append} />}
                    </InputGroup>
                    <Form.Control.Feedback
                      type="invalid"
                      className={errors.subTotal && 'd-block'}
                    >
                      {errors.subTotal}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className={classes.newTransactionTemplateModal__block}>
                  <p className={classes.newTransactionTemplateModal__title}>
                    {t('block4Title')}
                  </p>
                  <p className={classes.newTransactionTemplateModal__subTitle}>
                    {t('block4SubTitle')}
                  </p>

                  {/* <div
                    className={
                      classes.newTransactionTemplateModal__shippingCostsCheckboxes
                    }
                  >
                    {TRANSACTION_DELIVERY_METHODS.map((deliveryMethod) => (
                      <RadioCheckbox
                        key={deliveryMethod.value}
                        id={deliveryMethod.value}
                        value={deliveryMethod}
                        checked={values.delivery.value === deliveryMethod.value}
                        onChange={() => {
                          setFieldValue('delivery', deliveryMethod);
                          setFieldValue('shippingFees', '');
                          setFieldError('shippingFees', '');
                        }}
                      >
                        {t(`form.delivery.${deliveryMethod.value}`)}
                      </RadioCheckbox>
                    ))}
                  </div> */}

                  {values.delivery.shippingCosts && (
                    <Form.Group
                      controlId="shippingFees"
                      className={
                        classes.newTransactionTemplateModal__shippingCostsInputGroup
                      }
                    >
                      <Form.Label
                        className={classes.newTransactionTemplateModal__label}
                      >
                        {t('form.shippingFees')}
                      </Form.Label>
                      <InputGroup>
                        {before && (
                          <CurrencySelector
                            as={InputGroup.Prepend}
                            className={classNames(
                              classes.newTransactionTemplateModal__currencySelector,
                              {
                                [classes[
                                  'newTransactionTemplateModal__currencySelector-invalid'
                                ]]: !!errors.shippingFees,
                              },
                            )}
                          />
                        )}
                        <Form.Control
                          placeholder={t('form.shippingFeesPlaceholder')}
                          value={values.shippingFees}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.shippingFees}
                          className={classes.newTransactionTemplateModal__input}
                        />
                        {!before && <CurrencySelector as={InputGroup.Append} />}
                      </InputGroup>
                      <Form.Control.Feedback
                        type="invalid"
                        className={errors.shippingFees && 'd-block'}
                      >
                        {errors.shippingFees}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </div>

                <div className="d-flex">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    className={classes.newTransactionTemplateModal__button}
                  >
                    {t('form.submit')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

NewTransactionTemplateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default NewTransactionTemplateModal;
