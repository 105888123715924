import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import classes from 'assets/style/takePictures/confirmModal.module.scss';

const ConfirmModal = ({
  show,
  onHide,
  title,
  confirmText,
  rejectText,
  onConfirm,
  onReject,
  img,
  text,
}) => (
  <Modal show={show} onHide={onHide} centered className={classes.confirmModal}>
    {img}
    <Modal.Header>
      <div className={classes.confirmModal__header}>{title}</div>
    </Modal.Header>
    {text}
    <Modal.Body>
      <div className={classes.confirmModal__body}>
        <Button onClick={onConfirm}>{confirmText}</Button>
        <Button variant="outline-primary" onClick={onReject}>
          {rejectText}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  rejectText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  img: PropTypes.element,
  text: PropTypes.string,
};

ConfirmModal.defaultProps = {
  img: <></>,
  text: '',
  onHide: () => {},
};

export default ConfirmModal;
