import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import BankAccountForm from 'components/BankAccountForm';
import 'assets/style/modal-payment.scss';

const AddBankAccountModal = ({
  show,
  onSubmit,
  onHide,
  countries = [],
  initialValues,
}) => {
  const { t } = useTranslation('addBankAccountModal');
  return (
    <Modal show={show} onHide={onHide} centered className="modal-add-payment">
      <Modal.Header className="modal-add-payment__header" closeButton>
        <div className="modal-add-payment__header__content">{t('title')}</div>
      </Modal.Header>

      <Modal.Body>
        <div className="form-content">
          <BankAccountForm
            countries={countries}
            onSubmit={onSubmit}
            initialValues={initialValues}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddBankAccountModal;
