import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/takePictures/slider.module.scss';
import upArrow from 'assets/images/up-arrow.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Slider = ({ rulesType }) => {
  const { t } = useTranslation('takePictures');

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <div className={classes.slider}>
      <input
        id="slider"
        name="slider"
        type="checkbox"
        checked={open}
        onChange={handleOpen}
      />
      <label
        htmlFor="slider"
        className={classNames(classes.slider__label, {
          [classes.slider__labelActive]: open,
        })}
      >
        <img src={upArrow} alt="arrow" />
      </label>
      <div
        className={classNames(classes.slider__title, {
          [classes.slider__titleWithBorder]: open,
        })}
      >
        {t('slider.title')}
      </div>
      <div
        className={classNames(classes.slider__content, {
          [classes.slider__contentInvisible]: !open,
        })}
      >
        <p>{`▶ ${t(`slider.${rulesType}.rule1`)}`}</p>
        <p>{`▶ ${t(`slider.${rulesType}.rule2`)}`}</p>
        <p>{`▶ ${t(`slider.${rulesType}.rule3`)}`}</p>
      </div>
    </div>
  );
};

Slider.propTypes = {
  rulesType: PropTypes.string.isRequired,
};

export default Slider;
