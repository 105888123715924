import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dryRunTransactionFromTemplate } from 'logic/actions/transactionTemplatesActions';
import { ERRORS_FROM_SERVER } from 'helpers/constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useDryRunTransaction = ({
  transactionTemplateId: id,
  transaction,
  setTransaction,
  transactionTemplate,
}) => {
  const { t } = useTranslation('checkout');

  const dispatch = useDispatch();

  const [promocode, setPromocode] = useState();
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const updateTransaction = useCallback(
    async (id, subTotal, promocode) => {
      const token = await executeRecaptcha('transaction');

      return dispatch(
        dryRunTransactionFromTemplate(id, { subTotal, promocode, captcha: token }),
      );
    },
    [dispatch, executeRecaptcha],
  );

  const applyNewOfferPrice = useCallback(
    ({ subTotal }, { resetForm }) => updateTransaction(id, subTotal * 100, promocode).then(
      ({ payload: { data } }) => {
        setTransaction(data);
        resetForm();
      },
    ),
    [updateTransaction, id, promocode, setTransaction],
  );

  const applyPromocode = useCallback(
    ({ promocode }, { setFieldError }) => {
      const currentPrice = transaction.subTotal !== transactionTemplate.subTotal
        ? transaction.subTotal
        : transactionTemplate.subTotal;

      setLoading(true);
      updateTransaction(id, currentPrice, promocode.toUpperCase())
        .then(({ payload: { data } }) => {
          setPromocode(promocode);
          setTransaction(data);
        })
        .catch(({ error }) => {
          switch (error.response?.data?.errors) {
            case ERRORS_FROM_SERVER.PROMOCODE_NOT_FOUND: {
              return setFieldError('promocode', t('notFoundPromocode'));
            }
            case ERRORS_FROM_SERVER.INVALID_PROMOCODE: {
              return setFieldError('promocode', t('invalidPromocode'));
            }
            default:
              return setFieldError('promocode', t('invalidPromocode'));
          }
        })
        .finally(() => setLoading(false));
    },
    [
      transaction.subTotal,
      transactionTemplate.subTotal,
      updateTransaction,
      id,
      setTransaction,
      t,
    ],
  );

  const clearDiscount = useCallback(() => {
    setPromocode(undefined);
    updateTransaction(id, transaction.subTotal, undefined).then(
      ({ payload: { data } }) => {
        setTransaction(data);
      },
    );
  }, [
    setPromocode,
    updateTransaction,
    transaction.subTotal,
    id,
    setTransaction,
  ]);

  const resetToTransactionTemplate = useCallback(() => {
    setPromocode(undefined);
    setTransaction(transactionTemplate);
  }, [setPromocode, setTransaction, transactionTemplate]);

  return {
    promocode,
    setPromocode,
    applyNewOfferPrice,
    applyPromocode,
    clearDiscount,
    resetToTransactionTemplate,
    loading,
  };
};

useDryRunTransaction.propTypes = {
  transaction: PropTypes.shape({
    subTotal: PropTypes.number.isRequired,
  }).isRequired,
  transactionTemplate: PropTypes.shape({
    subTotal: PropTypes.number.isRequired,
  }).isRequired,
  setTransaction: PropTypes.func.isRequired,
};

export default useDryRunTransaction;
