import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import classes from 'assets/style/errorMessageBlock.module.scss';
import omit from 'lodash/omit';

const ErrorMessagesBlock = ({ skipErrors }) => {
  const { errors } = useFormikContext();
  const errorFields = [...Object.keys(omit(errors, skipErrors))];

  return (
    <div className={classes.errorMessageBlock}>
      {errorFields.map((fieldWithError) => (
        <p
          className={classes.errorMessageBlock__errorItem}
          key={fieldWithError}
        >
          {errors[fieldWithError]}
        </p>
      ))}
    </div>
  );
};

ErrorMessagesBlock.propTypes = {
  skipErrors: PropTypes.arrayOf(PropTypes.string),
};

ErrorMessagesBlock.defaultProps = {
  skipErrors: [''],
};

export default ErrorMessagesBlock;
