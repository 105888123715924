import React, { useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import 'assets/style/modal-payment.scss';
import PaymentFormDisclaimer from 'components/PaymentFormDisclaimer';
import ErrorMessagesBlock from 'components/ErrorMessagesBlock';
import FormReset from 'components/FormReset';

const PaymentCardForm = ({ isCheckoutPage, onSubmit }) => {
  const { t } = useTranslation('paymentCardForm');

  const validateCardExpirationDate = ({ cardExpirationDate }) => {
    const errors = {};

    if (cardExpirationDate) {
      const [month, year] = cardExpirationDate.split('/');
      const monthToNumber = Number(month);
      const yearToNumber = Number(year);
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;

      if (monthToNumber > 12 || monthToNumber < 1) {
        errors.cardExpirationDate = t('invalidMonth');
        return errors;
      }

      if (yearToNumber < 1) {
        errors.cardExpirationDate = t('invalidYear');
        return errors;
      }

      if (yearToNumber < currentYear) {
        errors.cardExpirationDate = t('cardExpired');
        return errors;
      }

      if (yearToNumber === currentYear && monthToNumber < currentMonth) {
        errors.cardExpirationDate = t('cardExpired');
        return errors;
      }
    }

    return errors;
  };

  const initialValues = useMemo(
    () => ({
      cardNumber: '',
      cardExpirationDate: '',
      cardCvx: '',
    }),
    [],
  );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        cardNumber: yup
          .string()
          .required(t('emptyCardNumber'))
          .matches(/^([0-9]{4} ?){4}$/, t('invalidCardNumber')),
        cardExpirationDate: yup
          .string()
          .required(t('emptyCardExpirationDate'))
          .matches(/^[0-9]{2}\/[0-9]{2}$/, t('invalidCardExpirationDate')),
        cardCvx: yup
          .string()
          .required(t('emptyCardCvx'))
          .matches(/^[0-9]{3}$/, t('invalidCardCvx')),
      })}
      onSubmit={({ cardNumber, cardExpirationDate, ...rest }, formikBag) => {
        onSubmit(
          {
            cardNumber: cardNumber.replace(/\s/g, ''),
            cardExpirationDate: cardExpirationDate.replace(/\//g, ''),
            ...rest,
          },
          formikBag,
        );
      }}
      initialValues={initialValues}
      validate={validateCardExpirationDate}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormReset defaults={initialValues} />
          <span className="payment-form__new-card-label">{t('title')}</span>
          <div className="groupedForm">
            <Row>
              <Col className="form-group-border-top">
                <Form.Group controlId="cardNumber">
                  <Form.Control
                    type="text"
                    maxLength={19}
                    name="cardNumber"
                    value={values.cardNumber}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(
                        /([0-9]{4}) *(?=[0-9])/g,
                        '$1 ',
                      );
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.cardNumber && !!errors.cardNumber}
                    placeholder="1234 1234 1234 1234"
                  />
                  <div className="line line-bottom" />
                </Form.Group>
              </Col>
            </Row>
            <Row className="groupedForm-inline">
              <Col className="form-group-border-bottom-left">
                <Form.Group controlId="cardExpirationDate">
                  <Form.Control
                    type="text"
                    maxLength={5}
                    name="cardExpirationDate"
                    value={values.cardExpirationDate}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(
                        /([0-9]{2})[./-]?(?=[0-9])/g,
                        '$1/',
                      );
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.cardExpirationDate && !!errors.cardExpirationDate
                    }
                    placeholder="MM/AA"
                  />
                  <div className="line line-top" />
                  <div className="line-vertical line-right" />
                </Form.Group>
              </Col>
              <Col className="form-group-border-bottom-right">
                <Form.Group controlId="cardCvx">
                  <Form.Control
                    type="text"
                    maxLength={3}
                    name="cardCvx"
                    value={values.cardCvx}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.cardCvx && !!errors.cardCvx}
                    placeholder="CVC"
                  />
                  <div className="line line-top" />
                  <div className="line-vertical line-left" />
                </Form.Group>
              </Col>
            </Row>
          </div>
          {!!Object.keys(errors).length && <ErrorMessagesBlock />}
          {isCheckoutPage ? (
            <div>
              <PaymentFormDisclaimer />
              <Button
                className="mt-2 btn-block"
                type="submit"
                isLoading={isSubmitting}
              >
                {t('checkoutSubmit')}
              </Button>
            </div>
          ) : (
            <Row>
              <Col className="form-group-border-bottom">
                <Button
                  type="submit"
                  className="modal-add-payment__button-submit"
                  isLoading={isSubmitting}
                >
                  {t('submit')}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PaymentCardForm;
