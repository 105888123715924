const validateGoogleAddress = (availableCountries, isoCodes) => {
  const validateCountry = (address) => {
    const isoCode = address?.countryIso2 || isoCodes?.[address?.country];

    return isoCode && availableCountries.includes(isoCode);
  };

  const validateEmptyCountry = (address) => address && !!Object.keys(address).length;

  const validateFields = (address) => address?.addressLine1
    && address?.city
    && address?.postcode
    && address?.country;

  const parseEmptyFields = (address) => Object.entries(address)
    .filter(([, value]) => !value)
    .map(([key]) => key);

  return {
    validateCountry,
    validateFields,
    parseEmptyFields,
    validateEmptyCountry,
  };
};

export default validateGoogleAddress;
