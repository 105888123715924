import iconAdd from 'assets/images/add-icon.svg';
import downArrow from 'assets/images/down-arrow.svg';
import iconHelpCenter from 'assets/images/icon-help-center_desktop.svg';
import iconLogout from 'assets/images/icon-logout_desktop.svg';
import iconMenuOff from 'assets/images/icon-menu-off.svg';
import iconMenuOn from 'assets/images/icon-menu-on.svg';
import iconSettings from 'assets/images/icon-settings_desktop.svg';
import logo from 'assets/images/logo-dark.svg';
import 'assets/style/header.scss';
import Button from 'components/Button';
import HeaderResponsive from 'components/HeaderResponsive';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import { logout } from 'logic/actions/authActions';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import localStorage from 'helpers/localStorage';
import { HOMEPAGE_TAB_KEY, HOMEPAGE_TABS } from 'helpers/constants';
import ProfilePicture from 'components/ProfilePicture';
import useWindowSize from 'helpers/useWindowSize';
import useModal from 'hooks/useModal';
import NewTransactionTemplateModal from 'components/NewTransactionTemplateModal';
import { selectCurrentTransactionsCount } from 'logic/actions/transactionsActions';
import RoundIcon from 'components/RoundIcon';

const Header = ({ me: { userProfile: { firstName } = {} } = {}, logout }) => {
  const { t } = useTranslation(['header', '_links']);

  const setLoadingScreen = useLoadingScreen();
  const [showMenu, setShowMenu] = useState(false);

  const currentTransactionsCount = useSelector(selectCurrentTransactionsCount);

  const { isMobile } = useWindowSize();

  const menuRef = useRef();

  const {
    modalState: transactionTemplateModal,
    openModal: openTransactionTemplateModal,
    closeModal: closeTransactionTemplateModal,
  } = useModal(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
    if (menuRef && menuRef.current) {
      menuRef.current.className += ' d-flex';
      document.body.className += 'overflow-hidden';
    }
  }, [menuRef, setShowMenu]);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
    if (menuRef && menuRef.current) {
      menuRef.current.className = menuRef.current.className
        ? menuRef.current.className.replaceAll(/ ?d-flex/g, '')
        : '';
      document.body.className = document.body.className.replaceAll(/ ?overflow-hidden/g, '');
    }
  }, [menuRef, setShowMenu]);

  useEffect(() => {
    if (showMenu && !isMobile) {
      closeMenu();
    }
  }, [
    isMobile,
    closeMenu,
    showMenu,
  ]);

  const handleLogout = useCallback(() => {
    setLoadingScreen(true);
    logout().finally(() => {
      setLoadingScreen(false);
    });
  }, [setLoadingScreen, logout]);

  const showCurrentTransactionsCount = useMemo(
    () => currentTransactionsCount > 0,
    [currentTransactionsCount],
  );

  return (
    <>
      <NewTransactionTemplateModal
        onHide={closeTransactionTemplateModal}
        show={transactionTemplateModal}
      />
      <div className="header-main bg-white">
        <div className="my-transactions header__left">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
          <Button onClick={openTransactionTemplateModal}>
            <img
              src={iconAdd}
              alt="add"
              className="header_new-transaction__icon"
            />
            {t('newTransaction')}
          </Button>
        </div>
        <div className="header-main__block-info">
          <div className="my-transactions">
            <NavLink exact activeClassName="header__link--active" to="/">
              <span className="my-transactions__text-info clickable">
                {t('dashboard')}
              </span>
            </NavLink>
          </div>
          <div className="my-transactions">
            <NavLink
              exact
              activeClassName="header__link--active"
              to="/settings/wallets"
            >
              <span className="my-transactions__text-info clickable">
                {t('wallet')}
              </span>
            </NavLink>
          </div>
          <div className="my-transactions my-transactions__widthIcon">
            <NavLink
              exact
              activeClassName="header__link--active"
              to="/transactions"
              onClick={() => {
                localStorage.set(HOMEPAGE_TAB_KEY, HOMEPAGE_TABS.IN_PROGRESS);
              }}
              onKeyDown={() => {
                localStorage.set(HOMEPAGE_TAB_KEY, HOMEPAGE_TABS.IN_PROGRESS);
              }}
            >
              <span className="my-transactions__text-info clickable">
                {t('transactions')}
              </span>
            </NavLink>
            {showCurrentTransactionsCount && (
              <RoundIcon className="my-transactions__icon">
                {currentTransactionsCount}
              </RoundIcon>
            )}
          </div>
          <div className="my-info clickable">
            <Dropdown className="custom-profile-dropdown">
              <Dropdown.Toggle variant="none">
                <ProfilePicture className="custom-profile-dropdown__profile-pic" />
                {firstName}
                <img className="icon-down" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/settings/profile">
                  <span className="d-flex">
                    <img className="icon-dropdown" src={iconSettings} alt="" />
                    {t('settings')}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  href={t('_links:supportCenter')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="d-flex">
                    <img
                      className="icon-dropdown"
                      src={iconHelpCenter}
                      alt=""
                    />
                    {t('helpCenter')}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout} onKeyDown={handleLogout}>
                  <span className="d-flex">
                    <img className="icon-dropdown" src={iconLogout} alt="" />
                    {t('logout')}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="header-main__block-icon-menu">
          {showMenu ? (
            <div
              onClick={closeMenu}
              onKeyDown={closeMenu}
              role="button"
              tabIndex={0}
            >
              <img
                className="header-main__icon-menu-off clickable"
                src={iconMenuOff}
                alt=""
              />
            </div>
          ) : (
            <div
              onClick={openMenu}
              onKeyDown={openMenu}
              role="button"
              tabIndex={0}
            >
              <img
                className="header-main__icon-menu-on clickable"
                src={iconMenuOn}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <HeaderResponsive
        hideMenu={closeMenu}
        onOpenTransactionTemplateModal={openTransactionTemplateModal}
        menuRef={menuRef}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  logout: bindActionCreators(logout, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
