import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import classes from 'assets/style/takePictures/notAvailableModal.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DownArrowWithLine from 'assets/svg/DownArrowWithLine';

const NotAvailableModal = ({ show, onHide, qrCode }) => {
  const { t } = useTranslation('takePictures');

  return (
    <Modal show={show} onHide={onHide} centered id={classes.notAvailableModal}>
      <Modal.Header closeButton>
        <p className={classes.notAvailableModal__title}>
          {t('notAvailableModal.title')}
        </p>
      </Modal.Header>
      <Modal.Body>
        <p
          className={classNames(
            classes.notAvailableModal__text,
            classes['notAvailableModal__text--top'],
          )}
        >
          {t('notAvailableModal.textTop')}
        </p>
        <div className={classes.notAvailableModal__content}>
          <DownArrowWithLine />
          <div className={classes.notAvailableModal__qrCode}>
            {qrCode && <img src={qrCode} alt="qrcode" />}
          </div>
        </div>
        <p
          className={classNames(
            classes.notAvailableModal__text,
            classes['notAvailableModal__text--bottom'],
          )}
        >
          {t('notAvailableModal.textBottom')}
        </p>
      </Modal.Body>
    </Modal>
  );
};

NotAvailableModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  qrCode: PropTypes.string.isRequired,
};

export default NotAvailableModal;
