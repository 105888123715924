import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { CURRENCY_EUR } from 'helpers/constants';
import { ReactComponent as ShowLinkIcon } from 'assets/images/show-link-icon.svg';
import { ReactComponent as LinkIcon } from 'assets/images/link-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash-icon.svg';
import classes from 'assets/style/transactionTemplateListItem.module.scss';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

const TransactionTemplateListItem = ({
  template,
  setTemplate,
  openPermalinkModal,
  openDeleteTemplateModal,
}) => {
  const {
    title, subTotal, currency = CURRENCY_EUR, permalink,
  } = template;

  const { handleCopyToClipboard } = useCopyToClipboard();

  const handleDelete = useCallback(() => {
    setTemplate(template);
    openDeleteTemplateModal();
  }, [
    openDeleteTemplateModal,
    setTemplate,
    template,
  ]);

  const handleOpenPermalinkModal = useCallback(() => {
    setTemplate(template);
    openPermalinkModal();
  }, [
    openPermalinkModal,
    setTemplate,
    template,
  ]);

  const handleCopy = useCallback(() => {
    handleCopyToClipboard(permalink);
  }, [
    handleCopyToClipboard,
    permalink,
  ]);

  return (
    <div className={classes.transactionTemplateListItem}>
      <div className={classes.transactionTemplateListItem__title}>{title}</div>
      <div className={classes.transactionTemplateListItem__info}>
        <span className={classes.transactionTemplateListItem__price}>
          <FormattedNumber
            value={subTotal / 100}
            style="currency"
            currency={currency}
          />
        </span>
        <div className={classes.transactionTemplateListItem__line} />
        <button
          type="button"
          onClick={handleOpenPermalinkModal}
          className={classes.transactionTemplateListItem__icon}
        >
          <ShowLinkIcon />
        </button>
        <button
          type="button"
          onClick={handleCopy}
          className={classes.transactionTemplateListItem__icon}
        >
          <LinkIcon />
        </button>
        <button
          type="button"
          onClick={handleDelete}
          className={classes.transactionTemplateListItem__icon}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

TransactionTemplateListItem.propTypes = {
  template: PropTypes.shape({
    title: PropTypes.string,
    subTotal: PropTypes.number,
    currency: PropTypes.string,
    permalink: PropTypes.string,
  }),
  setTemplate: PropTypes.func.isRequired,
  openPermalinkModal: PropTypes.func.isRequired,
  openDeleteTemplateModal: PropTypes.func.isRequired,
};

TransactionTemplateListItem.defaultProps = {
  template: {},
};

export default TransactionTemplateListItem;
