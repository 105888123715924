import classes from 'assets/style/login.module.scss';
import Footer from 'components/Footer';
import LoginForm from 'components/LoginForm';
import LoginHeader from 'components/LoginHeader';
import { getToken } from 'logic/actions/authActions';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

const Login = ({
  getToken,
  loggedIn,
  location: { state: { referer = '/', externalId, transactionId } = {} } = {},
}) => {
  const { t } = useTranslation('login');

  const loginErrorToast = useCallback(() => toast.error(t('form.loginError')), [
    t,
  ]);

  if (loggedIn) {
    const queryParams = queryString.parse(referer?.search);

    const externalId = queryParams['external-id'];

    if (externalId) {
      return <Redirect to={referer?.pathname || referer} />;
    }

    return <Redirect to={referer} />;
  }

  const authPayloadWithExternalId = externalId
    ? {
      externalId,
      transactionId,
    }
    : {};

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <LoginHeader />
          <p className={classes.title}>{t('title')}</p>
          <LoginForm
            externalId={externalId}
            transactionId={transactionId}
            referer={referer}
            onSubmit={(values, { resetForm }) => {
              getToken({ ...values, ...authPayloadWithExternalId }).catch(
                () => {
                  loginErrorToast();
                  resetForm({
                    values: {
                      ...values,
                      password: '',
                    },
                  });
                },
              );
            }}
          />
        </div>
        <Footer className={classes.footer} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  getToken: bindActionCreators(getToken, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
