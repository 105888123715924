import React, { useEffect, useState, useCallback } from 'react';
import sortBy from 'lodash/sortBy';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'components/Button';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sort } from 'helpers/stringHelpers';
import { ERROR_EMAIL_ALREADY_EXISTS, STATUS_VERIFIED } from 'helpers/constants';
import {
  refreshMe,
  updateMe,
  deleteMyAccount,
  updateMyLang,
} from 'logic/actions/meActions';
import { logout } from 'logic/actions/authActions';
import { checkEmail } from 'logic/actions/usersActions';
import { getCountries } from 'logic/actions/countriesActions';
import AddProfilePicture from 'pages/settings/AddProfilePicture';
import useModal from 'hooks/useModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import LanguageSelector from 'components/LanguageSelector';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Profile = ({
  me: {
    email,
    identityVerifyStatus,
    canBeDeleted,
    userProfile: {
      firstName, lastName, birthday, nationality, residency,
    } = {},
    lang: emailLang,
  } = {},
  countries = [],
  refreshMe,
  updateMe,
  deleteMyAccount,
  checkEmail,
  getCountries,
  logout,
  updateMyLang,
}) => {
  const { t, i18n } = useTranslation(['profile', '_countries']);
  const updateSuccessToast = useCallback(
    () => toast.success(t('form.updateSuccess')),
    [t],
  );
  const updateErrorToast = useCallback(
    () => toast.error(t('form.updateError')),
    [t],
  );

  const [previousEmail, setPreviousEmail] = useState(email);
  const [previousEmailTestResult, setPreviousEmailTestResult] = useState(true);

  const [nationalities, setNationalities] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    modalState: showConfirmDeleteModal,
    closeModal: closeConfirmDeleteModal,
    openModal: openConfirmDeleteModal,
  } = useModal(false);

  useEffect(() => {
    refreshMe();
    getCountries();
  }, [
    refreshMe,
    getCountries,
  ]);

  useEffect(() => {
    setNationalities(
      countries.filter(({ slug }) => i18n.exists(`_countries:nationality.${slug}`)),
    );
  }, [countries, i18n]);

  const [deleting, setDeleting] = useState(false);

  const deleteErrorToast = useCallback(() => toast.error(t('deleteError')), [
    t,
  ]);

  const handleDelete = useCallback(() => {
    setDeleting(true);
    deleteMyAccount()
      .then(() => {
        logout();
      })
      .catch(() => {
        deleteErrorToast();
      })
      .finally(() => {
        setDeleting(false);
      });
  }, [
    deleteErrorToast,
    deleteMyAccount,
    logout,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        onClose={closeConfirmDeleteModal}
        disabled={!canBeDeleted}
        onDelete={handleDelete}
        loading={deleting}
      />
      <AddProfilePicture />
      <div className="block-profile">
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={yup.object({
            firstName: yup.string().required(t('form.emptyFirstName')),
            lastName: yup.string().required(t('form.emptyLastName')),
            residency: yup.string().required(t('form.emptyResidency')),
            nationality: yup.string().required(t('form.emptyNationality')),
            email: yup
              .string()
              .required(t('form.emptyEmail'))
              .email(t('form.invalidEmail'))
              .test({
                name: 'emailValid',
                test: (newEmail, { createError }) => {
                  if (!newEmail || newEmail === previousEmail) {
                    return previousEmailTestResult;
                  }
                  setPreviousEmail(newEmail);
                  return checkEmail(newEmail)
                    .then(() => {
                      setPreviousEmailTestResult(true);
                      return true;
                    })
                    .catch(
                      ({
                        error: { response: { data: { errors } = {} } = {} } = {},
                      }) => {
                        let result = createError({
                          message: t('form.invalidEmail'),
                        });
                        if (errors === ERROR_EMAIL_ALREADY_EXISTS) {
                          result = createError({
                            message: t('form.alreadyUsedEmail'),
                          });
                        }
                        setPreviousEmailTestResult(result);
                        return result;
                      },
                    );
                },
              }),
            birthday: yup
              .date()
              .max(moment().subtract(18, 'years'), t('form.mustBeEighteen'))
              .required(t('form.emptyBirthday')),
          })}
          onSubmit={(
            {
              email, firstName, lastName, birthday, nationality, residency,
            },
            { resetForm },
          ) => {
            executeRecaptcha('updateUser').then(
              (token) => {
                updateMe({
                  email,
                  userProfile: {
                    firstName,
                    lastName,
                    birthday,
                    nationality,
                    residency,
                  },
                  captcha: token,
                })
                  .then(() => {
                    updateSuccessToast();
                  })
                  .catch(() => {
                    updateErrorToast();
                  })
                  .finally(() => {
                    resetForm({
                      values: {
                        email,
                        firstName,
                        lastName,
                        birthday,
                        nationality,
                        residency,
                      },
                    });
                  });
              },
            ).catch(() => { updateErrorToast(); });
          }}
          initialValues={{
            email,
            firstName,
            lastName,
            birthday,
            nationality,
            residency,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            dirty,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <p className="block-profile__title">{t('personalInformation')}</p>
              {identityVerifyStatus === STATUS_VERIFIED && (
                <Alert variant="warning">{t('warnKycRevocation')}</Alert>
              )}
              <Form.Group className="pb-1" controlId="firstName">
                <Form.Label className="profile-form__label">
                  {t('form.firstName')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.firstName && !!errors.firstName}
                  placeholder={t('form.firstName')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="pb-1" md="6" controlId="lastName">
                <Form.Label className="profile-form__label">
                  {t('form.lastName')}
                </Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.lastName && !!errors.lastName}
                  placeholder={t('form.lastName')}
                />
                {/* <span className="message-success">Enregistré</span> */}
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="pb-1" md="6" controlId="birthday">
                <Form.Label className="profile-form__label">
                  {t('form.birthday')}
                </Form.Label>
                <Form.Control
                  className={`form-control ${
                    !values.birthday ? 'placeholder' : ''
                  }`}
                  type="date"
                  max={moment()
                    .subtract(18, 'years')
                    .format('YYYY-MM-DD')}
                  name="birthday"
                  value={values.birthday}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.birthday && !!errors.birthday}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birthday}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="pb-1" controlId="email">
                <Form.Label className="profile-form__label">
                  {t('form.email')}
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                  placeholder={t('form.email')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                {errors.birthday}
              </Form.Control.Feedback>
              <Form.Group className="pb-1" controlId="nationality">
                <Form.Label className="profile-form__label">
                  {t('form.nationality')}
                </Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="nationality"
                  value={values.nationality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.nationality && !!errors.nationality}
                >
                  <option disabled selected>
                    {t('form.nationality')}
                  </option>
                  {sort(nationalities, ({ slug }) => t(`_countries:nationality.${slug}`)).map(({ slug }) => (
                    <option key={slug} value={slug} className="color-input">
                      {t(`_countries:nationality.${slug}`)}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.nationality}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="residency">
                <Form.Label className="profile-form__label">
                  {t('form.residency')}
                </Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="residency"
                  value={values.residency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.residency && !!errors.residency}
                >
                  <option disabled selected>
                    {t('form.residency')}
                  </option>
                  {sortBy(countries, ({ slug }) => t(`_countries:country.${slug}`)).map(({ slug }) => (
                    <option key={slug} value={slug} className="color-input">
                      {t(`_countries:country.${slug}`)}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.residency}
                </Form.Control.Feedback>
              </Form.Group>
              {dirty && (
                <Button
                  isLoading={isSubmitting}
                  className="btn-block"
                  type="submit"
                >
                  {t('form.submit')}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <div className="block-profile">
        <p className="block-profile__title block-profile__hidden">
          {t('settings')}
        </p>
        <div>
          <p className="profile-form__label font-weight-bold">
            {t('preferences.emailLangLabel')}
          </p>
          <LanguageSelector
            className="ml-1 p-1 border rounded d-inline-block"
            selectedLang={emailLang}
            onSelect={(lang) => {
              updateMyLang(lang);
            }}
          />
        </div>
      </div>
      <div className="block-profile">
        <p className="block-profile__title block-profile__hidden">
          {t('deleteMyAccount')}
        </p>
        {!canBeDeleted && (
          <div className="block-profile__delete-alert">
            {t('cannotBeDeleted')}
          </div>
        )}
        <Button
          className="block-profile__delete-profile block-profile__hidden"
          variant="danger"
          isLoading={deleting}
          disabled={!canBeDeleted}
          onClick={openConfirmDeleteModal}
        >
          {t('deleteMyAccount')}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  countries: state.persistent.countriesReducer.countries,
});

const mapDispatchToProps = (dispatch) => ({
  refreshMe: bindActionCreators(refreshMe, dispatch),
  updateMe: bindActionCreators(updateMe, dispatch),
  updateMyLang: bindActionCreators(updateMyLang, dispatch),
  deleteMyAccount: bindActionCreators(deleteMyAccount, dispatch),
  checkEmail: bindActionCreators(checkEmail, dispatch),
  getCountries: bindActionCreators(getCountries, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
