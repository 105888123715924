import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MediaContext from 'components/takePictures/mediaContext';
import { useLocation } from 'react-router-dom';
import useGeolocation from 'hooks/useGeolocation';
import useItemsPictures from 'hooks/useItemsPictures';
import useParcelPictures from 'hooks/useParcelPictures';
import { MEDIA_TYPES, VIDEO_MAX_DURATION } from 'helpers/constants';
import useTakePicturesButtons from 'hooks/useTakePicturesButtons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getBase64FromBlob } from 'helpers/decode';
import { isSafari } from 'react-device-detect';

const CAMERA_CONSTRAINTS = {
  audio: false,
  video: { facingMode: 'environment' },
};

const MediaContainer = ({ children }) => {
  const { t } = useTranslation('takePictures');

  const { pathname } = useLocation();
  const { latitude, longitude } = useGeolocation();
  const {
    contentType,
    isRecordingVideo,
    setIsRecordingVideo,
    changeContentType,
  } = useTakePicturesButtons();

  const { items, updateItemsActions } = useItemsPictures();

  const { parcel, updateParcelActions } = useParcelPictures();

  const video = useRef(null);
  const replay = useRef(null);
  const recorder = useRef(null);
  const videoFile = useRef(null);
  const timer = useRef(null);

  const [currentPreviewElementId, setCurrentPreviewElementId] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [recording, setRecording] = useState(null);
  const [streamStared, setStreamStared] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [preview, setPreview] = useState('');
  const [photoPreview, setPhotoPreview] = useState('');
  const [mediaStream, setMediaStream] = useState(null);
  const [loading, setLoading] = useState(false);

  const isSteamActive = useMemo(
    () => streamStared && mediaStream,
    [streamStared, mediaStream],
  );

  const actionTypes = useMemo(
    () => ({
      [MEDIA_TYPES.ITEMS]: updateItemsActions,
      [MEDIA_TYPES.PARCEL]: updateParcelActions,
    }),
    [
      updateItemsActions,
      updateParcelActions,
    ],
  );

  const mediaTypeFromUrl = useMemo(() => {
    if (pathname.includes(MEDIA_TYPES.ITEMS)) {
      return MEDIA_TYPES.ITEMS;
    }
    return MEDIA_TYPES.PARCEL;
  }, [pathname]);

  const showDeleteBtn = useCallback(() => {
    setShowDeleteButton(true);
  }, []);

  const stopStream = useCallback(() => {
    video.current.pause();
    video.current.src = '';
    video.current.srcObject = null;
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setMediaStream(null);
    setStreamStared(false);
  }, [mediaStream]);

  const takePhoto = useCallback(() => {
    video.current.pause();
    const canvas = document.createElement('canvas');
    canvas.width = video.current.videoWidth;
    canvas.height = video.current.videoHeight;
    canvas.getContext('2d').drawImage(video.current, 0, 0);
    canvas.toBlob((blob) => {
      const file = new File([blob], Date.now(), { type: blob.type });
      setPhoto(file);
      setPhotoPreview(canvas.toDataURL());
    });
  }, []);

  const handleRecordVideoSafari = useCallback((e) => {
    if (isSafari) {
      stopStream();
    }
    const video = e.target.files[0];
    videoFile.current = video;
    getBase64FromBlob(video).then((videoBase64) => {
      replay.current.src = videoBase64;
      setRecording(videoBase64);
    });
  }, [stopStream]);

  const startStream = useCallback(async () => {
    setLoading(true);
    try {
      if (!streamStared && !loading) {
        const stream = await navigator.mediaDevices.getUserMedia(
          CAMERA_CONSTRAINTS,
        );
        setMediaStream(stream);
        video.current.srcObject = stream;
        setStreamStared(true);

        if (window.MediaRecorder) {
          recorder.current = new MediaRecorder(stream);

          recorder.current.ondataavailable = (e) => {
            const options = { type: 'video/mp4;' };
            videoFile.current = new Blob([e.data], options);

            const videoUrl = window.URL.createObjectURL(videoFile.current);
            replay.current.src = videoUrl;
            setRecording(videoUrl);
          };
        }
      }
    } catch (e) {
      toast.error(t('errors.noAccess'));
    }
    setLoading(false);
  }, [
    streamStared,
    loading,
    t,
  ]);

  const savePhoto = useCallback(() => {
    const id = Date.now();
    if (photo) {
      const itemObject = {
        id,
        type: 'image',
        title: id,
        file: photo,
      };
      actionTypes[mediaTypeFromUrl].addNewMedia(id, itemObject);
      setPhoto('');
      setPhotoPreview('');
      video.current.play();
    }
  }, [
    photo,
    actionTypes,
    mediaTypeFromUrl,
  ]);

  const handleClosePhotoPreview = useCallback(() => {
    setPhoto('');
    setPhotoPreview('');
    setCurrentPreviewElementId(null);
    setShowDeleteButton(false);
    if (isSteamActive) {
      video.current.play();
    }
  }, [isSteamActive]);

  const deletePhoto = useCallback(() => {
    actionTypes[mediaTypeFromUrl].removeMedia(currentPreviewElementId);
    handleClosePhotoPreview();
  }, [
    currentPreviewElementId,
    actionTypes,
    handleClosePhotoPreview,
    mediaTypeFromUrl,
  ]);

  const stopRecordingVideo = useCallback(() => {
    setIsRecordingVideo(false);
    recorder.current.stop();
    clearTimeout(timer.current);
  }, [
    recorder,
    setIsRecordingVideo,
  ]);

  const generatePreviewForVideo = useCallback((video) => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    return canvas.toDataURL();
  }, []);

  const startRecordingVideo = useCallback(() => {
    setPreview(generatePreviewForVideo(video.current));

    setIsRecordingVideo(true);
    recorder.current.start();
    timer.current = setTimeout(() => {
      if (recorder.current.state === 'recording') {
        stopRecordingVideo();
      }
    }, VIDEO_MAX_DURATION);
  }, [
    recorder,
    generatePreviewForVideo,
    setIsRecordingVideo,
    stopRecordingVideo,
  ]);

  const handleCloseVideoPreview = useCallback(() => {
    setPreview('');
    setRecording('');
    videoFile.current = null;
    replay.current.src = '';
    setShowDeleteButton(false);
    setCurrentPreviewElementId(null);
    if (isSteamActive) {
      video.current.play();
    }
  }, [isSteamActive]);

  const deleteVideo = useCallback(() => {
    actionTypes[mediaTypeFromUrl].removeMedia(currentPreviewElementId);
    handleCloseVideoPreview();
  }, [
    currentPreviewElementId,
    actionTypes,
    handleCloseVideoPreview,
    mediaTypeFromUrl,
  ]);

  const saveVideo = useCallback(() => {
    const id = Date.now();
    if (videoFile.current) {
      const itemObject = {
        id,
        type: 'video',
        title: id,
        file: videoFile.current,
        preview: isSafari ? generatePreviewForVideo(replay.current) : preview,
      };
      actionTypes[mediaTypeFromUrl].addNewMedia(id, itemObject);
      videoFile.current = null;
      setRecording('');
      replay.current.src = '';
    } else {
      toast.error(t('errors.processVideo'));
    }
  }, [
    preview,
    actionTypes,
    generatePreviewForVideo,
    mediaTypeFromUrl,
    t,
  ]);

  useEffect(() => {
    if (recording) {
      replay.current.src = recording;
    }
  }, [recording]);

  return (
    <MediaContext.Provider
      value={{
        handleRecordVideoSafari,
        loading,
        isSteamActive,
        mediaStream,
        startStream,
        latitude,
        longitude,
        setStreamStared,
        stopStream,
        items,
        photo,
        photoPreview,
        deletePhoto,
        deleteVideo,
        streamStared,
        showDeleteButton,
        recording,
        videoFile,
        video,
        replay,
        contentType,
        isRecordingVideo,
        startRecordingVideo,
        stopRecordingVideo,
        changeContentType,
        takePhoto,
        setRecording,
        setPhoto,
        setPhotoPreview,
        showDeleteBtn,
        setCurrentPreviewElementId,
        handleClosePhotoPreview,
        savePhoto,
        handleCloseVideoPreview,
        saveVideo,
        parcel,
        updateItemsActions,
        updateParcelActions,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export default MediaContainer;
