import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'helpers/useWindowSize';
import { useHistory } from 'react-router-dom';
import classes from 'assets/style/checkoutSuccess.module.scss';
import Button from 'components/Button';
import IconFly from 'assets/images/icon-fly.svg';
import classNames from 'classnames';
import { getSuccessReturnUrl } from 'helpers/transactionTemplateReturnUrl';
import { CLIENT_TRIPARTIE } from 'helpers/constants';

const CheckoutSuccess = ({ adUrl, transactionId, client }) => {
  const { push } = useHistory();

  const { t } = useTranslation('checkoutSuccess');

  const linkToClient = useMemo(
    () => (adUrl ? getSuccessReturnUrl(adUrl, transactionId) : null),
    [
      adUrl,
      transactionId,
    ],
  );

  const isTripartie = useMemo(() => client === CLIENT_TRIPARTIE, [client]);

  const isBackToStoreButtonVisible = useMemo(
    () => !!linkToClient && !isTripartie,
    [isTripartie, linkToClient],
  );

  const goBackToStore = useCallback(() => {
    window.location = linkToClient;
  }, [linkToClient]);

  const goToTripartie = useCallback(() => {
    push('/');
  }, [push]);

  const { isNarrow } = useWindowSize();

  return (
    <div className={classes.checkoutSuccess}>
      <div className={classes.checkoutSuccess__container}>
        <div className={classes.checkoutSuccess__imageContainer}>
          <img src={IconFly} alt="" />
        </div>

        <h4
          className={classNames(
            'checkout__title',
            classes.checkoutSuccess__title,
          )}
        >
          {t('title')}
        </h4>
        <p className={classes.checkoutSuccess__subtitle}>{t('subTitle')}</p>
        {isBackToStoreButtonVisible && (
          <Button
            className={classNames(classes.checkoutSuccess__returnButton, {
              'w-100': isNarrow,
              'w-75': !isNarrow,
            })}
            onClick={goBackToStore}
          >
            {t('returnToAd', { client })}
          </Button>
        )}
        <Button
          variant="outline-primary"
          className={classNames(classes.checkoutSuccess__gotoTripartieButton, {
            'w-100': isNarrow,
            'w-75': !isNarrow,
          })}
          onClick={goToTripartie}
        >
          {t('goToMyTripartieAccount')}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
