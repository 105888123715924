import { createContext } from 'react';

const MediaContext = createContext({
  items: {
    byIds: {},
    ids: [],
  },
  parcel: {
    byIds: {},
    ids: [],
  },
});

export default MediaContext;
