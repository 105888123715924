import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useParseUserAddress = () => {
  const { t } = useTranslation('_countries');

  const parseUserAddress = useCallback((addressObject) => {
    if (!addressObject) {
      return undefined;
    }

    const {
      id,
      country: { slug, iso2 },
      ...rest
    } = addressObject;

    return {
      ...rest,
      isManualAddress: false,
      countryIso2: iso2,
      country: t(`country.${slug}`),
    };
  }, [t]);

  return {
    parseUserAddress,
  };
};

export default useParseUserAddress;
