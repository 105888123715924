import SwissPostIcon from 'assets/images/shippingProviders/swissPost.svg';
import ColissimoIcon from 'assets/images/shippingProviders/colissimo.svg';
import MondialRelayIcon from 'assets/images/shippingProviders/mondialRelay.svg';

const shippingProvidersConfig = {
  'swiss-post': {
    icon: SwissPostIcon,
    name: 'Swiss Post',
  },
  fedex: {
    name: 'FedEx',
  },
  colissimo: {
    icon: ColissimoIcon,
    name: 'Colissimo',
  },
  tnt: {
    name: 'TNT',
  },
  'chronopost-france': {
    name: 'Chronopost',
  },
  dpd: {
    name: 'DPD',
  },
  ups: {
    name: 'UPS',
  },
  dhl: {
    name: 'DHL',
  },
  'mondial-relay': {
    icon: MondialRelayIcon,
    name: 'Mondial Relay',
  },
};

export default shippingProvidersConfig;
