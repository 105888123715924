import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/deleteTemplateModal.module.scss';

const DeleteTemplateModal = ({
  show, onHide, onDelete, loading,
}) => {
  const { t } = useTranslation('confirmDeleteModal');

  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName={classes.deleteTemplateModal__content}
      centered
    >
      <p className={classes.deleteTemplateModal__title}>{t('title')}</p>

      <p className={classes.deleteTemplateModal__info}>{t('areYouSure')}</p>
      <Button
        className={classes.deleteTemplateModal__button}
        isLoading={loading}
        onClick={onDelete}
      >
        {t('confirm')}
      </Button>
      <Button variant="outline-primary" onClick={onHide}>
        {t('close')}
      </Button>
    </Modal>
  );
};

DeleteTemplateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DeleteTemplateModal;
