import { createReducer } from '@reduxjs/toolkit';
import { success, shouldResetState } from 'logic/reducers/helpers';
import {
  GET_OWN_ACTIVE_TRANSACTION_TEMPLATES,
  DELETE_TRANSACTION_TEMPLATE,
  CREATE_TRANSACTION_TEMPLATE,
} from 'logic/actions/transactionTemplatesActions';

const initialState = {
  loading: false,
  items: {},
  nbPages: 0,
  lastLoadedPage: 0,
  count: undefined,
};

const handleLoading = (state) => {
  state.loading = true;
};

const handleGetOwnTransactionTemplates = (
  state,
  {
    payload: {
      data: {
        page, nbPages = 0, items = [], count,
      } = {},
    } = {},
  },
) => {
  if (page) {
    state.items[page] = items;
    state.lastLoadedPage = page;
  }
  state.nbPages = nbPages;
  state.count = count;
  state.loading = false;
};

export default createReducer(
  initialState,
  {
    [GET_OWN_ACTIVE_TRANSACTION_TEMPLATES]: handleLoading,
    [success(GET_OWN_ACTIVE_TRANSACTION_TEMPLATES)]:
      handleGetOwnTransactionTemplates,
  },
  [
    {
      matcher: (action) => shouldResetState(action),
      reducer: () => initialState,
    },
    {
      matcher: ({ type }) => type === success(DELETE_TRANSACTION_TEMPLATE)
        || type === success(CREATE_TRANSACTION_TEMPLATE),
      reducer: () => initialState,
    },
  ],
);
