import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classes from 'assets/style/radioCheckOutlined.module.scss';

const RadioCheckOutlined = ({
  label,
  name,
  value,
  id,
  onChange,
  isChecked,
  ...rest
}) => (
  <label id={id} className={classes.radioCheckOutlined}>
    <Form.Check
      name={name}
      type="radio"
      value={value}
      id={id}
      onChange={onChange}
      checked={isChecked}
      className={classes.radioCheckOutlined__check}
      {...rest}
    />
    {label}
  </label>
);

RadioCheckOutlined.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default RadioCheckOutlined;
