import { useEffect, useState } from 'react';
import {
  EVENT_TRANSACTION_CANCEL_REQUESTED,
  EVENT_TRANSACTION_CLOSE_ISSUE,
} from 'helpers/constants';
import { getBase64FromBlob } from 'helpers/decode';
import allSettled from 'promise.allsettled';
import { getExternalId } from 'helpers/transactionTemplateReturnUrl';
import { useDispatch } from 'react-redux';
import { logout } from 'logic/actions/authActions';
import { useHistory } from 'react-router-dom';

function useTransaction({
  me,
  transactionId,
  transactions,
  getTransaction,
  fetchTransactionVerificationCode,
  fetchTransactionPicture,
}) {
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState(false);
  const transaction = transactions[transactionId] || { id: transactionId };

  const history = useHistory();

  const dispatch = useDispatch();

  const externalId = getExternalId(window.location.href);

  useEffect(() => {
    setLoading(true);

    getTransaction(transactionId)
      .then(({ payload: { data: transaction } = {} }) => {
        const promises = [];

        if (fetchTransactionVerificationCode && transaction.buyerId === me.id) {
          promises.push(fetchTransactionVerificationCode(transaction.id));
        }

        if (fetchTransactionPicture && transaction.hasPicture) {
          promises.push(
            fetchTransactionPicture(transaction.id).then(
              ({ payload: { data } = {} }) => {
                getBase64FromBlob(data).then(setPicture);
              },
            ),
          );
        }
        allSettled.shim();
        Promise.allSettled(promises).then(() => {
          setLoading(false);
        });
      })
      .catch(({ error }) => {
        if (error?.response?.status === 403) {
          if (externalId) {
            dispatch(logout());
          } else {
            history.push('/');
          }
        }
      });
  }, [
    transactionId,
    externalId,
    dispatch,
    fetchTransactionPicture,
    fetchTransactionVerificationCode,
    getTransaction,
    history,
    me.id,
  ]);

  const isPurchase = me.id === transaction.buyerId;
  const ownFees = isPurchase ? transaction.buyerFees : transaction.sellerFees;
  const ownTotal = isPurchase
    ? transaction.buyerTotal
    : transaction.sellerTotal;

  const showTimeline = !transaction.isRejected
    && !transaction.isCanceled
    && !transaction.isExpired
    && transaction.lastEventSlug !== EVENT_TRANSACTION_CLOSE_ISSUE;
  const showCancelButton = !transaction.isRejected
    && !transaction.isCanceled
    && !transaction.isCompleted
    && !transaction.isExpired
    && transaction.lastEventSlug !== EVENT_TRANSACTION_CLOSE_ISSUE
    && !(
      isPurchase
      && transaction.lastEventSlug === EVENT_TRANSACTION_CANCEL_REQUESTED
    );

  return {
    ...transaction,
    isPurchase,
    ownFees,
    ownTotal,
    picture,
    showTimeline,
    showCancelButton,
    loading,
  };
}

export default useTransaction;
