import React, {
  useEffect,
  useState,
} from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { loggedIn } from 'logic/actions/meActions';

const FormReset = ({ defaults }) => {
  const { resetForm, setFieldValue } = useFormikContext();

  const isLoggedIn = useSelector(loggedIn);
  const [resetted, setResetted] = useState(false);

  useEffect(() => {
    if (!isLoggedIn && !resetted) {
      setResetted(true);
      resetForm();
      Object.keys(defaults).forEach((value) => setFieldValue(value, defaults[value]));
    }
  }, [
    isLoggedIn,
    defaults,
    resetForm,
    setFieldValue,
    resetted,
    setResetted,
  ]);

  return <></>;
};

export default FormReset;
