import React, { useState, createContext, useContext } from 'react';

const LoadingScreenContext = createContext();
const LoadingScreenActionsContext = createContext();

const factoryUseContext = (name, context) => () => {
  const ctx = useContext(context);
  if (ctx === undefined) {
    throw new Error(`use${name}Context must be used withing a ${name}ContextProvider.`);
  }
  return ctx;
};

export const useLoadingScreenContext = factoryUseContext('LoadingScreenContext', LoadingScreenContext);
export const useLoadingScreen = factoryUseContext('LoadingScreenActionsContext', LoadingScreenActionsContext);

const LoadingScreenContextProvider = ({ children }) => {
  const [isLoadingScreenOn, setLoadingScreen] = useState(false);

  return (
    <LoadingScreenContext.Provider value={isLoadingScreenOn}>
      <LoadingScreenActionsContext.Provider value={setLoadingScreen}>
        {children}
      </LoadingScreenActionsContext.Provider>
    </LoadingScreenContext.Provider>
  );
};

export default LoadingScreenContextProvider;
