import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import LanguageSelector from 'components/LanguageSelector';
import classes from 'assets/style/tutoModal.module.scss';
import TutoModalRadioChecked from 'assets/svg/TutoModalRadioChecked';
import TutoModalRadioUnchecked from 'assets/svg/TutoModalRadioUnchecked';
import tutoModalBuyer1 from 'assets/images/tuto_modal_buyer_1.svg';
import tutoModalBuyer2 from 'assets/images/tuto_modal_buyer_2.svg';
import tutoModalBuyer3 from 'assets/images/tuto_modal_buyer_3.svg';

const HEADER_IMAGES = [tutoModalBuyer1, tutoModalBuyer2, tutoModalBuyer3];

const IMAGES = {
  buyer: HEADER_IMAGES,
  newUserGreetings: HEADER_IMAGES,
};

const TutoModals = ({ show, onHide, type }) => {
  const { t } = useTranslation('tutoModals');

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = IMAGES[type] || null;
  const tBase = `${type}.${currentIndex}`;

  const changeIndex = (index) => () => {
    setCurrentIndex(index);
  };

  const goBack = useCallback(() => {
    setCurrentIndex((currentIndex) => currentIndex - 1);
  }, [setCurrentIndex]);

  const goNext = useCallback(() => {
    setCurrentIndex((prev) => {
      if (prev === images.length - 1) {
        onHide();
        return 0;
      }
      return prev + 1;
    });
  }, [setCurrentIndex, images.length, onHide]);

  if (!images) {
    return null;
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      centered
      className={classes.tutoModal}
      contentClassName={classes.modalContent}
      dialogClassName={classes.modalDialog}
    >
      <Modal.Body className={classes.body}>
        <div className={classes.image}>
          <img src={images[currentIndex] || null} alt="" />
        </div>
        <div className={classes.content}>
          <div>
            {images.map((path, index) => (index === currentIndex ? (
              <TutoModalRadioChecked
                key={path}
                onClick={changeIndex(index)}
                className={classes.radio}
              />
            ) : (
              <TutoModalRadioUnchecked
                key={path}
                onClick={changeIndex(index)}
                className={classes.radio}
              />
            )))}
          </div>
          <p className={classes.title}>{t(`${tBase}.title`)}</p>
          <p className={classes.text}>{t(`${tBase}.text`)}</p>
          <Row className={classes.buttonsRow}>
            <Col>
              <Button
                variant="outline-primary"
                onClick={goBack}
                disabled={currentIndex === 0}
                className={classes.button}
              >
                {t('previous')}
              </Button>
            </Col>
            <Col>
              <Button onClick={goNext} className={classes.button}>
                {currentIndex === images.length - 1 ? t('end') : t('next')}
              </Button>
            </Col>
          </Row>
          {currentIndex !== images.length - 1 && (
            <Button
              variant="link"
              onClick={onHide}
              className={classes.hideButton}
            >
              {t('ignore')}
            </Button>
          )}
          <div className={classes.languageSelector}>
            <LanguageSelector small align="right" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TutoModals;
