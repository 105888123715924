import { createReducer } from '@reduxjs/toolkit';
import { success } from 'logic/reducers/helpers';
import { GET_CURRENCIES } from 'logic/actions/currenciesActions';

const initialState = { currencies: [] };

const handleGetCurrencies = (
  state,
  { payload: { data: currencies = [] } = {} },
) => {
  state.currencies = currencies;
};

export default createReducer(initialState, {
  [success(GET_CURRENCIES)]: handleGetCurrencies,
});
