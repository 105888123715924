import React from 'react';
import IconInfo from 'assets/svg/IconInfo';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/paymentFormDisclaimer.module.scss';

const PaymentFormDisclaimer = () => {
  const { t } = useTranslation('paymentCardForm');

  return (
    <div
      className={classes.paymentFromDisclaimer}
    >
      <IconInfo className={classes.paymentFromDisclaimer__icon} />
      <p className="keep-line-breaks">
        <small>{t('paymentCardForm:checkoutDisclaimer')}</small>
      </p>
    </div>
  );
};

export default PaymentFormDisclaimer;
