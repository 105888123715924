import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RequestResetPassword from 'pages/resetPassword/RequestResetPassword';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import { ROUTES } from 'helpers/constants';
import MailBox from 'pages/resetPassword/MailBox';

const ResetPasswordRoutes = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.RESET_PASSWORD.REQUEST}
      component={RequestResetPassword}
    />
    <Route exact path={ROUTES.RESET_PASSWORD.MAIL_INFO} component={MailBox} />
    <Route
      path={ROUTES.RESET_PASSWORD.NEW_PASSWORD}
      component={ResetPassword}
    />
  </Switch>
);

export default ResetPasswordRoutes;
