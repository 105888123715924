import React, { useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/manualAddressFormCheckout.module.scss';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAllCountries } from 'logic/actions/countriesActions';
import countriesMapper from 'helpers/countriesMapper';
import { AVAILABLE_COUNTRIES_SLUGS_FOR_PARCEL } from 'helpers/constants';

const CountriesSelect = ({ allowedCountries }) => {
  const { t } = useTranslation(['_countries', 'checkoutAddressForm']);

  const {
    values, errors, setFieldValue, touched, handleBlur,
  } = useFormikContext();

  const allCountries = useSelector(selectAllCountries);

  const formattedCountriesArray = useMemo(
    () => countriesMapper(allCountries),
    [allCountries],
  );

  const { isoCodes, slugFromIso } = formattedCountriesArray;

  const getOptions = useCallback(() => {
    if (allowedCountries) {
      return allowedCountries.map(
        (countryIsoCode) => slugFromIso[countryIsoCode],
      );
    }

    return AVAILABLE_COUNTRIES_SLUGS_FOR_PARCEL;
  }, [
    allowedCountries,
    slugFromIso,
  ]);

  const handleChange = useCallback((e) => {
    setFieldValue('manualAddress.countryIso2', e.target.value);
    setFieldValue(
      'manualAddress.country',
      t(`country.${slugFromIso[e.target.value]}`),
    );
  }, [
    setFieldValue,
    slugFromIso,
    t,
  ]);

  return (
    <Form.Group
      controlId="manualAddress.countryIso2"
      className={classes.manualAddressFormCheckout__formGroup}
    >
      <Form.Label>{t('checkoutAddressForm:country')}</Form.Label>
      <Form.Control
        as="select"
        name="manualAddress.countryIso2"
        value={values.manualAddress.countryIso2}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={
          touched?.manualAddress?.countryIso2
          && !!errors?.manualAddress?.countryIso2
        }
        className={classes.manualAddressFormCheckout__select}
      >
        <option value="" disabled>
          {t('checkoutAddressForm:countryPlaceholder')}
        </option>
        {getOptions().map((countrySlug) => (
          <option key={countrySlug} value={isoCodes[countrySlug]}>
            {t(`country.${countrySlug}`)}
          </option>
        ))}
      </Form.Control>
      <div className={classes.manualAddressFormCheckout__selectError}>
        {errors?.manualAddress?.countryIso2}
      </div>
    </Form.Group>
  );
};

export default CountriesSelect;
