import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/checkoutAuthStep.module.scss';
import { CHECKOUT_STEPS } from 'helpers/constants';
import SignupForm from 'components/SignupForm';
import LoginForm from 'components/LoginForm';
import { toast } from 'react-toastify';
import { getToken, logout } from 'logic/actions/authActions';
import { checkEmail, createUser } from 'logic/actions/usersActions';
import { getOwnPaymentCards } from 'logic/actions/paymentCardsActions';
import { connect } from 'react-redux';
import { getExternalId } from 'helpers/transactionTemplateReturnUrl';
import Button from 'components/Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const CheckoutAuthStep = ({
  templateId,
  loggedIn,
  me,
  checkEmail,
  getOwnPaymentCards,
  createUser,
  getToken,
  showSignup,
  setShowSignup,
  setActiveKey,
  logout,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation('checkout');
  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';

  const externalId = getExternalId(window.location.href);

  const { email, userProfile: { firstName, lastName } = {} } = me;

  const [loading, setLoading] = useState(false);

  const loginErrorToast = useCallback(() => {
    toast.error(t('loginError'));
  }, [t]);

  const signupErrorToast = useCallback(() => {
    toast.error(t('signupError'));
  }, [t]);

  const switchAccount = useCallback(() => {
    logout();
  }, [logout]);

  const gotoDelivery = useCallback(() => {
    setActiveKey(CHECKOUT_STEPS.delivery);
  }, [setActiveKey]);

  const authPayloadWithExternalId = externalId
    ? {
      externalId,
      transactionTemplateId: externalId ? templateId : undefined,
    }
    : {};

  if (loggedIn && email && !loading) {
    return (
      <div>
        <p className="checkout__title checkout__title--xlarge">
          {t('confirmation.title')}
        </p>
        <div className={classes.checkoutAuthStep__info}>
          <p>{`${firstName} ${lastName}`}</p>
          <p>{email}</p>
        </div>
        <div className={classes.checkoutAuthStep__buttons}>
          <Button className="btn-block" onClick={gotoDelivery}>
            {t('confirmation.confirm')}
          </Button>
          <Button
            variant="outline-primary"
            className="text-primary btn-block"
            onClick={switchAccount}
          >
            {t('confirmation.switch')}
          </Button>
        </div>
      </div>
    );
  }

  return showSignup ? (
    <>
      <h4 className="checkout__mb-25 checkout__title checkout__title--xlarge">
        {t('signup')}
      </h4>
      <SignupForm
        isCheckoutPage
        setShowSignup={setShowSignup}
        checkEmail={checkEmail}
        onSubmit={(
          {
            email, firstName, lastName, password,
          },
          { setSubmitting },
        ) => {
          setLoading(true);
          executeRecaptcha('signup').then(
            (token) => {
              createUser({
                email,
                plainPassword: password,
                userProfile: {
                  firstName,
                  lastName,
                },
                captcha: token,
                lang: currentLang,
                ...authPayloadWithExternalId,
              })
                .then(() => {
                  getToken({
                    email,
                    password,
                  })
                    .then(() => {
                      setActiveKey(CHECKOUT_STEPS.delivery);
                      setLoading(false);
                      getOwnPaymentCards();
                    })
                    .catch(() => {
                      loginErrorToast();
                      setShowSignup(false);
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                })
                .catch(() => {
                  signupErrorToast();
                  setSubmitting(false);
                });
            },
          ).catch(
            () => {
              signupErrorToast();
              setSubmitting(false);
            },
          );
        }}
      />
    </>
  ) : (
    <>
      <h4 className="mb-3 checkout__title checkout__title--xlarge">
        {t('login')}
      </h4>
      <LoginForm
        isCheckoutPage
        setShowSignup={setShowSignup}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          getToken({ ...values, ...authPayloadWithExternalId })
            .then(() => {
              setActiveKey(CHECKOUT_STEPS.delivery);
              setLoading(false);
              getOwnPaymentCards();
            })
            .catch(() => {
              loginErrorToast();
            })
            .finally(() => {
              resetForm({
                values: {
                  ...values,
                  password: '',
                },
              });
            });
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  me: state.persistent.meReducer.me,
});

const actionCreators = {
  getToken,
  createUser,
  checkEmail,
  getOwnPaymentCards,
  logout,
};

export default connect(mapStateToProps, actionCreators)(CheckoutAuthStep);
