import React, {
  useCallback, useContext, useRef, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/takePictures/takePicturesResult.module.scss';
import PageTitle from 'components/takePictures/PageTitle';
import MediaContext from 'components/takePictures/mediaContext';
import PreviewItem from 'components/takePictures/PreviewItem';
import closeIcon from 'assets/images/close-cross.svg';
import Button from 'components/Button';
import {
  PICTURES_UPLOAD_STATUS_KEY,
  TAKE_PICTURES_ROUTES,
} from 'helpers/constants';
import useModal from 'hooks/useModal';
import ConfirmModal from 'components/takePictures/ConfirmModal';
import Loader from 'components/takePictures/Loader';
import { useDispatch } from 'react-redux';
import { sendMediaToServer, createProof } from 'logic/actions/picturesActions';
import InformModal from 'components/takePictures/InformModal';
import itemsStorage from 'helpers/itemsStorage';
import parcelStorage from 'helpers/parcelStorage';
import { toast } from 'react-toastify';
import CheckedRoundedIcon from 'assets/svg/CheckedRoundedIcon';
import localStorage from 'helpers/localStorage';
import classNames from 'classnames';
// import mediaStreamActions from 'helpers/mediaStream';

const TakePicturesResult = () => {
  const dispatch = useDispatch();

  const [elementToDelete, setElementToDelete] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { state: { transactionId } = {} } = location;

  const {
    modalState: confirmDeleteModal,
    openModal: openConfirmDeleteModal,
    closeModal: closeConfirmDeleteModal,
  } = useModal(false);

  const { modalState: saveModal, openModal: openSaveModal } = useModal(false);

  const {
    updateItemsActions,
    updateParcelActions,
    items,
    parcel,
    latitude,
    longitude,
  } = useContext(MediaContext);

  const { t } = useTranslation('takePictures');

  const navigationBlocker = useRef(null);

  // useEffect(() => {
  //   mediaStreamActions.stopStream();
  // }, []);

  const gotoItems = useCallback(() => {
    history.push({
      pathname: TAKE_PICTURES_ROUTES.ITEMS_SCREEN,
      state: location.state,
    });
  }, [
    history,
    location.state,
  ]);

  const gotoParcel = useCallback(() => {
    history.push({
      pathname: TAKE_PICTURES_ROUTES.PARCEL_SCREEN,
      state: location.state,
    });
  }, [
    history,
    location.state,
  ]);

  const gotoTripartie = useCallback(() => {
    if (navigationBlocker.current) {
      navigationBlocker.current();
    }
    history.push('/');
  }, [
    history,
  ]);

  const openDeleteModal = (id, type) => () => {
    setElementToDelete({
      id,
      type,
    });
    openConfirmDeleteModal();
  };

  const deleteElement = useCallback(() => {
    if (elementToDelete.type === 'item') {
      updateItemsActions.removeMedia(elementToDelete.id);
    } else {
      updateParcelActions.removeMedia(elementToDelete.id);
    }
    closeConfirmDeleteModal();
  }, [
    elementToDelete.type,
    elementToDelete.id,
    closeConfirmDeleteModal,
    updateItemsActions,
    updateParcelActions,
  ]);

  const saveMedia = useCallback(() => {
    setLoading(true);
    navigationBlocker.current = history.block();
    dispatch(
      sendMediaToServer({
        items: items.current,
        parcel: parcel.current,
      }),
    )
      .then((res) => {
        dispatch(
          createProof({
            mediaArray: res.payload.data,
            transactionId,
            latitude,
            longitude,
          }),
        );
      })
      .then(() => {
        items.current = { ids: [], byIds: {} };
        parcel.current = { ids: [], byIds: {} };
        return Promise.all([itemsStorage.clear(), parcelStorage.clear()]).then(
          () => {
            openSaveModal();
            setLoading(false);
            localStorage.set(PICTURES_UPLOAD_STATUS_KEY, 'success');
          },
        );
      })
      .catch(() => toast.error(t('errors.loadMediaToServer')))
      .finally(() => {
        items.current = { ids: [], byIds: {} };
        parcel.current = { ids: [], byIds: {} };
        return Promise.all([itemsStorage.clear(), parcelStorage.clear()]).then(
          () => {
            setLoading(false);
          },
        );
      });
  }, [
    dispatch,
    history,
    items,
    latitude,
    longitude,
    openSaveModal,
    parcel,
    t,
    transactionId,
  ]);

  const disabledButton = loading
    || items.current.ids.length === 0
    || parcel.current.ids.length === 0;

  const removeEmptyBlock = useCallback(
    (elementsArray) => (elementsArray.length + 1) % 3 === 0,
    [],
  );

  if (loading || !(updateItemsActions.updated && updateParcelActions.updated)) return <Loader />;

  return (
    <div className={classes.takePicturesResult}>
      <InformModal
        show={saveModal}
        confirmCallback={gotoTripartie}
        title={t('successModal.title')}
        confirmText={t('successModal.confirm')}
        image={<CheckedRoundedIcon />}
      />
      <ConfirmModal
        show={confirmDeleteModal}
        onHide={closeConfirmDeleteModal}
        onConfirm={deleteElement}
        onReject={closeConfirmDeleteModal}
        title={t('deleteModal.title')}
        confirmText={t('deleteModal.confirm')}
        rejectText={t('deleteModal.reject')}
      />
      {!saveModal && (
        <>
          <PageTitle
            title={t('resultTitle')}
            step={3}
            goBack={history.goBack}
          />
          <div className={classes.takePicturesResult__container}>
            <div>
              <div className={classes.takePicturesResult__mediaContainer}>
                <span className={classes.takePicturesResult__title}>
                  Item(s)
                </span>
                <div className={classes.takePicturesResult__previewsContainer}>
                  {items.current.ids.map((itemId) => (
                    <div key={itemId}>
                      <Button
                        variant="light"
                        onClick={openDeleteModal(itemId, 'item')}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </Button>
                      <PreviewItem
                        media={items.current.byIds[itemId]}
                        disabled
                      />
                    </div>
                  ))}
                  {items.current.ids.length < 5 && (
                    <div>
                      <span />
                      <button
                        type="button"
                        className={classes.takePicturesResult__empty}
                        onClick={gotoItems}
                      >
                        +
                      </button>
                    </div>
                  )}
                  <div
                    className={classNames(
                      classes.takePicturesResult__emptyBlock,
                      {
                        'display-none': removeEmptyBlock(items.current.ids),
                      },
                    )}
                  />
                </div>
              </div>
              <div className={classes.takePicturesResult__mediaContainer}>
                <span className={classes.takePicturesResult__title}>
                  Parcel
                </span>
                <div className={classes.takePicturesResult__previewsContainer}>
                  {parcel.current.ids.map((parcelId) => (
                    <div key={parcelId}>
                      <Button
                        variant="light"
                        onClick={openDeleteModal(parcelId, 'parcel')}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </Button>
                      <PreviewItem
                        media={parcel.current.byIds[parcelId]}
                        disabled
                      />
                    </div>
                  ))}
                  {parcel.current.ids.length < 5 && (
                    <div>
                      <span />
                      <button
                        type="button"
                        className={classes.takePicturesResult__empty}
                        onClick={gotoParcel}
                      >
                        +
                      </button>
                    </div>
                  )}
                  <div
                    className={classNames(
                      classes.takePicturesResult__emptyBlock,
                      {
                        'display-none': removeEmptyBlock(parcel.current.ids),
                      },
                    )}
                  />
                </div>
              </div>
            </div>
            <Button
              disabled={disabledButton}
              onClick={saveMedia}
              className={classes.takePicturesResult__saveButton}
            >
              {t('saveMedia')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TakePicturesResult;
