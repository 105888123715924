import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/checkoutAddressModal.module.scss';
import CheckoutAddressForm from 'components/CheckoutAddressForm';
import MondialRelayForm from 'components/MondialRelayForm';
import { CHECKOUT_ADDRESS_MODAL_STEPS } from 'helpers/constants';
import DeliveryProviders from 'components/DeliveryProviders';
import { useSelector } from 'react-redux';
import { selectCurrentUserAddress } from 'logic/actions/meActions';
import useParseUserAddress from 'hooks/useParseUserAddress';
import isEmpty from 'lodash/isEmpty';

const CheckoutAddressModal = ({
  resetDeliveryParams,
  isDeliveryWithProvider,
  providers,
  currency,
  setCurrentProvider,
  currentProvider,
  deliveryAddress = {},
  visible,
  onSubmit,
  onHide,
  needsPickupPoint = false,
}) => {
  const { t } = useTranslation('checkoutAddressForm');
  const { parseUserAddress } = useParseUserAddress();

  const currentUserAddress = useSelector(selectCurrentUserAddress);

  const {
    DELIVERY_METHOD,
    DELIVERY_ADDRESS,
    PICKUP_POINT,
  } = CHECKOUT_ADDRESS_MODAL_STEPS;

  const [step, setStep] = useState();
  const [localProvider, setLocalProvider] = useState(currentProvider);
  const [addressForPickup, setAddressForPickup] = useState();

  useEffect(() => {
    if (isDeliveryWithProvider && visible) {
      setStep(DELIVERY_METHOD);
    } else {
      setStep(DELIVERY_ADDRESS);
    }
  }, [
    isDeliveryWithProvider,
    visible,
    DELIVERY_ADDRESS,
    DELIVERY_METHOD,
  ]);

  useEffect(() => {
    setLocalProvider(currentProvider);
  }, [visible, currentProvider]);

  const titleConfig = useMemo(
    () => ({
      [DELIVERY_METHOD]: 'deliveryMethodTitle',
      [DELIVERY_ADDRESS]: 'deliveryAddressTitle',
      [PICKUP_POINT]: 'pickupPointTitle',
    }),
    [
      DELIVERY_ADDRESS,
      DELIVERY_METHOD,
      PICKUP_POINT,
    ],
  );

  const onChangeProvider = useCallback((e) => {
    if (providers) {
      const selectedProvider = providers.find(
        (provider) => provider.name === e.target.value,
      );
      setLocalProvider(selectedProvider);
    }
  }, [providers]);

  const gotoDeliveryAddress = useCallback(() => {
    if (localProvider) {
      if (currentProvider && localProvider !== currentProvider) {
        resetDeliveryParams();
      }
      setStep(DELIVERY_ADDRESS);
      setCurrentProvider(localProvider);
    }
  }, [
    localProvider,
    currentProvider,
    DELIVERY_ADDRESS,
    resetDeliveryParams,
    setCurrentProvider,
  ]);

  const submitAddress = useCallback((address) => {
    if (needsPickupPoint) {
      setAddressForPickup(address);
      setStep(PICKUP_POINT);
    } else {
      onSubmit(address);
    }
  }, [
    PICKUP_POINT,
    needsPickupPoint,
    onSubmit,
  ]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      onHide={onHide}
      className={classes.checkoutAddressModal}
      dialogClassName={classes.checkoutAddressModal__dialog}
      contentClassName={classes.checkoutAddressModal__content}
    >
      <Modal.Header closeButton className={classes.checkoutAddressModal__title}>
        {t(titleConfig[step])}
      </Modal.Header>
      <Modal.Body>
        {step === DELIVERY_METHOD && (
          <DeliveryProviders
            onChangeProvider={onChangeProvider}
            currentProvider={localProvider}
            gotoDeliveryAddress={gotoDeliveryAddress}
            providers={providers}
            currency={currency}
          />
        )}
        {step === DELIVERY_ADDRESS && (
          <CheckoutAddressForm
            deliveryAddress={
              currentUserAddress && isEmpty(deliveryAddress)
                ? parseUserAddress(currentUserAddress)
                : deliveryAddress
            }
            onSubmit={submitAddress}
            isPhoneFieldVisible={isDeliveryWithProvider}
            allowedCountries={localProvider?.allowedCountries}
            needsPickupPoint={needsPickupPoint}
          />
        )}
        {step === PICKUP_POINT && (
          <MondialRelayForm
            allowedCountries={localProvider?.allowedCountries}
            onSubmit={({ pickupPointCode, formattedAddress: pickupPointAddress }) => {
              onSubmit({
                ...addressForPickup,
                pickupPointCode,
                pickupPointAddress,
              });
            }}
            deliveryAddress={addressForPickup}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutAddressModal;
