import React from 'react';
import { Spinner } from 'react-bootstrap';
import classes from 'assets/style/takePictures/loader.module.scss';

const Loader = () => (
  <div className={classes.loader}>
    <Spinner animation="grow" />
  </div>
);

export default Loader;
