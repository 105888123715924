import * as React from 'react';

const IconDelivery2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.83 25.091"
    {...props}
  >
    <defs>
      <style>
        {'.icon-delivery-2_svg__a,.icon-delivery-2_svg__b{fill:none;stroke:#050038;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.091px}.icon-delivery-2_svg__a{fill-rule:evenodd}'}
      </style>
    </defs>
    <path
      className="icon-delivery-2_svg__a"
      d="M37.638 5.434a39.414 39.414 0 010 14.224 32.825 32.825 0 01-11 4.882 32.832 32.832 0 01-11-4.882 39.414 39.414 0 010-14.224v.006a33.349 33.349 0 0111-4.9v.007a33.32 33.32 0 0111 4.9"
    />
    <path
      className="icon-delivery-2_svg__a"
      d="M33.067 12.724a27.174 27.174 0 00-.6-4.985 35.355 35.355 0 00-11.665-5.194"
    />
    <path
      className="icon-delivery-2_svg__b"
      d="M26.634 24.545V10.327l-11-4.882 11 4.882 11-4.882M.546 19.276h11.197M11.198 12.554H4.357M11.742 5.833H8.168"
    />
  </svg>
);

export default IconDelivery2;
