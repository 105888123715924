import { useCallback, useState } from 'react';

const useCropper = (imageFile) => {
  const [cropper, setCropper] = useState();

  const initCropper = useCallback((instance) => {
    setCropper(instance);
  }, []);

  const cropImage = useCallback(
    () => new Promise((resolve, reject) => {
      if (typeof cropper !== 'undefined') {
        const newCanvas = cropper.getCroppedCanvas();

        newCanvas.toBlob((blob) => {
          const file = new File([blob], imageFile?.name, { type: blob.type });
          resolve(file);
        });
      } else {
        reject();
      }
    }),
    [
      cropper,
      imageFile?.name,
    ],
  );

  return {
    initCropper,
    cropImage,
  };
};

export default useCropper;
