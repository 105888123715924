/* eslint-disable import/no-unused-modules */
export const REQUEST_RESET_PASSWORD = 'app/resetPassword/REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD = 'app/resetPassword/RESET_PASSWORD';
/* eslint-enable import/no-unused-modules */

export const requestResetPassword = (email, returnTo) => ({
  type: REQUEST_RESET_PASSWORD,
  payload: {
    request: {
      method: 'POST',
      url: '/api/webapp/reset-password/',
      data: { email, returnTo },
    },
  },
});

export const resetPassword = (token, password) => ({
  type: RESET_PASSWORD,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/reset-password/${token}/`,
      data: { password },
    },
  },
});
