import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import shippingProvidersConfig from 'helpers/shippingProvidersConfig';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import classes from 'assets/style/deliveryProviderInfo.module.scss';
import PersonalDeliveryIcon from 'assets/images/personal-delivery.svg';

const DeliveryProviderInfo = ({ provider, currency, onEdit }) => {
  const { t } = useTranslation('deliveryProviders');

  const config = useMemo(() => {
    if (provider) {
      const { name: slug, shippingFees } = provider;
      const { icon, name } = shippingProvidersConfig[slug];

      return {
        title: name,
        subTitle: `providers.${slug}.subTitles`,
        icon,
        shippingFees,
      };
    }

    return {
      title: t('personalizedTitle'),
      icon: PersonalDeliveryIcon,
    };
  }, [
    provider,
    t,
  ]);

  const subTitles = Object.values(
    t(config.subTitle, { returnObjects: true }) || {},
  );

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <img
          src={config.icon}
          alt="carrier"
          className={classes.icon}
        />
        <div>
          <span className={classes.title}>
            <span>{config.title}</span>
            <button
              type="button"
              className={classes.editButton}
              onClick={onEdit}
            >
              {t('edit')}
            </button>
          </span>
          {!!subTitles.length && (
            <span className={classes.subtitle}>
              {subTitles[0]}
            </span>
          )}
        </div>
      </div>
      {!!provider && (
        <div className={classes.price}>
          <FormattedNumber
            value={config?.shippingFees / 100}
            style="currency"
            currency={currency}
          />
        </div>
      )}
    </div>
  );
};

DeliveryProviderInfo.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    shippingFees: PropTypes.number.isRequired,
  }),
  currency: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};

DeliveryProviderInfo.defaultProps = {
  provider: undefined,
};

export default DeliveryProviderInfo;
