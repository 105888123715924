import React, {
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { selectAllCountries } from 'logic/actions/countriesActions';
import countriesMapper from 'helpers/countriesMapper';
import { mondialRelayId } from 'helpers/env';
import classes from 'assets/style/mondialRelayForm.module.scss';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import jQuery from 'helpers/mondialrelay';

const MondialRelayForm = ({
  allowedCountries,
  onSubmit,
  deliveryAddress: {
    postcode: deliveryPostcode,
    city: deliveryCity,
    pickupPointCode: deliveryPickupPointCode,
    countryIso2: deliveryCountryIso2,
  } = {},
}) => {
  const { t } = useTranslation(['checkoutAddressForm', '_countries']);
  const widget = useRef();

  const [pickupPointData, setPickupPointData] = useState({});

  const allCountries = useSelector(selectAllCountries);

  const { slugFromIso } = useMemo(
    () => countriesMapper(allCountries),
    [allCountries],
  );

  const handleShopSelect = useCallback(({
    ID: pickupPointCode,
    Nom: name,
    Adresse1: addressLine1,
    Adresse2: addressLine2,
    CP: postcode,
    Ville: city,
    Pays: countryIso2,
  }) => {
    let country;
    if (countryIso2) {
      const countrySlug = slugFromIso[countryIso2];
      country = t(`_countries:country.${countrySlug}`);
    }

    setPickupPointData({
      pickupPointCode,
      addressLine1,
      addressLine2,
      postcode,
      city,
      country,
      countryIso2,
      formattedAddress: `${name}, ${addressLine1},${addressLine2
        ? ` ${addressLine2},` : ''} ${postcode} ${city}, ${country}`,
    });
  }, [
    t,
    slugFromIso,
  ]);

  const widgetParams = useMemo(() => ({
    Target: '#ParcelShopCode',
    Brand: mondialRelayId,
    AllowedCountries: allowedCountries,
    Responsive: true,
    EnableGeolocalisatedSearch: true,
    OnParcelShopSelected: handleShopSelect,
    PostCode: deliveryPostcode,
    City: deliveryCity,
    AutoSelect: deliveryPickupPointCode
      ? `${deliveryCountryIso2}-${deliveryPickupPointCode}`
      : undefined,
  }), [
    allowedCountries,
    deliveryPostcode,
    deliveryCity,
    deliveryPickupPointCode,
    deliveryCountryIso2,
    handleShopSelect,
  ]);

  useLayoutEffect(() => {
    if (widget?.current) {
      const $widget = jQuery(widget.current);

      $widget.MR_ParcelShopPicker(widgetParams);
    }
  }, [widgetParams]);

  useEffect(() => {
    if (widget?.current) {
      const $widget = jQuery(widget.current);

      $widget.trigger('MR_SetParams', widgetParams);
    }
  }, [widgetParams]);

  return (
    <div>
      <div className={classes.widget} ref={widget} />
      <input type="hidden" id="ParcelShopCode" />
      <Button
        type="submit"
        disabled={!pickupPointData?.pickupPointCode}
        className={classes.button}
        onClick={() => {
          onSubmit(pickupPointData);
        }}
      >
        {t('submit')}
      </Button>
    </div>
  );
};

export default MondialRelayForm;
