import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { isIOS } from 'react-device-detect';
import Button from 'components/Button';
import { ReactComponent as CopyPasteIcon } from 'assets/images/copyPasteIcon.svg';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import classes from 'assets/style/permalinkModal.module.scss';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { HOMEPAGE_TAB_KEY } from 'helpers/constants';

const PermalinkModal = ({
  permalink,
  show,
  onHide,
  subTotal,
  currency,
  title,
}) => {
  const intl = useIntl();
  const { t } = useTranslation('transactionTemplate');

  const history = useHistory();

  const { handleCopyToClipboard } = useCopyToClipboard();

  const onCopy = useCallback(() => {
    handleCopyToClipboard(permalink);
  }, [
    permalink,
    handleCopyToClipboard,
  ]);

  const handleHide = useCallback(() => {
    onHide();
    history.replace({
      pathname: history.location.pathname,
      state: {
        template: null,
        [HOMEPAGE_TAB_KEY]: null,
      },
    });
  }, [
    history,
    onHide,
  ]);

  const handleShare = useCallback(() => {
    const formattedSubTotal = intl.formatNumber(subTotal / 100, {
      style: 'currency',
      currency,
    });
    const emailSubject = t('permalinkModal.emailSubject', {
      title,
      subTotal: formattedSubTotal,
    });
    const emailBody = t('permalinkModal.emailBody', {
      title,
      subTotal: formattedSubTotal,
      permalink: encodeURIComponent(permalink),
    });
    window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`);
    onHide();
  }, [
    onHide,
    subTotal,
    currency,
    permalink,
    intl,
    t,
    title,
  ]);

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      className={classes.permalinkModal}
      dialogClassName={classes.permalinkModal__dialog}
      contentClassName={classes.permalinkModal__content}
    >
      <Modal.Header closeButton />

      <h4 className={classes.permalinkModal__title}>
        {t('permalinkModal.title')}
      </h4>

      <Modal.Body className="keep-line-breaks">
        <Form.Label
          htmlFor="permalinkInput"
          className={classes.permalinkModal__label}
        >
          {t('permalinkModal.label')}
        </Form.Label>
        <InputGroup>
          <FormControl
            id="permalinkInput"
            className={classes.permalinkModal__input}
            value={permalink}
            readOnly={!isIOS}
            contentEditable={isIOS}
          />
          <InputGroup.Append>
            <Button
              variant="outline-light"
              className={classes.permalinkModal__copyButton}
              onClick={onCopy}
            >
              <CopyPasteIcon />
            </Button>
          </InputGroup.Append>
        </InputGroup>

        <div className={classes.permalinkModal__info}>
          {t('permalinkModal.info')}
        </div>
      </Modal.Body>

      <Button
        variant="primary"
        onClick={handleShare}
        className={classes.permalinkModal__button}
      >
        {t('permalinkModal.share')}
      </Button>
    </Modal>
  );
};

PermalinkModal.propTypes = {
  permalink: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  subTotal: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PermalinkModal;
