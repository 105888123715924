import { createReducer } from '@reduxjs/toolkit';
import { success, shouldResetState } from 'logic/reducers/helpers';
import { GET_OWN_WALLETS } from 'logic/actions/walletsActions';

const initialState = { wallets: [] };

const handleGetOwnWallets = (state, { payload: { data = [] } = {} }) => {
  state.wallets = data;
};

export default createReducer(
  initialState,
  { [success(GET_OWN_WALLETS)]: handleGetOwnWallets },
  [
    {
      matcher: (action) => shouldResetState(action),
      reducer: () => initialState,
    },
  ],
);
