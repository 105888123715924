import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import classes from 'assets/style/disputeResolution.module.scss';
import { fetchMediaFile } from 'logic/actions/mediaActions';
import Button from 'components/Button';
import DocumentPreview from 'components/DocumentPreview';
import { BUYER, SELLER } from 'helpers/constants';
import { SOLUTION_PARTIAL } from 'pages/disputeResolution/ChooseSolution';
import { snake2Camel } from 'helpers/caseConvert';
import { ReactComponent as RecapAmount } from 'assets/images/disputeResolution/recapAmount.svg';
import { ReactComponent as RecapCalendar } from 'assets/images/disputeResolution/recapCalendar.svg';
import { ReactComponent as RecapComment } from 'assets/images/disputeResolution/recapComment.svg';
import { ReactComponent as RecapSolution } from 'assets/images/disputeResolution/recapSolution.svg';
import { ReactComponent as RecapType } from 'assets/images/disputeResolution/recapType.svg';

const IssueRecap = ({
  transaction: {
    currency = 'EUR',
    issue: {
      type,
      buyerRefund = 0,
      sellerRefund = 0,
      openedAt,
      arbitrationStartedAt,
      messages: [
        {
          message: buyerMessage,
          proposedSolution: buyerProposedSolution,
          proposedRefund: buyerProposedRefund = 0,
          medias: buyerMediaIds = [],
        } = {},
        { response: sellerResponse, medias: sellerMediaIds = [] } = {},
      ] = [],
    } = {},
  } = {},
  role,
  back,
  next,
}) => {
  const { t } = useTranslation('disputeResolution');
  const intl = useIntl();
  const dispatch = useDispatch();

  const [previousBuyerMediaIds, setPreviousBuyerMediaIds] = useState([]);
  const [buyerMedias, setBuyerMedias] = useState([]);

  const [previousSellerMediaIds, setPreviousSellerMediaIds] = useState([]);
  const [sellerMedias, setSellerMedias] = useState([]);

  const deadline = moment(openedAt)
    .add(3, 'days')
    .toDate();

  useEffect(() => {
    if (
      Array.isArray(buyerMediaIds)
      && !isEqual(buyerMediaIds, previousBuyerMediaIds)
    ) {
      setPreviousBuyerMediaIds(buyerMediaIds);
      setBuyerMedias([]);
      buyerMediaIds.forEach((id) => {
        dispatch(fetchMediaFile(id))
          .then(({ payload: { data: media } = {} }) => {
            setBuyerMedias((prev) => [...prev, media]);
          })
          .catch(() => {});
      });
    }
  }, [
    buyerMediaIds,
    dispatch,
    previousBuyerMediaIds,
  ]);

  useEffect(() => {
    if (
      Array.isArray(sellerMediaIds)
      && !isEqual(sellerMediaIds, previousSellerMediaIds)
    ) {
      setPreviousSellerMediaIds(sellerMediaIds);
      setSellerMedias([]);
      sellerMediaIds.forEach((id) => {
        dispatch(fetchMediaFile(id))
          .then(({ payload: { data: media } = {} }) => {
            setSellerMedias((prev) => [...prev, media]);
          })
          .catch(() => {});
      });
    }
  }, [
    sellerMediaIds,
    dispatch,
    previousSellerMediaIds,
  ]);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {role === BUYER || arbitrationStartedAt
          ? t('backToTransaction')
          : t('back')}
      </Button>
      {!arbitrationStartedAt && role === BUYER && (
        <p className={classes.title}>
          {t('issueRecap.buyerTitle', {
            deadlineDate: intl.formatDate(deadline),
            deadlineTime: intl.formatTime(deadline),
          })}
        </p>
      )}
      {!arbitrationStartedAt && role === SELLER && (
        <p className={classes.title}>{t('issueRecap.sellerTitle')}</p>
      )}
      {arbitrationStartedAt && (
        <p className={classes.title}>{t('issueRecap.arbitrationTitle')}</p>
      )}
      <div className={classes.form}>
        <p className={classes.recapLine}>
          <RecapCalendar />
          {t('issueRecap.openedAt', {
            date: intl.formatDate(new Date(openedAt)),
            time: intl.formatTime(new Date(openedAt)),
          })}
        </p>
        <p className={classes.recapLine}>
          <RecapType />
          {t('issueRecap.type', {
            type: t(`issueRecap.types.${snake2Camel(type)}`),
          })}
        </p>
        <p className={classes.recapLine}>
          <RecapComment />
          {t('issueRecap.buyerMessage', { buyerMessage })}
        </p>
        <hr />
        <p className={classes.recapLine}>
          <RecapSolution />
          {t('issueRecap.buyerProposedSolution', {
            buyerProposedSolution: t(
              `issueRecap.solutions.${snake2Camel(buyerProposedSolution)}`,
            ),
          })}
        </p>
        {buyerProposedSolution === SOLUTION_PARTIAL && (
          <p className={classes.recapLine}>
            <RecapAmount />
            {t('issueRecap.buyerProposedRefund', {
              buyerProposedRefund: intl.formatNumber(
                buyerProposedRefund / 100,
                {
                  style: 'currency',
                  currency,
                },
              ),
            })}
          </p>
        )}
        {sellerResponse && (
          <p className={classes.recapLine}>
            <RecapSolution />
            {t('issueRecap.sellerResponse', {
              sellerResponse: t(
                `issueRecap.responses.${snake2Camel(sellerResponse)}`,
              ),
            })}
          </p>
        )}
        {(buyerRefund !== 0 || sellerRefund !== 0) && (
          <>
            <hr />
            <p className={classes.recapLine}>
              <RecapAmount />
              {t('issueRecap.buyerRefund', {
                buyerRefund: intl.formatNumber(buyerRefund / 100, {
                  style: 'currency',
                  currency,
                }),
              })}
            </p>
            <p className={classes.recapLine}>
              <RecapAmount />
              {t('issueRecap.sellerRefund', {
                sellerRefund: intl.formatNumber(sellerRefund / 100, {
                  style: 'currency',
                  currency,
                }),
              })}
            </p>
          </>
        )}
        {buyerMedias.length > 0 && (
          <>
            <hr />
            <p className={classes.recapLine}>{t('issueRecap.buyerMedias')}</p>
            <div className={classes.recapMedias}>
              {buyerMedias.map((media, i) => (
                <DocumentPreview
                  key={URL.createObjectURL(media)}
                  file={media}
                  onDownload={(file) => {
                    saveAs(file, `Buyer evidence ${i}`);
                  }}
                />
              ))}
            </div>
          </>
        )}
        {sellerMedias.length > 0 && (
          <>
            <hr />
            <p className={classes.recapLine}>{t('issueRecap.sellerMedias')}</p>
            <div className={classes.recapMedias}>
              {sellerMedias.map((media, i) => (
                <DocumentPreview
                  key={URL.createObjectURL(media)}
                  file={media}
                  onDownload={(file) => {
                    saveAs(file, `Seller evidence ${i}`);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {next && (
        <Button className={classes.nextButton} onClick={next}>
          {t('issueRecap.next')}
        </Button>
      )}
    </Row>
  );
};

export default IssueRecap;
