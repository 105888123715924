import React from 'react';

const TutoModalRadioUnchecked = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      width={16}
      height={16}
      rx={8}
      transform="translate(.501 .501)"
      fill="#fff"
      stroke="#9b99af"
      strokeMiterlimit={10}
      strokeWidth={1.003}
    />
  </svg>
);

export default TutoModalRadioUnchecked;
