import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import trophyIcon from 'assets/images/trophy-icon.svg';
import WatchIcon from 'assets/svg/WatchIcon';
import CoinsIcon from 'assets/svg/CoinsIcon';
import 'assets/style/dashboard.scss';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer';
import ReactStars from 'react-rating-stars-component';
import { NavLink, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  CURRENCY_EUR,
  HOMEPAGE_TAB_KEY,
  HOMEPAGE_TABS,
  STATUS_VERIFIED,
} from 'helpers/constants';
import { FormattedNumber } from 'react-intl';
import { getOwnWallets } from 'logic/actions/walletsActions';
import {
  refreshMe,
  selectCurrentUserVerifyStatus,
} from 'logic/actions/meActions';
import Spinner from 'react-bootstrap/Spinner';
import {
  getOwnActiveTransactions,
  getOwnEndedTransactions,
} from 'logic/actions/transactionsActions';
import Header from 'components/Header';
import localStorage from 'helpers/localStorage';
import starFullIcon from 'assets/images/star.svg';
import starEmptyIcon from 'assets/images/star-empty.svg';
import halfStarIcon from 'assets/images/half-star-icon.svg';
import colors from 'assets/style/_colors.module.scss';
import ProfilePicture from 'components/ProfilePicture';
import allSettled from 'promise.allsettled';

const DEFAULT_WALLET = {
  balance: 0,
  currency: CURRENCY_EUR,
};

const getWallet = (wallets) => {
  const positiveWallets = wallets.filter(({ balance }) => balance);
  const walletsToDisplay = positiveWallets.length
    ? positiveWallets
    : [DEFAULT_WALLET];

  // Multiple currency wallet is not handle by design for now
  // Only first positive wallet is displayed
  return walletsToDisplay[0];
};

const Dashboard = ({ me, wallets, getOwnWallets }) => {
  const {
    averageReviewScore,
    nbCompletedTransactions = 0,
    pendingAmounts: [
      { total: pendingAmount = 0, currency: pendingCurrency = 'EUR' } = {},
    ] = [],
    userProfile: { firstName, lastName } = {},
  } = me;

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('dashboard');

  const { balance, currency } = getWallet(wallets);
  const verifyStatus = useSelector(selectCurrentUserVerifyStatus);

  useEffect(() => {
    setLoading(!wallets.length);
    allSettled.shim();
    Promise.allSettled([refreshMe(), getOwnWallets()]).then(() => {
      setLoading(false);
    });
  }, [
    getOwnWallets,
    wallets.length,
  ]);

  const haveBalance = useMemo(() => balance > 0, [balance]);
  const havePendingAmount = useMemo(() => pendingAmount > 0, [pendingAmount]);
  const isStatusVerified = useMemo(() => verifyStatus === STATUS_VERIFIED, [
    verifyStatus,
  ]);

  const gotoTransactions = useCallback(() => {
    history.push('/transactions');
    localStorage.set(HOMEPAGE_TAB_KEY, HOMEPAGE_TABS.IN_PROGRESS);
  }, [history]);

  const gotoWalletPage = useCallback(() => {
    history.push('settings/wallets');
  }, [history]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Header />
      {loading ? (
        <Spinner className="m-auto d-block text-primary" animation="border" />
      ) : (
        <div className="dashboard__container">
          <div className="dashboard__content">
            <div className="dashboard__profile-card">
              <div className="dashboard__image-container">
                <ProfilePicture className="dashboard__profile-pic" />
                <NavLink
                  to="/settings/profile"
                  className="dashboard__edit-link btn-link"
                >
                  {t('editProfile')}
                </NavLink>
              </div>
              <div className="profile-card__name">{`${firstName} ${lastName}`}</div>
              <div className="profile-card__stars">
                <ReactStars
                  count={5}
                  value={averageReviewScore}
                  isHalf
                  emptyIcon={(
                    <img
                      alt="empty-star"
                      className="profile-card__star"
                      src={starEmptyIcon}
                    />
                  )}
                  halfIcon={(
                    <img
                      alt="half-star"
                      className="profile-card__star"
                      src={halfStarIcon}
                    />
                  )}
                  filledIcon={(
                    <img
                      alt="filled-star"
                      className="profile-card__star"
                      src={starFullIcon}
                    />
                  )}
                  edit={false}
                  size={32}
                />
              </div>
              <div className="profile-card__metrics">
                <img src={trophyIcon} alt="trophy" className="metrics__icon" />
                <p className="metrics__text">
                  <span className="mr-2">{nbCompletedTransactions}</span>
                  {t('endedTransactions')}
                </p>
              </div>
              {isStatusVerified && (
                <div className="profile-card__message">{t('message')}</div>
              )}
            </div>
            <div className="dashboard__card-wrapper">
              <div className="dashboard__balance-card">
                <div className="balance-card__title">
                  <CoinsIcon
                    className={`title__balance-icon ${haveBalance ? 'text-primary' : ''}`}
                    fill={colors.whiteColor}
                    stroke={haveBalance ? colors.primaryColor : colors.grayDarkenColor}
                  />
                  <div className={haveBalance ? 'text-primary' : ''}>{t('balance')}</div>
                </div>
                <div
                  className={`balance-card__amount ${haveBalance ? 'text-primary' : ''}`}
                >
                  <FormattedNumber
                    value={balance / 100}
                    style="currency"
                    currency={currency}
                  />
                </div>
                <button
                  type="button"
                  disabled={!haveBalance}
                  onClick={gotoWalletPage}
                  className="btn btn-primary"
                >
                  {t('transfer')}
                </button>
              </div>
              <div className="dashboard__money-card">
                <div className="money-card__title">
                  <WatchIcon
                    className="title__money-icon"
                    stroke={havePendingAmount ? colors.primaryColor : colors.grayDarkenColor}
                  />
                  <div className={`title__money ${havePendingAmount ? 'text-primary' : ''}`}>
                    {t('pending')}
                  </div>
                </div>
                <div className={`money-card__amount ${havePendingAmount ? 'text-primary' : ''}`}>
                  <FormattedNumber
                    value={pendingAmount / 100}
                    style="currency"
                    currency={pendingCurrency}
                  />
                </div>
                <button
                  type="button"
                  onClick={gotoTransactions}
                  disabled={!havePendingAmount}
                  className="btn btn-primary"
                >
                  {t('see')}
                </button>
              </div>
            </div>
          </div>
          <Footer className="webapp__footer" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  wallets: state.persistent.walletsReducer.wallets,
});

const actionCreators = {
  refreshMe,
  getOwnWallets,
  getOwnActiveTransactions,
  getOwnEndedTransactions,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(Dashboard);
