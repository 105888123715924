import authReducer from 'logic/reducers/authReducer';
import meReducer from 'logic/reducers/meReducer';
import countriesReducer from 'logic/reducers/countriesReducer';
import currenciesReducer from 'logic/reducers/currenciesReducer';
import transactionsReducer from 'logic/reducers/transactionsReducer';
import transactionTemplatesReducer from 'logic/reducers/transactionTemplatesReducer';
import walletsReducer from 'logic/reducers/walletsReducer';
import bankAccountsReducer from 'logic/reducers/bankAccountsReducer';
import paymentCardsReducer from 'logic/reducers/paymentCardsReducer';
import clientsReducer from 'logic/reducers/clientsReducer';

export const persistentReducers = {
  meReducer,
  countriesReducer,
  currenciesReducer,
  transactionsReducer,
  transactionTemplatesReducer,
  walletsReducer,
  bankAccountsReducer,
  paymentCardsReducer,
  clientsReducer,
};

export const volatileReducers = {
  authReducer,
};
