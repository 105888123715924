import { createReducer } from '@reduxjs/toolkit';
import { success } from 'logic/reducers/helpers';
import {
  FETCH_CLIENT_LOGO,
  FETCH_CLIENT_SQUARE_LOGO,
  SET_CLIENT_PERMALINK,
} from 'logic/actions/clientsActions';

const initialState = {};

const handleLogo = (logoType) => (state, { payload: { data } = {}, meta: { previousAction } = {} }) => ({
  ...state,
  [previousAction.clientId]: {
    ...state[previousAction.clientId],
    [logoType]: data,
  },
});

const handlePermalink = (state, { permalink, clientId }) => ({
  ...state,
  [clientId]: {
    ...state[clientId],
    permalink,
  },
});

export default createReducer(initialState, {
  [success(FETCH_CLIENT_LOGO)]: handleLogo('logo'),
  [success(FETCH_CLIENT_SQUARE_LOGO)]: handleLogo('squareLogo'),
  [SET_CLIENT_PERMALINK]: handlePermalink,
});
