import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import classes from 'assets/style/takePictures/informModal.module.scss';

const InformModal = ({
  show,
  onHide,
  title,
  confirmText,
  confirmCallback,
  image,
}) => (
  <Modal show={show} onHide={onHide} centered className={classes.informModal}>
    <div className={classes.informModal__imageContainer}>{image}</div>
    <Modal.Header>
      <div className={classes.informModal__header}>{title}</div>
    </Modal.Header>
    <Modal.Body>
      <div className={classes.informModal__body}>
        <Button onClick={confirmCallback}>{confirmText}</Button>
      </div>
    </Modal.Body>
  </Modal>
);

InformModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  image: PropTypes.element.isRequired,
};

InformModal.defaultProps = {
  onHide: () => {},
};

export default InformModal;
