/* eslint-disable import/no-unused-modules */
export const GET_OWN_BANK_ACCOUNTS = 'app/bankAccounts/GET_OWN_BANK_ACCOUNTS';
export const DELETE_BANK_ACCOUNT = 'app/bankAccounts/DELETE_BANK_ACCOUNT';
export const CREATE_BANK_ACCOUNT = 'app/bankAccounts/CREATE_BANK_ACCOUNT';
/* eslint-enable import/no-unused-modules */

export const getOwnBankAccounts = () => ({
  type: GET_OWN_BANK_ACCOUNTS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/bank-accounts/',
    },
  },
});

export const deleteBankAccount = (bankAccountId) => ({
  type: DELETE_BANK_ACCOUNT,
  payload: {
    request: {
      method: 'DELETE',
      url: `/api/webapp/bank-accounts/${bankAccountId}/`,
    },
  },
});

export const createBankAccount = (data) => ({
  type: CREATE_BANK_ACCOUNT,
  payload: {
    request: {
      method: 'POST',
      url: '/api/webapp/bank-accounts/',
      data,
    },
  },
});
