import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from 'components/Button';
import CompleteTransactionModal from 'components/CompleteTransactionModal';
import TrackingNumberModal from 'components/TrackingNumberModal';
import EvaluationFormModal from 'components/EvaluationFormModal';
import {
  acceptTransaction,
  rejectTransaction,
  shipTransaction,
  completeTransaction,
  rejectCancelTransaction,
  cancelTransaction,
  evaluateTransaction,
  getShippingLabel,
  generateShippingLabel,
  createDispute,
  createDisputeLoginLink,
} from 'logic/actions/transactionsActions';
import {
  CURRENCY_EUR,
  BUYER,
  SELLER,
  EVENT_TRANSACTION_CANCEL_REQUESTED,
  DELIVERY_METHOD_IN_PERSON,
  DELIVERY_METHOD_REMOTE,
  TRANSACTION_STATUSES,
  EVENT_TRANSACTION_ACCEPT,
  EVENT_TRANSACTION_DELIVERED,
  DISPUTE_STATUS_MEDIATION,
  DISPUTE_STATUS_ARBITRATION,
  DISPUTE_STATUS_CLOSED,
  DISPUTE_STATUS_RETURN_SHIPPED,
  DISPUTE_STATUS_RETURN_DELIVERED,
  DISPUTE_STATUS_RETURN,
  DISPUTE_STATUS_CANCELLED,
} from 'helpers/constants';
import useModal from 'hooks/useModal';
import ConfirmRejectModal from 'components/ConfirmRejectModal';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import CancelRejectModal from 'components/CancelRejectModal';
import images from 'helpers/imagesForTransactionActionPanel';
import TransactionMessage from 'components/TransactionMessage';
import SecureFundsModal from 'components/SecureFundsModal';
import ShippingLabelModal from 'components/ShippingLabelModal';
import ShippingLabelSuccessModal from 'components/ShippingLabelSuccessModal';
import useTriggerModalOnTransactionStatusChange from 'hooks/useTriggerModalOnTransactionStatusChange';
import saveFileFromDataUrl from 'helpers/saveFileFromDataUrl';

const TransactionActionPanel = ({
  transaction: {
    id,
    title,
    isPreauthorized,
    isAccepted,
    isRejected,
    isShipped,
    isDelivered,
    isCanceledByAdmin,
    isCanceledByBuyer,
    isCanceledBySeller,
    isCanceledAutomatically,
    isCompleted,
    isExpired,
    canBeCanceled,
    buyerId,
    sellerReview,
    buyerReview,
    buyerDisplayName,
    sellerDisplayName,
    sellerTotal,
    buyerTotal,
    client,
    currency = CURRENCY_EUR,
    templatePermalink,
    events = [],
    lastEventSlug,
    lastEventCreatedAt,
    verificationCode,
    trackingUrl,
    deliveryMethod = DELIVERY_METHOD_IN_PERSON,
    deliveryAddress: { formattedAddress } = {},
    chatMessage: messageFromBuyer,
    shippingProvider,
    shippingProviderAllowedCountries,
    labelGeneration = false,
    trackingNumber,
    dispute: {
      externalId: disputeExternalId,
      status: disputeStatus,
      deadline: disputeDeadline,
    } = {},
  } = {},
}) => {
  const intl = useIntl();
  const { t, i18n } = useTranslation('transactionActionPanel');
  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';
  const dateTimeFormatter = new Intl.DateTimeFormat(currentLang, {
    dateStyle: 'long',
    timeStyle: 'short',
  });

  const history = useHistory();

  const me = useSelector((state) => state.persistent.meReducer.me);
  const dispatch = useDispatch();
  const doAcceptTransaction = useCallback(
    () => dispatch(acceptTransaction(id)),
    [dispatch, id],
  );
  const doRejectTransaction = useCallback(
    (reason) => dispatch(rejectTransaction(id, reason)),
    [dispatch, id],
  );
  const doShipTransaction = useCallback(
    (data) => dispatch(shipTransaction(id, data)),
    [dispatch, id],
  );
  const doCompleteTransaction = useCallback(
    (verificationCode = null) => dispatch(completeTransaction(id, verificationCode)),
    [dispatch, id],
  );
  const doRejectCancelTransaction = useCallback(
    (reason) => dispatch(rejectCancelTransaction(id, reason)),
    [dispatch, id],
  );

  const doCancelTransaction = useCallback(
    () => dispatch(cancelTransaction(id)),
    [dispatch, id],
  );

  const doEvaluateTransaction = useCallback(
    (values) => dispatch(evaluateTransaction(id, values)),
    [dispatch, id],
  );

  const doGenerateShippingLabel = useCallback(
    (senderAddress) => dispatch(generateShippingLabel(id, senderAddress)),
    [dispatch, id],
  );

  const doGetShippingLabel = useCallback(() => dispatch(getShippingLabel(id)), [
    dispatch,
    id,
  ]);

  const doCreateDispute = useCallback(() => dispatch(createDispute(id)), [
    dispatch,
    id,
  ]);

  const doCreateDisputeLoginLink = useCallback(() => dispatch(createDisputeLoginLink(id)), [
    dispatch,
    id,
  ]);

  const navigateToDispute = useCallback(() => (
    doCreateDisputeLoginLink().then(({ payload: { data: { link } = {} } = {} }) => {
      window.location = link;
    })
  ), [doCreateDisputeLoginLink]);

  const errorToast = useCallback(() => toast.error(t('anErrorOccured')), [t]);

  const [btn1Submitting, setBtn1Submitting] = useState(false);
  const [btn2Submitting, setBtn2Submitting] = useState(false);

  const {
    openModal: openConfirmCancelModal,
    closeModal: closeConfirmCancelModal,
    modalState: confirmCancelModal,
  } = useModal(false);

  const {
    openModal: openConfirmRejectModal,
    closeModal: closeConfirmRejectModal,
    modalState: confirmRejectModal,
  } = useModal(false);

  const {
    openModal: openCancelRejectModal,
    closeModal: closeCancelRejectModal,
    modalState: cancelRejectModal,
  } = useModal(false);

  const {
    openModal: openCompleteModal,
    closeModal: closeCompleteModal,
    modalState: completeModal,
  } = useModal(false);

  const {
    openModal: openTrackingModal,
    closeModal: closeTrackingModal,
    modalState: trackingModal,
  } = useModal(false);

  const {
    openModal: openEvaluationForm,
    closeModal: closeEvaluationForm,
    modalState: evaluationForm,
  } = useModal(false);

  const {
    openModal: openSecureFundsModal,
    closeModal: closeSecureFundsModal,
    modalState: secureFundsModal,
  } = useModal(false);

  const {
    openModal: openShippingLabelModal,
    closeModal: closeShippingLabelModal,
    modalState: shippingLabelModal,
  } = useModal(false);

  const {
    openModal: openShippingLabelSuccessModal,
    closeModal: closeShippingLabelSuccessModal,
    modalState: shippingLabelSuccessModal,
  } = useModal(false);

  const isPurchase = me.id === buyerId;
  const role = isPurchase ? BUYER : SELLER;
  const needReview = (isPurchase && !buyerReview) || (!isPurchase && !sellerReview);

  const acceptEventCreatedAt = events.reduce(
    (result, { slug, date }) => (slug === EVENT_TRANSACTION_ACCEPT ? date : result),
    null,
  );
  const deliveredEventCreatedAt = events.reduce(
    (result, { slug, date }) => (slug === EVENT_TRANSACTION_DELIVERED ? date : result),
    null,
  );

  let status = null;
  const variant = 'primary';
  let btn1Action = null;
  let btn2Action = null;
  let isMessageVisible = messageFromBuyer?.message;
  let deadline = null;

  useTriggerModalOnTransactionStatusChange(isCompleted, openEvaluationForm);

  useTriggerModalOnTransactionStatusChange(
    isAccepted,
    openSecureFundsModal,
    !isPurchase,
  );
  const shippingLabelLink = useRef(null);

  const showShippingLabelSuccessModal = useMemo(
    () => !isPurchase && !!labelGeneration,
    [isPurchase, labelGeneration],
  );

  useTriggerModalOnTransactionStatusChange(
    labelGeneration,
    openShippingLabelSuccessModal,
    showShippingLabelSuccessModal,
  );

  if (isPreauthorized) {
    status = 'preauthorized';

    if (role === SELLER) {
      btn1Action = doAcceptTransaction;
      btn2Action = () => {
        openConfirmRejectModal();
        return Promise.resolve();
      };
    }
  }

  if (isAccepted) {
    status = TRANSACTION_STATUSES.ACCEPTED;
    isMessageVisible = false;

    if (role === SELLER) {
      if (deliveryMethod === DELIVERY_METHOD_IN_PERSON) {
        btn1Action = () => {
          openCompleteModal();
          return Promise.resolve();
        };
      }

      if (deliveryMethod === DELIVERY_METHOD_REMOTE) {
        deadline = moment(acceptEventCreatedAt)
          .add(5, 'days')
          .toDate();
        if (labelGeneration) {
          status = TRANSACTION_STATUSES.ACCEPTED_LABEL_GENERATION;

          btn1Action = () => {
            openShippingLabelModal();
            return Promise.resolve();
          };
        } else {
          btn1Action = () => {
            openTrackingModal();
            return Promise.resolve();
          };
        }
      }
    }
  }

  if (role === SELLER && isAccepted && labelGeneration && trackingNumber && !isShipped) {
    status = TRANSACTION_STATUSES.LABEL_GENERATED;
    deadline = moment(acceptEventCreatedAt)
      .add(5, 'days')
      .toDate();

    btn1Action = () => doShipTransaction({
      shippingProvider,
      trackingNumber,
    });

    btn2Action = () => {
      if (shippingLabelLink.current) {
        shippingLabelLink.current.click();
        return Promise.resolve();
      }

      return doGetShippingLabel().then(({ payload: { data } }) => {
        const link = saveFileFromDataUrl(data, `Shipping label #${id}`);
        shippingLabelLink.current = link;
        link.click();
      });
    };
  }

  if (isShipped) {
    status = TRANSACTION_STATUSES.SHIPPED;

    if (deliveryMethod === DELIVERY_METHOD_REMOTE) {
      btn1Action = () => {
        window.open(trackingUrl);
        return Promise.resolve();
      };
    }
  }

  if (isDelivered) {
    status = TRANSACTION_STATUSES.DELIVERED;
    deadline = moment(deliveredEventCreatedAt)
      .add(3, 'days')
      .toDate();

    if (role === BUYER && deliveryMethod === DELIVERY_METHOD_REMOTE) {
      btn1Action = () => {
        openCompleteModal();
        return Promise.resolve();
      };

      btn2Action = () => (
        disputeExternalId
          ? navigateToDispute()
          : doCreateDispute().then(() => (navigateToDispute()))
      );
    }
  }

  if (isCompleted) {
    if (needReview) {
      status = TRANSACTION_STATUSES.NEED_REVIEW;

      btn1Action = () => {
        openEvaluationForm();
        return Promise.resolve();
      };

      if (role === SELLER) {
        btn2Action = () => {
          history.push('/settings/wallets');
          return Promise.resolve();
        };
      }
    } else {
      status = TRANSACTION_STATUSES.COMPLETED;

      btn1Action = () => {
        history.push('/settings/wallets');
        return Promise.resolve();
      };
    }
  }

  if (disputeStatus) {
    btn1Action = navigateToDispute;
    deadline = moment(disputeDeadline).toDate();

    switch (disputeStatus) {
      case DISPUTE_STATUS_MEDIATION:
        status = TRANSACTION_STATUSES.ISSUE_OPENED;
        break;

      case DISPUTE_STATUS_RETURN:
        status = TRANSACTION_STATUSES.ISSUE_OPENED;
        break;

      case DISPUTE_STATUS_RETURN_SHIPPED:
        status = TRANSACTION_STATUSES.ISSUE_OPENED;
        break;

      case DISPUTE_STATUS_RETURN_DELIVERED:
        status = TRANSACTION_STATUSES.ISSUE_OPENED;
        break;

      case DISPUTE_STATUS_ARBITRATION:
        status = TRANSACTION_STATUSES.ISSUE_IN_ARBITRATION;
        break;

      case DISPUTE_STATUS_CLOSED:
        status = TRANSACTION_STATUSES.ISSUE_CLOSED;
        break;

      case DISPUTE_STATUS_CANCELLED:
        status = TRANSACTION_STATUSES.ISSUE_CLOSED;
        break;

      default:
        break;
    }
  }

  if (isRejected) {
    status = TRANSACTION_STATUSES.REJECTED;
    isMessageVisible = false;
  }

  if (isExpired) {
    status = TRANSACTION_STATUSES.EXPIRED;
    isMessageVisible = false;
  }

  if (isCanceledByBuyer) {
    if (!isAccepted) {
      status = TRANSACTION_STATUSES.CANCEL_BUYER_BEFORE_PAYMENT;
    } else {
      status = TRANSACTION_STATUSES.CANCEL_BUYER_AFTER_PAYMENT;
    }
  }

  if (isCanceledBySeller) {
    if (!isAccepted) {
      status = TRANSACTION_STATUSES.CANCEL_SELLER_BEFORE_PAYMENT;
    } else {
      status = TRANSACTION_STATUSES.CANCEL_SELLER_AFTER_PAYMENT;
    }
  }

  if (isCanceledByAdmin) {
    if (!isAccepted) {
      status = TRANSACTION_STATUSES.CANCEL_ADMIN_BEFORE_PAYMENT;
    } else {
      status = TRANSACTION_STATUSES.CANCEL_ADMIN_AFTER_PAYMENT;
    }
  }

  if (isCanceledAutomatically) {
    if (!isAccepted) {
      status = TRANSACTION_STATUSES.CANCEL_AUTO_BEFORE_PAYMENT;
    } else {
      status = TRANSACTION_STATUSES.CANCEL_AUTO_AFTER_PAYMENT;
    }
  }

  switch (lastEventSlug) {
    case EVENT_TRANSACTION_CANCEL_REQUESTED:
      status = TRANSACTION_STATUSES.CANCEL_REQUESTED;
      deadline = moment(lastEventCreatedAt)
        .add(2, 'days')
        .toDate();

      btn1Action = () => {
        openConfirmCancelModal();
        return Promise.resolve();
      };

      btn2Action = () => {
        openCancelRejectModal();
        return Promise.resolve();
      };
      break;

    default:
      break;
  }

  if (status === null) {
    return null;
  }

  const translationKey = `status.${deliveryMethod}.${role}.${status}`;

  const showBtn1 = i18n.exists(`transactionActionPanel:${translationKey}.btn1`);
  const showBtn2 = i18n.exists(`transactionActionPanel:${translationKey}.btn2`);
  const disclaimer = i18n.exists(
    `transactionActionPanel:${translationKey}.disclaimer`,
  );
  const showSupportBtn = i18n.exists(
    `transactionActionPanel:${translationKey}.supportBtn`,
  );
  const showNewPropositionBtn = i18n.exists(
    `transactionActionPanel:${translationKey}.newPropositionBtn`,
  );

  const showBtnCol = showBtn1 || showBtn2 || showSupportBtn || showNewPropositionBtn;

  const showVerificationCode = status === TRANSACTION_STATUSES.ACCEPTED
    && role === BUYER
    && deliveryMethod === DELIVERY_METHOD_IN_PERSON;

  const translationValues = {
    buyerDisplayName,
    sellerDisplayName,
    title,
    client,
    sellerTotal: intl.formatNumber(sellerTotal / 100, {
      style: 'currency',
      currency,
    }),
    buyerTotal: intl.formatNumber(buyerTotal / 100, {
      style: 'currency',
      currency,
    }),
    deliveryAddress: formattedAddress,
    deadline:
      deadline && !Number.isNaN(deadline.valueOf())
        ? dateTimeFormatter.format(deadline)
        : null,
  };

  return (
    <>
      {isMessageVisible && <TransactionMessage message={messageFromBuyer} />}
      <div className="transaction__suggest border rounded">
        <SecureFundsModal
          show={secureFundsModal}
          onHide={closeSecureFundsModal}
        />
        <ShippingLabelModal
          allowedCountries={shippingProviderAllowedCountries}
          show={shippingLabelModal}
          onHide={closeShippingLabelModal}
          onSubmit={(senderAddress) => doGenerateShippingLabel(senderAddress)
            .then(() => {
              doGetShippingLabel().then(({ payload: { data } }) => {
                const link = saveFileFromDataUrl(
                  data,
                  `Shipping label #${id}`,
                );
                shippingLabelLink.current = link;
                link.click();
                closeShippingLabelModal();
              });
            })
            .catch(errorToast)}
        />
        <ShippingLabelSuccessModal
          show={shippingLabelSuccessModal}
          onHide={closeShippingLabelSuccessModal}
        />
        <CompleteTransactionModal
          deliveryMethod={deliveryMethod}
          visible={completeModal}
          onHide={closeCompleteModal}
          onSubmit={doCompleteTransaction}
        />
        <TrackingNumberModal
          visible={trackingModal}
          onHide={closeTrackingModal}
          onSubmit={(data, { resetForm }) => {
            doShipTransaction(data)
              .catch(errorToast)
              .finally(() => {
                resetForm(true);
                closeTrackingModal();
              });
          }}
        />
        <CancelRejectModal
          show={cancelRejectModal}
          onHide={closeCancelRejectModal}
          onCancel={doRejectCancelTransaction}
        />
        <ConfirmCancelModal
          onHide={closeConfirmCancelModal}
          onCancel={doCancelTransaction}
          show={confirmCancelModal}
          disabled={!canBeCanceled}
        />
        <ConfirmRejectModal
          show={confirmRejectModal}
          onHide={closeConfirmRejectModal}
          onReject={doRejectTransaction}
        />
        <EvaluationFormModal
          show={evaluationForm}
          onHide={closeEvaluationForm}
          onSubmit={(values, { resetForm }) => {
            doEvaluateTransaction(values).finally(() => {
              closeEvaluationForm();
              resetForm();
            });
          }}
          partner={isPurchase ? sellerDisplayName : buyerDisplayName}
        />
        <div className="transaction__intro mb-lg-0">
          {status && (
            <img
              src={images[status]}
              alt="picto"
              className="transaction__img-placeholder"
            />
          )}
          <div className="transaction__intro__body">
            <h5 className="transaction__title-main">
              {t(`${translationKey}.title`)}
            </h5>
            <div className="transaction__description keep-line-breaks">
              <p>
                <Trans
                  t={t}
                  i18nKey={`${translationKey}.content`}
                  values={translationValues}
                  components={{ bold: <strong /> }}
                />
              </p>
              {disclaimer && (
                <p className="transaction__disclaimer">
                  {t(`${translationKey}.disclaimer`, translationValues)}
                </p>
              )}
            </div>
            {showVerificationCode && (
              <div className="transaction__verification-code">
                <Trans
                  t={t}
                  i18nKey="verificationCode"
                  values={{ verificationCode }}
                >
                  Verification code:
                  <strong />
                </Trans>
              </div>
            )}
          </div>
        </div>
        {showBtnCol && (
          <div className="transaction__button-wrap">
            {showBtn1 && (
              <Button
                className="btn1"
                isLoading={btn1Submitting}
                variant={variant}
                disabled={btn2Submitting}
                onClick={() => {
                  setBtn1Submitting(true);
                  btn1Action()
                    .catch(errorToast)
                    .finally(() => {
                      setBtn1Submitting(false);
                    });
                }}
              >
                {t(`${translationKey}.btn1`)}
              </Button>
            )}
            {showBtn2 && (
              <Button
                variant={`outline-${variant}`}
                className="btn2"
                isLoading={btn2Submitting}
                disabled={btn1Submitting}
                onClick={() => {
                  setBtn2Submitting(true);
                  btn2Action()
                    .catch(errorToast)
                    .finally(() => {
                      setBtn2Submitting(false);
                    });
                }}
              >
                {t(`${translationKey}.btn2`)}
              </Button>
            )}
            {showNewPropositionBtn && (
              <Button
                variant="outline-primary"
                className="supportBtn"
                onClick={() => {
                  window.open(templatePermalink);
                }}
              >
                {t(`${translationKey}.newPropositionBtn`)}
              </Button>
            )}
            {showSupportBtn && (
              <Button
                variant="outline-primary"
                className="supportBtn"
                onClick={() => {
                  window.open('mailto:support@tripartie.com');
                }}
              >
                {t(`${translationKey}.supportBtn`)}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionActionPanel;
