import React, { useContext } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/takePictures/controls.module.scss';
import startVideoIcon from 'assets/images/startVideoIcon.svg';
import cameraIcon from 'assets/images/cameraIcon.svg';
import MediaContext from 'components/takePictures/mediaContext';
import { CONTENT_TYPE } from 'helpers/constants';
import Button from 'components/Button';
import { isSafari } from 'react-device-detect';

const Controls = () => {
  const { t } = useTranslation('takePictures');

  const {
    loading,
    contentType,
    isRecordingVideo,
    startRecordingVideo,
    stopRecordingVideo,
    changeContentType,
    takePhoto,
    isSteamActive,
    startStream,
    handleRecordVideoSafari,
  } = useContext(MediaContext);

  const recordButtonState = isRecordingVideo ? (
    <CountdownCircleTimer
      isPlaying
      duration={15}
      colors="#f53333"
      size={70}
      strokeWidth={5}
      trailColor="none"
    >
      {/* eslint-disable-next-line */}
      <button
        type="button"
        onClick={stopRecordingVideo}
        className={classes.controls__stopRecordingButton}
      />
    </CountdownCircleTimer>
  ) : (
    // eslint-disable-next-line
    <button
      type="button"
      onClick={startRecordingVideo}
      className={classes.controls__startRecordingButton}
    />
  );

  const recordButtonSafari = (
    <label htmlFor="video" className={classes.controls__startRecordingButton} />
  );

  const videoCapture = isRecordingVideo
    ? t('tapToStopVideo')
    : t('tapToStartVideo');

  const videoButton = isSafari ? recordButtonSafari : recordButtonState;

  return (
    <div className={classes.controls}>
      <input
        type="file"
        accept="video/*"
        capture="environment"
        id="video"
        className={classes.controls__safariFileInput}
        onChange={handleRecordVideoSafari}
      />
      <div className={classes.controls__buttons}>
        {!isSteamActive ? (
          <Button
            className={classes.controls__startStreamButton}
            onClick={startStream}
            disabled={loading}
          >
            START
          </Button>
        ) : (
          <>
            {contentType === CONTENT_TYPE.PHOTO ? (
              <button
                type="button"
                onClick={takePhoto}
                className={classes.controls__photographButton}
              >
                <div />
              </button>
            ) : (
              videoButton
            )}
            <button
              type="button"
              className={classes.controls__changeButton}
              onClick={changeContentType}
              disabled={isRecordingVideo}
            >
              <img
                src={
                  contentType === CONTENT_TYPE.PHOTO
                    ? startVideoIcon
                    : cameraIcon
                }
                alt="changeRecordType"
              />
            </button>
          </>
        )}
      </div>
      <div>
        {contentType === CONTENT_TYPE.PHOTO ? t('tapToPhoto') : videoCapture}
      </div>
    </div>
  );
};

export default Controls;
