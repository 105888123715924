import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { TAKE_PICTURES_ROUTES } from 'helpers/constants';
import PageTitle from 'components/takePictures/PageTitle';
import LogoTripartie from 'assets/svg/LogoTripartie';
import classes from 'assets/style/takePictures/takePicturesFirst.module.scss';
import { useTranslation } from 'react-i18next';

const TakePicturesFirst = () => {
  const { t } = useTranslation('takePictures');

  const location = useLocation();
  const history = useHistory();

  const goNext = useCallback(() => {
    history.push({
      pathname: TAKE_PICTURES_ROUTES.SECOND_SCREEN,
      state: location.state,
    });
  }, [
    location.state,
    history,
  ]);

  return (
    <div className={classes.takePicturesFirst}>
      <div className={classes.takePicturesFirst__headerContainer}>
        <PageTitle goBack={history.goBack}>
          <LogoTripartie
            fill="#004BB4"
            className={classes.takePicturesFirst__logo}
          />
        </PageTitle>
      </div>
      <div className={classes.takePicturesFirst__container}>
        <div>
          <p className={classes.takePicturesFirst__title}>
            {t('firstScreen.title')}
          </p>
          <div className={classes.takePicturesFirst__listItemContainer}>
            <div className={classes.takePicturesFirst__icon}>1</div>
            <div>
              <div className={classes.takePicturesFirst__subTitle}>
                {t('firstScreen.step1.title')}
              </div>
              <div className={classes.takePicturesFirst__description}>
                {t('firstScreen.step1.description')}
              </div>
            </div>
          </div>
          <div className={classes.takePicturesFirst__listItemContainer}>
            <div className={classes.takePicturesFirst__icon}>2</div>
            <div>
              <div className={classes.takePicturesFirst__subTitle}>
                {t('firstScreen.step2.title')}
              </div>
              <div className={classes.takePicturesFirst__description}>
                {t('firstScreen.step2.description')}
              </div>
            </div>
          </div>
          <div className={classes.takePicturesFirst__listItemContainer}>
            <div className={classes.takePicturesFirst__icon}>3</div>
            <div>
              <div className={classes.takePicturesFirst__subTitle}>
                {t('firstScreen.step3.title')}
              </div>
              <div className={classes.takePicturesFirst__description}>
                {t('firstScreen.step3.description')}
              </div>
            </div>
          </div>
        </div>
        <Button
          onClick={goNext}
          className={classes.takePicturesFirst__nextButton}
        >
          Lets secure my sale
        </Button>
      </div>
    </div>
  );
};

TakePicturesFirst.propTypes = {};

export default TakePicturesFirst;
