import React from 'react';
import { useTranslation } from 'react-i18next';

const SpanWithColon = ({
  children,
  ...props
}) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';

  return (
    <span {...props}>
      {children}
      {currentLang === 'fr' ? ' :' : ':'}
    </span>
  );
};

export default SpanWithColon;
