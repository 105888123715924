import React from 'react';

const WatchIcon = ({ stroke, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.129"
    height="23"
    viewBox="0 0 18.129 23"
    {...rest}
  >
    <defs>
      <style>
        {`.a,.b{fill:none;stroke:${stroke};stroke-linecap:round;stroke-linejoin:round;}.b{fill - rule:evenodd;}`}
      </style>
    </defs>
    <g transform="translate(-1028.386 -220.869)">
      <line className="a" y2="1.535" transform="translate(1037.451 223.804)" />
      <line className="a" y2="1.535" transform="translate(1037.451 239.404)" />
      <line className="a" x1="1.535" transform="translate(1044.456 232.399)" />
      <line className="a" x1="1.535" transform="translate(1028.911 232.399)" />
      <path
        className="b"
        d="M1037.451,223.8c-6.852,0-8.565,1.713-8.565,8.564s1.713,8.564,8.565,8.564,8.564-1.712,8.564-8.564-1.713-8.564-8.564-8.564Z"
      />
      <path
        className="a"
        d="M1032.588,240.378v1.913a1.078,1.078,0,0,0,1.078,1.078h7.57a1.078,1.078,0,0,0,1.077-1.078v-1.913a21.616,21.616,0,0,1-9.725,0Z"
      />
      <path
        className="a"
        d="M1042.313,224.359v-1.913a1.077,1.077,0,0,0-1.077-1.077h-7.57a1.078,1.078,0,0,0-1.078,1.077v1.913a21.578,21.578,0,0,1,9.725,0Z"
      />
      <line className="a" y1="5.059" transform="translate(1037.451 227.31)" />
      <line
        className="a"
        x2="2.845"
        y2="1.312"
        transform="translate(1037.451 232.369)"
      />
    </g>
  </svg>
);

export default WatchIcon;
