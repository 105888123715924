import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { baseUrl } from 'helpers/env';

const useRedirectFromWebapp = (disabled) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (!disabled) {
      const webappUrl = new URL(baseUrl);

      if (
        webappUrl.hostname
        && window.location.hostname !== webappUrl.hostname
      ) {
        window.location.hostname = webappUrl.hostname;
      }
    }
  }, [
    pathname,
    disabled,
  ]);
};

export default useRedirectFromWebapp;
