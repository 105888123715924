import * as React from 'react';

const IconPackage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
    <g data-name="Calque 2">
      <path
        data-name="Trac\xE9 380"
        d="M80 20L40 40 0 20 40 0z"
        fill="#e3bb6d"
      />
      <g data-name="Groupe 383" fill="#5b677e">
        <path
          data-name="Trac\xE9 381"
          d="M40.35 2.16L29.71 7.48l-.33-.16L40 2z"
        />
        <path
          data-name="Trac\xE9 382"
          d="M41.4 2.69L30.75 8l-.33-.16 10.65-5.32z"
        />
        <path
          data-name="Trac\xE9 383"
          d="M42.44 3.21L31.8 8.53l-.33-.17L42.11 3z"
        />
        <path
          data-name="Trac\xE9 384"
          d="M43.49 3.73L32.84 9.05l-.33-.17 10.65-5.31z"
        />
      </g>
      <g data-name="Groupe 384" fill="#5b677e">
        <path
          data-name="Trac\xE9 385"
          d="M47.52 31.08l-10.64 5.31-.33-.16 10.64-5.32z"
        />
        <path
          data-name="Trac\xE9 386"
          d="M48.57 31.6l-10.65 5.32-.33-.17 10.64-5.32z"
        />
        <path
          data-name="Trac\xE9 387"
          d="M49.61 32.12L39 37.44l-.33-.17L49.28 32z"
        />
        <path
          data-name="Trac\xE9 388"
          d="M50.66 32.64L40 38l-.33-.17 10.64-5.31z"
        />
      </g>
      <g data-name="Groupe 385">
        <path
          data-name="Trac\xE9 389"
          d="M40 80L0 60V20l40 20z"
          fill="#d8b268"
        />
        <path
          data-name="Trac\xE9 390"
          d="M40 80l40-20V20L40 40z"
          fill="#ca9761"
        />
      </g>
      <path
        data-name="Trac\xE9 391"
        d="M38 48.13L25.33 41.8v-6L38 42.11z"
        fill="#fff"
      />
      <path
        data-name="Trac\xE9 392"
        d="M79.91 19.93L40 39.87.09 19.93 0 20v.09l40 20 40-20V20z"
        fill="#f0c673"
      />
      <path
        data-name="Trac\xE9 393"
        d="M21.74 41.16L16 38.28V28l5.78 2.88z"
        fill="#fbf2d2"
      />
      <path
        data-name="Trac\xE9 394"
        d="M16 28l5.77 2.89L62 11l-5.77-2.89z"
        fill="#fff6d6"
      />
      <g data-name="Groupe 386" fill="#5b677e">
        <path
          data-name="Trac\xE9 395"
          d="M6.8 55.31l-4.5-2.25c-.16-.09-.3 0-.3.15v4.5a.57.57 0 00.3.45l4.5 2.25c.17.09.3 0 .3-.15v-4.5a.53.53 0 00-.3-.45zm.07 4.59c0 .18-.14.24-.31.16l-4-2a.56.56 0 01-.31-.47v-4c0-.17.13-.24.31-.15l4 2a.58.58 0 01.31.47z"
        />
        <path
          data-name="Trac\xE9 396"
          d="M3.45 57.79l.28.14v-2.72l.25.12-.39-1-.38.58.24.13z"
        />
        <path
          data-name="Trac\xE9 397"
          d="M5.37 58.75l.29.14v-2.72l.24.12-.38-1-.39.59.25.12z"
        />
        <path
          data-name="Trac\xE9 398"
          d="M2.8 57.89l3.5 1.75v-.29L2.8 57.6z"
        />
        <path
          data-name="Trac\xE9 399"
          d="M12.86 58.34l-4.5-2.25c-.17-.09-.3 0-.3.15v4.5a.57.57 0 00.3.45l4.5 2.25c.17.09.3 0 .3-.15v-4.5a.57.57 0 00-.3-.45zm.07 4.6c0 .17-.14.24-.31.15l-4-2a.62.62 0 01-.31-.47v-4c0-.17.14-.24.31-.15l4 2a.59.59 0 01.31.46z"
        />
        <path
          data-name="Trac\xE9 400"
          d="M11 59.55a1.34 1.34 0 00.61 1v1.56l-.45-.22c-.09 0-.16 0-.16.08l1.38.68a.31.31 0 00-.16-.24l-.45-.22v-1.58c.34.12.61 0 .61-.37v-1.59L11 58z"
        />
        <path
          data-name="Trac\xE9 401"
          d="M8.86 58.49a1.27 1.27 0 00.61 1V61L9 60.8c-.09 0-.16 0-.16.08l1.38.69a.31.31 0 00-.16-.24l-.45-.22v-1.57c.34.13.61 0 .61-.36v-1.6l-1.36-.68zM9 57.11l1.1.55v1.45c0 .29-.25.42-.55.27a1 1 0 01-.55-.82z"
        />
        <path
          data-name="Trac\xE9 402"
          d="M18.92 61.36l-4.5-2.25c-.17-.08-.3 0-.3.15v4.5a.55.55 0 00.3.46l4.5 2.25c.16.08.3 0 .3-.15v-4.5a.59.59 0 00-.3-.46zM19 66c0 .17-.14.24-.31.15l-4-2a.6.6 0 01-.31-.47v-4c0-.18.14-.25.31-.16l4 2a.56.56 0 01.31.46z"
        />
        <path
          data-name="Trac\xE9 403"
          d="M16.83 61.19V61a.2.2 0 00-.09-.13.05.05 0 00-.08 0v.15c-.78-.34-1.41-.05-1.48.69h.08a1.35 1.35 0 01.49.43.6.6 0 01.49.06 1.57 1.57 0 01.42.35v1.78c0 .13-.11.19-.25.12a.47.47 0 01-.25-.37v.02a.16.16 0 00-.08-.13L16 64v.08a.79.79 0 00.42.63c.23.12.42 0 .42-.21v-1.8a.67.67 0 01.39.08 1.38 1.38 0 01.48.42.64.64 0 01.49.06l.12.07a3 3 0 00-1.49-2.14z"
        />
      </g>
    </g>
  </svg>
);

export default IconPackage;
