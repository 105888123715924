import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  DELIVERY_METHOD_IN_PERSON,
  DELIVERY_METHOD_REMOTE,
} from 'helpers/constants';
import SpanWithColon from 'helpers/SpanWithColon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import shippingProvidersConfig from 'helpers/shippingProvidersConfig';
import defaultTransactionImage from 'assets/images/defaultTransactionImage.svg';
import useWindowSize from 'helpers/useWindowSize';

const TransactionDetailsCard = ({ transaction }) => {
  const {
    title,
    buyerDisplayName,
    sellerDisplayName,
    client,
    deliveryMethod = DELIVERY_METHOD_IN_PERSON,
    events = [],
    adUrl,
    isPurchase,
    deliveryAddress: { recipientName, formattedAddress } = {},
    picture,
    shippingProvider,
  } = transaction;

  const { t } = useTranslation('transaction');

  const { width } = useWindowSize();
  const intl = useIntl();

  const isMobile = useMemo(() => width < 768, [width]);

  const displayShippingProvider = shippingProvider
    ? ` - ${shippingProvidersConfig[shippingProvider]?.name}`
    : '';

  return (
    <div className="transaction-detail rounded">
      <div className="transaction-detail__image">
        <img src={picture || defaultTransactionImage} alt="transaction" />
      </div>
      <h5 className="transaction-detail__title overflow-ellipsis">
        {t('details.title')}
      </h5>
      <div className="transaction-detail__list">
        <div className="transaction-detail__list-container">
          <div className="d-flex justify-content-between">
            <p className="m-0">{t('details.purchase')}</p>
            <p className="m-0 text-right overflow-ellipsis">{title}</p>
          </div>

          <div className="d-flex justify-content-between">
            <p className="m-0">{t('details.deliveryMode')}</p>
            <p className="m-0 text-right">
              {deliveryMethod === DELIVERY_METHOD_IN_PERSON
                && t('details.handover')}
              {deliveryMethod === DELIVERY_METHOD_REMOTE
                && `${t('details.delivery')}${displayShippingProvider}`}
            </p>
          </div>

          {isPurchase ? (
            <div className="d-flex justify-content-between">
              <p className="m-0">
                <SpanWithColon>{t('details.seller')}</SpanWithColon>
              </p>
              <p className="m-0 text-right">{sellerDisplayName}</p>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <p className="m-0">{t('details.buyer')}</p>
              <p className="m-0 text-right">{buyerDisplayName}</p>
            </div>
          )}

          <div className="d-flex justify-content-between">
            <p className="m-0">{t('details.client')}</p>
            <p className="m-0 text-right">
              {client}
              {adUrl && (
                <p className="m-0">
                  <small>
                    <a href={adUrl}>{t('details.adUrl')}</a>
                  </small>
                </p>
              )}
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <p className="m-0">{t('details.sentOn')}</p>
            <p className="m-0 text-right">
              {events[0]?.date
                ? intl.formatDate(new Date(events[0]?.date)).replace(/\//g, '.')
                : ''}
            </p>
          </div>
        </div>

        {deliveryMethod === DELIVERY_METHOD_REMOTE && (
          <div className="d-flex justify-content-between transaction-detail-address">
            <p className="m-0">{t('details.deliveryAddress')}</p>
            <p
              className={classNames('m-0 text-right', {
                'keep-line-breaks': !isMobile,
              })}
            >
              {recipientName}
              <br />
              {formattedAddress?.replace(/,/g, '\n')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionDetailsCard;
