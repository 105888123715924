import React, { useEffect, useState, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import Spinner from 'react-bootstrap/Spinner';
import * as yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { refreshMe } from 'logic/actions/meActions';
import { uploadKycDocument } from 'logic/actions/kycActions';
import SpanWithColon from 'helpers/SpanWithColon';
import 'assets/style/setting-wallet.scss';
import {
  STATUS_VERIFIED,
  STATUS_VERIFICATION_PENDING,
  STATUS_NOT_VERIFIED,
} from 'helpers/constants';

const FILE_SIZE = 5 * 1024 * 1024; // 5MB
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'application/pdf'];

const ProfileVerification = ({
  me: { identityVerifyStatus, hasCompletedRegistration } = {},
  refreshMe,
  uploadKycDocument,
}) => {
  const { t } = useTranslation('profileVerification');

  const uploadSuccessToast = useCallback(
    () => toast.success(t('form.uploadSuccess')),
    [t],
  );
  const uploadErrorToast = useCallback(
    () => toast.error(t('form.uploadError')),
    [t],
  );
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadId, setUploadId] = useState(); // true: ID, false: passport

  useEffect(() => {
    setLoading(true);
    refreshMe().then(() => {
      setLoading(false);
    });
  }, [refreshMe]);

  const uploadDocumentModal = (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      centered
      className="modal-add-payment"
    >
      <Modal.Header closeButton>
        {uploadId ? t('form.titleID') : t('form.titlePassport')}
      </Modal.Header>

      <Modal.Body>
        <div className="form-content">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
              recto: yup
                .mixed()
                .required(t('form.emptyPages'))
                .test(
                  'fileSize',
                  t('form.fileTooBig'),
                  (file) => !file || file.size <= FILE_SIZE,
                )
                .test(
                  'fileFormat',
                  t('form.formatNotSupported'),
                  (file) => !file || SUPPORTED_FORMATS.includes(file.type),
                ),
              verso:
                uploadId
                && yup
                  .mixed()
                  .required(t('form.emptyPages'))
                  .test(
                    'fileSize',
                    t('form.fileTooBig'),
                    (file) => !file || file.size <= FILE_SIZE,
                  )
                  .test(
                    'fileFormat',
                    t('form.formatNotSupported'),
                    (file) => !file || SUPPORTED_FORMATS.includes(file.type),
                  ),
            })}
            onSubmit={({ recto, verso }, { setSubmitting }) => {
              uploadKycDocument([recto, verso])
                .then(() => {
                  setLoading(true);
                  refreshMe().finally(() => {
                    setLoading(false);
                  });
                  uploadSuccessToast();
                })
                .catch(() => {
                  uploadErrorToast();
                  setSubmitting(false);
                })
                .finally(() => {
                  setShowModal(false);
                });
            }}
            initialValues={{
              recto: null,
              verso: null,
            }}
          >
            {({
              handleSubmit,
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Alert variant="info">{t('form.warning')}</Alert>
                {uploadId && (
                  <span className="modal-add-payment__text-holder-address">
                    {t('form.recto')}
                  </span>
                )}
                <Row>
                  <Col className="form-group-top">
                    <Form.File id="recto" custom>
                      <Form.File.Input
                        onChange={({
                          target: { files: [recto = null] = [] },
                        }) => {
                          setFieldValue('recto', recto);
                        }}
                        accept="image/png, image/jpeg, application/pdf"
                        isInvalid={touched.recto && !!errors.recto}
                      />
                      <Form.File.Label data-browse={t('form.browse')}>
                        {values.recto ? values.recto.name : t('form.noFile')}
                      </Form.File.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.recto}
                      </Form.Control.Feedback>
                    </Form.File>
                  </Col>
                </Row>
                {uploadId && (
                  <>
                    <span className="modal-add-payment__text-holder-address">
                      {t('form.verso')}
                    </span>
                    <Row>
                      <Col className="form-group-top">
                        <Form.File id="verso" custom>
                          <Form.File.Input
                            onChange={({
                              target: { files: [verso = null] = [] },
                            }) => {
                              setFieldValue('verso', verso);
                            }}
                            accept="image/png, image/jpeg, application/pdf"
                            isInvalid={touched.verso && !!errors.verso}
                          />
                          <Form.File.Label data-browse={t('form.browse')}>
                            {values.verso
                              ? values.verso.name
                              : t('form.noFile')}
                          </Form.File.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.verso}
                          </Form.Control.Feedback>
                        </Form.File>
                      </Col>
                    </Row>
                  </>
                )}
                <div className="my-2">
                  <Button type="submit" isLoading={isSubmitting}>
                    {t('form.submit')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {loading ? (
        <Spinner className="mx-auto d-block" animation="border" />
      ) : (
        <div className="block-wallet">
          {uploadDocumentModal}

          {!hasCompletedRegistration && (
            <Alert variant="info">
              <Trans t={t} i18nKey="mustCompleteRegistration">
                Prior to verifying your account, you must
                <Link to="/settings/profile">complete your profile</Link>
                .
              </Trans>
            </Alert>
          )}

          {identityVerifyStatus === STATUS_VERIFIED && (
            <Alert variant="success">{t(STATUS_VERIFIED)}</Alert>
          )}

          {identityVerifyStatus === STATUS_VERIFICATION_PENDING && (
            <Alert variant="info">{t(STATUS_VERIFICATION_PENDING)}</Alert>
          )}

          {identityVerifyStatus === STATUS_NOT_VERIFIED && (
            <>
              <div className="block-wallet__item">
                <p className="block-profile__title-main">{t('title')}</p>
                <p className="block-wallet__description">{t('description')}</p>
                <div className="block-wallet__description__list">
                  <p>
                    <SpanWithColon className="text-underline">
                      {t('allowedDocuments')}
                    </SpanWithColon>
                  </p>
                  <ul>
                    <li>{t('documents.id')}</li>
                    <li>{t('documents.passport')}</li>
                  </ul>
                </div>
                <div className="block-wallet__description__btn-wrapper">
                  <Button
                    className="btn-block first-btn"
                    variant="outline-primary"
                    disabled={!hasCompletedRegistration}
                    onClick={() => {
                      setUploadId(true);
                      setShowModal(true);
                    }}
                  >
                    {t('addID')}
                  </Button>
                  <Button
                    className="btn-block"
                    variant="outline-primary"
                    disabled={!hasCompletedRegistration}
                    onClick={() => {
                      setUploadId(false);
                      setShowModal(true);
                    }}
                  >
                    {t('addPassport')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  countries: state.persistent.countriesReducer.countries,
});

const mapDispatchToProps = (dispatch) => ({
  refreshMe: bindActionCreators(refreshMe, dispatch),
  uploadKycDocument: bindActionCreators(uploadKycDocument, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileVerification);
