import React from 'react';
import LogoTripartie from 'assets/svg/LogoTripartie';

const LoginHeader = () => (
  <div className="login__header">
    <LogoTripartie className="login__logo" />
  </div>
);

export default LoginHeader;
