import { envName, isDev } from 'helpers/env';

export const CURRENCY_EUR = 'EUR';

export const CARD_PROVIDER_VISA = 'VISA';
export const CARD_PROVIDER_MASTERCARD = 'MASTERCARD';
export const CARD_PROVIDER_CB = 'CB';

export const PAYMENT_CREATED = 'CREATED';
export const PAYMENT_SUCCEEDED = 'SUCCEEDED';

export const FLOW_OBJECTS = 'objects';
export const FLOW_SERVICES = 'services';

export const DELIVERY_METHOD_IN_PERSON = 'in_person';
export const DELIVERY_METHOD_REMOTE = 'remote';
export const DELIVERY_METHOD_MIXED = 'mixed';

export const CLIENT_TRIPARTIE = 'Tripartie';

export const ERROR_EMAIL_ALREADY_EXISTS = 'email_already_exists';

export const STATUS_VERIFIED = 'status_verified';
export const STATUS_VERIFICATION_PENDING = 'status_verification_pending';
export const STATUS_NOT_VERIFIED = 'status_not_verified';

export const BUYER = 'buyer';
export const SELLER = 'seller';

export const EVENT_TRANSACTION_ACCEPT = 'event_transaction_accept';
export const EVENT_TRANSACTION_COMPLETE = 'event_transaction_complete';
export const EVENT_TRANSACTION_CANCEL_REQUESTED = 'event_transaction_cancel_requested';
export const EVENT_TRANSACTION_OPEN_ISSUE = 'event_transaction_open_issue';
export const EVENT_TRANSACTION_UPDATE_ISSUE = 'event_transaction_update_issue';
export const EVENT_TRANSACTION_CLOSE_ISSUE = 'event_transaction_close_issue';
export const EVENT_TRANSACTION_SHIP = 'event_transaction_ship';
export const EVENT_TRANSACTION_DELIVERED = 'event_transaction_delivered';

export const CURRENCIES = [
  'EUR',
  'GBP',
  'USD',
  'CHF',
  'AUD',
  'CAD',
  'SEK',
  'NOK',
  'DKK',
  'ZAR',
  'PLN',
];

export const PRODUCT_CATEGORIES = [
  'auto_moto',
  'house_garden',
  'sport',
  'fashion',
  'telecom',
  'other',
];

export const HOMEPAGE_TAB_KEY = 'HOMEPAGE_TAB';

export const HOMEPAGE_TABS = {
  LINKS: 'links',
  FINISHED: 'finished',
  IN_PROGRESS: 'inProgress',
};

export const IMAGE_MAX_SIZE_FOR_CROPPER = 600;

export const CHECKOUT_STEPS = {
  sign: 'sign',
  delivery: 'delivery',
  message: 'message',
  payment: 'payment',
};

export const CANCEL_REJECT_REASONS = ['alreadyShipped', 'alreadyDelivered'];

export const ROUTES = {
  RESET_PASSWORD: {
    MAIN: '/reset-password',
    REQUEST: '/reset-password/request',
    MAIL_INFO: '/reset-password/mail',
    NEW_PASSWORD: '/reset-password/:token',
  },
};

export const ERRORS_FROM_SERVER = {
  NO_USER: 'user_not_found',
  NO_TOKEN: 'token_not_found',
  PASSWORD_ERROR: 'same_password',
  WRONG_IMAGE_TYPE: 'wrong_image_type',
  MEDIA_NOT_FOUND: 'media_not_found',
  INVALID_PROMOCODE: 'promo_code_not_found',
  PROMOCODE_NOT_FOUND: 'coupon_not_found',
  INVALID_VERIFICATION_CODE: 'invalid_verification_code',
};

export const LANG_COOKIE_NAME = `tripartie-lang-${envName}`;
export const LANG_COOKIE_OPT = {
  path: '/',
  domain: isDev ? 'localhost' : 'tripartie.app',
};

export const ERRORS = {
  sameUser: 'same_user_error',
};

export const REJECT_OFFER_REASONS_KEYS = [
  'alreadySold',
  'acceptedAnotherOffer',
  'notSatisfied',
  'other',
];

export const TRANSACTION_STATUSES = {
  PREAUTHORIZED: 'preauthorized',
  ACCEPTED: 'accepted',
  ACCEPTED_LABEL_GENERATION: 'acceptedLabelGeneration',
  LABEL_GENERATED: 'labelGenerated',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  NEED_REVIEW: 'needReview',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  CANCEL_BUYER_BEFORE_PAYMENT: 'canceledByBuyerBeforePayment',
  CANCEL_BUYER_AFTER_PAYMENT: 'canceledByBuyerAfterPayment',
  CANCEL_SELLER_BEFORE_PAYMENT: 'canceledBySellerBeforePayment',
  CANCEL_SELLER_AFTER_PAYMENT: 'canceledBySellerAfterPayment',
  CANCEL_ADMIN_BEFORE_PAYMENT: 'canceledByAdminBeforePayment',
  CANCEL_ADMIN_AFTER_PAYMENT: 'canceledByAdminAfterPayment',
  CANCEL_AUTO_BEFORE_PAYMENT: 'canceledAutoBeforePayment',
  CANCEL_AUTO_AFTER_PAYMENT: 'canceledAutoAfterPayment',
  CANCEL_REQUESTED: 'cancelRequested',
  ISSUE_OPENED: 'issueOpened',
  ISSUE_IN_ARBITRATION: 'issueInArbitration',
  ISSUE_CLOSED: 'issueClosed',
};

export const AVATAR_MAX_WEIGHT = 20971520;

export const TRANSACTION_TEMPLATE_IMAGE_MAX_SIZE = 2 * 1024 * 1024;
export const TRANSACTION_TEMPLATE_IMAGE_FORMATS = ['image/png', 'image/jpeg'];

export const ISSUE_PROOFS_FILE_FORMATS = [
  'image/png',
  'image/jpeg',
  'application/pdf',
];

export const AVAILABLE_COUNTRIES_SLUGS_FOR_PARCEL = [
  'country_france',
  'country_switzerland',
];
export const AVAILABLE_COUNTRIES_ISO_FOR_PARCEL = ['FR', 'CH'];

export const TRANSACTION_DELIVERY_METHODS = [
  {
    value: DELIVERY_METHOD_IN_PERSON,
    shippingCosts: false,
    requestValues: {
      allowInPerson: true,
      allowDelivery: false,
    },
  },
  {
    value: DELIVERY_METHOD_REMOTE,
    shippingCosts: true,
    requestValues: {
      allowInPerson: false,
      allowDelivery: true,
    },
  },
  {
    value: DELIVERY_METHOD_MIXED,
    shippingCosts: true,
    requestValues: {
      allowInPerson: true,
      allowDelivery: true,
    },
  },
];

export const REPORT_PROBLEM_OPTIONS_KEYS = [
  'doesNotMatchDescription',
  'hasDamage',
  'orderIncomplete',
  'didNotReceive',
];

export const REPORT_PROBLEM_MODAL_STEPS = {
  REASONS: 'reasons',
  DETAILS: 'details',
  THANKS: 'thanks',
};

export const externalId = 'external-id';

export const CHECKOUT_ADDRESS_MODAL_STEPS = {
  DELIVERY_METHOD: 'deliveryMethod',
  DELIVERY_ADDRESS: 'deliveryAddress',
  PICKUP_POINT: 'pickupPoint',
};

const MONDIAL_RELAIS = 'mondial-relay';
export const PICKUP_PROVIDERS = [
  MONDIAL_RELAIS,
];
export const DISABLED_PROVIDERS = [
  // MONDIAL_RELAIS,
];

export const CALLING_CODES_DEFAULT_OPTIONS = ['FR', 'CH'];

export const MEDIA_TYPES = {
  ITEMS: 'items',
  PARCEL: 'parcel',
};

export const CONTENT_TYPE = {
  PHOTO: 'photo',
  VIDEO: 'video',
};

export const VIDEO_MAX_DURATION = 15000;

export const TAKE_PICTURES_ROUTES = {
  FIRST_SCREEN: '/pictures/first',
  SECOND_SCREEN: '/pictures/second',
  ITEMS_SCREEN: `/pictures/${MEDIA_TYPES.ITEMS}`,
  PARCEL_SCREEN: `/pictures/${MEDIA_TYPES.PARCEL}`,
  RESULT_SCREEN: '/pictures/result',
  COMMON: '/pictures',
};

export const PICTURES_UPLOAD_STATUS_KEY = 'uploadStatus';

const BETA_TESTERS_REGEXP = [
  /^chris.boucrot(\+.+)?@gmail.com$/,
  /^andreas.lambropoulos30(\+.+)?@gmail.com$/,
  /^andreas.lambropoulos274(\+.+)?@gmail.com$/,
  /^andreas(\+.+)?@tripartie.com$/,
  /^andreas@andreaslambropoulos.com$/,
];

export const isBetaTester = (email) => BETA_TESTERS_REGEXP.some((regexp) => regexp.test(email));

export const MISSING_REFRESH_TOKEN = 'missing_refresh_token';

export const DISPUTE_STATUS_MEDIATION = 'mediation';
export const DISPUTE_STATUS_RETURN = 'return';
export const DISPUTE_STATUS_RETURN_SHIPPED = 'return_shipped';
export const DISPUTE_STATUS_RETURN_DELIVERED = 'return_delivered';
export const DISPUTE_STATUS_ARBITRATION = 'arbitration';
export const DISPUTE_STATUS_CLOSED = 'closed';
export const DISPUTE_STATUS_CANCELLED = 'cancel';

export const MANGOPAY = 'mangopay';
