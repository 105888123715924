import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as clipboard from 'clipboard-polyfill/text';
import copyToClipboard from 'helpers/copyToClipboard';

const useCopyToClipboard = () => {
  const { t } = useTranslation('transactionTemplate');

  const copyPermalinkSuccessToast = useCallback(
    () => toast.success(t('copyPermalinkSuccess')),
    [t],
  );
  const copyPermalinkErrorToast = useCallback(
    () => toast.error(t('copyPermalinkError')),
    [t],
  );

  const handleCopyToClipboard = useCallback((permalink) => {
    clipboard
      .writeText(permalink)
      .then(copyPermalinkSuccessToast)
      .catch(() => {
        copyToClipboard(permalink)
          .then(copyPermalinkSuccessToast)
          .catch(copyPermalinkErrorToast);
      });
  }, [
    copyPermalinkErrorToast,
    copyPermalinkSuccessToast,
  ]);

  return {
    handleCopyToClipboard,
  };
};

export default useCopyToClipboard;
