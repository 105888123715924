/* eslint-disable import/no-unused-modules */
export const CREATE_USER = 'app/users/CREATE_USER';
export const CHECK_EMAIL = 'app/users/CHECK_EMAIL';
/* eslint-enable import/no-unused-modules */

export const createUser = (data) => ({
  type: CREATE_USER,
  payload: {
    request: {
      method: 'POST',
      url: '/api/webapp/users/',
      data,
    },
  },
});

export const checkEmail = (email) => ({
  type: CHECK_EMAIL,
  payload: {
    request: {
      method: 'POST',
      url: '/api/webapp/users/check/email/',
      data: { email },
    },
  },
});
