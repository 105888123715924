import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/confirmDeleteAccountModal.module.scss';
import Button from 'components/Button';

const ConfirmDeleteModal = ({
  show, onClose, disabled, onDelete, loading,
}) => {
  const { t } = useTranslation('confirmDeleteAccount');

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      containerClassName={classes.confirmDeleteAccountModal__container}
      contentClassName={classes.confirmDeleteAccountModal__content}
    >
      <div className={classes.confirmDeleteAccountModal__title}>
        {t('title')}
      </div>

      <div className={classes.confirmDeleteAccountModal__info}>{t('text')}</div>

      <div className={classes.confirmDeleteAccountModal__buttonsContainer}>
        <Button
          variant="primary"
          isLoading={loading}
          disabled={disabled}
          onClick={onDelete}
        >
          {t('confirmText')}
        </Button>
        <Button variant="outline-primary" onClick={onClose}>
          {t('cancelText')}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteModal;
