import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/passwordInput.module.scss';
import HidePasswordIcon from 'assets/images/hide-password-icon.svg';
import ShowPasswordIcon from 'assets/images/show-password-icon.svg';
import PasswordStrengthBar from 'components/PasswordStrengthBar';
import { useTranslation } from 'react-i18next';
import { debug } from 'helpers/env';

const icons = {
  password: ShowPasswordIcon,
  text: HidePasswordIcon,
};

export function usePasswordStrengthTest() {
  const { t } = useTranslation('passwordInput');

  return {
    name: 'passwordStrong',
    message: t('weakPassword'),
    test: async (password = '') => debug
      || import('zxcvbn').then(({ default: zxcvbn }) => zxcvbn(password).score > 2),
  };
}

const PasswordInput = ({
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  placeholder,
  showStrenghtBar,
  children,
}) => {
  const [type, setType] = useState('password');

  const changeType = useCallback(() => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'));
  }, [setType]);

  return (
    <div className={classes.passwordInput}>
      <Form.Control
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
        placeholder={placeholder}
        className="password-input"
      />
      {value && (
        <div className={classes.changeButton}>
          <button type="button" onClick={changeType}>
            <img src={icons[type]} alt="show/hide password" />
          </button>
        </div>
      )}
      {showStrenghtBar && value && (
        <PasswordStrengthBar
          className={classes.pwdStrengthBar}
          password={value}
        />
      )}
      {children}
    </div>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  children: PropTypes.node,
  showStrenghtBar: PropTypes.bool,
};

PasswordInput.defaultProps = {
  isInvalid: false,
  showStrenghtBar: false,
  children: null,
};

export default PasswordInput;
