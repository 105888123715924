import { createReducer } from '@reduxjs/toolkit';
import { success, shouldResetState } from 'logic/reducers/helpers';
import { GET_OWN_PAYMENT_CARDS } from 'logic/actions/paymentCardsActions';

const initialState = { paymentCards: [] };

const handleGetOwnPaymentCards = (state, { payload: { data = [] } = {} }) => {
  state.paymentCards = data;
};

export default createReducer(
  initialState,
  { [success(GET_OWN_PAYMENT_CARDS)]: handleGetOwnPaymentCards },
  [
    {
      matcher: (action) => shouldResetState(action),
      reducer: () => initialState,
    },
  ],
);
