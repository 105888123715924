import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import DocumentsPicker from 'components/DocumentsPicker';
import { ISSUE_PROOFS_FILE_FORMATS } from 'helpers/constants';

const IssueAddProof = ({ proofs = [], back, next }) => {
  const { t } = useTranslation('disputeResolution');

  const [documents, setDocuments] = useState(proofs);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>{t('issueAddProof.title')}</p>
      <div className={classes.form}>
        <label className={classes.formLabel}>
          {t('issueAddProof.imagePickerTitle')}
        </label>
        <DocumentsPicker
          acceptFileTypes={ISSUE_PROOFS_FILE_FORMATS}
          selectDocument={(newDoc) => {
            setDocuments((documents) => [...documents, newDoc]);
          }}
          removeDocument={(docToRemove) => {
            setDocuments((documents) => documents.filter((doc) => doc !== docToRemove));
          }}
          documents={documents}
          addButtonText={t('issueAddProof.addFile')}
        />
        <label className={classes.formBottomLabel}>
          {t('issueAddProof.imagePickerBottomLabel')}
        </label>
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(documents);
        }}
        disabled={!documents.length}
      >
        {t('issueAddProof.next')}
      </Button>
    </Row>
  );
};

export default IssueAddProof;
