import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPermalink } from 'logic/actions/clientsActions';

const useRedirectFromCheckout = (clientId) => {
  const permalink = useSelector(selectPermalink(clientId));

  useEffect(() => {
    if (permalink) {
      const permalinkUrl = new URL(permalink);

      if (
        permalinkUrl.hostname
        && window.location.hostname !== permalinkUrl.hostname
      ) {
        window.location.hostname = permalinkUrl.hostname;
      }
    }
  }, [permalink]);
};

export default useRedirectFromCheckout;
