import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import ReactStars from 'react-rating-stars-component';
import starFullIcon from 'assets/images/star.svg';
import classes from 'assets/style/evaluateFormModal.module.scss';
import classNames from 'classnames';
import EmptyStarIcon from 'assets/svg/EmptyStarIcon';

const EvaluationFormModal = ({
  show, onHide, onSubmit, partner,
}) => {
  const { t } = useTranslation('evaluationForm');

  const getError = (errors) => {
    if (errors.score && errors.comment) {
      return t('emptyForm');
    }

    return errors.score || errors.comment;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={classes.evaluateFormModal}
      contentClassName={classes.evaluateFormModal__content}
    >
      <Modal.Header closeButton />
      <h4 className={classes.evaluateFormModal__title}>
        {t('title', { partner })}
      </h4>
      <p className={classes.evaluateFormModal__subtitle}>{t('overall')}</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={yup.object({
          score: yup.number().min(1, t('emptyRate')).max(5, t('invalidScore')),
          comment: yup.string().required(t('emptyComment')),
        })}
        onSubmit={onSubmit}
        initialValues={{
          score: 0,
          comment: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ReactStars
              count={5}
              value={values.score}
              onChange={(score) => {
                setFieldValue('score', score);
                setFieldError('score', '');
              }}
              emptyIcon={(
                <EmptyStarIcon
                  className={classNames(
                    classes.evaluateFormModal__star,
                    classes.evaluateFormModal__grayStar,
                    {
                      [classes.evaluateFormModal__errorStar]: !!errors.score,
                    },
                  )}
                />
              )}
              filledIcon={(
                <img
                  alt="filled-star"
                  className={classNames(classes.evaluateFormModal__star)}
                  src={starFullIcon}
                />
              )}
              edit
              classNames={classes.evaluateFormModal__starContainer}
            />
            <Form.Group
              controlId="comment"
              className={classes.evaluateFormModal__formGroup}
            >
              <Form.Label className={classes.evaluateFormModal__label}>
                {t('comment')}
              </Form.Label>
              <Form.Control
                as="textarea"
                maxLength={300}
                type="text"
                name="comment"
                placeholder={t('commentPlaceholder')}
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.comment && !!errors.comment}
                className={classes.evaluateFormModal__commentTextarea}
              />
            </Form.Group>
            <div
              className={classNames(
                classes.evaluateFormModal__errorMessage,
                'invalid-feedback',
              )}
            >
              {getError(errors)}
            </div>
            <Button
              className={classes.evaluateFormModal__button}
              type="submit"
              isLoading={isSubmitting}
            >
              {t('submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EvaluationFormModal;
