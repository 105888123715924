import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/messageForm.module.scss';
import { CHECKOUT_STEPS } from 'helpers/constants';
import Button from 'components/Button';

const MessageForm = ({ activeKey, setActiveKey, setMessage }) => {
  const { t } = useTranslation('checkout');

  const [note, setNote] = useState('');

  useEffect(() => {
    if (activeKey === CHECKOUT_STEPS.sign) {
      setNote('');
    }
  }, [activeKey]);

  const handleChange = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [setNote],
  );

  const addNote = useCallback(() => {
    setActiveKey(CHECKOUT_STEPS.payment);
    if (note) {
      setMessage(note.replace(/\s\s+/g, ' ').trim());
    } else {
      setMessage(undefined);
    }
  }, [setActiveKey, setMessage, note]);

  return (
    <div id={classes.messageForm}>
      <label htmlFor="note" className={classes.messageForm__messageLabel}>
        <span>{t('message.label')}</span>
        {!!note && <span className="ml-1">{`(${note.length}/140)`}</span>}
      </label>
      <textarea
        className={classes.messageForm__textArea}
        maxLength={140}
        value={note}
        onChange={handleChange}
        id="note"
        placeholder={t('message.placeholder')}
      />
      <Button
        type="button"
        className={classes.messageForm__addBtn}
        onClick={addNote}
      >
        {note ? t('message.addNote') : t('message.skipNote')}
      </Button>
    </div>
  );
};

MessageForm.propTypes = {
  activeKey: PropTypes.string.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default MessageForm;
