import React, { useEffect, useState, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormattedNumber } from 'react-intl';
import { refreshMe } from 'logic/actions/meActions';
import { getOwnBankAccounts } from 'logic/actions/bankAccountsActions';
import { getOwnWallets, cashout } from 'logic/actions/walletsActions';
import { CURRENCY_EUR } from 'helpers/constants';
import useWindowSize from 'helpers/useWindowSize';
import 'assets/style/setting-wallet.scss';

const DEFAULT_WALLET = {
  balance: 0,
  currency: CURRENCY_EUR,
};

const Wallets = ({
  me: { canCashout } = {},
  wallets = [],
  bankAccounts = [],
  refreshMe,
  getOwnBankAccounts,
  getOwnWallets,
  cashout,
}) => {
  const { t } = useTranslation('wallets');
  const { isMobile } = useWindowSize();

  const cashoutSuccessToast = useCallback(
    () => toast.success(t('cashoutSuccess')),
    [t],
  );
  const cashoutErrorToast = useCallback(
    () => toast.error(t('cashoutError')),
    [t],
  );
  const [cashingOut, setCashingOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [walletId, setWalletId] = useState(0);
  const [walletPspSlug, setWalletPspSlug] = useState(null);
  const [bankAccountId, setBankAccountId] = useState(0);
  const showModal = (walletId, walletPspSlug) => {
    setModalIsVisible(true);
    setWalletId(walletId);
    setWalletPspSlug(walletPspSlug);
    setBankAccountId(0);
  };
  const hideModal = () => {
    setModalIsVisible(false);
    setWalletId(0);
    setWalletPspSlug(null);
    setBankAccountId(0);
  };

  useEffect(() => {
    setLoading(!wallets.length);
    refreshMe();
    getOwnBankAccounts();
    getOwnWallets().finally(() => {
      setLoading(false);
    });
  }, [
    getOwnBankAccounts,
    getOwnWallets,
    refreshMe,
    wallets.length,
  ]);

  const cashoutModal = (
    <Modal show={modalIsVisible} onHide={hideModal} centered>
      <Modal.Header closeButton>{t('modal.title')}</Modal.Header>

      <Modal.Body>
        <Form.Control
          as="select"
          custom
          onChange={({ target: { value } = {} }) => {
            setBankAccountId(value);
          }}
        >
          <option disabled selected>
            {t('modal.chooseBankAccount')}
          </option>
          {bankAccounts
            ?.filter(({ pspSlug }) => (pspSlug === walletPspSlug))
            ?.map(({ id, iban }) => (
              <option key={id} value={id}>
                {iban.replace(/([^ ]{4})/g, '$1 ')}
              </option>
            ))}
        </Form.Control>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={hideModal}>
          {t('modal.close')}
        </Button>
        <Button
          variant="primary"
          disabled={bankAccountId === 0}
          isLoading={cashingOut}
          onClick={() => {
            setCashingOut(true);
            cashout(walletId, bankAccountId)
              .then(() => {
                cashoutSuccessToast();
                setLoading(true);
                getOwnWallets().finally(() => {
                  setLoading(false);
                });
              })
              .catch(() => {
                cashoutErrorToast();
              })
              .finally(() => {
                hideModal();
                setCashingOut(false);
              });
          }}
        >
          {t('modal.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const positiveWallets = wallets.filter(({ balance }) => balance);
  const walletsToDisplay = positiveWallets.length
    ? positiveWallets
    : [DEFAULT_WALLET];

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {cashoutModal}
      {loading ? (
        <Spinner className="mx-auto d-block" animation="border" />
      ) : (
        <div className="block-wallet">
          <p className="block-wallet__title">{t('pageTitle')}</p>

          {!canCashout && positiveWallets.length > 0 && (
            <Alert variant="warning">
              <Trans t={t} i18nKey="cannotCashout">
                To be able to withdraw, you must
                <Link
                  to="/settings/profile-verification"
                  className="block-wallet__warningLink"
                >
                  verify your profile
                </Link>
                and
                <Link
                  to="/settings/payments"
                  className="block-wallet__warningLink"
                >
                  add a bank account
                </Link>
                .
              </Trans>
            </Alert>
          )}

          {walletsToDisplay.map(
            ({
              id,
              balance,
              currency,
              hasInProgessCashout,
              pspSlug,
            }) => (
              <div key={id}>
                {hasInProgessCashout && (
                  <div className="block-wallet__transferMessage">
                    {t('transferProcessedMessage')}
                  </div>
                )}
                <div className="block-wallet__item">
                  <div className="block-wallet__infos">
                    <div className="block-wallet__subtitle">{t('infos')}</div>
                    <p className="block-wallet__money">
                      <FormattedNumber
                        value={balance / 100}
                        style="currency"
                        currency={currency}
                      />
                    </p>
                    <p className="block-wallet__title-main">
                      {t('walletTitle')}
                    </p>
                  </div>
                  <div className="block-wallet__btn-wrapper">
                    <Button
                      className="block-wallet__button-move"
                      disabled={!balance || !canCashout || hasInProgessCashout}
                      onClick={() => {
                        showModal(id, pspSlug);
                      }}
                    >
                      {t('cashout')}
                    </Button>
                  </div>
                </div>
              </div>
            ),
          )}
          {isMobile && (
            <div className="block-wallet__mobile-info-bottom">{t('infos')}</div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  bankAccounts: state.persistent.bankAccountsReducer.bankAccounts,
  wallets: state.persistent.walletsReducer.wallets,
});

const mapDispatchToProps = (dispatch) => ({
  refreshMe: bindActionCreators(refreshMe, dispatch),
  getOwnBankAccounts: bindActionCreators(getOwnBankAccounts, dispatch),
  getOwnWallets: bindActionCreators(getOwnWallets, dispatch),
  cashout: bindActionCreators(cashout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallets);
