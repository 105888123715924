import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import useWindowSize from 'helpers/useWindowSize';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import checkoutCssGenerator from 'helpers/checkoutCssGenerator';
import {
  getCancelReturnUrl,
  getExternalId,
} from 'helpers/transactionTemplateReturnUrl';
import CheckoutDesktop from 'pages/checkout/CheckoutDesktop';
import CheckoutMobile from 'pages/checkout/CheckoutMobile';
import CheckoutHeader from 'components/CheckoutHeader';
import Footer from 'components/Footer';
import CheckoutCounterOfferModal from 'components/CheckoutCounterOfferModal';
import TutoModals from 'components/TutoModals';
import Button from 'components/Button';
import { setClientPermalink } from 'logic/actions/clientsActions';
import {
  getTransactionTemplateByUuid,
  fetchTransactionTemplatePicture,
} from 'logic/actions/transactionTemplatesActions';
import { getOwnPaymentCards } from 'logic/actions/paymentCardsActions';
import Cat from 'assets/svg/Cat';
import 'assets/style/checkout.scss';
import { CHECKOUT_STEPS } from 'helpers/constants';
import useRedirectFromCheckout from 'hooks/useRedirectFromCheckout';
import { getBase64FromBlob } from 'helpers/decode';
import SameUserModal from 'components/SameUserModal';
import useModal from 'hooks/useModal';
import allSettled from 'promise.allsettled';
import {
  refreshMe,
  selectCurrentUserAddress,
} from 'logic/actions/meActions';
import useParseUserAddress from 'hooks/useParseUserAddress';
import CheckoutPromocodes from 'pages/checkout/CheckoutPromocodes';

const Checkout = ({
  me: { email } = {},
  loggedIn,
  getTransactionTemplateByUuid,
  fetchTransactionTemplatePicture,
  getOwnPaymentCards,
  refreshMe,
  setClientPermalink,
  location: { state: { retryTransaction = {} } = {} } = {},
  match: { params: { templateUuid } = {} } = {},
}) => {
  const setLoadingScreen = useLoadingScreen();
  const { t } = useTranslation('checkout');
  const { parseUserAddress } = useParseUserAddress();

  const { isNarrow } = useWindowSize();
  const intl = useIntl();

  const externalId = getExternalId(window.location.href);

  const [is404, setIs404] = useState(false);
  const [isUnknownError, setIsUnknownError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [transactionTemplate, setTransactionTemplate] = useState({});
  const [transaction, setTransaction] = useState(retryTransaction);

  const [currentProvider, setCurrentProvider] = useState(undefined);

  const [picture, setPicture] = useState();

  const {
    openModal: openShowPaymentErrorModal,
    closeModal: closeShowPaymentErrorModal,
    modalState: showPaymentErrorModal,
  } = useModal(false);

  const {
    openModal: openTutoModal,
    closeModal: closeTutoModal,
    modalState: tutoModal,
  } = useModal(false);

  const {
    openModal: openCounterOfferModal,
    closeModal: closeCounterOfferModal,
    modalState: counterOfferModal,
  } = useModal(false);

  const [showAddressModal, setShowAddressModal] = useState(false);

  const [showMobileRecap, setShowMobileRecap] = useState(isEmpty(retryTransaction) && isNarrow);
  const [showSignup, setShowSignup] = useState(true);

  const currentUserAddress = useSelector(selectCurrentUserAddress);

  const [deliveryMethod, setDeliveryMethod] = useState(retryTransaction.deliveryMethod);

  const [deliveryAddress, setDeliveryAddress] = useState(parseUserAddress(retryTransaction.deliveryAddress));

  const [message, setMessage] = useState();

  const activeKeyForDesktop = loggedIn
    ? CHECKOUT_STEPS.delivery
    : CHECKOUT_STEPS.sign;

  const [activeKey, setActiveKey] = useState(
    isNarrow ? CHECKOUT_STEPS.sign : activeKeyForDesktop,
  );

  useEffect(() => {
    if (loggedIn && !isNarrow) {
      setActiveKey(CHECKOUT_STEPS.delivery);
    }
  }, [isNarrow, loggedIn, setActiveKey]);

  useEffect(() => {
    if (!loggedIn) {
      setCurrentProvider(undefined);
    }
  }, [loggedIn, setCurrentProvider]);

  useEffect(() => {
    // logic to prefill currentProvider if pre auth error happen
    if (
      retryTransaction?.shippingProvider
      && transactionTemplate?.shippingProviders
    ) {
      setCurrentProvider(
        transactionTemplate.shippingProviders.find(
          (provider) => provider.name === retryTransaction.shippingProvider,
        ),
      );
    }

    if (!isEmpty(retryTransaction)) {
      setTransaction((prev) => ({
        ...prev,
        buyerTotal: transactionTemplate.buyerTotal,
        subTotal: prev.initialSubTotal || prev.subTotal,
        buyerFees: transactionTemplate.buyerFees,
      }));
    }
  }, [
    retryTransaction,
    transactionTemplate,
  ]);

  const [pageTitle, setPageTitle] = useState(t('pageTitle'));

  const { adUrl } = transactionTemplate;

  const [clientId, setClientId] = useState(null);

  const {
    modalState: sameUserModal,
    openModal: openSameUserModal,
    closeModal: closeSameUserModal,
  } = useModal(false);

  useRedirectFromCheckout(clientId);

  const onBackButtonEvent = useCallback((e) => {
    if (activeKey === CHECKOUT_STEPS.payment) {
      e.preventDefault();
      setActiveKey(CHECKOUT_STEPS.delivery);
    } else if (isNarrow && !showMobileRecap) {
      e.preventDefault();
      setShowMobileRecap(true);
    } else {
      e.preventDefault();
      window.location = getCancelReturnUrl(adUrl);
    }
  }, [
    activeKey,
    isNarrow,
    adUrl,
    showMobileRecap,
  ]);

  // > handle navigation
  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  useEffect(() => {
    const { pathname, search } = window.location;

    if (isNarrow && !showMobileRecap) {
      window.history.pushState(
        null,
        null,
        externalId ? `${pathname}${search}` : pathname,
      );
    }

    if (activeKey === CHECKOUT_STEPS.payment) {
      window.history.pushState(null, null, pathname);
    }
  }, [
    activeKey,
    showMobileRecap,
    externalId,
    isNarrow,
  ]);
  // < handle navigation

  useEffect(() => {
    if (!loggedIn) {
      setActiveKey(CHECKOUT_STEPS.sign);
      setMessage(undefined);
    } else if (isNarrow) {
      setActiveKey(CHECKOUT_STEPS.sign);
    }
  }, [loggedIn, isNarrow]);

  useEffect(() => {
    if (undefined === showMobileRecap) {
      setShowMobileRecap(isNarrow);
    }
  }, [isNarrow, showMobileRecap]);

  useEffect(() => {
    const { title, subTotal, currency = 'EUR' } = transaction;

    if (title && subTotal) {
      const amount = intl.formatNumber(subTotal / 100, {
        style: 'currency',
        currency,
      });

      const newPageTitle = `${title} - ${amount} - ${t('pageTitle')}`;

      if (newPageTitle !== pageTitle) {
        setPageTitle(newPageTitle);
      }
    }
  }, [
    pageTitle,
    transaction,
    intl,
    t,
  ]);

  useEffect(() => {
    if (!isFetching && !is404 && !isUnknownError && isEmpty(transactionTemplate)) {
      setIsFetching(true);
      setLoadingScreen(true);
      getTransactionTemplateByUuid(templateUuid)
        .then((response) => {
          const {
            payload: {
              data: { permalink, clientId },
            },
          } = response;
          if (permalink) {
            setClientPermalink({ clientId, permalink });
            setClientId(clientId);
          }
          return Promise.resolve(response);
        })
        .then(({ payload: { data: transactionTemplate } = {} }) => {
          const { id, hasPicture } = transactionTemplate;

          setTransactionTemplate(transactionTemplate);
          if (isEmpty(transaction)) {
            setTransaction(transactionTemplate);
          }

          const promises = [refreshMe()];

          if (hasPicture) {
            promises.push(
              fetchTransactionTemplatePicture(id).then(
                ({ payload: { data } = {} }) => {
                  getBase64FromBlob(data).then(setPicture);
                },
              ),
            );
          }

          if (loggedIn) {
            promises.push(getOwnPaymentCards());
          }
          allSettled.shim();
          Promise.allSettled(promises).then(() => {
            if (!isEmpty(retryTransaction)) {
              openShowPaymentErrorModal();
            }
            if (!loggedIn && !showPaymentErrorModal) {
              openTutoModal();
            }
            setIsFetching(false);
            setLoadingScreen(false);
          });
        })
        .catch(({ error: { response: { status } = {} } = {} }) => {
          switch (status) {
            case 404:
              setIs404(true);
              break;
            default:
              setIsUnknownError(true);
          }
          setIsFetching(false);
          setLoadingScreen(false);
        });
    }
  }, [
    isFetching,
    fetchTransactionTemplatePicture,
    getOwnPaymentCards,
    getTransactionTemplateByUuid,
    loggedIn,
    openShowPaymentErrorModal,
    openTutoModal,
    refreshMe,
    retryTransaction,
    setClientPermalink,
    setLoadingScreen,
    showPaymentErrorModal,
    templateUuid,
    transaction,
    transactionTemplate,
  ]);

  const {
    id,
    clientTheme: { primary = '#004bb4' } = {},
    shippingFees,
  } = transactionTemplate;

  useEffect(() => {
    if (!deliveryAddress && currentUserAddress && shippingFees) {
      setDeliveryAddress(parseUserAddress(currentUserAddress));
    }
  }, [
    deliveryAddress,
    currentUserAddress,
    shippingFees,
    setDeliveryAddress,
    parseUserAddress,
  ]);

  const paymentErrorModal = (
    <Modal
      show={showPaymentErrorModal}
      onHide={closeShowPaymentErrorModal}
      centered
    >
      <Modal.Header>{t('paymentErrorModal.title')}</Modal.Header>

      <Modal.Body className="keep-line-breaks">
        <Trans t={t} i18nKey="paymentErrorModal.text">
          An error occurred during payment. Please check your banking
          information and try again.\nIf the problem persists, contact
          <a href="mailto:support@tripartie.com">our support team</a>
          .
        </Trans>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={closeShowPaymentErrorModal}>
          {t('paymentErrorModal.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (is404 || isUnknownError) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className="w-100 checkout-container d-flex flex-column align-items-center">
          <CheckoutHeader />
          <div className="w-50 my-auto d-flex flex-column align-items-center">
            <Cat className="cat mb-3" />
            {is404 && <p>{t('notFound')}</p>}
            {isUnknownError && <p>{t('unknownError')}</p>}
          </div>
          <Footer className="checkout__footer" />
        </div>
      </>
    );
  }

  return (
    <CheckoutPromocodes
      transactionTemplateId={id}
      transaction={transaction}
      transactionTemplate={transactionTemplate}
      setTransaction={setTransaction}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <style>{checkoutCssGenerator(primary, picture)}</style>
      </Helmet>
      {paymentErrorModal}
      <SameUserModal
        show={sameUserModal}
        onHide={closeSameUserModal}
        email={email}
      />
      <TutoModals show={tutoModal} onHide={closeTutoModal} type="buyer" />
      <CheckoutCounterOfferModal
        transactionTemplate={transactionTemplate}
        visible={counterOfferModal}
        onClose={closeCounterOfferModal}
      />
      {!sameUserModal && (
        <div className="w-100 checkout-container">
          <CheckoutHeader />

          {isNarrow ? (
            <CheckoutMobile
              showPaymentErrorModal={openShowPaymentErrorModal}
              transactionTemplate={transactionTemplate}
              transaction={transaction}
              picture={picture}
              showCounterOfferModal={openCounterOfferModal}
              showSignup={showSignup}
              setShowSignup={setShowSignup}
              deliveryMethod={deliveryMethod}
              setDeliveryMethod={setDeliveryMethod}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              showMobileRecap={showMobileRecap}
              setShowMobileRecap={setShowMobileRecap}
              showAddressModal={showAddressModal}
              setShowAddressModal={setShowAddressModal}
              deliveryAddress={deliveryAddress}
              setDeliveryAddress={setDeliveryAddress}
              showTuto={openTutoModal}
              message={message}
              setMessage={setMessage}
              openSameUserModal={openSameUserModal}
              currentProvider={currentProvider}
              setCurrentProvider={setCurrentProvider}
            />
          ) : (
            <CheckoutDesktop
              showPaymentErrorModal={openShowPaymentErrorModal}
              transactionTemplate={transactionTemplate}
              transaction={transaction}
              picture={picture}
              showCounterOfferModal={openCounterOfferModal}
              showSignup={showSignup}
              setShowSignup={setShowSignup}
              deliveryMethod={deliveryMethod}
              setDeliveryMethod={setDeliveryMethod}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              showAddressModal={showAddressModal}
              setShowAddressModal={setShowAddressModal}
              deliveryAddress={deliveryAddress}
              setDeliveryAddress={setDeliveryAddress}
              showTuto={openTutoModal}
              message={message}
              setMessage={setMessage}
              openSameUserModal={openSameUserModal}
              currentProvider={currentProvider}
              setCurrentProvider={setCurrentProvider}
            />
          )}
        </div>
      )}
    </CheckoutPromocodes>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  loggedIn: state.persistent.meReducer.loggedIn,
});

const actionCreators = {
  getTransactionTemplateByUuid,
  fetchTransactionTemplatePicture,
  getOwnPaymentCards,
  setClientPermalink,
  refreshMe,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(Checkout);
