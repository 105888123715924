import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import chevronIcon from 'assets/images/nav-dropdown-shevron.svg';
import colors from 'assets/style/_colors.module.scss';
import { HOMEPAGE_TABS } from 'helpers/constants';
import { useSelector } from 'react-redux';
import { selectCurrentTransactionsCount } from 'logic/actions/transactionsActions';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    height: '60px',
    padding: '18px 20px',
    color: colors.primaryColor,
    display: state.isSelected ? 'none' : 'block',
    ':hover': {
      backgroundColor: colors.infoColor,
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 100,
    position: 'fixed',
    left: 0,
    right: 0,
    borderWidth: 0,
    height: '55px',
    padding: '18px 20px',
    backgroundColor: colors.blueColor,
    width: '100%',
    boxShadow: 'none',
    color: colors.primaryColor,
    display: 'flex',
    flexWrap: 'nowrap !important',
    ': hover': {
      backgroundColor: 'none',
      borderColor: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: colors.blueColor,
    position: 'fixed',
    top: '125px',
    left: 0,
    right: 0,
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    width: '100vw',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorsContainer: () => ({
    height: '100%',
    width: '100%',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    padding: '0',
    transition: 'transform 1s',
    color: colors.primaryColor,
    '> img': {
      width: '20px',
      height: '10px',
      transition: 'transform 0.5s',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    },
  }),
};

const DropdownIndicator = ({ getValue, ...restProps }) => (
  <components.DropdownIndicator {...restProps}>
    <div>{getValue()?.[0].label}</div>
    <img alt="nav-arrow" src={chevronIcon} />
  </components.DropdownIndicator>
);

const TransactionsNavbarMobile = ({ onChangeTab, currentTab }) => {
  const { t } = useTranslation('transactions');

  const currentTransactionsCount = useSelector(selectCurrentTransactionsCount);

  const options = useMemo(
    () => [
      {
        value: HOMEPAGE_TABS.IN_PROGRESS,
        label: t('active', {
          count:
            currentTransactionsCount >= 0
              ? `(${currentTransactionsCount})`
              : '',
        }),
      },
      { value: HOMEPAGE_TABS.FINISHED, label: t('ended') },
      { value: HOMEPAGE_TABS.LINKS, label: t('links.mobile') },
    ],
    [
      currentTransactionsCount,
      t,
    ],
  );

  const defaultValue = useMemo(
    () => options.find((option) => option.value === currentTab),
    [currentTab, options],
  );

  return (
    <Select
      key={JSON.stringify(currentTransactionsCount)}
      defaultValue={defaultValue}
      options={options}
      onChange={(option) => {
        onChangeTab(option.value);
      }}
      components={{
        IndicatorSeparator: () => null,
        Input: () => null,
        ValueContainer: () => null,
        DropdownIndicator,
      }}
      styles={customStyles}
      selectProps={{
        isSearchable: false,
        isClearable: false,
      }}
    />
  );
};

TransactionsNavbarMobile.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default TransactionsNavbarMobile;
