import React from 'react';

const AvatarMask = ({ width, height, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 66 66"
    {...rest}
  >
    <path d="M66,66H0V0H66V66ZM33,.5A70.4,70.4,0,0,0,16.953,1.922,20.229,20.229,0,0,0,7,7a20.229,20.229,0,0,0-5.078,9.953A70.4,70.4,0,0,0,.5,33,70.4,70.4,0,0,0,1.922,49.047,20.229,20.229,0,0,0,7,59a20.229,20.229,0,0,0,9.953,5.078A70.4,70.4,0,0,0,33,65.5a70.4,70.4,0,0,0,16.047-1.422A20.23,20.23,0,0,0,59,59a20.23,20.23,0,0,0,5.078-9.953A70.4,70.4,0,0,0,65.5,33a70.4,70.4,0,0,0-1.422-16.047A20.23,20.23,0,0,0,59,7a20.23,20.23,0,0,0-9.953-5.078A70.4,70.4,0,0,0,33,.5Z" />
  </svg>
);

export default AvatarMask;
