import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import useWindowSize from 'helpers/useWindowSize';
import checkoutCssGenerator from 'helpers/checkoutCssGenerator';
import CheckoutBreadcrumbBar from 'components/CheckoutBreadcrumbBar';
import CheckoutRecapDesktop from 'components/CheckoutRecapDesktop';
import CheckoutSuccess from 'components/CheckoutSuccess';
import CheckoutHeader from 'components/CheckoutHeader';
import Footer from 'components/Footer';
import {
  getTransaction,
  cancelTransaction,
  postProcessPreauth,
  fetchTransactionPicture,
} from 'logic/actions/transactionsActions';
import { setClientPermalink } from 'logic/actions/clientsActions';
import { getBase64FromBlob } from 'helpers/decode';
import { CLIENT_TRIPARTIE } from 'helpers/constants';
import useRedirectFromCheckout from 'hooks/useRedirectFromCheckout';

const PostProcessPreauth = ({
  setClientPermalink,
  getTransaction,
  cancelTransaction,
  postProcessPreauth,
  fetchTransactionPicture,
  transactions,
  match: { params: { transactionId } = {} } = {},
  history,
}) => {
  const setLoadingScreen = useLoadingScreen();
  const { t } = useTranslation('checkout');
  const transaction = transactions[transactionId] || { id: transactionId };
  const windowSize = useWindowSize();
  const [picture, setPicture] = useState();

  const {
    clientId,
    client,
    adUrl,
    clientTheme: { primary } = {},
    shippingProvider,
  } = transaction;

  const loadPictures = useCallback(
    ({ id, hasPicture }) => {
      if (hasPicture) {
        fetchTransactionPicture(id)
          .then(({ payload: { data } = {} }) => getBase64FromBlob(data))
          .then(setPicture)
          .catch(() => Promise.resolve());
      }

      return Promise.resolve();
    },
    [fetchTransactionPicture],
  );

  useRedirectFromCheckout(clientId);

  useEffect(() => {
    setLoadingScreen(true);
    let currentTransaction;

    getTransaction(transactionId)
      .then((response) => {
        const {
          payload: {
            data: { templatePermalink: permalink, clientId },
          },
        } = response;
        if (permalink) {
          setClientPermalink({ permalink, clientId });
        }
        return Promise.resolve(response);
      })
      .then(({ payload: { data: transaction } = {} }) => {
        currentTransaction = transaction;

        if (!transaction.isPreauthorized) {
          return postProcessPreauth(transactionId)
            .then(() => loadPictures(transaction))
            .then(() => setLoadingScreen(false));
        }
        return Promise.resolve(
          loadPictures(transaction).then(() => setLoadingScreen(false)),
        );
      })
      .catch(() => {
        cancelTransaction(currentTransaction.id);
        const { pathname } = new URL(currentTransaction.templatePermalink);
        history.push(pathname, { retryTransaction: currentTransaction });
      });
  }, [
    transactionId,
    cancelTransaction,
    getTransaction,
    history,
    loadPictures,
    postProcessPreauth,
    setClientPermalink,
    setLoadingScreen,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <style>{checkoutCssGenerator(primary, picture)}</style>
      </Helmet>
      <div className="checkout-container">
        {!windowSize.isNarrow && <CheckoutHeader />}
        <div className="checkout">
          {windowSize.isNarrow ? (
            <CheckoutSuccess
              client={client}
              adUrl={adUrl}
              transactionId={transactionId}
            />
          ) : (
            <Row className="checkout__content">
              <Col lg="5">
                {client && client !== CLIENT_TRIPARTIE && (
                  <CheckoutBreadcrumbBar
                    showLogo
                    adUrl={adUrl}
                    transactionId={transactionId}
                    clientId={clientId}
                    client={transaction.client}
                  />
                )}
                <CheckoutRecapDesktop
                  transactionTemplate={transaction}
                  transaction={transaction}
                  picture={picture}
                  isPostProcess
                  allowPriceChange={false}
                  currentProvider={{ name: shippingProvider }}
                />
              </Col>
              <Col lg="2">
                <div className="h-100 m-auto checkout__separator" />
              </Col>
              <Col lg="5">
                <CheckoutSuccess
                  client={client}
                  adUrl={adUrl}
                  transactionId={transactionId}
                />
              </Col>
            </Row>
          )}
          <Footer className="checkout__footer" />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  transactions: state.persistent.transactionsReducer.transactions,
});

const actionCreators = {
  getTransaction,
  cancelTransaction,
  postProcessPreauth,
  fetchTransactionPicture,
  setClientPermalink,
};

export default connect(mapStateToProps, actionCreators)(PostProcessPreauth);
