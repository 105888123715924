import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useWindowSize from 'helpers/useWindowSize';
import classes from 'assets/style/checkoutBreadcrumbBar.module.scss';
import classNames from 'classnames';
import {
  getCancelReturnUrl,
  getSuccessReturnUrl,
} from 'helpers/transactionTemplateReturnUrl';
import useClientLogos from 'hooks/useClientLogos';
import ArrowLeftIcon from 'assets/svg/ArrowLeftIcon';

const CheckoutBreadcrumbBar = ({
  showLogo,
  adUrl,
  transactionId,
  client,
  clientId,
}) => {
  const { logo, squareLogo } = useClientLogos(clientId);

  const cancelUrl = useMemo(() => getCancelReturnUrl(adUrl), [adUrl]);
  const successUrl = useMemo(
    () => getSuccessReturnUrl(adUrl, transactionId),
    [adUrl, transactionId],
  );

  const { isNarrow } = useWindowSize();

  const content = showLogo ? (
    <div
      className={classes.checkoutBreadcrumbBar__logo}
    >
      {!isNarrow && !!adUrl && (
        <ArrowLeftIcon
          className={classNames(
            classes.checkoutBreadcrumbBar__backbtn,
            classes['checkoutBreadcrumbBar__backbtn--large'],
          )}
        />
      )}
      {logo && <img src={logo} alt="icon" />}
    </div>
  ) : (
    <>
      {!!adUrl && (
        <ArrowLeftIcon
          className={classNames(
            classes.checkoutBreadcrumbBar__backbtn,
            classes['checkoutBreadcrumbBar__backbtn--small'],
          )}
        />
      )}
      {squareLogo && (
        <img
          className={classes.checkoutBreadcrumbBar__squareLogo}
          src={squareLogo}
          alt="icon"
        />
      )}
      <span
        className={classNames(classes.checkoutBreadcrumbBar__link, {
          'text-sub': isNarrow,
        })}
      >
        {client}
      </span>
    </>
  );

  return adUrl ? (
    <a
      href={transactionId ? successUrl : cancelUrl}
      className={classes.checkoutBreadcrumbBar}
    >
      {content}
    </a>
  ) : (
    <span className={classes.checkoutBreadcrumbBar}>{content}</span>
  );
};

CheckoutBreadcrumbBar.propTypes = {
  showLogo: PropTypes.bool.isRequired,
  adUrl: PropTypes.string,
  client: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  transactionId: PropTypes.string,
};

CheckoutBreadcrumbBar.defaultProps = {
  adUrl: '',
  transactionId: '',
};

export default CheckoutBreadcrumbBar;
