import React from 'react';

const ArrowLeftIconWithLine = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.943"
    height="13.081"
    viewBox="0 0 13.943 13.081"
    {...props}
  >
    <defs>
      <style>
        {'.a,.b{fill:none;stroke:#8a93a8;stroke-miterlimit:10;stroke-width:1.25px;}.a{fill-rule:evenodd;}'}
      </style>
    </defs>
    <g transform="translate(0.884 12.639) rotate(-90)">
      <path className="a" d="M0,6.1,6.1,0l6.1,6.1" />
      <line className="b" y2="13.059" transform="translate(6.099)" />
    </g>
  </svg>
);

export default ArrowLeftIconWithLine;
