import React from 'react';

const BulbIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.5"
    height="32.5"
    viewBox="0 0 32.5 32.5"
    {...props}
  >
    <defs>
      <style>
        {'.a{fill:#004bb4;stroke:#004bb4;stroke-width:0.5px;}'}
      </style>
    </defs>
    <g transform="translate(-10.75 -10.75)">
      <g transform="translate(11 11)">
        <path
          className="a"
          d="M127.5,92.3a8.932,8.932,0,0,0-8.9,7.491,8.649,8.649,0,0,0,2.227,7.014,8.874,8.874,0,0,1,2.364,6.06l-.007,3.54a2.613,2.613,0,0,0,2.645,2.586h3.409a2.613,2.613,0,0,0,2.645-2.586l.007-3.54a8.362,8.362,0,0,1,2.227-5.923,8.414,8.414,0,0,0,2.436-5.923A8.917,8.917,0,0,0,127.5,92.3Zm3.056,24.1a1.236,1.236,0,0,1-1.254,1.228h-3.409a1.232,1.232,0,0,1-1.254-1.228v-1.567h5.91V116.4Zm2.586-10.416a9.686,9.686,0,0,0-2.645,6.877v.614h-5.91v-.614a10.472,10.472,0,0,0-2.782-6.942A7.187,7.187,0,0,1,119.925,100a7.676,7.676,0,0,1,7.582-6.335,7.526,7.526,0,0,1,7.654,7.353A7.116,7.116,0,0,1,133.143,105.988Z"
          transform="translate(-111.5 -86.991)"
        />
        <rect
          className="a"
          width="1.391"
          height="3.265"
          rx="0.696"
          transform="translate(15.301 0)"
        />
        <path
          className="a"
          d="M389.8,79.021h0a.688.688,0,0,0,.964.009l1.389-1.336a.688.688,0,0,0,.008-.985l0,0a.688.688,0,0,0-.963-.007l-1.388,1.338A.688.688,0,0,0,389.8,79.021Z"
          transform="translate(-364.878 -72.23)"
        />
        <rect
          className="a"
          width="3.409"
          height="1.365"
          rx="0.682"
          transform="translate(28.591 14.707)"
        />
        <rect
          className="a"
          width="3.409"
          height="1.365"
          rx="0.682"
          transform="translate(0 14.707)"
        />
        <path
          className="a"
          d="M79.812,76.57l-.01.01a.682.682,0,0,0,.008.976l1.4,1.344a.682.682,0,0,0,.956-.009l.008-.008a.682.682,0,0,0-.009-.974l-1.4-1.346A.682.682,0,0,0,79.812,76.57Z"
          transform="translate(-75.12 -72.102)"
        />
      </g>
    </g>
  </svg>
);

export default BulbIcon;
