import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import classes from 'assets/style/sameUserModal.module.scss';
import ExclamationIcon from 'assets/images/exclamation-icon.svg';

const SameUserModal = ({ show, onHide, email: emailFromCheckout }) => {
  const { t } = useTranslation('checkout');

  const [email, setEmail] = useState(emailFromCheckout);
  useEffect(() => {
    if (emailFromCheckout) {
      setEmail(emailFromCheckout);
    }
  }, [emailFromCheckout]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={classes.sameUserModal}
      dialogClassName={classes.sameUserModal__dialog}
      contentClassName={classes.sameUserModal__content}
    >
      <img src={ExclamationIcon} alt="exclamation-icon" />
      <p className={classes.sameUserModal__text}>
        {t('sameUserModal.message', { email })}
      </p>
      <Button onClick={onHide} className={classes.sameUserModal__button}>
        {t('sameUserModal.button')}
      </Button>
    </Modal>
  );
};

SameUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default SameUserModal;
