import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/shippingLabelSuccessModal.module.scss';

const ShippingLabelSuccessModal = ({ show, onHide }) => {
  const { t } = useTranslation('shippingLabelSuccess');

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={classes.shippingLabelSuccessModal}
      contentClassName={classes.shippingLabelSuccessModal__content}
    >
      <Modal.Header closeButton />
      <h4 className={classes.shippingLabelSuccessModal__title}>{t('title')}</h4>
      <p className={classes.shippingLabelSuccessModal__info}>{t('info')}</p>
      <Button
        className={classes.shippingLabelSuccessModal__button}
        onClick={onHide}
      >
        {t('ok')}
      </Button>
    </Modal>
  );
};

ShippingLabelSuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ShippingLabelSuccessModal;
