/**
 *
 * @param {string} dataUrl
 * @param {string} fileName
 * @return {Element}
 */
const saveFileFromDataUrl = (dataUrl, fileName) => {
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = fileName;

  return link;
};

export default saveFileFromDataUrl;
