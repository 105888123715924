import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import DisputeResolutionRadioButton from 'components/DisputeResolutionRadioButton';

const ChooseTransaction = ({
  transaction: { title, subTotal, currency } = {},
  back,
  next,
}) => {
  const { t } = useTranslation('disputeResolution');
  const intl = useIntl();
  const formattedSubTotal = intl.formatNumber(subTotal / 100, {
    style: 'currency',
    currency,
  });

  const [selected, setSelected] = useState(true);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>{t('chooseTransaction.title')}</p>
      <div className={classes.form}>
        <DisputeResolutionRadioButton
          id={1}
          name="transaction"
          title={`${title} - ${formattedSubTotal}`}
          onChange={() => {
            setSelected(true);
          }}
          defaultChecked
        />
        <DisputeResolutionRadioButton
          id={-1}
          name="transaction"
          title={t('chooseTransaction.cantFind')}
          onChange={() => {
            setSelected(false);
          }}
        />
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(selected);
        }}
      >
        {t('chooseTransaction.next')}
      </Button>
    </Row>
  );
};

export default ChooseTransaction;
