import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/takePictures/processMediaButtons.module.scss';
import Button from 'components/Button';

const ProcessNewMediaButtons = ({ handleSave, handleRetry }) => {
  const { t } = useTranslation('takePictures');

  return (
    <div className={classes.processNewMediaButtons}>
      <Button
        onClick={handleSave}
        className={classes.processNewMediaButtons__saveBtn}
      >
        {t('save')}
      </Button>
      <Button
        onClick={handleRetry}
        variant="light"
        className={classes.processNewMediaButtons__retryBtn}
      >
        {t('retry')}
      </Button>
    </div>
  );
};

ProcessNewMediaButtons.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleRetry: PropTypes.func.isRequired,
};

export default ProcessNewMediaButtons;
