import React from 'react';
import PropTypes from 'prop-types';
import useDryRunTransaction from 'hooks/useDryRunTransaction';
import CheckoutPromocodesContext from 'pages/checkout/CheckoutPromocodesContext';

const CheckoutPromocodes = ({ children, ...props }) => {
  const values = useDryRunTransaction(props);

  return (
    <CheckoutPromocodesContext.Provider
      value={{
        promocode: values.promocode,
        setPromocode: values.setPromocode,
        applyNewOfferPrice: values.applyNewOfferPrice,
        applyPromocode: values.applyPromocode,
        clearDiscount: values.clearDiscount,
        resetToTransactionTemplate: values.resetToTransactionTemplate,
        loading: values.loading,
      }}
    >
      {children}
    </CheckoutPromocodesContext.Provider>
  );
};

CheckoutPromocodes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  transaction: PropTypes.shape({
    subTotal: PropTypes.number,
  }).isRequired,
  transactionTemplate: PropTypes.shape({
    subTotal: PropTypes.number,
  }).isRequired,
  setTransaction: PropTypes.func.isRequired,
};

export default CheckoutPromocodes;
