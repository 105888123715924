import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/radioCheckbox.module.scss';
import classNames from 'classnames';

const RadioCheckbox = ({
  id,
  value,
  checked,
  isInvalid,
  onChange,
  className,
  children,
  ...restProps
}) => (
  <Form.Label htmlFor={id} className={classes.radioCheckbox}>
    <input
      id={id}
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
      className={classNames(className, classes.radioCheckbox__input)}
      {...restProps}
    />
    <p>{children}</p>
    <span />
  </Form.Label>
);

RadioCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

RadioCheckbox.defaultProps = {
  className: '',
};

export default RadioCheckbox;
