import * as React from 'react';

const LogoTripartie = ({ fill, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.03 26" {...rest}>
    <g data-name="Calque 2">
      <g data-name="Calque 1" fill={fill}>
        <path
          d="M8.65 17.29a4.32 4.32 0 010-8.64 4.21 4.21 0 011.84.42 4.31 4.31 0 000 7.81 4.35 4.35 0 01-1.84.41zm4.32 0a4.32 4.32 0 010-8.64 4.21 4.21 0 011.84.42 4.3 4.3 0 000 7.8 4.21 4.21 0 01-1.81.42zm4.32 0A4.32 4.32 0 1121.6 13a4.32 4.32 0 01-4.31 4.29zM13 0C2.59 0 0 2.59 0 13s2.59 13 13 13 13-2.59 13-13S23.35 0 13 0z"
          fillRule="evenodd"
        />
        <path d="M50.17 8.85h2.37v10.61h-2.37zM51.35 3.31A1.65 1.65 0 1053 5a1.65 1.65 0 00-1.65-1.69zM75.79 9.86a5.53 5.53 0 100 8.62v1h2.36V8.85h-2.36zm-3.27 7.63a3.32 3.32 0 113.27-3.32 3.3 3.3 0 01-3.27 3.32zM95.15 8.85h2.37v10.61h-2.37zM96.34 3.31A1.65 1.65 0 1098 5a1.65 1.65 0 00-1.66-1.69zM110 14.88a5.47 5.47 0 10-10.88-.71 5.63 5.63 0 0010.14 3.27l-2-1.12a3.65 3.65 0 01-2.62 1.17 3.47 3.47 0 01-3.31-2.61zm-8.45-2a3.51 3.51 0 013.14-2 3.19 3.19 0 013 2zM60.4 8.64a5.52 5.52 0 00-3.46 1.22v-1h-2.36V26h2.36v-7.52a5.53 5.53 0 103.46-9.84zm-.19 8.85a3.32 3.32 0 113.27-3.32 3.3 3.3 0 01-3.27 3.32zM41.07 8.85H37.7v-5.3h-2.37v5.3h-2.89v2.09h2.89v4.17a4.47 4.47 0 001 3.12 4.21 4.21 0 003.31 1.37 7.49 7.49 0 001.48-.15l-.14-2.24a5.49 5.49 0 01-1.26.15 2.18 2.18 0 01-1.62-.63 2.24 2.24 0 01-.35-1.49v-4.3h3.37zM93.26 8.85h-3.37v-5.3h-2.37v11.56a4.47 4.47 0 001 3.12 4.2 4.2 0 003.31 1.37 7.57 7.57 0 001.48-.15l-.14-2.24a5.57 5.57 0 01-1.27.15 2.19 2.19 0 01-1.62-.63 2.3 2.3 0 01-.34-1.49v-4.3h3.37zM82.59 10.27V8.85h-2.36v10.61h2.36v-5.08c0-4.2 3.48-3.28 3.48-3.28V8.64c-1.07 0-2.99.5-3.48 1.63zM45.27 10.27V8.85H42.9v10.61h2.37v-5.08c0-4.2 3.48-3.28 3.48-3.28V8.64c-1.11 0-2.99.5-3.48 1.63z" />
      </g>
    </g>
  </svg>
);

LogoTripartie.defaultProps = {
  fill: '#fff',
};

export default LogoTripartie;
