import React, { useEffect } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Profile from 'pages/settings/Profile';
import Wallets from 'pages/settings/Wallets';
import Payments from 'pages/settings/Payments';
import ProfileVerification from 'pages/settings/ProfileVerification';
import { refreshMe } from 'logic/actions/meActions';
import iconWallet from 'assets/images/icon-wallet_desktop.svg';
import iconProfile from 'assets/images/icon-profile_desktop.svg';
import iconPayment from 'assets/images/icon-payments_desktop.svg';
import iconVerifiedProfile from 'assets/images/icon-verified-profile_desktop.svg';
import Header from 'components/Header';
import Footer from 'components/Footer';

const Settings = ({ match: { path, url }, refreshMe }) => {
  const { t } = useTranslation('settings');

  useEffect(() => {
    refreshMe();
  }, [refreshMe]);

  return (
    <div className="settings__container">
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Header />
      <div className="block-settings">
        <div className="settings__menu">
          <ul className="list-menu-bar">
            <li>
              <NavLink to={`${url}/wallets`}>
                <span className="text-list">
                  <img className="icon-list" src={iconWallet} alt="" />
                  {t('links.wallets')}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${url}/profile`}
                isActive={(match, { pathname }) => [`${url}`, `${url}/profile`].includes(pathname)}
              >
                <span className="text-list">
                  <img className="icon-list" src={iconProfile} alt="" />
                  {t('links.profile')}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/payments`}>
                <span className="text-list">
                  <img className="icon-list" src={iconPayment} alt="" />
                  {t('links.payments')}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/profile-verification`}>
                <span className="text-list">
                  <img className="icon-list" src={iconVerifiedProfile} alt="" />
                  {t('links.profileVerification')}
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="settings__content">
          <Switch>
            <Route exact path={`${path}/wallets`} component={Wallets} />
            <Route
              exact
              path={[`${path}`, `${path}/profile`]}
              component={Profile}
            />
            <Route exact path={`${path}/payments`} component={Payments} />
            <Route
              exact
              path={`${path}/profile-verification`}
              component={ProfileVerification}
            />
          </Switch>
        </div>
      </div>
      <Footer className="webapp__footer" />
    </div>
  );
};

const mapStateToProps = (state) => ({ me: state.persistent.meReducer.me });

const mapDispatchToProps = (dispatch) => ({
  refreshMe: bindActionCreators(refreshMe, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
