import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import omit from 'lodash/omit';
import parcelStorage from 'helpers/parcelStorage';

const useParcelPictures = () => {
  const [updated, setUpdated] = useState(false);

  const parcel = useRef({
    byIds: {},
    ids: [],
  });

  useEffect(() => {
    setUpdated(false);
    parcelStorage
      .getAll()
      .then((loadedData) => {
        parcel.current = loadedData;
      })
      .finally(() => setUpdated(true));
  }, []);

  const addNewMedia = useCallback((id, media) => {
    parcel.current.ids.push(id);
    parcel.current.byIds[id] = media;
    parcelStorage.add(id, media);
  }, []);

  const removeMedia = useCallback((mediaId) => {
    parcel.current.ids = parcel.current.ids.filter((id) => id !== mediaId);
    parcel.current.byIds = omit(parcel.current.byIds, mediaId);
    parcelStorage.remove(mediaId);
  }, []);

  return {
    parcel,
    updateParcelActions: {
      addNewMedia,
      removeMedia,
      updated,
    },
  };
};

export default useParcelPictures;
