import * as Sentry from '@sentry/react';
import { createReducer } from '@reduxjs/toolkit';
import { success, shouldResetState } from 'logic/reducers/helpers';
import { GET_TOKEN } from 'logic/actions/authActions';
import {
  SYNC_LOGGED_IN,
  SYNC_REFRESH_TOKEN,
  SYNC_LOGGED_OUT,
} from 'logic/actions/syncActions';
import {
  GET_AVATAR,
  REFRESH_ME,
  UPDATE_ME,
  DELETE_AVATAR,
  UPDATE_MY_LANG,
  HANDLE_GREETING_MODAL,
} from 'logic/actions/meActions';

const initialState = {
  loggedIn: false,
  greetingModal: false,
  me: {},
  avatarUrl: '',
};

const handleLoggedIn = (state) => ({
  ...state,
  loggedIn: true,
});

const handleLoggedOut = (state) => ({
  ...state,
  loggedIn: false,
});

const handleModal = (state, { show }) => ({
  ...state,
  greetingModal: show,
});

const handleMe = (state, { payload: { data = {} } = {} }) => {
  const email = data?.email;

  if (email) {
    state.me = data;
    Sentry.setUser({ email });
  }
};

const getAvatar = (state, { payload: { data } = {} }) => ({
  ...state,
  avatarUrl: data,
});

const deleteAvatar = (state) => ({
  ...state,
  avatarUrl: '',
});

export default createReducer(
  initialState,
  {
    [SYNC_LOGGED_IN]: handleLoggedIn,
    [SYNC_REFRESH_TOKEN]: handleLoggedIn,
    [SYNC_LOGGED_OUT]: handleLoggedOut,
    [HANDLE_GREETING_MODAL]: handleModal,
    [success(REFRESH_ME)]: handleMe,
    [success(UPDATE_ME)]: handleMe,
    [success(UPDATE_MY_LANG)]: handleMe,
    [success(GET_AVATAR)]: getAvatar,
    [success(DELETE_AVATAR)]: deleteAvatar,
  },
  [
    {
      matcher: ({ type }) => shouldResetState({ type }) && type !== success(GET_TOKEN),
      reducer: () => {
        Sentry.setUser({ email: 'Not logged in' });
        return initialState;
      },
    },
  ],
);
