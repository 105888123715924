import React, { useState, useCallback, useMemo } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames';
import {
  CURRENCY_EUR,
  CLIENT_TRIPARTIE,
  CHECKOUT_STEPS,
  DELIVERY_METHOD_REMOTE,
} from 'helpers/constants';
import Button from 'components/Button';
import CheckoutRecapMobile from 'components/CheckoutRecapMobile';
import CheckoutBreadcrumbBar from 'components/CheckoutBreadcrumbBar';
import CheckoutDeliveryMethod from 'components/CheckoutDeliveryMethod';
import CheckoutPaymentForm from 'components/CheckoutPaymentForm';
import Footer from 'components/Footer';
import MessageForm from 'components/MessageForm';
import GoBackCheckoutLink from 'components/GoBackCheckoutLink';
import IconArrowDown from 'assets/images/header-2-arrow.svg';
import IconBag from 'assets/images/icon-bag.svg';
import CheckoutAuthStep from 'components/CheckoutAuthStep';
import { getCancelReturnUrl } from 'helpers/transactionTemplateReturnUrl';
import 'assets/style/checkoutMobile.scss';

const CheckoutMobile = ({
  transactionTemplate = {},
  transaction,
  picture,
  showCounterOfferModal,
  showPaymentErrorModal,
  showSignup,
  setShowSignup,
  showAddressModal,
  setShowAddressModal,
  deliveryAddress,
  setDeliveryAddress,
  deliveryMethod,
  setDeliveryMethod,
  activeKey,
  setActiveKey,
  showMobileRecap,
  setShowMobileRecap,
  showTuto,
  message,
  setMessage,
  openSameUserModal,
  currentProvider,
  setCurrentProvider,
}) => {
  const { t } = useTranslation('checkout');

  const [visibleDropdownBoxDetail, setVisibleDropdownBoxDetail] = useState(false);
  const [selectingPickupPointOnMobile, setSelectingPickupPointOnMobile] = useState(false);

  const setVisibleDropdownBox = useCallback(
    () => setVisibleDropdownBoxDetail((prev) => !prev),
    [],
  );

  const {
    id: templateId,
    client,
    adUrl,
    currency = CURRENCY_EUR,
    allowPriceChange,
    clientId,
    shippingFees = 0,
  } = transactionTemplate;

  const gotoClient = useCallback(() => {
    window.location = getCancelReturnUrl(adUrl);
  }, [adUrl]);

  const { buyerTotal = 0, initialSubTotal, subTotal } = transaction;
  const withCounterOffer = initialSubTotal !== undefined && initialSubTotal !== subTotal;

  const showShippingFees = deliveryMethod === DELIVERY_METHOD_REMOTE;

  const calculatedShippingFees = useMemo(
    () => (currentProvider ? currentProvider?.shippingFees : shippingFees),
    [shippingFees, currentProvider],
  );

  const displayedBuyerTotal = useMemo(
    () => (showShippingFees ? buyerTotal + calculatedShippingFees : buyerTotal),
    [showShippingFees, buyerTotal, calculatedShippingFees],
  );

  return (
    <div className="checkout">
      <div
        className={classNames('d-lg-none checkout-header-mobile', {
          'bg-white': visibleDropdownBoxDetail || showMobileRecap,
        })}
      >
        <div className="w-100">
          {client && client !== CLIENT_TRIPARTIE && (
            <CheckoutBreadcrumbBar
              showLogo={showMobileRecap}
              client={client}
              adUrl={adUrl}
              clientId={clientId}
            />
          )}
        </div>
        {!showMobileRecap && (
          <div
            className="d-flex align-items-center justify-content-end"
            onClick={setVisibleDropdownBox}
            onKeyPress={setVisibleDropdownBox}
            role="button"
            tabIndex={0}
          >
            <p className="m-0 d-flex align-items-center justify-content-end">
              {visibleDropdownBoxDetail ? (
                <span className="mr-2">{t('close')}</span>
              ) : (
                <>
                  <img src={IconBag} width="15px" alt="icon" className="mb-1" />
                  <span
                    className={`ml-2 mr-2 text-main-semi-bold ${
                      withCounterOffer && 'text-primary'
                    }`}
                  >
                    <FormattedNumber
                      value={displayedBuyerTotal / 100}
                      style="currency"
                      currency={currency}
                    />
                  </span>
                </>
              )}
              {!showMobileRecap && (
                <img
                  className={`clickable mb-1 open-menu-arrow ${visibleDropdownBoxDetail ? 'opened' : ''}`}
                  src={IconArrowDown}
                  width="10px"
                  alt="expand icon"
                />
              )}
            </p>
          </div>
        )}
        <div
          className={classNames(
            'position-fixed bg-white checkout-box-dropdown',
            {
              active: visibleDropdownBoxDetail,
            },
          )}
        >
          <CheckoutRecapMobile
            isDropdown
            transactionTemplate={transactionTemplate}
            transaction={transaction}
            picture={picture}
            deliveryAddress={deliveryAddress}
            deliveryMethod={deliveryMethod}
            displayedBuyerTotal={displayedBuyerTotal}
            currentProvider={currentProvider}
          />
          {allowPriceChange && (
            <Button
              variant="link"
              className="w-100 btn-block btn-primary"
              onClick={showCounterOfferModal}
            >
              {t('counterPrice')}
            </Button>
          )}
          {adUrl && (
            <GoBackCheckoutLink
              handleClick={gotoClient}
              text={t('goBackToClient', { client })}
            />
          )}
        </div>
      </div>

      {showMobileRecap ? (
        <div className="checkout__content">
          <CheckoutRecapMobile
            transactionTemplate={transactionTemplate}
            transaction={transaction}
            picture={picture}
            deliveryMethod={deliveryMethod}
            displayedBuyerTotal={displayedBuyerTotal}
            currentProvider={currentProvider}
          />
          <div>
            <Button
              className="btn-block"
              onClick={() => {
                setShowMobileRecap(false);
              }}
            >
              {t('continue')}
            </Button>
            {allowPriceChange && (
              <Button
                variant="outline-primary"
                className="checkout__counter-price-btn w-100 text-primary"
                textClassName="counter-offer-text"
                onClick={showCounterOfferModal}
              >
                {t('counterPrice')}
              </Button>
            )}
            {adUrl && (
              <GoBackCheckoutLink
                handleClick={gotoClient}
                text={t('goBackToClient', { client })}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="checkout__content">
          <Tabs activeKey={activeKey} className="checkout__tab-mobile">
            <Tab
              eventKey={CHECKOUT_STEPS.sign}
              title={<div className="m-0 h-100 active bg-primary" />}
              disabled
            >
              <div className="checkout__tabContainer">
                <CheckoutAuthStep
                  templateId={templateId}
                  showSignup={showSignup}
                  setShowSignup={setShowSignup}
                  setActiveKey={setActiveKey}
                />
              </div>
            </Tab>
            <Tab
              eventKey={CHECKOUT_STEPS.delivery}
              title={(
                <div
                  className={classNames('m-0 h-100', {
                    'active bg-primary': [
                      CHECKOUT_STEPS.delivery,
                      CHECKOUT_STEPS.payment,
                      CHECKOUT_STEPS.message,
                    ].includes(activeKey),
                  })}
                />
              )}
              disabled
            >
              <div className="checkout__tabContainer">
                <p className="checkout__title checkout__title--xlarge">
                  {t('deliveryMethod.mobile')}
                </p>
                <CheckoutDeliveryMethod
                  transactionTemplate={transactionTemplate}
                  deliveryMethod={deliveryMethod}
                  setDeliveryMethod={setDeliveryMethod}
                  setActiveKey={setActiveKey}
                  showAddressModal={showAddressModal}
                  setShowAddressModal={setShowAddressModal}
                  deliveryAddress={deliveryAddress}
                  setDeliveryAddress={setDeliveryAddress}
                  message={message}
                  currentProvider={currentProvider}
                  setCurrentProvider={setCurrentProvider}
                  selectingPickupPointOnMobile={selectingPickupPointOnMobile}
                  setSelectingPickupPointOnMobile={setSelectingPickupPointOnMobile}
                />
                <GoBackCheckoutLink
                  handleClick={() => {
                    if (selectingPickupPointOnMobile) {
                      setSelectingPickupPointOnMobile(false);
                    } else {
                      setActiveKey(CHECKOUT_STEPS.sign);
                    }
                  }}
                />
              </div>
            </Tab>

            <Tab
              eventKey={CHECKOUT_STEPS.message}
              title={(
                <div
                  className={classNames('m-0 h-100', {
                    'active bg-primary': [
                      CHECKOUT_STEPS.payment,
                      CHECKOUT_STEPS.message,
                    ].includes(activeKey),
                  })}
                />
              )}
              disabled
            >
              <div className="checkout__tabContainer">
                <p className="checkout__title checkout__title--xlarge">
                  {t('message.title')}
                </p>
                <MessageForm
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  setMessage={setMessage}
                />
                <GoBackCheckoutLink
                  handleClick={() => {
                    setActiveKey(CHECKOUT_STEPS.delivery);
                  }}
                />
              </div>
            </Tab>

            <Tab
              eventKey={CHECKOUT_STEPS.payment}
              title={(
                <div
                  className={classNames('m-0 h-100', {
                    'active bg-primary': activeKey === CHECKOUT_STEPS.payment,
                  })}
                />
              )}
              disabled
            >
              <div>
                <p className="checkout__title checkout__title--xlarge">
                  {t('paymentMethod.mobile')}
                </p>
                <CheckoutPaymentForm
                  transactionTemplate={transactionTemplate}
                  transaction={transaction}
                  deliveryMethod={deliveryMethod}
                  deliveryAddress={deliveryAddress}
                  showPaymentErrorModal={showPaymentErrorModal}
                  openSameUserModal={openSameUserModal}
                  message={message}
                  goBack={() => {
                    setActiveKey(CHECKOUT_STEPS.message);
                  }}
                  currentProvider={currentProvider}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
      <Footer checkout showTuto={showTuto} className="checkout__footer" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  me: state.persistent.meReducer.me,
});

export default connect(mapStateToProps)(CheckoutMobile);
