import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import {
  DELIVERY_METHOD_IN_PERSON,
  ERRORS_FROM_SERVER,
} from 'helpers/constants';
import LockIcon from 'assets/images/lock-icon.svg';
import BoxIcon from 'assets/images/box-icon.svg';
import classes from 'assets/style/completeTransactionModal.module.scss';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import { toast } from 'react-toastify';

const CompleteTransactionModal = ({
  deliveryMethod = DELIVERY_METHOD_IN_PERSON,
  visible,
  onHide,
  onSubmit,
}) => {
  const { t } = useTranslation('completeTransactionModal');
  const needsValidationCode = deliveryMethod === DELIVERY_METHOD_IN_PERSON;

  const handleSubmit = useCallback(
    ({ verificationCode }, { setFieldError, setSubmitting }) => onSubmit(verificationCode)
      .then(onHide)
      .catch(({ error }) => {
        if (
          error?.response?.data?.errors
            === ERRORS_FROM_SERVER.INVALID_VERIFICATION_CODE
        ) {
          setFieldError('verificationCode', t('invalidCode'));
          setSubmitting(false);
        } else {
          toast.error(t('errorWhileApplyingCode'));
        }
      }),
    [
      onHide,
      onSubmit,
      t,
    ],
  );

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={onHide}
      className={classes.completeTransactionModal}
      contentClassName={classes.completeTransactionModal__content}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={yup.object({
            verificationCode: needsValidationCode
              ? yup
                .string()
                .length(6, t('invalidLength'))
                .required(t('emptyVerificationCode'))
              : yup.string(),
          })}
          onSubmit={handleSubmit}
          initialValues={{ verificationCode: '' }}
        >
          {({
            handleSubmit, values, errors, isSubmitting, setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {needsValidationCode ? (
                <>
                  <img
                    alt="lock"
                    src={LockIcon}
                    className={classes.completeTransactionModal__icon}
                  />
                  <p className={classes.completeTransactionModal__title}>
                    {t('validationCode')}
                  </p>
                  <p className={classes.completeTransactionModal__subTitle}>
                    {t('msgWithValidationCode')}
                  </p>
                  <OtpInput
                    value={values.verificationCode}
                    onChange={(code) => setFieldValue('verificationCode', code)}
                    numInputs={6}
                    isInputNum
                    hasErrored={!!errors.verificationCode}
                    containerStyle={
                      classes.completeTransactionModal__codeContainer
                    }
                    inputStyle={classNames(
                      classes.completeTransactionModal__inputStyle,
                      'form-control',
                    )}
                    errorStyle={classes.completeTransactionModal__errorStyle}
                  />
                  <div
                    className={classNames(
                      {
                        [classes.completeTransactionModal__error]:
                          errors.verificationCode,
                      },
                      'invalid-feedback',
                    )}
                  >
                    {errors.verificationCode}
                  </div>
                </>
              ) : (
                <>
                  <img
                    alt="lock"
                    src={BoxIcon}
                    className={classNames(
                      classes.completeTransactionModal__icon,
                      classes.completeTransactionModal__iconBox,
                    )}
                  />
                  <p
                    className={classNames(
                      classes.completeTransactionModal__title,
                      classes.completeTransactionModal__titleCompleteTransaction,
                    )}
                  >
                    {t('areYouSatisfied')}
                  </p>
                  <p
                    className={classNames(
                      classes.completeTransactionModal__subTitle,
                      classes.completeTransactionModal__subTitleCompleteTransaction,
                    )}
                  >
                    {t('completeDisclaimer')}
                  </p>
                </>
              )}
              <Button
                type="submit"
                isLoading={isSubmitting}
                className={classNames(
                  classes.completeTransactionModal__button,
                  {
                    [classes.completeTransactionModal__buttonCompleteTransaction]:
                      !needsValidationCode,
                  },
                )}
              >
                {t('submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CompleteTransactionModal;
