import React from 'react';
import { useTranslation } from 'react-i18next';
import useDynamicPagination from 'hooks/useDynamicPagination';
import { getOwnActiveTransactions } from 'logic/actions/transactionsActions';
import parseObjectValuesToArray from 'helpers/parseObjectValuesToArray';
import TransactionListItem from 'components/TransactionListItem';
import Loader from 'components/Loader';

const ActiveTransactionsTab = ({ isCurrentTab }) => {
  const { t } = useTranslation('transactions');

  const {
    loader,
    loading,
    numberOfPages,
    items,
  } = useDynamicPagination(
    getOwnActiveTransactions,
    'activeTransactions',
    isCurrentTab,
  );

  return (
    <>
      <div className="transactions__transaction-list">
        {!loading && numberOfPages === 0 && <p>{t('noActiveTransactions')}</p>}
        {parseObjectValuesToArray(items).map((transaction) => (
          <TransactionListItem
            transaction={transaction}
            key={transaction.id}
            isCurrentTab={isCurrentTab}
          />
        ))}
      </div>
      <div ref={loader}>
        <Loader />
      </div>
    </>
  );
};

export default ActiveTransactionsTab;
