import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import 'assets/style/transaction-recap.scss';
import { CURRENCY_EUR, DELIVERY_METHOD_REMOTE } from 'helpers/constants';

const TransactionRecap = ({ transaction }) => {
  const { t } = useTranslation('transactionRecap');

  const {
    isPurchase,
    initialSubTotal,
    subTotal = 0,
    sellerTotal = 0,
    buyerTotal = 0,
    currency = CURRENCY_EUR,
    deliveryMethod,
    shippingFees = 0,
    ownFees = 0,
    buyerDiscount = 0,
    labelGeneration,
  } = transaction;

  const sellingPrice = initialSubTotal ?? subTotal;

  const showDeliveryFees = useMemo(
    () => deliveryMethod === DELIVERY_METHOD_REMOTE
      && (isPurchase || !labelGeneration),
    [
      deliveryMethod,
      isPurchase,
      labelGeneration,
    ],
  );

  if (isPurchase) {
    return (
      <div className="transaction-recap__wrapper">
        <div>
          <div>{t('purchase')}</div>
          <div>
            <FormattedNumber
              value={subTotal / 100}
              style="currency"
              currency={currency}
            />
          </div>
        </div>
        <div>
          <div>{t('serviceFees')}</div>
          <div>
            <FormattedNumber
              value={ownFees / 100}
              style="currency"
              currency={currency}
            />
          </div>
        </div>
        {showDeliveryFees && (
          <div>
            <div>{t('deliveryFees')}</div>
            <div>
              <FormattedNumber
                value={shippingFees / 100}
                style="currency"
                currency={currency}
              />
            </div>
          </div>
        )}
        {!!buyerDiscount && (
          <div>
            <div>{t('buyerDiscount')}</div>
            <div>
              <FormattedNumber
                value={buyerDiscount / 100}
                style="currency"
                currency={currency}
              />
            </div>
          </div>
        )}
        <div>
          <div className="collect-infos__label">{t('total')}</div>
          <div className="collect-infos__value">
            <FormattedNumber
              value={buyerTotal / 100}
              style="currency"
              currency={currency}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="transaction-recap__wrapper">
      <div>
        <div>{t('sellingPrice')}</div>
        <div>
          <FormattedNumber
            value={sellingPrice / 100}
            style="currency"
            currency={currency}
          />
        </div>
      </div>
      <div>
        <div>{t('buyerOffer')}</div>
        <div>
          <FormattedNumber
            value={subTotal / 100}
            style="currency"
            currency={currency}
          />
        </div>
      </div>
      {showDeliveryFees && (
        <div>
          <div>{t('deliveryFees')}</div>
          <div>
            <FormattedNumber
              value={shippingFees / 100}
              style="currency"
              currency={currency}
            />
          </div>
        </div>
      )}
      <div>
        <div>{t('collectedAmount')}</div>
        <div>
          <FormattedNumber
            value={sellerTotal / 100}
            style="currency"
            currency={currency}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionRecap;
