import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/promocode.module.scss';
import { FormattedNumber } from 'react-intl';
// import Button from 'react-bootstrap/Button';
import { useFormikContext } from 'formik';
import CloseIcon from 'assets/svg/CloseIcon';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'helpers/useWindowSize';
import { useSelector } from 'react-redux';
import { loggedIn } from 'logic/actions/meActions';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';

const PromocodeForm = ({
  promocode,
  discount,
  currency,
  clearDiscount,
  loading,
}) => {
  const { t } = useTranslation('checkoutRecap');

  const { isNarrow } = useWindowSize();

  const isLoggedIn = useSelector(loggedIn);

  const {
    values, resetForm, handleSubmit, handleChange, handleBlur, errors,
  } = useFormikContext();

  const showApplyButton = useMemo(
    () => (isNarrow ? !promocode && values.promocode : !promocode),
    [isNarrow, promocode, values.promocode],
  );

  const resetPromocode = useCallback(() => {
    resetForm();
    clearDiscount();
  }, [resetForm, clearDiscount]);

  useEffect(() => {
    if (!isLoggedIn) {
      resetForm();
    }
  }, [isLoggedIn, resetForm]);

  return (
    <form id={classes.formContainer} onSubmit={handleSubmit}>
      <div id={classes.inputGroup}>
        {promocode ? (
          <div className={classes.promocodeAdded}>
            <div className={classes.label}>
              <div>{promocode.toUpperCase()}</div>
              <button type="button" onClick={resetPromocode}>
                <CloseIcon className={classes.closeIcon} />
              </button>
            </div>
            {discount && (
              <div className={classes.discount}>
                <FormattedNumber
                  value={discount / 100}
                  style="currency"
                  currency={currency}
                />
              </div>
            )}
          </div>
        ) : (
          <Form.Control
            type="text"
            name="promocode"
            id="promocode"
            placeholder={t('promocodePlaceholder')}
            value={values.promocode.toUpperCase()}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={20}
            isInvalid={!!errors.promocode}
            autoComplete="off"
          />
        )}
        {!!errors.promocode && (
          <div className={classes.promocode__feedback}>{errors.promocode}</div>
        )}
      </div>
      {showApplyButton && (
        <Button
          type="submit"
          onSubmit={handleSubmit}
          disabled={!values.promocode}
          className={classes.promocode__applyBtn}
          isLoading={loading}
        >
          {t('apply')}
        </Button>
      )}
    </form>
  );
};

PromocodeForm.propTypes = {
  discount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  clearDiscount: PropTypes.func.isRequired,
};

export default PromocodeForm;
