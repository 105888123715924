import React from 'react';

const CoinsIcon = ({ stroke, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36.261"
    height="23"
    viewBox="0 0 36.261 23"
    {...rest}
  >
    <defs>
      <style>
        {`.c{fill:'#fff';stroke:${stroke};stroke-linecap:round;stroke-linejoin:round;}`}
      </style>
    </defs>
    <g transform="translate(-662.953 -220.869)">
      <path
        className="c"
        d="M698.714,232.054v2.97c0,2.266-4.785,4.18-10.45,4.18s-10.45-1.914-10.45-4.18v-2.97"
      />
      <path
        className="c"
        d="M688.264,236.454c-5.469,0-10.45-2.1-10.45-4.4,0-2.378,5.424-3.85,10.45-3.85s10.45,1.472,10.45,3.85C698.714,234.357,693.733,236.454,688.264,236.454Z"
      />
      <path
        className="c"
        d="M684.353,236.219v2.97c0,2.266-4.785,4.18-10.45,4.18s-10.45-1.914-10.45-4.18v-2.97"
      />
      <path
        className="c"
        d="M684.325,235.926a1.5,1.5,0,0,1,.028.293c0,2.3-4.981,4.4-10.45,4.4s-10.45-2.1-10.45-4.4c0-.822.648-1.536,1.7-2.118"
      />
      <path
        className="c"
        d="M686,230.719v2.97c0,2.266-4.785,4.18-10.45,4.18s-10.45-1.914-10.45-4.18v-2.97"
      />
      <path
        className="c"
        d="M684.753,228.876a2.338,2.338,0,0,1,1.25,1.843c0,2.3-4.981,4.4-10.45,4.4s-10.45-2.1-10.45-4.4a1.587,1.587,0,0,1,.121-.6"
      />
      <path
        className="c"
        d="M684.9,225.219v2.97c0,2.266-4.785,4.18-10.45,4.18S664,230.455,664,228.189v-2.97"
      />
      <path
        className="c"
        d="M674.453,229.619c-5.469,0-10.45-2.1-10.45-4.4,0-2.379,5.424-3.85,10.45-3.85s10.45,1.471,10.45,3.85C684.9,227.521,679.922,229.619,674.453,229.619Z"
      />
    </g>
  </svg>
);

export default CoinsIcon;
