import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import classes from 'assets/style/cancelRejectModal.module.scss';
import RadioCheckOutlined from 'components/RadioCheckOutlined';
import { toast } from 'react-toastify';
import { CANCEL_REJECT_REASONS } from 'helpers/constants';

const CancelRejectModal = ({ show, onHide, onCancel }) => {
  const { t } = useTranslation('transactionActionPanel');

  const [cancelling, setCancelling] = useState(false);
  const [currentReason, setCurrentReason] = useState('');

  const handleCancel = useCallback(() => {
    setCancelling(true);
    onCancel(currentReason)
      .then(() => {
        setCancelling(false);
        onHide();
      })
      .catch(() => {
        setCancelling(false);
        toast.error(t('confirmRejectModal.error'));
      });
  }, [
    currentReason,
    onCancel,
    onHide,
    t,
  ]);

  const handleReason = useCallback((e) => {
    setCurrentReason(e.target.value);
  }, []);

  useEffect(() => {
    setCurrentReason('');
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={classes.cancelRejectModal}
      dialogClassName={classes.cancelRejectModal__dialog}
      centered
    >
      <Modal.Header closeButton>
        <p className={classes.cancelRejectModal__header}>
          {t('cancelRejectModal.title')}
        </p>
      </Modal.Header>
      {CANCEL_REJECT_REASONS.map((reasonKey) => (
        <RadioCheckOutlined
          key={reasonKey}
          label={t(`cancelRejectModal.reasons.${reasonKey}`)}
          onChange={handleReason}
          value={reasonKey}
          name="reason"
          id={reasonKey}
          isChecked={reasonKey === currentReason}
        />
      ))}
      <p className={classes.cancelRejectModal__info}>
        {t('cancelRejectModal.info')}
      </p>
      <Button
        variant="primary"
        isLoading={cancelling}
        onClick={handleCancel}
        className={classes.cancelRejectModal__button}
        disabled={!currentReason}
      >
        {t('cancelRejectModal.confirm')}
      </Button>
    </Modal>
  );
};

CancelRejectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CancelRejectModal;
