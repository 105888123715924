import parseUserDevice from 'helpers/userDevice';

/* eslint-disable import/no-unused-modules */
export const GET_OWN_ACTIVE_TRANSACTIONS = 'transactions/GET_OWN_ACTIVE_TRANSACTIONS';
export const GET_OWN_ENDED_TRANSACTIONS = 'transactions/GET_OWN_ENDED_TRANSACTIONS';
export const GET_TRANSACTION = 'transactions/GET_TRANSACTION';
export const DO_PREAUTH = 'transactions/DO_PREAUTH';
export const POST_PROCESS_PREAUTH = 'transactions/POST_PROCESS_PREAUTH';
export const ACCEPT_TRANSACTION = 'transactions/ACCEPT_TRANSACTION';
export const REJECT_TRANSACTION = 'transactions/REJECT_TRANSACTION';
export const SHIP_TRANSACTION = 'transactions/SHIP_TRANSACTION';
export const COMPLETE_TRANSACTION = 'transactions/COMPLETE_TRANSACTION';
export const REQUEST_CANCEL_TRANSACTION = 'transactions/REQUEST_CANCEL_TRANSACTION';
export const REJECT_CANCEL_TRANSACTION = 'transactions/REJECT_CANCEL_TRANSACTION';
export const CANCEL_TRANSACTION = 'transactions/CANCEL_TRANSACTION';
export const EVALUATE_TRANSACTION = 'transactions/EVALUATE_TRANSACTION';
export const FETCH_TRANSACTION_PICTURE = 'transactions/FETCH_TRANSACTION_PICTURE';
export const FETCH_TRANSACTION_VERIFICATION_CODE = 'transactions/FETCH_TRANSACTION_VERIFICATION_CODE';
export const OPEN_ISSUE = 'transactions/OPEN_ISSUE';
export const UPDATE_ISSUE = 'transactions/UPDATE_ISSUE';
export const GENERATE_SHIPPING_LABEL = 'transactions/GENERATE_SHIPPING_LABEL';
export const GET_SHIPPING_LABEL = 'transactions/GET_SHIPPING_LABEL';
export const CREATE_DISPUTE = 'transactions/CREATE_DISPUTE';
export const CREATE_DISPUTE_LOGIN_LINK = 'transactions/CREATE_DISPUTE_LOGIN_LINK';
/* eslint-enable import/no-unused-modules */

export const getOwnActiveTransactions = (page = 1) => ({
  type: GET_OWN_ACTIVE_TRANSACTIONS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/transactions/',
      params: {
        active: 1,
        page,
      },
    },
  },
});

export const getOwnEndedTransactions = (page = 1) => ({
  type: GET_OWN_ENDED_TRANSACTIONS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/transactions/',
      params: {
        active: 0,
        page,
      },
    },
  },
});

export const getTransaction = (transactionId) => ({
  type: GET_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/webapp/transactions/${transactionId}/`,
    },
  },
});

export const fetchTransactionVerificationCode = (transactionId) => ({
  type: FETCH_TRANSACTION_VERIFICATION_CODE,
  transactionId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/webapp/transactions/${transactionId}/verification-code/`,
    },
  },
});

export const fetchTransactionPicture = (transactionId, cancelToken) => ({
  type: FETCH_TRANSACTION_PICTURE,
  payload: {
    request: {
      method: 'GET',
      url: `/api/cached/transactions/${transactionId}/picture/`,
      responseType: 'blob',
      cancelToken,
    },
  },
});

export const doPreauth = (transactionId, paymentMethodId) => ({
  type: DO_PREAUTH,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/preauth/`,
      data: { paymentMethodId },
    },
  },
});

export const doWebPreauth = (transactionId) => ({
  type: DO_PREAUTH,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/web-preauth/`,
    },
  },
});

export const postProcessPreauth = (transactionId) => ({
  type: POST_PROCESS_PREAUTH,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/preauth/post-process/`,
    },
  },
});

export const acceptTransaction = (transactionId) => ({
  type: ACCEPT_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/accept/`,
    },
  },
});

export const rejectTransaction = (transactionId, reason) => ({
  type: REJECT_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/reject/`,
      data: {
        reason,
      },
    },
  },
});

export const shipTransaction = (transactionId, data) => ({
  type: SHIP_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/ship/`,
      data,
    },
  },
});

export const completeTransaction = (
  transactionId,
  verificationCode = null,
) => ({
  type: COMPLETE_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/complete/`,
      data: { verificationCode },
    },
  },
});

export const requestCancelTransaction = (transactionId) => ({
  type: REQUEST_CANCEL_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/request-cancel/`,
    },
  },
});

export const rejectCancelTransaction = (transactionId, reason) => ({
  type: REJECT_CANCEL_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/reject-cancel/`,
      data: {
        reason,
      },
    },
  },
});

export const cancelTransaction = (transactionId) => ({
  type: CANCEL_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/cancel/`,
    },
  },
});

export const evaluateTransaction = (transactionId, data) => ({
  type: EVALUATE_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/evaluate/`,
      data,
    },
  },
});

export const openIssue = (transactionId, data) => ({
  type: OPEN_ISSUE,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/open-issue/`,
      data,
    },
  },
});

export const updateIssue = (transactionId, data) => ({
  type: UPDATE_ISSUE,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/update-issue/`,
      data,
    },
  },
});

export const generateShippingLabel = (transactionId, data) => ({
  type: GENERATE_SHIPPING_LABEL,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/webapp/transactions/${transactionId}/generate-shipping-label/ `,
      data,
    },
  },
});

export const getShippingLabel = (transactionId) => ({
  type: GET_SHIPPING_LABEL,
  payload: {
    request: {
      method: 'GET',
      url: `/api/webapp/transactions/${transactionId}/fetch-label/`,
    },
  },
});

export const createDispute = (transactionId) => ({
  type: CREATE_DISPUTE,
  transactionId,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/transactions/${transactionId}/dispute/ `,
    },
  },
});

const browserInfo = parseUserDevice();

export const createDisputeLoginLink = (transactionId) => ({
  type: CREATE_DISPUTE_LOGIN_LINK,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/transactions/${transactionId}/dispute-login-link/ `,
      data: { browserInfo },
    },
  },
});

export const selectCurrentTransactionsCount = (state) => state.persistent.transactionsReducer.activeTransactions.count;
export const selectLastLoadedPage = (transactionType) => (state) => state.persistent.transactionsReducer[transactionType].lastLoadedPage;
export const selectNbPages = (transactionType) => (state) => state.persistent.transactionsReducer[transactionType].nbPages;
export const selectLoadedPages = (transactionType) => (state) => Object.keys(state.persistent.transactionsReducer[transactionType].items).map(
  (pageNumber) => Number(pageNumber),
);
export const selectCount = (transactionType) => (state) => state.persistent.transactionsReducer[transactionType].count;
export const selectItems = (transactionType) => (state) => state.persistent.transactionsReducer[transactionType].items;
