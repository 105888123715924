import React from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/deliveryProviders.module.scss';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import DeliveryProviderItem from 'components/DeliveryProviderItem';
import useWindowSize from 'helpers/useWindowSize';
import { DISABLED_PROVIDERS } from 'helpers/constants';

const DeliveryProviders = ({
  onChangeProvider,
  currentProvider,
  gotoDeliveryAddress,
  providers,
  currency,
  carrierError,
}) => {
  const { t } = useTranslation('deliveryProviders');

  const { isNarrow } = useWindowSize();

  return (
    <div className={classes.container}>
      {isNarrow && (
        <p className={classes.label}>{t('selectCarrier')}</p>
      )}
      <div className={classes.list}>
        {providers?.map((provider) => (
          <DeliveryProviderItem
            key={provider?.name}
            provider={provider}
            onChange={onChangeProvider}
            currentProvider={currentProvider}
            currency={currency}
            tempDisabled={DISABLED_PROVIDERS.includes(provider?.name)}
          />
        ))}
      </div>
      {carrierError && (
        <div className="invalid-feedback d-block">{t('carrierError')}</div>
      )}
      {!isNarrow && (
        <Button
          className={classes.button}
          disabled={!currentProvider}
          onClick={gotoDeliveryAddress}
        >
          {t('confirmDeliveryMethod')}
        </Button>
      )}
    </div>
  );
};

DeliveryProviders.propTypes = {
  onChangeProvider: PropTypes.func.isRequired,
  currentProvider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    shippingFees: PropTypes.number.isRequired,
  }),
  gotoDeliveryAddress: PropTypes.func,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      shippingFees: PropTypes.number.isRequired,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  carrierError: PropTypes.bool,
};

DeliveryProviders.defaultProps = {
  currentProvider: undefined,
  carrierError: false,
  gotoDeliveryAddress: () => {},
};

export default DeliveryProviders;
