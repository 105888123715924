import React, { useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import PreviewItem from 'components/takePictures/PreviewItem';
import classes from 'assets/style/takePictures/previewCarousel.module.scss';
import arrowLeftMobileSlider from 'assets/images/arrowLeftMobileSlider.svg';
import arrowRightMobileSlider from 'assets/images/arrowRightMobileSlider.svg';

const PreviewCarousel = ({ ids, byIds }) => {
  const carouselItemsCount = useMemo(
    () => Math.ceil((ids.length + 1) / 4),
    [ids.length],
  );

  const showControls = carouselItemsCount > 1;

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (carouselItemsCount > 0) {
      setIndex(carouselItemsCount - 1);
    }
  }, [carouselItemsCount, ids?.length]);

  if (!ids.length) return null;

  return (
    <Carousel
      activeIndex={index}
      onSelect={setIndex}
      indicators={false}
      controls={showControls}
      interval={null}
      id={classes.previewCarousel}
      prevIcon={<img alt="goLeftIcon" src={arrowLeftMobileSlider} />}
      nextIcon={<img alt="goRightIcon" src={arrowRightMobileSlider} />}
    >
      {[...Array(carouselItemsCount).keys()]
        .map((i) => i * 4)
        .map((carouselItem) => {
          const mediaIdsPerSlide = ids.slice(carouselItem, carouselItem + 4);
          return (
            <Carousel.Item key={carouselItem}>
              <div className={classes.previewCarousel__slideContainer}>
                <div className={classes.previewCarousel__itemsContainer}>
                  {mediaIdsPerSlide.map((id) => (
                    <PreviewItem key={byIds[id]?.title} media={byIds[id]} />
                  ))}
                  {mediaIdsPerSlide.length < 4
                    && [...Array(4 - mediaIdsPerSlide.length).keys()].map(
                      (emptyItem) => <PreviewItem key={emptyItem} />,
                    )}
                </div>
              </div>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

PreviewCarousel.propTypes = {};

export default PreviewCarousel;
