/* eslint-disable import/no-unused-modules */
export const GET_CURRENCIES = 'app/currencies/GET_CURRENCIES';
/* eslint-enable import/no-unused-modules */

export const getCurrencies = () => ({
  type: GET_CURRENCIES,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/currencies/',
    },
  },
});
