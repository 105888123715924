import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import LanguageSelector from 'components/LanguageSelector';
import { useDispatch, useSelector } from 'react-redux';
import 'assets/style/footer.scss';
import TutoModals from 'components/TutoModals';
import {
  handleGreetingModal,
  selectGreetingModal,
} from 'logic/actions/meActions';

const Footer = ({ checkout = false, showTuto = () => {}, className = '' }) => {
  const { t } = useTranslation(['footer', '_links']);

  const greetingModal = useSelector(selectGreetingModal);

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(handleGreetingModal(false));
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(handleGreetingModal(true));
  }, [dispatch]);

  return (
    <>
      {greetingModal && (
        <TutoModals
          show={greetingModal}
          type="newUserGreetings"
          onHide={closeModal}
        />
      )}
      <ul
        className={`w-100 d-flex justify-content-center flex-wrap list-unstyled footer ${className}`}
      >
        {checkout && (
          <li className="footer__link">
            <a
              className="text-body"
              href={t('_links:tripartieWebsite')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('poweredByTripartie')}
            </a>
          </li>
        )}
        {checkout && (
          <li className="footer__link">
            <Button className="text-body" variant="link" onClick={showTuto}>
              {t('showTuto')}
            </Button>
          </li>
        )}
        <li className="footer__link">
          <a
            className="text-body"
            href={t('_links:supportCenter')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('helpCenter')}
          </a>
        </li>
        {!checkout && (
          <li className="footer__link">
            <button
              className="text-body footer__button"
              onClick={openModal}
              type="button"
            >
              {t('explanations')}
            </button>
          </li>
        )}
        <li className="footer__link">
          <a
            className="text-body"
            href={t('_links:terms')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('terms')}
          </a>
        </li>
        <li>
          <LanguageSelector up small />
        </li>
      </ul>
    </>
  );
};

export default Footer;
