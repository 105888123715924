import * as React from 'react';

const IconInfo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      d="M7 0C1.4 0 0 1.4 0 7s1.4 7 7 7 7-1.4 7-7-1.4-7-7-7z"
      fill="var(--primary)"
      fillRule="evenodd"
    />
    <path
      d="M8.549 10.594c-.335.132-.6.233-.8.3a2.1 2.1 0 01-.7.1 1.387 1.387 0 01-.947-.3.959.959 0 01-.337-.754 2.673 2.673 0 01.025-.364c.017-.123.044-.262.081-.418l.419-1.483c.037-.143.069-.278.1-.4a1.813 1.813 0 00.037-.349.534.534 0 00-.117-.4.661.661 0 00-.448-.111 1.2 1.2 0 00-.334.049 3.354 3.354 0 00-.292.1l.111-.456q.412-.168.789-.288a2.345 2.345 0 01.712-.12 1.362 1.362 0 01.933.292.972.972 0 01.328.759c0 .065-.008.179-.023.341a2.261 2.261 0 01-.084.447l-.417 1.479a4.019 4.019 0 00-.092.406 2.154 2.154 0 00-.041.345.5.5 0 00.132.4.738.738 0 00.456.107 1.3 1.3 0 00.346-.054 1.826 1.826 0 00.279-.094zm-.074-6a1 1 0 01-.7.272 1.01 1.01 0 01-.707-.272.866.866 0 01-.294-.658.876.876 0 01.294-.659 1 1 0 01.707-.275.985.985 0 01.7.275.888.888 0 010 1.317z"
      fill="#fff"
    />
  </svg>
);

export default IconInfo;
