/* eslint-disable */
import jQuery from 'jquery';
import L from 'leaflet';

!(function ($, window, document) {
  const MAX_RETRY = 20;
  const WAIT_FOR_LIB_TIMEOUT = 500;
  const CSS_VERSION = '4.0.9';
  const DEFAULT_THEME = 'mondialrelay';
  window.MR_jQuery = $;
  if (!$.fn.on) {
    $.fn.on = function (selector, handler) {
      if (selector == 'click') {
        this.click(handler);
      } else {
        this.live(selector, handler);
      }
    };
  }
  console.log(`MondialRelay widget jQuery ${$.fn.jquery}`);
  const pluginName = 'MR_ParcelShopPicker';
  const defaults = {
    Target: '',
    TargetDisplay: '',
    TargetDisplayInfoPR: '',
    Brand: '',
    Country: 'FR',
    AllowedCountries: '',
    PostCode: '',
    City: '',
    EnableGeolocalisatedSearch: true,
    ColLivMod: '24R',
    Weight: '',
    Service: '',
    NbResults: '7',
    SearchDelay: '',
    SearchFar: '75',
    CSS: '1',
    MapScrollWheel: false,
    MapStreetView: false,
    ShowResultsOnMap: true,
    UseSSL: true,
    ServiceUrl: 'widget.mondialrelay.com',
    OnParcelShopSelected: null,
    OnNoResultReturned: null,
    OnSearchSuccess: null,
    DisplayMapInfo: true,
    EnableGmap: false,
    Host: null,
    AutoSelect: null,
    EnableLoadMore: true,
    Theme: DEFAULT_THEME,
  };
  function MR_ParcelShopPicker(element, options) {
    this.element = element;
    this.zoomDefault = 14;
    this.prLoaded = [];
    this.settings = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = pluginName;
    this.ashx = 'service2_3.ashx';
    this.svc = 'services/parcelshop-picker.svc';
    this.w_name = 'parcelshop-picker/v4_0';
    this.sw_url = options.Brand.trim().toLowerCase() == 'bdtest' && options.Host ? options.Host : 'widget.mondialrelay.com';
    this.widget = this.settings.Responsive ? (this.settings.ShowResultsOnMap ? 'widget_responsive.aspx' : 'widget_responsive_list.aspx') : 'widget.aspx';
    this.img_url = 'www.mondialrelay.com';
    this.bounds = {};
    this.map = {};
    this.overlays = [];
    this.InfoWindow = null;
    this.container = {};
    this.callback = null;
    this.mapLoaded = {};
    this.containerId = null;
    this.params = null;
    this.mapId = null;
    this.protocol = 'https://';
    this.version = options.version || CSS_VERSION;
    this.theme = selectTheme(this.settings.Theme);
    extendOpenMap();
    this.init();
  }
  var selectTheme = function (theme) {
    if (theme != null && theme != undefined && theme === 'inpost') {
      return 'inpost';
    }
    return DEFAULT_THEME;
  };
  var extendOpenMap = function () {
    $.extend(MR_ParcelShopPicker.prototype, {
      MR_LoadMap(prms) {
        this.map[this.mapId] = L.map(this.mapId, {
          keyboard: true, scrollWheelZoom: prms.MapScrollWheel ? prms.MapScrollWheel : false, doubleClickZoom: false, trackResize: true, minZoom: 9, maxZoom: 20,
        }).setView([46.8, 1.69], 5);
        L.control.scale({ metric: true, imperial: false }).addTo(this.map[this.mapId]);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', scrollwheel: false }).addTo(this.map[this.mapId]);
        this.bounds[this.mapId] = [];
        this.overlays[this.mapId] = [];
        this.mapLoaded[this.mapId] = true;
        if (this.settings.EnableLoadMore) {
          const ins = this;
          this.map[this.mapId].on('dragend', () => {
            ins.MR_LoadFromCenter();
          });
        }
      },
      MR_ClearOverlays() {
        for (var n = 0, overlay; (overlay = this.overlays[this.mapId][n]); n++) {
          overlay.removeFrom(this.map[this.mapId]);
        }
        this.overlays[this.mapId] = [];
        this.bounds[this.mapId] = [];
      },
      MR_FocusOnMarker(id) {
        let result = false;
        for (let i = 0; i < this.overlays[this.mapId].length; i++) {
          if (id == this.overlays[this.mapId][i].id) {
            this.MR_FocusOnMap(i);
            result = true;
          }
        }
        return result;
      },
      MR_AddMarker(map, latitude, longitude, PRI, Id, sw_url) {
        const image = PRI.Available ? 'pointsrelais_pin' : 'pointsrelais_pin_grey';
        const latLng = L.latLng(latitude, longitude);
        const imageUrl = `${this.protocol + this.sw_url}/${this.w_name}/css/themes/${this.theme}/imgs/${image}.png`;
        const icon = new L.DivIcon({
          className: 'my-div-icon',
          html: `<div class="marker-container"> <img class="marker-image" src="${imageUrl}"/>` + `<span class="marker-text">${Id + 1}</span></div>`,
          iconSize: [45, 45],
          iconAnchor: [22, 38],
          popupAnchor: [0, -35],
        });
        const marker = new L.Marker(latLng, { icon }).addTo(this.map[this.mapId]);
        marker.id = `${PRI.Pays}-${PRI.ID}`;
        const ins = this;
        if (this.settings.DisplayMapInfo) {
          const popup = marker.bindPopup(`${this.MR_BuildParcelShopDetails(PRI)}`, { closeButton: true });
        } else {
          marker.on('click', () => {
            ins.map[ins.mapId].setView(latLng);
          });
        }
        marker.on('click', () => {
          const targetZoom = 17;
          const padding = [0, 125];
          const map = ins.map[ins.mapId];
          var targetLatLng = [latitude, longitude];
          const targetPoint = map.project(targetLatLng, targetZoom).subtract(padding);
          var targetLatLng = map.unproject(targetPoint, targetZoom);
          map.setView(targetLatLng, targetZoom);
          ins.MR_SelectParcelShop(PRI);
        });
        this.overlays[this.mapId].push(marker);
        this.bounds[this.mapId].push(latLng);
        return marker;
      },
      MR_FocusOnMap(i) {
        if (this.params.ShowResultsOnMap) {
          this.overlays[this.mapId][i].fire('click');
        } else if (this.settings.Responsive) {
          const detailVisible = $(`.InfoWindowFull:eq(${i})`, this.container).hasClass('Detail-Selected');
          const detailSelected = $('.InfoWindowFull.Detail-Selected', this.container);
          detailSelected.slideUp(() => {
            detailSelected.removeClass('Detail-Selected');
          });
          if (!detailVisible) {
            $(`.InfoWindowFull:eq(${i})`, this.container)
              .addClass('Detail-Selected')
              .slideDown();
          }
          $(`.PR-List-Item:eq(${i})`).trigger('select');
        } else {
          $(`#${this.mapId} > div`, this.container).hide();
          $(`#${this.mapId} > div:nth-child(${i + 1})`, this.container)
            .show()
            .trigger('select');
        }
      },
      MR_RebindMap() {
        console.info('MR_RebindMap');
        if (this.params == null) {
          console.info('MR_RebindMap() method has been called too early.');
        } else if (this.params.ShowResultsOnMap) {
          this.map[this.mapId].fitBounds(this.bounds[this.mapId]);
        }
      },
      MR_FitBounds() {
        const ins = this;
        this.map[this.mapId].on('click', () => {
          ins.map[ins.mapId].fitBounds(ins.bounds[ins.mapId]);
        });
        setTimeout(() => {
          ins.map[ins.mapId].invalidateSize();
          if (!ins.container.find('.PR-Selected').length) {
            ins.map[ins.mapId].fitBounds(ins.bounds[ins.mapId]);
          }
          setTimeout(() => {
            ins.zoomDefault = ins.map[ins.mapId].getZoom();
          }, 100);
        }, 500);
        this.map[this.mapId].on('resize', (ResizeEvent) => {
          if (!ins.container.find('.PR-Selected').length) {
            ResizeEvent.target.fitBounds(ins.bounds[ins.mapId]);
          }
        });
      },
      MR_IsMapLibLoaded() {
        return typeof L === 'undefined';
      },
    });
  };
  const suggest = function (self, targetName, targetElement, sideName, sideElement, makeObj) {
    const { container } = self;
    container.find(targetElement).on('keyup', (e) => {
      clearTimeout(self.searchTimeout);
      switch (e.keyCode || e.which) {
        case 40:
          if (container.find('.PR-AutoCplCity .AutoCpl-Hover').length === 0) {
            container.find('.PR-AutoCplCity div:first-child').addClass('AutoCpl-Hover');
          } else if (container.find('.AutoCpl-Hover').next().length > 0) {
            container.find('.AutoCpl-Hover').removeClass('AutoCpl-Hover').next().addClass('AutoCpl-Hover');
          }
          break;
        case 38:
          if (container.find('.PR-AutoCplCity .AutoCpl-Hover').length === 0) {
            container.find('.PR-AutoCplCity div:last-child').addClass('AutoCpl-Hover');
          } else if (container.find('.AutoCpl-Hover').prev().length > 0) {
            container.find('.AutoCpl-Hover').removeClass('AutoCpl-Hover').prev().addClass('AutoCpl-Hover');
          }
          break;
        case 13:
        case 9:
          if (container.find('.AutoCpl-Hover').length > 0) {
            e.preventDefault();
            container.find(sideElement)[0].value = container.find('.AutoCpl-Hover').attr(sideName);
            container.find(targetElement)[0].value = container.find('.AutoCpl-Hover').attr(targetName);
            container.find('.PR-AutoCplCity').html('').slideUp('fast');
          }
          break;
        default:
          self.searchTimeout = setTimeout(() => {
            const a2 = container.find(targetElement)[0].value;
            if (a2.length >= 3 && /^[a-zA-Z0-9\-_ ]+$/.test(a2)) {
              container.find('.PR-AutoCplCity').css('top', `${self.offsetTop + 20}px`);
              container.find('.PR-AutoCplCity').css('left', `${container.find(targetElement)[0].offsetLeft}px`);
              const a1 = container.find('input.Arg1')[0].value;
              self.jsonpcall(`${self.w_name}/${self.svc}/AutoCPLCity`, makeObj(a1, a2), (result) => {
                container.find('.PR-AutoCplCity').html('');
                for (let i = 0; i < result.Value.length; i++) {
                  const elm = $('<div>');
                  elm.attr('title', result.Value[i].PostCode);
                  elm.attr('name', result.Value[i].Name);
                  elm.addClass('PR-City');
                  elm.html(`${result.Value[i].Name} (${result.Value[i].PostCode})`);
                  container.find('.PR-AutoCplCity').append(elm);
                  elm.on('click', function () {
                    container.find(targetElement)[0].value = $(this).attr(targetName);
                    container.find(sideElement)[0].value = $(this).attr(sideName);
                    container.find('.PR-AutoCplCity').html('').slideUp('fast');
                    self.MR_Widget_Call(self.container, self.settings.Target, self.settings.TargetDisplay, self.settings.TargetDisplayInfoPR, false);
                  });
                }
                container.find('.PR-AutoCplCity').slideDown('fast');
              });
            } else {
              container.find('.PR-AutoCplCity').html('').slideUp('fast');
            }
            clearTimeout(self.searchTimeout);
          }, 300);
      }
    });
  };
  $.extend(MR_ParcelShopPicker.prototype, {
    waitForLibThenInit(i) {
      if (i === MAX_RETRY) {
        console.error('Unable to load map lib');
        this.libraryError = true;
        this.settings.ShowResultsOnMap = false;
        this.init();
        return;
      }
      if (this.MR_IsMapLibLoaded()) {
        const ins = this;
        setTimeout(() => {
          ins.waitForLibThenInit(!i ? 1 : ++i);
        }, WAIT_FOR_LIB_TIMEOUT);
      } else {
        this.init();
      }
    },
    bind() {
      $(this.element).bind('FocusOnMap', function (evt, id) {
        $.data(this, `plugin_${pluginName}`).MR_FocusOnMap(id);
      });
      $(this.element).bind('TabSelect', function (evt, id) {
        $.data(this, `plugin_${pluginName}`).MR_tabselect(id);
      });
      $(this.element).bind('ClearSelection', function () {
        $.data(this, `plugin_${pluginName}`).MR_ClearSelection();
      });
      $(this.element).bind('CloseModalLocker', function () {
        $.data(this, `plugin_${pluginName}`).MR_CloseModalLocker();
      });
      $(this.element).bind('MR_RebindMap', function (evt) {
        $.data(this, `plugin_${pluginName}`).MR_RebindMap();
      });
      $(this.element).bind('MR_SetParams', function (evt, prms) {
        $.data(this, `plugin_${pluginName}`).MR_SetParams(prms);
      });
      $(this.element).bind('MR_DoSearch', function (evt, postcode, country) {
        $.data(this, `plugin_${pluginName}`).MR_DoSearch(postcode, country);
      });
    },
    init(includeOpenMap) {
      // this.MR_loadjscssfile('https://unpkg.com/leaflet/dist/leaflet.css', 'css');
      // this.MR_loadjscssfile('https://unpkg.com/leaflet/dist/leaflet.js', 'js');
      this.protocol = 'https://';

      if (!$(this.element).attr('id')) {
        $(this.element).attr('id', `MRParcelShopPicker_${Math.floor(Math.random() * 1e7 + 1)}`);
      }
      this.containerId = $(this.element).attr('id');
      if (this.settings.CSS != '0') {
        this.MR_loadjscssfile(`${this.protocol + this.sw_url}/${this.w_name}/css/themes/${this.theme}/style.min.css`, 'css', true);
        if (this.settings.Responsive) {
          if (this.settings.ShowResultsOnMap) {
            this.MR_loadjscssfile(`${this.protocol + this.sw_url}/${this.w_name}/css/themes/${this.theme}/responsive.min.css`, 'css', true);
          } else {
            this.MR_loadjscssfile(`${this.protocol + this.sw_url}/${this.w_name}/css/themes/${this.theme}/responsive-list.min.css`, 'css', true);
          }
        }
      }
      this.container = this.element;
      this.loadhtml(
        $(this.element),
        `${this.protocol
                + this.sw_url
        }/${
          this.w_name
        }/services/${
          this.widget
        }?allowedCountries=${
          this.settings.AllowedCountries
        }&Country=${
          this.settings.Country
        }&UseSSL=${
          this.settings.UseSSL
        }&Brand=${
          $.trim(this.settings.Brand)
        }&Container=${
          this.containerId
        }&CSS=${
          this.settings.CSS
        }&Responsive=${
          this.settings.Responsive
        }&NbResults=${
          this.settings.NbResults
        }&EnableGmap=${
          this.settings.EnableGmap
        }&ShowResultsOnMap=${
          this.settings.ShowResultsOnMap}`,
        function () {
          this.MR_Widget_Init(this.settings);
        },
      );
      this.bind();
    },
    jsonpcall(fn, paramArray, callbackFn) {
      $.ajax({
        context: this, url: `${this.protocol + this.sw_url}/${fn}`, jsonp: 'method', dataType: 'jsonp', data: paramArray, success: callbackFn,
      });
    },
    loadhtml(container, urlraw, callback) {
      const urlselector = urlraw.split(' ', 1);
      const url = urlselector[0];
      const selector = urlraw.substring(urlraw.indexOf(' ') + 1, urlraw.length);
      this.container = container;
      this.callback = callback;
      this.jsonpcall(this.ashx, { downloadurl: url, container: container.attr('id') }, function (msg) {
        $(`#${msg.container}`).html(msg.html);
        if ($.isFunction(this.callback)) {
          this.callback(msg.container);
        }
      });
    },
    MR_BuildItemPR(pr, i) {
      let result = '';
      result
                += `<div class='PR-List-Item ${
          pr.Available ? '' : 'PR-Disabled'
        }' onclick='MR_jQuery("#${
          this.containerId
        }").trigger("FocusOnMap",${
          i
        })'><input type='hidden' class='PR-Id' value='${
          pr.Pays
        }-${
          pr.ID
        }'/><div class='PR-Name'>${
          i + 1
        } - ${
          pr.Nom
        }</div>`;
      result += `<div>${pr.Adresse1}</div><div>${pr.Adresse2 || ''}</div><div>${pr.CP} - ${pr.Ville}</div></div>`;
      return result;
    },
    Manage_Response(result, container, Target, TargetDisplay, TargetDisplayInfoPR, loadMore) {
      if (result.PRList == null) {
        if (this.params.OnNoResultReturned) {
          this.params.OnNoResultReturned();
        }
      } else {
        for (var i = 0; i < result.PRList.length; i++) {
          result.PRList[i].Letter = String.fromCharCode('A'.charCodeAt(0) + i);
        }
        if (this.params.OnSearchSuccess) {
          this.params.OnSearchSuccess(result);
        }
      }
      let autoSelectedId = -1;
      if (result.Error == null) {
        const thisContainer = this.container;
        const mrwResults = this.container.find('.MRW-Results');
        mrwResults.slideDown('fast', () => {
          mrwResults.show();
        });
        if (!loadMore) {
          this.prLoaded = [];
        }
        let compteurPr = this.prLoaded.length;
        let listeHtml = '';
        const prList = [];
        for (var i = 0; i < result.PRList.length; i++) {
          const prId = `${result.PRList[i].Pays}-${result.PRList[i].ID}`;
          if (this.prLoaded.indexOf(prId) < 0) {
            listeHtml += this.MR_BuildItemPR(result.PRList[i], this.prLoaded.length);
            prList.push(result.PRList[i]);
            this.prLoaded.push(prId);
          }
        }
        if (!loadMore && this.params.AutoSelect) {
          autoSelectedId = this.prLoaded.indexOf(this.params.AutoSelect);
          if (autoSelectedId != -1 && (!prList[autoSelectedId] || prList[autoSelectedId].Available == false)) {
            autoSelectedId = -1;
          }
        }
        if (loadMore) {
          this.container.find('.MRW-RList').append(listeHtml).show();
        } else {
          this.container.find('.MRW-RList').html(listeHtml).show();
        }
        if (this.params.ShowResultsOnMap) {
          if (!this.mapLoaded[this.mapId]) {
            this.MR_LoadMap(this.params);
          }
          if (!loadMore) this.MR_ClearOverlays();
          for (var i = 0; i < prList.length; i++) {
            this.MR_AddMarker(this.mapLoaded[this.mapId], prList[i].Lat.replace(',', '.'), prList[i].Long.replace(',', '.'), prList[i], compteurPr, this.sw_url);
            compteurPr++;
          }
          if (!loadMore) this.MR_FitBounds();
        } else {
          $(`#${this.mapId}`, this.container).html('');
          var ins = this;
          for (var i = 0; i < result.PRList.length; i++) {
            if (this.settings.Responsive && !this.settings.ShowResultsOnMap) {
              $(this.MR_BuildParcelShopFullDetails(result.PRList[i])).insertAfter(`.PR-List-Item:eq(${i})`);
              $.data($(`.PR-List-Item:eq(${i})`)[0], 'ParcelShop', result.PRList[i]);
              $(`.PR-List-Item:eq(${i})`).bind('select', (event) => {
                ins.MR_SelectParcelShop($.data(event.target, 'ParcelShop'));
              });
            } else {
              $(`#${this.mapId}`, this.container).append(this.MR_BuildParcelShopDetails(result.PRList[i]));
              $.data($(`#${this.mapId} > div:last-child`)[0], 'ParcelShop', result.PRList[i]);
              $(`#${this.mapId} > div:last-child`).bind('select', (event) => {
                ins.MR_SelectParcelShop($.data(event.target, 'ParcelShop'));
              });
              $(`#${this.mapId} > div`, this.container).hide();
            }
          }
        }
        if (autoSelectedId != -1) {
          const $prContainer = this.container.find(`.PR-Id[value="${this.params.AutoSelect}"]`);
          const t = $prContainer.parent().offset().top - 150;
          $('.MRW-RList').scrollTop(t);
          this.MR_FocusOnMap(autoSelectedId);
        } else if (this.settings.Responsive && !loadMore) {
          this.container.find('.MRW-RList').removeClass('Hide').animate({ scrollTop: 0 }, 'fast');
        }
      } else if (!loadMore) {
        this.container.find('.MRW-Results').hide();
        this.container.find('.MRW-Errors').html(result.Error).slideDown('slow');
      }
      this.container.find('.progressBar').hide();
      this.container.find('.PR-List-Item').on('mouseover', function () {
        if (!$(this).hasClass('PR-Disabled')) {
          $(this).addClass('PR-hover');
        }
      });
      this.container.find('.PR-List-Item').on('mouseout', function () {
        $(this).removeClass('PR-hover');
      });
      if (this.settings.Responsive && this.settings.ShowResultsOnMap && this.settings.CSS == true) {
        var ins = this;
        function calculerHauteurMap() {
          const MRresults = $('.MRW-Results');
          const resultHeight = MRresults.height();
          const resultWidth = MRresults.width();
          $('.MRW-Map').height(resultHeight);
          if (!loadMore) ins.MR_FitBounds();
          if (!resultWidth || !resultHeight) {
            setTimeout(calculerHauteurMap, 1e3);
          }
        }
        setTimeout(calculerHauteurMap, 1e3);
      }
    },
    MR_LoadFromCenter() {
      if (this.prLoaded.length > 50) return;
      const zoom = this.map[this.mapId].getZoom();
      if (zoom > this.zoomDefault) return;
      const latlng = this.map[this.mapId].getCenter();
      const { container } = this;
      const { Target } = this.settings;
      const { TargetDisplay } = this.settings;
      const { TargetDisplayInfoPR } = this.settings;
      const a0 = this.settings.Brand;
      const a1 = this.container.find('input.Arg1')[0].value;
      const a3 = this.params.ColLivMod;
      const a4 = this.settings.Weight;
      const a5 = 10;
      const a6 = this.settings.SearchDelay;
      const a7 = this.settings.SearchFar;
      const a8 = this.settings.VacationBefore || '';
      const a9 = this.settings.VacationAfter || '';
      const a10 = this.settings.Service;
      const a11 = latlng.lat.toFixed(7);
      const a12 = latlng.lng.toFixed(7);
      this.jsonpcall(
        `${this.w_name}/${this.svc}/SearchPR`,
        {
          Brand: a0,
          Country: a1,
          PostCode: '',
          ColLivMod: a3,
          Weight: a4,
          NbResults: a5,
          SearchDelay: a6,
          SearchFar: a7,
          ClientContainerId: this.containerId,
          VacationBefore: a8,
          VacationAfter: a9,
          Service: a10,
          Latitude: a11,
          Longitude: a12,
          LoadMore: true,
          City: '',
        },
        function (result) {
          this.Manage_Response(result, this.container, Target, TargetDisplay, TargetDisplayInfoPR, true);
        },
      );
    },
    MR_Widget_Call(container, Target, TargetDisplay, TargetDisplayInfoPR, UseMyPosition) {
      this.container.find('.MRW-Errors').hide();
      this.container.find('.progressBar').show();
      this.container.find('.MRW-Errors').html('');
      const a0 = this.settings.Brand;
      const a1 = this.container.find('input.Arg1')[0].value;
      const a2 = this.container.find('input.Arg2')[0].value;
      const a3 = this.params.ColLivMod;
      const a4 = this.settings.Weight;
      const a5 = this.settings.NbResults;
      const a6 = this.settings.SearchDelay;
      const a7 = this.settings.SearchFar;
      const a8 = this.settings.VacationBefore || '';
      const a9 = this.settings.VacationAfter || '';
      const a10 = this.settings.Service;
      const ins = this;
      if (UseMyPosition) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude.toFixed(7);
            const longitude = position.coords.longitude.toFixed(7);
            ins.jsonpcall(
              `${ins.w_name}/${ins.svc}/SearchPR`,
              {
                Brand: a0,
                Country: a1,
                PostCode: '',
                ColLivMod: a3,
                Weight: a4,
                NbResults: a5,
                SearchDelay: a6,
                SearchFar: a7,
                ClientContainerId: ins.containerId,
                VacationBefore: a8,
                VacationAfter: a9,
                Service: a10,
                Latitude: latitude,
                Longitude: longitude,
              },
              (result) => {
                ins.Manage_Response(result, ins.container, Target, TargetDisplay, TargetDisplayInfoPR);
              },
            );
          },
          (error) => {
            alert(`Erreur Ã  l'obtention des donnÃ©es de gÃ©olocalisation : [${error.code}] ${error.message}`);
          },
          { maximumAge: 12e4 },
        );
      } else {
        const city = this.container.find('input.iArg0').val();
        this.jsonpcall(
          `${this.w_name}/${this.svc}/SearchPR`,
          {
            Brand: a0,
            Country: a1,
            PostCode: a2,
            ColLivMod: a3,
            Weight: a4,
            NbResults: a5,
            SearchDelay: a6,
            SearchFar: a7,
            ClientContainerId: this.containerId,
            VacationBefore: a8,
            VacationAfter: a9,
            Service: a10,
            Latitude: '',
            Longitude: '',
            City: city,
          },
          function (result) {
            this.Manage_Response(result, this.container, Target, TargetDisplay, TargetDisplayInfoPR);
          },
        );
      }
    },
    MR_ClearSelection() {
      this.container.find('.PR-Selected').removeClass('PR-Selected');
      $(this.settings.TargetDisplayInfoPR).html('');
      $(this.settings.Target).val('').trigger('change');
      if (this.settings.TargetDisplay) {
        $(this.settings.TargetDisplay).val('');
      }
      if (this.settings.OnParcelShopSelected) {
        const BlankPR = {
          ID: '', Nom: '', Adresse1: '', Adresse2: '', CP: '', Ville: '', Pays: '',
        };
        this.settings.OnParcelShopSelected(BlankPR);
      }
      this.map[this.mapId].closePopup();
      this.MR_RebindMap();
      $('#LockerModal').hide();
    },
    MR_CloseModalLocker() {
      $('#LockerModal').hide();
    },
    MR_SelectParcelShop(PRI) {
      if (PRI.Available) {
        $(this.settings.Target)
          .val(`${PRI.Pays}-${PRI.ID}`)
          .trigger('change');
        if (this.settings.TargetDisplay) {
          $(this.settings.TargetDisplay).val(`${PRI.Pays}-${PRI.ID}`);
        }
        if (this.settings.TargetDisplayInfoPR) {
          $(this.settings.TargetDisplayInfoPR).html(
            `${(PRI.Nom == null ? '' : `${PRI.Nom}<br/>`) + (PRI.Adresse1 == null ? '' : `${PRI.Adresse1}<br/>`) + (PRI.Adresse2 == null ? '' : `${PRI.Adresse2}<br/>`) + PRI.CP} ${PRI.Ville} ${PRI.Pays}`,
          );
        }
        this.container.find('.PR-Selected').removeClass('PR-Selected');
        this.container
          .find(`.PR-Id[Value="${PRI.Pays}-${PRI.ID}"]`)
          .parent()
          .addClass('PR-Selected');
        if (PRI.Nature == 'K') {
          $('#LockerModal').show();
        }
        if (this.settings.OnParcelShopSelected) {
          this.settings.OnParcelShopSelected(PRI);
        }
      }
    },
    MR_BuildParcelShopDetails(PRI) {
      const id1 = `${PRI.ID}_01`;
      const id2 = `${PRI.ID}_02`;
      const content = `${'<div class="InfoWindow">'
                + '<div class="PR-Name" style="font-family: \'Montserrat\',Verdana !important;font-size:1.1rem;">'}${
        PRI.Nom
      }</div>${
        PRI.Warning
      }<div class="Tabs-Btns">`
                + `<span class="Tabs-Btn Tabs-Btn-Selected" id="btn_${
                  id1
                }" onclick="MR_jQuery('#${
                  this.containerId
                }').trigger('TabSelect','${
                  id1
                }');" style="font-family: 'Montserrat',Verdana !important;">${
                  MondialRelayLanguage.Horaires
                }</span>`
                + `<span class="Tabs-Btn" id="btn_${
                  id2
                }" onclick="MR_jQuery('#${
                  this.containerId
                }').trigger('TabSelect','${
                  id2
                }');">${
                  MondialRelayLanguage.Photo
                }</span>`
                + '</div>'
                + '<div class="Tabs-Tabs">'
                + `<div class="Tabs-Tab Tabs-Tab-Selected" id="tab_${
                  id1
                }" style="font-family: 'Montserrat',Verdana !important;">${
                  PRI.HoursHtmlTable
                }</div>`
                + `<div class="Tabs-Tab" id="tab_${
                  id2
                }">`
                + `<img src="${
                  this.protocol
                }${this.img_url
                }/ww2/img/dynamique/pr.aspx?id=${
                  PRI.Pays
                }${this.MR_pad_left(PRI.ID, '0', 6)
                }" width="182" height="112" alt="No picture"/>`
                + '</div>'
                + '</div>'
                + '</div>';
      return content;
    },
    MR_BuildParcelShopFullDetails(PRI) {
      const content = `${'<div class="InfoWindow InfoWindowFull" style="display:none">'
                + '<div class="InfoWindowFull-Hours"  style="font-family: \'Montserrat\',Verdana !important;">'}${
        PRI.HoursHtmlTable
      }</div>`
                + '<div class="InfoWindowFull-Image">'
                + `<img src="${
                  this.protocol
                }${this.img_url
                }/ww2/img/dynamique/pr.aspx?id=${
                  PRI.Pays
                }${this.MR_pad_left(PRI.ID, '0', 6)
                }" alt="No picture"/>`
                + '</div>'
                + '</div>';
      return content;
    },
    MR_loadjscssfile(filename, filetype, internal) {
      // var fileref;
      // if (internal && this.version) {
      //     filename += "?v=" + this.version;
      // }
      // if (filetype == "js") {
      //     var scripts = document.getElementsByTagName("head")[0].getElementsByTagName("script");
      //     if (scripts && scripts.length) {
      //         for (var i = 0; i < scripts.length; i++) {
      //             if (scripts[i].src == filename) {
      //                 return;
      //             }
      //         }
      //     }
      //     fileref = document.createElement("script");
      //     fileref.setAttribute("type", "text/javascript");
      //     fileref.setAttribute("src", filename);
      // } else if (filetype == "css") {
      //     var links = document.getElementsByTagName("head")[0].getElementsByTagName("link");
      //     if (links && links.length) {
      //         for (var i = 0; i < links.length; i++) {
      //             if (links[i].href == filename) {
      //                 return;
      //             }
      //         }
      //     }
      //     fileref = document.createElement("link");
      //     fileref.setAttribute("rel", "stylesheet");
      //     fileref.setAttribute("type", "text/css");
      //     fileref.setAttribute("href", filename);
      // }
      // if (typeof fileref != "undefined") {
      //     document.getElementsByTagName("head")[0].appendChild(fileref);
      // }
    },
    MR_pad_left(s, c, n) {
      if (!s || !c || s.length >= n) {
        return s;
      }
      const max = (n - s.length) / c.length;
      for (let i = 0; i < max; i++) {
        s = c + s;
      }
      return s;
    },
    MR_Widget_Init(prms) {
      const ins = this;
      this.params = prms;
      const t = this.container.find('input.iArg0');
      const autoCpl = $('<div>');
      this.mapId = this.container.find('.MRW-Map').attr('id');
      this.bounds[this.mapId] = [];
      this.overlays[this.mapId] = [];
      this.overlays[this.mapId] = [];
      autoCpl.addClass('PR-AutoCplCity');
      autoCpl.css('width', t.width());
      this.container.find('.MRW-Search').append(autoCpl);
      suggest(this, 'title', 'input.Arg2', 'name', 'input.iArg0', (country, postcode) => ({ country, postcode, city: '' }));
      suggest(this, 'name', 'input.iArg0', 'title', 'input.Arg2', (country, city) => ({ country, postcode: '', city }));
      this.container.find('input.iArg0').on('blur', (event) => {
        if (ins.container.find('.AutoCpl-Hover').length) {
          ins.container.find('input.Arg2')[0].value = ins.container.find('.AutoCpl-Hover').attr('title');
          ins.container.find('input.iArg0')[0].value = ins.container.find('.AutoCpl-Hover').attr('name');
        }
      });
      this.container.find('input.Arg2').on('blur', (event) => {
        if (ins.container.find('.AutoCP-Hover').length) {
          ins.container.find('input.iArg0')[0].value = ins.container.find('.AutoCpl-Hover').attr('title');
          ins.container.find('input.Arg2')[0].value = ins.container.find('.AutoCpl-Hover').attr('name');
        }
      });
      const $btnSearch = this.container.find('.MRW-BtGo');
      $btnSearch.on('click', function () {
        const btn = $(this);
        ins.MR_Widget_Call(ins.container, ins.settings.Target, ins.settings.TargetDisplay, ins.settings.TargetDisplayInfoPR, false);
        return false;
      });
      const doSearch = function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          $btnSearch.click();
        }
      };
      this.container.find('input.Arg2').on('keyup', doSearch);
      this.container.find('input.iArg0').on('keyup', doSearch);
      if (!('geolocation' in navigator)) {
        this.params.EnableGeolocalisatedSearch = false;
      }
      if (this.params.EnableGeolocalisatedSearch) {
        this.container.find('.MRW-BtGeoGo').on('click', function () {
          const btn = $(this);
          ins.MR_Widget_Call(ins.container, ins.settings.Target, ins.settings.TargetDisplay, ins.settings.TargetDisplayInfoPR, true);
          return false;
        });
      } else {
        this.container.find('.MRW-BtGeoGo').hide();
      }
      this.container.find('.MRW-flag').on('click', function () {
        const btn = $(this);
        ins.container
          .find('.MRW-fl-Select')
          .slideToggle(200)
          .css('top', `${this.offsetTop + this.height + 2}px`)
          .css('left', `${this.offsetLeft - 3}px`);
      });
      this.container.find('.MRW-fl-Item').on('click', function () {
        const btn = $(this);
        ins.container.find('.MRW-fl-Select').slideUp('fast');
        ins.container.find('.MRW-flag').attr('src', btn.find('img').attr('src'));
        ins.container.find('input.Arg1')[0].value = btn.find('img').attr('alt');
      });
      this.container.find('input.Arg0')[0].value = prms.Brand;
      this.container.find('input.Arg1')[0].value = prms.Country;
      this.container.find('input.Arg2')[0].value = prms.PostCode;
      this.container.find('input.Arg3')[0].value = prms.ColLivMod;
      this.container.find('input.Arg4')[0].value = prms.Weight;
      this.container.find('input.Arg5')[0].value = prms.NbResults;
      this.container.find('input.Arg6')[0].value = prms.SearchDelay;
      this.container.find('input.Arg7')[0].value = prms.SearchFar;
      this.container.find('input.Arg10')[0].value = prms.Service;
      this.container.find('input.iArg0')[0].value = prms.City;
      if (prms.PostCode != '' || prms.City != '') {
        this.MR_Widget_Call(this.container, prms.Target, prms.TargetDisplay, prms.TargetDisplayInfoPR, false);
      }
    },
    MR_Destroy(Div, prms) {
      this.container = $(Div);
      this.container.find('input.Arg2').unbind('keydown');
      this.container.find('input.Arg2').unbind('keyup');
      this.container.find('input.iArg0').unbind('keydown');
      this.mapLoaded[this.mapId] = false;
    },
    MR_SetParams(params) {
      if (this.params == null) {
        console.info('MR_SetParams() method has been called too early.');
      } else {
        this.settings = $.extend(this.params, params);
        this.params = $.extend(this.params, params);
      }
    },
    MR_DoSearch(PostCode, Country) {
      this.container.find('input.Arg2').val(PostCode);
      this.container.find('input.Arg1').val(Country);
      this.MR_Widget_Call(this.container, this.settings.Target, this.settings.TargetDisplay, this.settings.TargetDisplayInfoPR, false);
    },
    MR_tabselect(tab) {
      const tabInfo = tab.split('_');
      const oldTab = `${tabInfo[0]}_${tabInfo[1] === '01' ? '02' : '01'}`;
      $(`#btn_${oldTab}`, this.container).removeClass('Tabs-Btn-Selected');
      $(`#btn_${tab}`, this.container).addClass('Tabs-Btn-Selected');
      $(`#tab_${oldTab}`, this.container).removeClass('Tabs-Tab-Selected');
      $(`#tab_${tab}`, this.container).addClass('Tabs-Tab-Selected');
    },
  });
  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, `plugin_${pluginName}`)) {
        $.data(this, `plugin_${pluginName}`, new MR_ParcelShopPicker(this, options));
      }
    });
  };
}(jQuery, window, document));

export default jQuery;
