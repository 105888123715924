import { GET_TOKEN, REFRESH_TOKEN, LOGOUT } from 'logic/actions/authActions';
import {
  REJECT_TRANSACTION,
  CANCEL_TRANSACTION,
  EVALUATE_TRANSACTION,
} from 'logic/actions/transactionsActions';

export function success(action) {
  return `${action}_SUCCESS`;
}
export function fail(action) {
  return `${action}_FAIL`;
}

const shouldResetStateRegExp = new RegExp(
  `${GET_TOKEN}|${fail(REFRESH_TOKEN)}|${success(LOGOUT)}|${fail(LOGOUT)}`,
);

const shouldResetTransactionsStateRegExp = new RegExp(
  `${success(REJECT_TRANSACTION)}|${success(EVALUATE_TRANSACTION)}|${success(
    CANCEL_TRANSACTION,
  )}`,
);

export function shouldResetState({ type = '' } = {}) {
  return type.match(shouldResetStateRegExp);
}

export function shouldResetTransactionsState({ type = '' } = {}) {
  return (
    type.match(shouldResetStateRegExp)
    || type.match(shouldResetTransactionsStateRegExp)
  );
}
