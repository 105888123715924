import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/shippingLabelModal.module.scss';
import { Modal } from 'react-bootstrap';
import CheckoutAddressForm from 'components/CheckoutAddressForm';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUserAddress } from 'logic/actions/meActions';
import useParseUserAddress from 'hooks/useParseUserAddress';

const ShippingLabelModal = ({
  allowedCountries, show, onHide, onSubmit,
}) => {
  const { t } = useTranslation('shippingLabelModal');
  const { parseUserAddress } = useParseUserAddress();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback((senderAddress) => {
    setLoading(true);
    onSubmit(senderAddress).finally(() => setLoading(false));
  }, [onSubmit]);

  const currentUserAddress = useSelector(selectCurrentUserAddress);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={classes.shippingLabelModal}
      dialogClassName={classes.shippingLabelModal__dialog}
      contentClassName={classes.shippingLabelModal__content}
    >
      <Modal.Header closeButton />
      <h4 className={classes.shippingLabelModal__title}>{t('title')}</h4>
      <Modal.Body>
        <CheckoutAddressForm
          allowedCountries={allowedCountries}
          deliveryAddress={parseUserAddress(currentUserAddress)}
          translation="shippingLabelModal"
          loading={loading}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

ShippingLabelModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ShippingLabelModal;
