import React from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AddPromocodeButton = ({
  children, eventKey, className, cb,
}) => {
  const handleOpen = useAccordionToggle(eventKey, cb);

  return (
    <button className={className} onClick={handleOpen} type="button">
      {children}
    </button>
  );
};

AddPromocodeButton.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired,
  cb: PropTypes.func.isRequired,
};

export default AddPromocodeButton;
