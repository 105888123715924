import classes from 'assets/style/signupForm.module.scss';
import Button from 'components/Button';
import { Formik } from 'formik';
import { ERROR_EMAIL_ALREADY_EXISTS } from 'helpers/constants';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ErrorMessagesBlock from 'components/ErrorMessagesBlock';
import FormReset from 'components/FormReset';
import PasswordInput, {
  usePasswordStrengthTest,
} from 'components/PasswordInput';

const SignupForm = ({
  onSubmit,
  checkEmail,
  isCheckoutPage,
  setShowSignup = {},
  referer,
  externalId,
  transactionId,
}) => {
  const { t } = useTranslation(['signupForm', '_links']);
  const [previousEmail, setPreviousEmail] = useState('');
  const [previousEmailTestResult, setPreviousEmailTestResult] = useState(true);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        firstName: yup.string().required(t('emptyFirstName')),
        lastName: yup.string().required(t('emptyLastName')),
        email: yup
          .string()
          .required(t('emptyEmail'))
          .email(t('invalidEmail'))
          .test({
            name: 'emailValid',
            test: (newEmail, { createError }) => {
              if (!newEmail || newEmail === previousEmail) {
                return previousEmailTestResult;
              }
              setPreviousEmail(newEmail);
              return checkEmail(newEmail)
                .then(() => {
                  setPreviousEmailTestResult(true);
                  return true;
                })
                .catch(
                  ({
                    error: { response: { data: { errors } = {} } = {} } = {},
                  }) => {
                    let result = createError({ message: t('invalidEmail') });
                    if (errors === ERROR_EMAIL_ALREADY_EXISTS) {
                      result = createError({ message: t('alreadyUsedEmail') });
                    }
                    setPreviousEmailTestResult(result);
                    return result;
                  },
                );
            },
          }),
        password: yup
          .string()
          .required(t('emptyPassword'))
          .min(10, t('invalidPassword'))
          .test(usePasswordStrengthTest()),
        agreeTerms: yup.boolean().oneOf([true]),
      })}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        agreeTerms: false,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form className={classes.container} noValidate onSubmit={handleSubmit}>
          <FormReset
            defaults={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              agreeTerms: false,
            }}
          />
          <div>
            <Form.Row>
              <Form.Group as={Col} controlId="firstName">
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.firstName && !!errors.firstName}
                  placeholder={t('firstName')}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="lastName">
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.lastName && !!errors.lastName}
                  placeholder={t('lastName')}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group md="4" controlId="email">
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                  placeholder={t('email')}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                md="4"
                controlId="password"
                className={classes.pwdFormGroup}
              >
                <PasswordInput
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                  placeholder={t('password')}
                  showStrenghtBar
                />
              </Form.Group>
            </Form.Row>
          </div>
          {!!Object.keys(errors).length && (
            <ErrorMessagesBlock skipErrors={['agreeTerms']} />
          )}
          <Form.Row>
            <Form.Check
              type="checkbox"
              id="agreeTerms"
              className={classes.agreeTerms}
            >
              <Form.Check.Input
                name="agreeTerms"
                value
                checked={values.agreeTerms}
                onChange={() => {
                  setFieldValue('agreeTerms', !values.agreeTerms);
                }}
                className={classes.agreeTermsInput}
                isInvalid={touched.agreeTerms && !!errors.agreeTerms}
              />
              <Form.Check.Label>
                <Trans t={t} i18nKey="agreeTerms">
                  I agree to the
                  <a
                    href={t('_links:terms')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and conditions
                  </a>
                  and the
                  <a
                    href={t('_links:privacyPolicy')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy policy
                  </a>
                  of Tripartie.
                </Trans>
              </Form.Check.Label>
            </Form.Check>
          </Form.Row>
          <Form.Row>
            <Button type="submit" isLoading={isSubmitting}>
              {t('submit')}
            </Button>
          </Form.Row>
          <p className={classes.switchLoginSignup}>
            <Trans t={t} i18nKey="login">
              Already have an account?
              {isCheckoutPage ? (
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() => setShowSignup(false)}
                >
                  login
                </Button>
              ) : (
                <Link
                  to={{
                    pathname: '/login',
                    state: {
                      referer: externalId ? referer : undefined,
                      externalId,
                      transactionId,
                    },
                  }}
                >
                  login
                </Link>
              )}
            </Trans>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
