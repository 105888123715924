/* eslint-disable import/no-unused-modules */
import { getBase64FromBlob } from 'helpers/decode';

export const CREATE_TRANSACTION_TEMPLATE = 'app/transactionTemplates/CREATE_TRANSACTION_TEMPLATE';
export const GET_TRANSACTION_TEMPLATE = 'app/transactionTemplates/GET_TRANSACTION_TEMPLATE';
export const GET_TRANSACTION_TEMPLATE_BY_UUID = 'app/transactionTemplates/GET_TRANSACTION_TEMPLATE_BY_UUID';
export const GET_OWN_ACTIVE_TRANSACTION_TEMPLATES = 'app/transactionTemplates/GET_OWN_ACTIVE_TRANSACTION_TEMPLATES';
export const FETCH_TRANSACTIONS = 'app/transactionTemplates/FETCH_TRANSACTIONS';
export const FETCH_TRANSACTION_TEMPLATE_PICTURE = 'app/transactionTemplates/FETCH_TRANSACTION_TEMPLATE_PICTURE';
export const DRY_RUN_TRANSACTION_FROM_TEMPLATE = 'app/transactionTemplates/DRY_RUN_TRANSACTION_FROM_TEMPLATE';
export const CREATE_TRANSACTION_FROM_TEMPLATE = 'app/transactionTemplates/CREATE_TRANSACTION_FROM_TEMPLATE';
export const DELETE_TRANSACTION_TEMPLATE = 'app/transactionTemplates/DELETE_TRANSACTION_TEMPLATE';
/* eslint-enable import/no-unused-modules */

export const createTransactionTemplate = (data) => (dispatch) => {
  if (data.picture) {
    return getBase64FromBlob(data.picture).then((base64) => dispatch({
      type: CREATE_TRANSACTION_TEMPLATE,
      payload: {
        request: {
          method: 'POST',
          url: '/api/webapp/transaction-templates/',
          data: {
            ...data,
            picture: base64.split(',')[1],
          },
        },
      },
    }));
  }
  return dispatch({
    type: CREATE_TRANSACTION_TEMPLATE,
    payload: {
      request: {
        method: 'POST',
        url: '/api/webapp/transaction-templates/',
        data,
      },
    },
  });
};

export const getTransactionTemplate = (transactionTemplateId) => ({
  type: GET_TRANSACTION_TEMPLATE,
  transactionTemplateId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/webapp/transaction-templates/${transactionTemplateId}/`,
    },
  },
});

export const getTransactionTemplateByUuid = (transactionTemplateUuid) => ({
  type: GET_TRANSACTION_TEMPLATE_BY_UUID,
  payload: {
    request: {
      method: 'GET',
      url: `/api/webapp/transaction-templates/${transactionTemplateUuid}/`,
    },
  },
});

export const getOwnTransactionTemplates = (page = 1) => ({
  type: GET_OWN_ACTIVE_TRANSACTION_TEMPLATES,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/transaction-templates/',
      params: { page },
    },
  },
});

export const fetchTransactionTemplatePicture = (transactionTemplateId) => ({
  type: FETCH_TRANSACTION_TEMPLATE_PICTURE,
  payload: {
    request: {
      method: 'GET',
      url: `/api/cached/transaction-templates/${transactionTemplateId}/picture/`,
      responseType: 'blob',
    },
  },
});

export const dryRunTransactionFromTemplate = (
  transactionTemplateId,
  data = {},
) => ({
  type: DRY_RUN_TRANSACTION_FROM_TEMPLATE,
  transactionTemplateId,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/transaction-templates/${transactionTemplateId}/transactions/?dry-run`,
      data,
    },
  },
});

export const createTransactionFromTemplate = (
  transactionTemplateId,
  data = {},
) => ({
  type: CREATE_TRANSACTION_FROM_TEMPLATE,
  transactionTemplateId,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/transaction-templates/${transactionTemplateId}/transactions/`,
      data,
    },
  },
});

export const deleteTransactionTemplate = (transactionTemplateId) => ({
  type: DELETE_TRANSACTION_TEMPLATE,
  transactionTemplateId,
  payload: {
    request: {
      method: 'DELETE',
      url: `/api/webapp/transaction-templates/${transactionTemplateId}/`,
    },
  },
});

export const selectTemplatesLoading = (state) => state.persistent.transactionTemplatesReducer.loading;

export const selectTemplatesItems = (state) => state.persistent.transactionTemplatesReducer.items;
export const selectTemplatesCount = (state) => state.persistent.transactionTemplatesReducer.count;
export const selectTemplatesLastLoadedPage = (state) => state.persistent.transactionTemplatesReducer.lastLoadedPage;
export const selectTemplatesNbPages = (state) => state.persistent.transactionTemplatesReducer.nbPages;

export const selectTemplatesLoadedPages = (state) => Object.keys(state.persistent.transactionTemplatesReducer.items).map(
  (pageNumber) => Number(pageNumber),
);
