const {
  REACT_APP_ENV_TYPE,
  REACT_APP_ENV_NAME,
  REACT_APP_BASE_URL,
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_SENTRY_DSN,
  REACT_APP_DEBUG,
  REACT_APP_HASH,
  REACT_APP_MONDIAL_RELAY_ID,
  REACT_APP_SENTRY_ENVIRONMENT_NAME,
  REACT_APP_SENTRY_RELEASE_VERSION,
} = process.env;

const envType = REACT_APP_ENV_TYPE || 'dev';
const isProd = envType === 'prod';
const isPreprod = envType === 'preprod';
const isDev = envType === 'dev';

const envName = REACT_APP_ENV_NAME;
const baseUrl = REACT_APP_BASE_URL || '/';
const apiUrl = REACT_APP_API_URL;
const clientId = REACT_APP_CLIENT_ID;
const clientSecret = REACT_APP_CLIENT_SECRET;
const sentryDSN = REACT_APP_SENTRY_DSN;
const debug = REACT_APP_DEBUG === 'true';
const hash = REACT_APP_HASH || '';
const mondialRelayId = REACT_APP_MONDIAL_RELAY_ID || '';
const sentryEnvironmentName = REACT_APP_SENTRY_ENVIRONMENT_NAME || 'develop';
const sentryReleaseVersion = REACT_APP_SENTRY_RELEASE_VERSION || 'preview';

export {
  isProd,
  isPreprod,
  isDev,
  envName,
  baseUrl,
  apiUrl,
  clientId,
  clientSecret,
  sentryDSN,
  debug,
  hash,
  mondialRelayId,
  sentryEnvironmentName,
  sentryReleaseVersion,
};
