// Intl import, don't move this !!
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/es';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/es';
import { IntlProvider } from 'react-intl';
// Intl import
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { useCookies } from 'react-cookie';
import { BrowserTracing } from '@sentry/tracing';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import PrivateRoute from 'helpers/PrivateRoute';
import LoadingScreen from 'components/LoadingScreen';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import { refreshMe } from 'logic/actions/meActions';
import { getOwnActiveTransactions } from 'logic/actions/transactionsActions';
import { sentryDSN, sentryEnvironmentName, sentryReleaseVersion } from 'helpers/env';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import Transactions from 'pages/Transactions';
import Settings from 'pages/Settings';
import PostProcessPreauth from 'pages/PostProcessPreauth';
import Transaction from 'pages/Transaction';
import Checkout from 'pages/checkout';
import Dashboard from 'pages/Dashboard';
import DisputeResolution from 'pages/DisputeResolution';
import {
  ROUTES,
  TAKE_PICTURES_ROUTES,
  LANG_COOKIE_NAME,
  LANG_COOKIE_OPT,
} from 'helpers/constants';
import ResetPasswordRoutes from 'pages/resetPassword/ResetPasswordRoutes';
import useRedirectFromWebapp from 'hooks/useRedirectFromWebapp';
import PrivateMobileOnlyRoute from 'helpers/PrivateMobileOnlyRoute';
import TakePicturesRoutes from 'components/takePictures/TakePicturesRoutes';

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new BrowserTracing()],
    environment: sentryEnvironmentName,
    release: sentryReleaseVersion,
    tracesSampleRate: 1.0,
    beforeSend: (event = {}) => {
      const { extra: { __serialized__: { error = {} } = {} } = {} } = event; /* eslint-disable-line no-underscore-dangle */

      if (
        error?.message
          && error.message.match(/request failed with status code/i)
      ) {
        return null;
      }

      if (error && typeof error.status === 'number' && [0, 400, 401, 403, 404, 500].includes(error.status)) {
        return null;
      }

      return event;
    },
  });
  Sentry.setUser({ email: 'Not logged in' });
}

const App = ({ getOwnActiveTransactions, refreshMe, loggedIn }) => {
  const { t, i18n } = useTranslation('app');
  const { pathname, search } = useLocation();
  const setLoadingScreen = useLoadingScreen();
  const [cookies, setCookie] = useCookies([LANG_COOKIE_NAME]);
  const isCheckout = /secured-payment|post-process-preauth/.test(pathname);
  const isTransactionsPage = /transactions/.test(pathname);

  useRedirectFromWebapp(isCheckout);

  useEffect(() => {
    const cookieLang = cookies[LANG_COOKIE_NAME];
    const browserLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';

    if (cookieLang !== browserLang) {
      i18n.changeLanguage(cookieLang);
    }
    if (!cookieLang) {
      setCookie(LANG_COOKIE_NAME, browserLang, LANG_COOKIE_OPT);
    }
  }, [
    cookies,
    setCookie,
    i18n,
  ]);

  useEffect(() => {
    const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';
    /* eslint-disable no-underscore-dangle */
    window._axcb = window._axcb || [];
    window._axcb.push((sdk) => {
      sdk.setCookiesVersion(currentLang);
    });
    /* eslint-enable no-underscore-dangle */
  }, [i18n.language]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('send', 'page_view', {
        page_path: pathname + search,
      });
    }
  }, [pathname, search]);

  useEffect(() => {
    if (!isCheckout) {
      setLoadingScreen(!loggedIn);
      refreshMe()
        .then(() => {
          if (!isCheckout && !isTransactionsPage) {
            return getOwnActiveTransactions(1);
          }
          return Promise.resolve();
        })
        .finally(() => {
          setLoadingScreen(false);
        });
    }
  }, [
    getOwnActiveTransactions,
    isCheckout,
    isTransactionsPage,
    loggedIn,
    refreshMe,
    setLoadingScreen,
  ]);

  return (
    <IntlProvider locale={i18n.language}>
      <HelmetProvider>
        <Helmet>
          <title>Tripartie</title>
          <meta name="description" content={t('websiteDescription')} />
        </Helmet>
        <LoadingScreen />
        <Container
          className="root-container d-flex flex-column min-vh-100"
          fluid="md"
        >
          <div className="d-flex min-vh-100">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route
                path={ROUTES.RESET_PASSWORD.MAIN}
                component={ResetPasswordRoutes}
              />
              <Route
                exact
                path="/secured-payment/:templateUuid"
                component={Checkout}
              />
              <PrivateRoute
                exact
                path="/transactions/:transactionId/post-process-preauth"
                component={PostProcessPreauth}
              />
              <PrivateRoute
                exact
                path="/transactions"
                component={Transactions}
              />
              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute
                exact
                path="/transactions/:transactionId"
                component={Transaction}
              />
              <PrivateRoute
                exact
                path="/transactions/:transactionId/dispute"
                component={DisputeResolution}
              />
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateMobileOnlyRoute
                path={TAKE_PICTURES_ROUTES.COMMON}
                component={TakePicturesRoutes}
              />
            </Switch>
          </div>
        </Container>
      </HelmetProvider>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  getOwnActiveTransactions: bindActionCreators(
    getOwnActiveTransactions,
    dispatch,
  ),
  refreshMe: bindActionCreators(refreshMe, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
