import * as React from 'react';

const IconNum = ({
  step,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M12.004 0c-9.6 0-12 2.4-12 12s2.4 12 12 12 12-2.4 12-12-2.4-12-12-12z"
      fill="var(--primary)"
      fillRule="evenodd"
    />
    <text
      x="50%"
      y="55%"
      textAnchor="middle"
      dominantBaseline="middle"
      fill="#fff"
      fontSize={16.941}
      fontFamily="CircularStd-Bold,Circular Std"
      fontWeight={700}
    >
      {step}
    </text>
  </svg>
);

export default IconNum;
