import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode';
import { isIOS, isMobile, isSafari } from 'react-device-detect';
import NotAvailableModal from 'components/takePictures/NotAvailableModal';
import { TAKE_PICTURES_ROUTES } from 'helpers/constants';
import { toast } from 'react-toastify';
import classes from 'assets/style/takePictures/gotoTakePicturesBlock.module.scss';
import BulbIcon from 'assets/svg/BulbIcon';
import { useTranslation } from 'react-i18next';

const GotoTakePicturesBlock = ({ transactionId, role }) => {
  const [showModal, setShowModal] = useState(false);
  const [qrUrl, setQrUrl] = useState('');

  const { t } = useTranslation('takePictures');

  const history = useHistory();

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const generateQrCode = useCallback(
    (sourceLink) => QRCode.toDataURL(sourceLink)
      .then((dataUrl) => {
        setQrUrl(dataUrl);
      })
      .catch((err) => {
        toast(err);
      }),
    [],
  );

  const qrCodeUrl = window.location.href;

  const gotoTakePictures = useCallback(() => {
    if (isMobile) {
      if (isIOS && !isSafari) {
        toast.error(t('errors.onlySafari'));
      } else {
        history.push({
          pathname: TAKE_PICTURES_ROUTES.FIRST_SCREEN,
          state: {
            previousPath: window.location.pathname,
            role,
            transactionId,
          },
        });
      }
    } else {
      generateQrCode(qrCodeUrl).then(() => setShowModal(true));
    }
  }, [
    transactionId,
    qrCodeUrl,
    generateQrCode,
    history,
    role,
    t,
  ]);

  return (
    <>
      <NotAvailableModal show={showModal} onHide={closeModal} qrCode={qrUrl} />
      <div className={classes.gotoTakePictures}>
        <BulbIcon className={classes.gotoTakePictures__icon} />
        <div className={classes.gotoTakePictures__text}>
          {t('gotoTakePicturesBlock.text')}
          <button type="button" onClick={gotoTakePictures}>
            {t('gotoTakePicturesBlock.link')}
          </button>
        </div>
      </div>
    </>
  );
};

GotoTakePicturesBlock.propTypes = {
  transactionId: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
};

export default GotoTakePicturesBlock;
