import React from 'react';
import PropTypes from 'prop-types';
import classes from 'assets/style/transactionMessage.module.scss';
import { FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCurrentUserId } from 'logic/actions/meActions';
import { useTranslation } from 'react-i18next';

const TransactionMessage = ({ message }) => {
  const {
    message: text, createdAt, author, authorId,
  } = message;

  const { t } = useTranslation('transactionMessage');

  const currentUserId = useSelector(selectCurrentUserId);

  return (
    <div className={classes.transactionMessage}>
      <div className={classes.transactionMessage__message}>{text}</div>
      <div className={classes.transactionMessage__description}>
        <div className={classes.transactionMessage__circle} />
        <div className={classes.transactionMessage__name}>
          {currentUserId === authorId ? t('you') : author}
        </div>
        <div className={classes.transactionMessage__date}>
          <FormattedDate
            value={new Date(createdAt)}
            year="numeric"
            month="long"
            day="2-digit"
            minute="2-digit"
            hour="2-digit"
          />
        </div>
      </div>
    </div>
  );
};

TransactionMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorId: PropTypes.number.isRequired,
  }).isRequired,
};

export default TransactionMessage;
