import React from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const Button = ({
  onClick = () => {},
  isLoading = false,
  disabled = false,
  className = '',
  textClassName = '',
  children,
  ...props
}) => (
  <BootstrapButton
    onClick={onClick}
    onKeyPress={onClick}
    className={`${className} ${isLoading ? 'btn-loading' : ''}`}
    disabled={isLoading || disabled}
    {...props}
  >
    <span className={`${textClassName} btn-content`}>{children}</span>
    {isLoading && (
      <Spinner as="span" animation="border" className="btn-spinner" />
    )}
  </BootstrapButton>
);

export default Button;
