import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import checkIcon from 'assets/images/check-round-icon.svg';
import classes from 'assets/style/takePictures/buttonWithCheckedIcon.module.scss';

const ButtonWithCheckedIcon = ({ handleClick }) => (
  <Button onClick={handleClick} className={classes.buttonWithCheckedIcon}>
    <img src={checkIcon} alt="checked-icon" />
    <span>Proceed to next step</span>
  </Button>
);

ButtonWithCheckedIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default ButtonWithCheckedIcon;
