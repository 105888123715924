import React from 'react';
import Form from 'react-bootstrap/Form';

const CheckoutRadioButton = ({
  title,
  subTitle,
  Icon,
  className = '',
  bottomPanel,
  ...props
}) => (
  <div className="checkout-radio-button mb-2 pb-1 pb-lg-0 mb-lg-4">
    <label
      className={`border clickable ${className} ${
        !bottomPanel ? 'rounded' : 'rounded-up'
      }`}
    >
      <Form.Check.Input type="radio" {...props} />
      <span className="pl-2">{title}</span>
      {subTitle && <span className="pl-2 text-secondary">{subTitle}</span>}
      {Icon ? <Icon className="ml-auto checkout-svg" /> : ''}
    </label>
    {bottomPanel && (
      <div className="mb-0 mb-lg-3 bottomPanelContainer border border-top-0 rounded-down ">
        {bottomPanel}
      </div>
    )}
  </div>
);

export default CheckoutRadioButton;
