import React from 'react';
import classes from 'assets/style/disputeResolutionHeader.module.scss';
import LogoTripartie from 'assets/svg/LogoTripartie';
import { useTranslation } from 'react-i18next';

const DisputeResolutionHeader = ({ nbSteps, currentStep, showProgress }) => {
  const { t } = useTranslation('disputeResolutionHeader');

  return (
    <div className={classes.container}>
      {showProgress && (
        <div className={classes.progressBar}>
          {Array.from(Array(nbSteps).keys()).map((i) => (
            <div
              key={`step-${i}`}
              className={
                i < currentStep
                  ? classes.progressActive
                  : classes.progressInactive
              }
            />
          ))}
          <p className={classes.progressTitle}>
            {t('progress', { currentStep, nbSteps })}
          </p>
        </div>
      )}
      <div className={classes.logoContainer}>
        <LogoTripartie fill="#004bb4" className={classes.logo} />
        <p className={classes.title}>{t('title')}</p>
      </div>
    </div>
  );
};

export default DisputeResolutionHeader;
