import * as React from 'react';

const IconDelivery1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.502 24.948"
    {...props}
  >
    <defs>
      <style>
        {'.icon-delivery-1_svg__a,.icon-delivery-1_svg__b{fill:none;stroke:#050038;stroke-linecap:round;stroke-linejoin:round;stroke-width:.947px}.icon-delivery-1_svg__b{fill-rule:evenodd}'}
      </style>
    </defs>
    <g transform="translate(-1264.981 -259.037)">
      <path
        className="icon-delivery-1_svg__a"
        d="M1268.694 275.255a13.735 13.735 0 013.89-.556c2.2 0 3.01 1.428 4.2 1.428h2.544a1.439 1.439 0 010 2.878h-2.486a3.423 3.423 0 00-1.829.893"
      />
      <rect
        className="icon-delivery-1_svg__a"
        width={3.239}
        height={10.205}
        rx={0.634}
        transform="translate(1265.455 273.307)"
      />
      <path
        className="icon-delivery-1_svg__a"
        d="M1280.772 277.566l8.031-2.294a.995.995 0 011.193.954.92.92 0 01-.62.954l-9.635 5.2a5.589 5.589 0 01-3.291.287l-3.577-1.05a7.355 7.355 0 00-4.179-.046"
      />
      <path
        className="icon-delivery-1_svg__b"
        d="M1269.824 274.975a29.64 29.64 0 01-.437-5.468 27.086 27.086 0 01.539-5.921 27.779 27.779 0 019.16-4.079v.006a27.769 27.769 0 019.161 4.078M1288.248 263.586a32.795 32.795 0 010 11.842"
      />
      <path
        className="icon-delivery-1_svg__b"
        d="M1284.443 269.65a22.6 22.6 0 00-.5-4.15 29.444 29.444 0 00-9.711-4.324"
      />
      <path
        className="icon-delivery-1_svg__a"
        d="M1279.087 276.127v-8.473l-9.161-4.064 9.161 4.064 9.161-4.064"
      />
    </g>
  </svg>
);

export default IconDelivery1;
