import { useCallback, useState } from 'react';
import { CONTENT_TYPE } from 'helpers/constants';

const useTakePicturesButtons = () => {
  const [contentType, setContentType] = useState(CONTENT_TYPE.PHOTO);
  const [isRecordingVideo, setIsRecordingVideo] = useState(false);

  const changeContentType = useCallback(() => {
    setContentType((prev) => (prev === CONTENT_TYPE.PHOTO ? CONTENT_TYPE.VIDEO : CONTENT_TYPE.PHOTO));
    setIsRecordingVideo(false);
  }, []);

  return {
    contentType,
    isRecordingVideo,
    setIsRecordingVideo,
    changeContentType,
  };
};

export default useTakePicturesButtons;
