import { useTranslation } from 'react-i18next';

const useCurrencyHelpers = () => {
  const { i18n } = useTranslation();

  return (currency) => {
    const parts = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency,
    }).formatToParts(1);

    let symbol = 'currency';
    const before = parts.findIndex((item) => {
      if (item.type === 'currency') {
        symbol = item.value;
        return true;
      }
      return false;
    }) === 0;

    return {
      symbol,
      before,
    };
  };
};

export default useCurrencyHelpers;
