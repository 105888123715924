/* eslint-disable import/no-unused-modules */
export const HANDLE_GREETING_MODAL = 'auth/HANDLE_GREETING_MODAL';
export const REFRESH_ME = 'me/REFRESH_ME';
export const UPDATE_ME = 'me/UPDATE_ME';
export const UPDATE_MY_PASSWORD = 'me/UPDATE_MY_PASSWORD';
export const UPDATE_MY_LANG = 'me/UPDATE_MY_LANG';
export const DELETE_MY_ACCOUNT = 'me/DELETE_MY_ACCOUNT';
export const ADD_AVATAR = 'me/ADD_AVATAR';
export const DELETE_AVATAR = 'me/DELETE_AVATAR';
export const GET_AVATAR = 'me/GET_AVATAR';
export const GET_AVATAR_ID = 'me/GET_AVATAR_ID';
/* eslint-enable import/no-unused-modules */

export const handleGreetingModal = (show) => ({
  type: HANDLE_GREETING_MODAL,
  show,
});

export const getAvatar = () => ({
  type: GET_AVATAR,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/users/profile-picture/get/',
    },
  },
});

export const refreshMe = () => (dispatch) => dispatch({
  type: REFRESH_ME,
  payload: {
    request: {
      method: 'GET',
      url: '/api/webapp/users/me/',
    },
  },
}).then(({ payload: { data: { hasProfilePicture = false } = {} } = {} }) => {
  if (hasProfilePicture) {
    return dispatch(getAvatar()).catch(() => { /* Do nothing */ });
  }
  return Promise.resolve();
});

export const updateMe = (data) => ({
  type: UPDATE_ME,
  payload: {
    request: {
      method: 'PATCH',
      url: '/api/webapp/users/me/',
      data,
    },
  },
});

export const updateMyPassword = (data) => ({
  type: UPDATE_MY_PASSWORD,
  payload: {
    request: {
      method: 'PATCH',
      url: '/api/webapp/users/change-password/',
      data,
    },
  },
});

export const updateMyLang = (lang) => ({
  type: UPDATE_MY_LANG,
  payload: {
    request: {
      method: 'PATCH',
      url: '/api/webapp/users/change-language/',
      data: { lang },
    },
  },
});

export const deleteMyAccount = () => ({
  type: DELETE_MY_ACCOUNT,
  payload: {
    request: {
      method: 'DELETE',
      url: '/api/webapp/users/me/',
    },
  },
});

export const addAvatar = (id) => ({
  type: ADD_AVATAR,
  payload: {
    request: {
      method: 'POST',
      url: `/api/webapp/users/profile-picture/${id}/upload/`,
    },
  },
});

export const deleteAvatar = () => ({
  type: DELETE_AVATAR,
  payload: {
    request: {
      method: 'DELETE',
      url: '/api/webapp/users/profile-picture/delete/',
    },
  },
});

export const loggedIn = (state) => state.persistent.meReducer.loggedIn;
export const selectGreetingModal = (state) => state.persistent.meReducer.greetingModal;
export const avatarUrl = (state) => state.persistent.meReducer?.avatarUrl;

export const selectCurrentUserId = (state) => state.persistent.meReducer?.me?.id;
export const selectCurrentUserVerifyStatus = (state) => state.persistent.meReducer?.me?.identityVerifyStatus;
export const selectCurrentUserAddress = (state) => state.persistent.meReducer?.me?.address;
