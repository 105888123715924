import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import { Range } from 'react-range';
import { FormattedNumber } from 'react-intl';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import { ReactComponent as Cursor } from 'assets/images/disputeResolution/rangeCursor.svg';

export const SOLUTION_RETURN = 'solution_return';
export const SOLUTION_PARTIAL = 'solution_partial';

const ChooseRefund = ({
  transaction: { subTotal, currency } = {},
  proposedRefund = 0,
  back,
  next,
}) => {
  const { t } = useTranslation('disputeResolution');
  const maxRefund = subTotal / 2;

  const [value, setValue] = useState(proposedRefund);

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>{t('chooseRefund.title')}</p>
      <div className={classes.form}>
        <Range
          step={100}
          min={0}
          max={maxRefund}
          values={[value]}
          onChange={([value]) => {
            setValue(value);
          }}
          renderTrack={({ props, children }) => (
            <div {...props} className={classes.rangeTrack}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} className={classes.rangeThumb}>
              <p className={classes.label}>
                <FormattedNumber
                  value={value / 100}
                  style="currency"
                  currency={currency}
                />
              </p>
              <Cursor />
            </div>
          )}
        />
        <div className={classes.rangeMinMaxLabels}>
          <p>0</p>
          <p>{maxRefund / 100}</p>
        </div>
      </div>
      <Button
        className={classes.nextButton}
        onClick={() => {
          next(value);
        }}
        disabled={!value}
      >
        {t('chooseRefund.next')}
      </Button>
    </Row>
  );
};

export default ChooseRefund;
