import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/takePictures/processMediaButtons.module.scss';
import closeIcon from 'assets/images/close-cross.svg';
import Button from 'components/Button';

const ProcessCurrentMediaButtons = ({ handleDelete, handleClose }) => {
  const { t } = useTranslation('takePictures');

  return (
    <div className={classes.processCurrentMediaButtons}>
      <Button
        variant="light"
        className={classes.processCurrentMediaButtons__closeBtn}
        onClick={handleClose}
      >
        <img src={closeIcon} alt="closeIcon" />
      </Button>
      <Button
        type="button"
        variant="light"
        onClick={handleDelete}
        className={classes.processCurrentMediaButtons__deleteBtn}
      >
        {t('delete')}
      </Button>
    </div>
  );
};

ProcessCurrentMediaButtons.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};

export default ProcessCurrentMediaButtons;
