import React from 'react';

const DownArrowWithLine = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.414"
    height="21.414"
    viewBox="0 0 21.414 21.414"
    {...props}
  >
    <defs>
      <style>
        {'.a,.b{fill:none;stroke:#8a93a8;stroke-width:2px;}.a{stroke - miterlimit:10;}'}
      </style>
    </defs>
    <g transform="translate(-777.793 -1948.5)">
      <path
        className="a"
        d="M-2970-5839l-10-10,10-10"
        transform="translate(6637.5 -1011.5) rotate(-90)"
      />
      <line className="b" y1="20" transform="translate(788.5 1948.5)" />
    </g>
  </svg>
);

export default DownArrowWithLine;
