import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AddBankAccountModal from 'components/AddBankAccountModal';
import useWindowSize from 'helpers/useWindowSize';
import {
  CARD_PROVIDER_VISA,
  CARD_PROVIDER_MASTERCARD,
  CARD_PROVIDER_CB,
} from 'helpers/constants';
import { refreshMe } from 'logic/actions/meActions';
import {
  getOwnBankAccounts,
  deleteBankAccount,
  createBankAccount,
} from 'logic/actions/bankAccountsActions';
import {
  getOwnPaymentCards,
  deleteCard,
} from 'logic/actions/paymentCardsActions';
import { getCountries } from 'logic/actions/countriesActions';
import BankAccountForm from 'components/BankAccountForm';
import iconAddPlus from 'assets/images/icon-add-plus.svg';
// import iconInfo from 'assets/images/icon-info.svg';
import iconLeft from 'assets/images/icon-left.svg';
import IconPaymentMethodCB from 'assets/images/icon-payment-method-cb.svg';
import IconPaymentMethodVisa from 'assets/images/icon-payment-method-visa.svg';
import IconPaymentMethodMastercard from 'assets/images/icon-payment-method-mastercard.svg';
import 'assets/style/modal-payment.scss';
import allSettled from 'promise.allsettled';

const Payments = ({
  me: { userProfile: { residency = '' } = {} } = {},
  bankAccounts = [],
  paymentCards = [],
  countries,
  refreshMe,
  getOwnBankAccounts,
  deleteBankAccount,
  createBankAccount,
  getOwnPaymentCards,
  deleteCard,
  getCountries,
}) => {
  const { t } = useTranslation('payments');
  const { isNarrow } = useWindowSize();
  const deleteBankAccountSuccessToast = useCallback(
    () => toast.success(t('deleteBankAccountSuccess')),
    [t],
  );
  const deleteBankAccountErrorToast = useCallback(
    () => toast.error(t('deleteBankAccountError')),
    [t],
  );
  const deleteCardSuccessToast = useCallback(
    () => toast.success(t('deleteCardSuccess')),
    [t],
  );
  const deleteCardErrorToast = useCallback(
    () => toast.error(t('deleteCardError')),
    [t],
  );
  const addBankAccountSuccessToast = useCallback(
    () => toast.success(t('addBankAccountSuccess')),
    [t],
  );
  const addBankAccountErrorToast = useCallback(
    () => toast.error(t('addBankAccountError')),
    [t],
  );

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [showNewBankAccountForm, setShowNewBankAccountForm] = useState(false);
  const showMain = !isNarrow || !showNewBankAccountForm;

  const [showDeleteBankAccountModal, setShowDeleteBankAccountModal] = useState(
    false,
  );
  const [bankAccountIds, setBankAccountIds] = useState([]);
  const openDeleteBankAccountModal = (bankAccountIds) => {
    setShowDeleteBankAccountModal(true);
    setBankAccountIds(bankAccountIds);
  };
  const closeDeleteBankAccountModal = () => {
    setShowDeleteBankAccountModal(false);
    setBankAccountIds(0);
  };

  const [showDeletePaymentCardModal, setShowDeletePaymentCardModal] = useState(
    false,
  );
  const [paymentCardId, setPaymentCardId] = useState(0);
  const openDeletePaymentCardModal = (paymentCardId) => {
    setShowDeletePaymentCardModal(true);
    setPaymentCardId(paymentCardId);
  };
  const closeDeletePaymentCardModal = () => {
    setShowDeletePaymentCardModal(false);
    setPaymentCardId(0);
  };

  useEffect(() => {
    setLoading(!bankAccounts.length && !paymentCards.length);
    refreshMe();
    getCountries();
    allSettled.shim();
    Promise.allSettled([getOwnBankAccounts(), getOwnPaymentCards()]).finally(
      () => {
        setLoading(false);
      },
    );
  }, [
    bankAccounts.length,
    getCountries,
    getOwnBankAccounts,
    getOwnPaymentCards,
    paymentCards.length,
    refreshMe,
  ]);

  const dedupedBankAcounts = useMemo(() => (
    Object.values(bankAccounts
      .reduce((dedupedBankAcounts, { id, iban }) => {
        if (iban in dedupedBankAcounts) {
          dedupedBankAcounts[iban].ids = [
            ...dedupedBankAcounts[iban].ids,
            id,
          ];
        } else {
          dedupedBankAcounts[iban] = {
            ids: [id],
            iban,
          };
        }

        return dedupedBankAcounts;
      }, {}))
  ), [bankAccounts]);

  const deleteBankAccountModal = (
    <Modal
      show={showDeleteBankAccountModal}
      onHide={closeDeleteBankAccountModal}
      centered
    >
      <Modal.Header closeButton>{t('deleteModal.title')}</Modal.Header>

      <Modal.Body>{t('deleteModal.areYouSure')}</Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={closeDeleteBankAccountModal}>
          {t('deleteModal.close')}
        </Button>
        <Button
          variant="danger"
          disabled={bankAccountIds.length === 0}
          isLoading={deleting}
          onClick={() => {
            setDeleting(true);
            Promise
              .all(
                bankAccountIds.map((id) => (deleteBankAccount(id))),
              )
              .then(() => (
                getOwnBankAccounts().finally(() => {
                  deleteBankAccountSuccessToast();
                  closeDeleteBankAccountModal();
                  setDeleting(false);
                })
              ))
              .catch(() => {
                deleteBankAccountErrorToast();
                closeDeleteBankAccountModal();
                setDeleting(false);
              });
          }}
        >
          {t('deleteModal.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const deletePaymentCardModal = (
    <Modal
      show={showDeletePaymentCardModal}
      onHide={closeDeletePaymentCardModal}
      centered
    >
      <Modal.Header closeButton>{t('deleteModal.title')}</Modal.Header>

      <Modal.Body>{t('deleteModal.areYouSure')}</Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={closeDeletePaymentCardModal}>
          {t('deleteModal.close')}
        </Button>
        <Button
          variant="danger"
          disabled={paymentCardId === 0}
          isLoading={deleting}
          onClick={() => {
            setDeleting(true);
            deleteCard(paymentCardId)
              .then(() => {
                getOwnPaymentCards().finally(() => {
                  deleteCardSuccessToast();
                  closeDeletePaymentCardModal();
                  setDeleting(false);
                });
              })
              .catch(() => {
                deleteCardErrorToast();
                closeDeletePaymentCardModal();
                setDeleting(false);
              });
          }}
        >
          {t('deleteModal.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const getCardProviderLogo = (cardProvider) => {
    switch (cardProvider) {
      case CARD_PROVIDER_VISA:
        return IconPaymentMethodVisa;

      case CARD_PROVIDER_MASTERCARD:
        return IconPaymentMethodMastercard;

      case CARD_PROVIDER_CB:
        return IconPaymentMethodCB;

      default:
        return <div />;
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {loading ? (
        <Spinner className="mx-auto d-block" animation="border" />
      ) : (
        <>
          {deleteBankAccountModal}
          {deletePaymentCardModal}

          <AddBankAccountModal
            show={!isNarrow && showNewBankAccountForm}
            onHide={() => {
              setShowNewBankAccountForm(false);
            }}
            onSubmit={(data, { setSubmitting }) => {
              createBankAccount(data)
                .then(() => {
                  addBankAccountSuccessToast();
                  setShowNewBankAccountForm(false);
                })
                .catch(() => {
                  addBankAccountErrorToast();
                  setSubmitting(false);
                });
            }}
            countries={countries}
            initialValues={{ country: residency }}
          />
          {isNarrow && showNewBankAccountForm && (
            <div className="form-add-payment-responsive">
              <div
                onClick={() => {
                  setShowNewBankAccountForm(false);
                }}
                onKeyPress={() => {
                  setShowNewBankAccountForm(false);
                }}
                role="button"
                tabIndex={0}
              >
                <p className="form-add-payment-responsive__title clickable">
                  <img
                    className="form-add-payment-responsive__icon-left"
                    src={iconLeft}
                    alt=""
                  />
                  {t('bankAccounts.add')}
                </p>
              </div>
              <div className="modal-add-payment">
                <div className="form-content">
                  <BankAccountForm
                    countries={countries}
                    initialValues={{ country: residency }}
                    onSubmit={(data, { setSubmitting }) => {
                      createBankAccount(data)
                        .then(() => {
                          getOwnBankAccounts().finally(() => {
                            addBankAccountSuccessToast();
                            setShowNewBankAccountForm(false);
                          });
                        })
                        .catch(() => {
                          addBankAccountErrorToast();
                          setSubmitting(false);
                        });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {showMain && (
            <>
              <div className="block-payment">
                <p className="block-payment__subtitle">
                  {t('paymentCards.title')}
                </p>
                {paymentCards.length === 0 ? (
                  <div className="block-payment__noAccount">
                    {t('paymentCards.noAccount')}
                  </div>
                ) : (
                  paymentCards.map(
                    ({
                      id,
                      obfuscatedNumber,
                      expirationDate,
                      cardProvider,
                      // currency,
                    }) => (
                      <div className="block-payment__card-info">
                        <div key={id} className="block-payment-item">
                          <div className="block-payment-item__cardInfo">
                            <img
                              className="card-provider-logo d-block"
                              src={getCardProviderLogo(cardProvider)}
                              alt=""
                            />
                            <p className="mr-2">
                              {obfuscatedNumber.replace(
                                /.*([0-9]{4})$/g,
                                '•••• $1',
                              )}
                            </p>
                            <p className="block-payment-item__expire">
                              {expirationDate.replace(/(.{2})(.{2})/, '$1/$2')}
                            </p>
                            {/* <p className="block-payment-item__currency">
                              {currency}
                            </p> */}
                            {/* !valid && (
                              <img
                                className="block-payment-item__icon-invalid"
                                src={iconInfo}
                                alt=""
                              />
                            ) */}
                          </div>

                          <div>
                            <Button
                              className="delete-btn"
                              onClick={() => {
                                openDeletePaymentCardModal(id);
                              }}
                            >
                              {t('paymentCards.delete')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    ),
                  )
                )}
              </div>
              <div className="block-payment">
                <p className="block-payment__subtitle">
                  {t('bankAccounts.title')}
                </p>
                {dedupedBankAcounts.length === 0 ? (
                  <div className="block-payment__noAccount">
                    {t('bankAccounts.noAccount')}
                  </div>
                ) : (
                  dedupedBankAcounts.map(({ ids, iban }) => (
                    <div className="block-payment__card-info">
                      <div key={iban} className="block-payment-item">
                        <div className="rib-info">
                          <p>
                            {iban.replace(/^(.{4}).+(.{4})$/, '$1 **** $2')}
                          </p>
                        </div>
                        <div>
                          <Button
                            className="delete-btn"
                            onClick={() => {
                              openDeleteBankAccountModal(ids);
                            }}
                          >
                            {t('bankAccounts.delete')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <div
                  className="block-payment__bottom"
                  onClick={() => {
                    setShowNewBankAccountForm(true);
                  }}
                  onKeyPress={() => {
                    setShowNewBankAccountForm(true);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span className="block-payment__text-add clickable text-primary">
                    <img
                      className="block-payment__icon-add-plus"
                      src={iconAddPlus}
                      alt=""
                    />
                    {t('bankAccounts.add')}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  bankAccounts: state.persistent.bankAccountsReducer.bankAccounts,
  paymentCards: state.persistent.paymentCardsReducer.paymentCards,
  countries: state.persistent.countriesReducer.countries,
});

const mapDispatchToProps = (dispatch) => ({
  refreshMe: bindActionCreators(refreshMe, dispatch),
  getOwnBankAccounts: bindActionCreators(getOwnBankAccounts, dispatch),
  deleteBankAccount: bindActionCreators(deleteBankAccount, dispatch),
  createBankAccount: bindActionCreators(createBankAccount, dispatch),
  getOwnPaymentCards: bindActionCreators(getOwnPaymentCards, dispatch),
  deleteCard: bindActionCreators(deleteCard, dispatch),
  getCountries: bindActionCreators(getCountries, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Payments);
