import colorConvert from 'color-convert';
import defaultTransactionImage from 'assets/images/defaultTransactionImage.svg';

const hslAddition = (hslValue, number) => {
  const newVal = hslValue + number;

  if (newVal < 0) {
    return 0;
  }

  if (newVal > 100) {
    return 100;
  }

  return newVal;
};

const isDark = (color) => {
  const rgb = colorConvert.hex.rgb(color);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * rgb[0] ** 2 + 0.587 * rgb[1] ** 2 + 0.114 * rgb[2] ** 2,
  );

  return hsp < 200;
};

const addRule = (css, selector, rule, value) => {
  css[selector] = {
    ...css[selector],
    [rule]: `${value} !important`,
  };

  return css;
};

const processColor = (color = '#004bb4') => {
  const hsl = colorConvert.hex.hsl(color);
  const rgb = colorConvert.hex.rgb(color);

  const t1 = `#${colorConvert.hsl.hex(hsl[0], hsl[1], hsl[2])}`;
  const t2 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], -10),
  )}`;
  const t3 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], 31),
  )}`;
  const t4 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], 36),
  )}`;
  const t5 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], -24),
  )}`;
  const t6 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], -13),
  )}`;
  const t7 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], -7),
  )}`;
  const t8 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], -34),
  )}`;
  const t9 = `#${colorConvert.hsl.hex(
    hsl[0],
    hsl[1],
    hslAddition(hsl[2], 40),
  )}`;
  const shadow = `0 0 0 0.2rem rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`;

  let css = {};

  //----------------------------------------
  // t1
  //----------------------------------------
  css = addRule(css, ':root', '--primary', t1);
  css = addRule(css, ':root', '--primary-alpha1', `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1)`);
  css = addRule(css, '.badge-primary', 'background-color', t1);
  css = addRule(css, '.bg-primary', 'background-color', t1);
  css = addRule(css, '.border-primary', 'border-color', t1);
  css = addRule(css, '.btn-primary', 'background-color', t1);
  css = addRule(css, '.btn-primary', 'border-color', t1);
  css = addRule(
    css,
    '.btn-primary.disabled, .btn-primary:disabled',
    'background-color',
    t1,
  );
  css = addRule(
    css,
    '.btn-primary.disabled, .btn-primary:disabled',
    'border-color',
    t1,
  );
  css = addRule(css, '.btn-outline-primary', 'color', t1);
  css = addRule(css, '.btn-outline-primary', 'border-color', t1);
  css = addRule(css, '.btn-outline-primary:hover', 'background-color', t1);
  css = addRule(css, '.btn-outline-primary:hover', 'border-color', t1);
  css = addRule(
    css,
    '.btn-outline-primary.disabled, .btn-outline-primary:disabled',
    'color',
    t1,
  );
  css = addRule(
    css,
    '.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle',
    'background-color',
    t1,
  );
  css = addRule(
    css,
    '.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle',
    'border-color',
    t1,
  );
  css = addRule(css, '.text-primary', 'color', t1);

  //----------------------------------------
  // t2
  //----------------------------------------
  css = addRule(
    css,
    '.badge-primary[href]:hover, .badge-primary[href]:focus',
    'background-color',
    t2,
  );
  css = addRule(
    css,
    'a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus',
    'background-color',
    t2,
  );
  css = addRule(css, '.btn-primary:hover', 'border-color', t2);
  css = addRule(
    css,
    '.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle',
    'background-color',
    t2,
  );
  css = addRule(css, 'a.text-primary:hover, a.text-primary:focus', 'color', t2);

  //----------------------------------------
  // t3
  //----------------------------------------
  css = addRule(css, '.alert-primary hr', 'border-top-color', t3);
  css = addRule(
    css,
    '.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus',
    'background-color',
    t3,
  );
  css = addRule(
    css,
    '.table-hover .table-primary:hover',
    'background-color',
    t3,
  );
  css = addRule(
    css,
    '.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th',
    'background-color',
    t3,
  );

  //----------------------------------------
  // t4
  //----------------------------------------
  css = addRule(css, '.alert-primary', 'border-color', t4);
  css = addRule(css, '.list-group-item-primary', 'background-color', t4);
  css = addRule(
    css,
    '.table-primary, .table-primary > th, .table-primary > td',
    'background-color',
    t4,
  );

  //----------------------------------------
  // t5
  //----------------------------------------
  css = addRule(css, '.alert-primary', 'color', t5);
  css = addRule(css, '.list-group-item-primary', 'color', t5);
  css = addRule(
    css,
    '.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus',
    'color',
    t5,
  );
  css = addRule(
    css,
    '.list-group-item-primary.list-group-item-action.active',
    'background-color',
    t5,
  );
  css = addRule(
    css,
    '.list-group-item-primary.list-group-item-action.active',
    'border-color',
    t5,
  );

  //----------------------------------------
  // t6
  //----------------------------------------
  css = addRule(
    css,
    '.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle',
    'border-color',
    t6,
  );

  //----------------------------------------
  // t7
  //----------------------------------------
  css = addRule(css, '.btn-primary:hover', 'background-color', t7);

  //----------------------------------------
  // t8
  //----------------------------------------
  css = addRule(css, '.alert-primary .alert-link', 'color', t8);

  //----------------------------------------
  // t9
  //----------------------------------------
  css = addRule(css, '.alert-primary', 'background-color', t9);

  //----------------------------------------
  // shadow
  //----------------------------------------
  css = addRule(
    css,
    '.btn-primary:focus, .btn-primary.focus',
    'box-shadow',
    shadow,
  );
  css = addRule(
    css,
    '.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus',
    'box-shadow',
    shadow,
  );
  css = addRule(
    css,
    '.btn-outline-primary:focus, .btn-outline-primary.focus',
    'box-shadow',
    shadow,
  );
  css = addRule(
    css,
    '.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus',
    'box-shadow',
    shadow,
  );

  //----------------------------------------
  // dark/light
  //----------------------------------------
  let textColor = '#fff';
  if (!isDark(color)) {
    textColor = '#050038';
  }
  css = addRule(css, '.badge-primary', 'color', textColor);
  css = addRule(
    css,
    '.badge-primary[href]:hover, .badge-primary[href]:focus',
    'color',
    textColor,
  );
  css = addRule(css, '.btn-primary', 'color', textColor);
  css = addRule(css, '.btn-primary:hover', 'color', textColor);
  css = addRule(
    css,
    '.btn-primary.disabled, .btn-primary:disabled',
    'color',
    textColor,
  );
  css = addRule(
    css,
    '.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle',
    'color',
    textColor,
  );
  css = addRule(css, '.btn-outline-primary:hover', 'color', textColor);
  css = addRule(
    css,
    '.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle',
    'color',
    textColor,
  );
  css = addRule(
    css,
    '.list-group-item-primary.list-group-item-action.active',
    'color',
    textColor,
  );

  const explodeSelector = (selector) => Object.keys(css[selector]).reduce(
    (result, rule) => `${result} ${rule}: ${css[selector][rule]};`,
    '',
  );

  return Object.keys(css).reduce(
    (result, selector) => `${result}
    ${selector} { ${explodeSelector(selector)} }`,
    '',
  );
};

const generate = (primary, picture = false) => (
  `${processColor(primary)}
  .checkout-detail-image__inner { background-image: url(${picture || defaultTransactionImage}); }
  .loading-overlay .spinner-border { color: var(--secondary); }`
);

export default generate;
