import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchClientLogo,
  fetchClientSquareLogo,
  selectLogo,
  selectSquareLogo,
} from 'logic/actions/clientsActions';

const useClientLogos = (clientId) => {
  const dispatch = useDispatch();

  const logo = useSelector(selectLogo(clientId));
  const squareLogo = useSelector(selectSquareLogo(clientId));

  useEffect(() => {
    if (clientId && !logo) {
      dispatch(fetchClientLogo(clientId));
    }
  }, [clientId, logo, dispatch]);

  useEffect(() => {
    if (clientId && !squareLogo) {
      dispatch(fetchClientSquareLogo(clientId));
    }
  }, [clientId, squareLogo, dispatch]);

  return { logo, squareLogo };
};

export default useClientLogos;
