class LocalStorage {
  constructor() {
    this.store = window.localStorage;
  }

  isExist(key) {
    return !!this.store[key];
  }

  get(key) {
    return JSON.parse(this.store.getItem(key));
  }

  set(key, value) {
    this.store.setItem(key, JSON.stringify(value));
  }

  delete(key) {
    this.store.removeItem(key);
  }

  clear() {
    this.store.clear();
  }
}

export default new LocalStorage();
