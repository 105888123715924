import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import omit from 'lodash/omit';
import itemsStorage from 'helpers/itemsStorage';

const useItemsPictures = () => {
  const [updated, setUpdated] = useState(false);

  const items = useRef({
    byIds: {},
    ids: [],
  });

  useEffect(() => {
    itemsStorage
      .getAll()
      .then((loadedData) => {
        items.current = loadedData;
      })
      .finally(() => setUpdated(true));
  }, []);

  const addNewMedia = useCallback((id, media) => {
    items.current.ids.push(id);
    items.current.byIds[id] = media;
    itemsStorage.add(id, media);
  }, []);

  const removeMedia = useCallback((mediaId) => {
    items.current.ids = items.current.ids.filter((id) => id !== mediaId);
    items.current.byIds = omit(items.current.byIds, mediaId);
    itemsStorage.remove(mediaId);
  }, []);

  return {
    items,
    updateItemsActions: {
      addNewMedia,
      removeMedia,
      updated,
    },
  };
};

export default useItemsPictures;
