import React from 'react';

const OrderInHands = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="132.829"
    height="88"
    viewBox="0 0 132.829 88"
    {...props}
  >
    <defs>
      <style>
        {'.a,.f{fill:#ff977c;}.a{fill - rule:evenodd;}.b{fill:#aa7243;}.c{fill:#824725;}.d{fill:#592a13;}.e{fill:#fff;}.g{fill:#e5f4ff;}.h{fill:#d8eeff;}.i{fill:#ffa289;}.j{fill:#172234;}.k{fill:#04070c;}.l{fill:#ff7f5f;}'}
      </style>
    </defs>
    <g transform="translate(-117.062 -175.334)">
      <g transform="translate(117.062 175.334)">
        <path
          className="a"
          d="M-1529.061,3284.015a7.018,7.018,0,0,1-4.306,3.388l-1.846,3.188-5.751-3.635,3.665-3.7,8.1-1.344Z"
          transform="translate(1635.952 -3242.777)"
        />
        <g transform="translate(85.685 8.227)">
          <path
            className="b"
            d="M-1550.831,3279.438l1.546-28.232,30.31,3.314-1.587,28.835Z"
            transform="translate(1550.831 -3249.249)"
          />
          <path
            className="c"
            d="M-1518.727,3283.389l15.588-4.488,1.283-27.149-15.286,2.8Z"
            transform="translate(1548.997 -3249.28)"
          />
          <path
            className="d"
            d="M-1549.19,3251.087l17.457-1.958,28.14,2.47-15.287,2.8Z"
            transform="translate(1550.737 -3249.13)"
          />
          <path
            className="e"
            d="M-1546.949,3259.736l.29-5.294,5.685.622-.3,5.407Z"
            transform="translate(1550.609 -3249.433)"
          />
        </g>
        <path
          className="f"
          d="M-1534.646,3284.99c.4-3.641-1.715-4.6-1.715-4.6-.473-2.885-.709-3.878-1.672-4.769-.831-.768-1.118,1.553-1.158,3.013-.04,1.5-.011,2.424-.011,2.424l.561,2.328-.828,1.353-1.5,1.851Z"
          transform="translate(1635.952 -3242.408)"
        />
        <g transform="translate(94.874 32.004)">
          <path
            className="f"
            d="M-1534.769,3284.927a6.327,6.327,0,0,0-1.917-5.507c.346-2.972-.208-4.1-1.594-4.947-.966-.587-.826,1.068-.749,1.391a8.544,8.544,0,0,1-.283,2.7,5.328,5.328,0,0,0-.011,2.424l.561,2.328-.828,1.353-1.5,1.851Z"
            transform="translate(1541.085 -3274.349)"
          />
        </g>
        <path
          className="g"
          d="M-1547.361,3284.346l-13.564,21.9c-17.792-18.48-28.614-22.468-28.614-22.468l-.2,6.143-1.559-2.543-19.052-8.252-1.589,4.179c-13.786,1.066-26.738,29.892-28.313,32.767a30.209,30.209,0,0,0-1.455,3.153c8.3,8.553,22.249,11.4,44.72,11.4,11.3,0,20.439-.718,27.794-2.519l-2.855-9.022s13.75,3.851,15.709,3.969a3.427,3.427,0,0,0,2.919-1.195c4.884-5.243,15.243-33,15.243-33A13.015,13.015,0,0,0-1547.361,3284.346Z"
          transform="translate(1641.709 -3242.617)"
        />
        <path
          className="h"
          d="M-1606.467,3300.52s-13.616,22.944-14.408,24.483a1.8,1.8,0,0,0,.258,2.35l5.932,3.54q2.039.246,4.22.422l-5.635-6.223Z"
          transform="translate(1640.535 -3243.84)"
        />
        <path
          className="h"
          d="M-1562.336,3321.422c1.869.5,3.854,1.018,5.53,1.429-2.208-3.041-20.426-28.012-26.867-33.265,0,0,10.456,14.855,14.648,29.819Z"
          transform="translate(1638.393 -3243.215)"
        />
        <path
          className="i"
          d="M-1602.454,3258.329c-4.035,3.144,2.435,8.666,3.938,9.071,0,0-2.685,3.248-5.673,3.2l.016.068-2.7,7.562,17.565,7.606,1.558,2.543.2-6.143.06.024.271-8.3,4.593.838a1.53,1.53,0,0,0,1.494-.918,28.312,28.312,0,0,0,1.474-7.379l2.642.173s.58.1.464-.638c-.089-.566-1.843-8.722-2.638-12.406-3.024,1.4-10.143-2.35-12.425-1.261-2.915,1.392.44,5.251-3.042,11.737C-1594.658,3264.1-1598.418,3255.189-1602.454,3258.329Z"
          transform="translate(1639.719 -3241.077)"
        />
        <path
          className="j"
          d="M-1591.028,3243.488c-1.469-.559-10.609-2.5-11.828-1.349s.871,2.691.871,2.691a14.067,14.067,0,0,0-8.553,10.2c10.367-.171,9.7-7,19.526-6.491,6.428.334,10.1-.97,11.94-1.98-2.31-3.9-7.757-5.895-9.419-6.124-2.136-.294-3.086,1.445-2.49,2.325S-1591.028,3243.488-1591.028,3243.488Z"
          transform="translate(1639.928 -3240.405)"
        />
        <path
          className="k"
          d="M-1608.43,3267.6c4.026,6.343,9.684-.5,9.684-.5-1.5-.405-7.974-5.929-3.937-9.071s7.795,5.773,7.795,5.773c3.48-6.486.125-10.344,3.042-11.737s13.737,5.118,13.8-1.314a7.244,7.244,0,0,0-1.05-3.818c-1.839,1.009-5.512,2.313-11.94,1.98-9.828-.51-9.159,6.319-19.526,6.49A16.909,16.909,0,0,0-1608.43,3267.6Z"
          transform="translate(1639.949 -3240.778)"
        />
        <path
          className="e"
          d="M-1583.163,3271.053l.227-3.381-4.457-.668C-1587.291,3270.155-1583.163,3271.053-1583.163,3271.053Z"
          transform="translate(1638.605 -3241.925)"
        />
        <path
          className="l"
          d="M-1601.213,3261.288l4.651,4.251S-1597.025,3259.859-1601.213,3261.288Z"
          transform="translate(1639.395 -3241.585)"
        />
        <path
          className="l"
          d="M-1586.828,3281.9l.22-6.726-9.584-1.688S-1596.052,3277.177-1586.828,3281.9Z"
          transform="translate(1639.108 -3242.295)"
        />
        <path
          className="g"
          d="M-1567.715,3329.789l-2.849-9c-1.764-2.253-7.861-9.014-17.049-8.361-8.652.618-12.683,2.416-17.43-.337q-.481-.28-.975-.623l-.009-.007c-.157-.11-.319-.227-.479-.349l-.041-.029q-.239-.182-.483-.38l-.015-.013q-.243-.2-.49-.412l-.027-.024c-.156-.137-.318-.28-.48-.429l-.08-.074q-.236-.218-.476-.452l-.08-.079c-.168-.163-.337-.33-.507-.506l-6.6,16.846,5.635,6.223c4.426.361,9.293.524,14.647.524C-1584.213,3332.307-1575.069,3331.59-1567.715,3329.789Z"
          transform="translate(1640.228 -3244.308)"
        />
        <path
          className="l"
          d="M-1578.041,3255.037c-.112-.515-.215-.992-.3-1.409-3.024,1.4-10.143-2.35-12.424-1.261a2.22,2.22,0,0,0-1.3,2.074C-1584.858,3256.826-1580.129,3255.8-1578.041,3255.037Z"
          transform="translate(1638.873 -3241.077)"
        />
      </g>
    </g>
  </svg>
);

export default OrderInHands;
