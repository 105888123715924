/* eslint-disable import/no-unused-modules */
export const SAVE_MEDIA = 'pictures/SAVE_MEDIA';
export const CREATE_PROOF = 'pictures/CREATE_PROOF';
/* eslint-enable import/no-unused-modules */

export const sendMediaToServer = ({ items, parcel }) => (dispatch) => {
  const { ids: itemsIds, byIds: itemsByIds } = items;
  const { ids: parcelIds, byIds: parcelByIds } = parcel;

  const mediaFormData = new FormData();

  itemsIds.forEach((id) => {
    mediaFormData.append(id, itemsByIds[id].file);
  });

  parcelIds.forEach((id) => {
    mediaFormData.append(id, parcelByIds[id].file);
  });

  return dispatch({
    type: SAVE_MEDIA,
    payload: {
      request: {
        method: 'POST',
        url: '/api/media/create/',
        data: mediaFormData,
      },
    },
  });
};

export const createProof = ({
  transactionId, mediaArray, latitude, longitude,
}) => (dispatch) => {
  const mediaIds = mediaArray.map((media) => media.id);

  return dispatch({
    type: CREATE_PROOF,
    payload: {
      request: {
        method: 'POST',
        url: `/api/webapp/proofs/${transactionId}/create/`,
        data: {
          media: mediaIds,
          title: transactionId,
          latitude,
          longitude,
        },
      },
    },
  });
};
