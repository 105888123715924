import classes from 'assets/style/signup.module.scss';
import Footer from 'components/Footer';
import LoginHeader from 'components/LoginHeader';
import SignupForm from 'components/SignupForm';
import { getToken } from 'logic/actions/authActions';
import { handleGreetingModal } from 'logic/actions/meActions';
import { checkEmail, createUser } from 'logic/actions/usersActions';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Signup = ({
  getToken,
  createUser,
  checkEmail,
  loggedIn,
  location: { state: { referer = '/', externalId, transactionId } = {} } = {},
  history,
  handleGreetingModal,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation('signup');
  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';
  const signupErrorToast = useCallback(
    () => toast.error(t('form.signupError')),
    [t],
  );
  const loginErrorToast = useCallback(() => toast.error(t('form.loginError')), [
    t,
  ]);

  if (loggedIn) {
    const queryParams = queryString.parse(referer?.search);

    const externalId = queryParams['external-id'];

    if (externalId) {
      return <Redirect to={referer?.pathname || referer} />;
    }

    return <Redirect to={referer} />;
  }

  const authPayloadWithExternalId = externalId
    ? {
      externalId,
      transactionId,
    }
    : {};

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <LoginHeader />
          <p className={classes.title}>{t('title')}</p>
          <SignupForm
            referer={referer}
            externalId={externalId}
            transactionId={transactionId}
            onSubmit={(
              {
                email, firstName, lastName, password,
              },
              { setSubmitting },
            ) => {
              executeRecaptcha('signup').then(
                (token) => {
                  createUser({
                    email,
                    plainPassword: password,
                    userProfile: {
                      firstName,
                      lastName,
                    },
                    lang: currentLang,
                    captcha: token,
                    ...authPayloadWithExternalId,
                  })
                    .then(() => {
                      getToken({
                        email,
                        password,
                      })
                        .then(() => handleGreetingModal(true))
                        .catch(() => {
                          loginErrorToast();
                          history.push('/login', { referer, externalId });
                        });
                    })
                    .catch(() => {
                      signupErrorToast();
                      setSubmitting(false);
                    });
                },
              ).catch(
                () => {
                  signupErrorToast();
                  setSubmitting(false);
                },
              );
            }}
            checkEmail={checkEmail}
          />
        </div>
        <Footer className={classes.footer} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
});

const actionCreators = {
  getToken,
  createUser,
  checkEmail,
  handleGreetingModal,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(Signup);
