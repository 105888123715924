import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormattedNumber } from 'react-intl';
import { CURRENCY_EUR } from 'helpers/constants';

const TransactionTimelineCard = ({
  title,
  sellerTotal = 0,
  buyerTotal = 0,
  isPurchase,
  currency = CURRENCY_EUR,
  children,
}) => (
  <div className="transaction__reference transaction-reference transaction__mt-30 rounded">
    <Row>
      <Col xs={6}>
        <h3 className="m-0 transaction-reference__title">{title}</h3>
      </Col>
      <Col xs={6}>
        <h3 className="m-0 text-right transaction-reference__price">
          <FormattedNumber
            value={(isPurchase ? buyerTotal : sellerTotal) / 100}
            style="currency"
            currency={currency}
          />
        </h3>
      </Col>
    </Row>
    {children}
  </div>
);

export default TransactionTimelineCard;
