import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

const CancelModal = ({
  transaction,
  showCancelModal,
  setShowCancelModal,
  requestCancelTransaction,
  cancelTransaction,
}) => {
  const { t } = useTranslation('transaction');
  const {
    id, canBeCanceled, isAccepted, isPurchase,
  } = transaction;

  const [canceling, setCanceling] = useState(false);
  const modalName = isPurchase && isAccepted
    ? 'confirmRequestCancelModal'
    : 'confirmCancelModal';
  const action = isPurchase && isAccepted ? requestCancelTransaction : cancelTransaction;

  return (
    <Modal
      show={showCancelModal}
      onHide={() => {
        setShowCancelModal(false);
      }}
      centered
    >
      <Modal.Header closeButton>{t(`${modalName}.title`)}</Modal.Header>

      <Modal.Body className="keep-line-breaks">
        {t(`${modalName}.areYouSure`)}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            setShowCancelModal(false);
          }}
        >
          {t(`${modalName}.close`)}
        </Button>
        <Button
          variant="danger"
          isLoading={canceling}
          disabled={!canBeCanceled}
          onClick={() => {
            setCanceling(true);
            action(id).finally(() => {
              setShowCancelModal(false);
              setCanceling(false);
            });
          }}
        >
          {t(`${modalName}.confirm`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;
