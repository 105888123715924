import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import { useIntl } from 'react-intl';
import classes from 'assets/style/disputeResolution.module.scss';
import Button from 'components/Button';
import { SOLUTION_PARTIAL } from 'pages/disputeResolution/ChooseSolution';

const ConfirmDispute = ({
  role,
  me: { userProfile: { firstName = '' } = {} } = {},
  transaction: { buyerTotal, currency = 'EUR' } = {},
  proposedSolution,
  proposedRefund,
  back,
  confirm,
  submitting,
}) => {
  const { t } = useTranslation('disputeResolution');
  const intl = useIntl();

  const amount = intl.formatNumber(
    proposedSolution === SOLUTION_PARTIAL
      ? proposedRefund / 100
      : buyerTotal / 100,
    { style: 'currency', currency },
  );

  return (
    <Row className={classes.content}>
      <Button variant="link" onClick={back} className={classes.back}>
        {t('back')}
      </Button>
      <p className={classes.title}>
        {t(`confirmDispute.title.${role}`, { firstName })}
      </p>
      <Button
        className={classes.nextButton}
        onClick={confirm}
        isLoading={submitting}
      >
        {t(`confirmDispute.confirm.${role}`, { amount })}
      </Button>
    </Row>
  );
};

export default ConfirmDispute;
