import React from 'react';

const TutoModalRadioChecked = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(858.501 -479.676)">
      <rect
        width={16}
        height={16}
        rx={8}
        transform="translate(-858 480.177)"
        fill="#fff"
        stroke="var(--primary)"
        strokeMiterlimit={10}
        strokeWidth={1.003}
      />
      <rect
        width={10}
        height={10}
        rx={5}
        transform="translate(-855 483.177)"
        fill="var(--primary)"
      />
    </g>
  </svg>
);

export default TutoModalRadioChecked;
