import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/goBackCheckoutLink.module.scss';
import ArrowLeftIconWithLine from 'assets/svg/ArrowLeftIconWithLine';
import classNames from 'classnames';

const GoBackCheckoutLink = ({ handleClick, text, className }) => {
  const { t } = useTranslation('checkout');

  return (
    <button
      onClick={handleClick}
      type="button"
      className={classNames(classes.goBackCheckoutLink, className)}
    >
      <ArrowLeftIconWithLine className={classes.goBackCheckoutLink__icon} />
      {text || t('goBack')}
    </button>
  );
};

GoBackCheckoutLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

GoBackCheckoutLink.defaultProps = {
  text: '',
  className: '',
};

export default GoBackCheckoutLink;
