import queryString from 'query-string';
import { externalId } from 'helpers/constants';

const SUCCESS_STATUS = 'success';
const CANCEL_STATUS = 'cancel';

export const getSuccessReturnUrl = (adUrl = '', transactionId = -1) => {
  const { url, query, fragmentIdentifier } = queryString.parseUrl(adUrl, {
    parseFragmentIdentifier: true,
  });

  return queryString.stringifyUrl({
    url,
    query: {
      ...query,
      tp_status: SUCCESS_STATUS,
      unique_transaction_id: transactionId,
      tp_transaction_id: transactionId,
    },
    fragmentIdentifier,
  });
};

export const getCancelReturnUrl = (adUrl = '') => {
  const { url, query, fragmentIdentifier } = queryString.parseUrl(adUrl, {
    parseFragmentIdentifier: true,
  });

  return queryString.stringifyUrl({
    url,
    query: {
      ...query,
      tp_status: CANCEL_STATUS,
    },
    fragmentIdentifier,
  });
};

export const getExternalId = (url) => {
  const { query } = queryString.parseUrl(url);

  return query?.[externalId];
};

export const getTransactionId = (pathname) => {
  if (pathname.match(/^\/transactions\/\d+$/)) {
    return pathname.match(/\d+/g)[0];
  }

  return undefined;
};
