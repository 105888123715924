import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import checkedIcon from 'assets/images/checked-rounded-icon.svg';
import classes from 'assets/style/secureFundsModal.module.scss';

const SecureFundsModal = ({ show, onHide }) => {
  const { t } = useTranslation('secureFundsModal');

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={classes.secureFundsModal}
      contentClassName={classes.secureFundsModal__content}
    >
      <img
        src={checkedIcon}
        alt="checked"
        className={classes.secureFundsModal__icon}
      />
      <h4 className={classes.secureFundsModal__title}>{t('title')}</h4>
      <p className={classes.secureFundsModal__info}>{t('info')}</p>
      <Button className={classes.secureFundsModal__button} onClick={onHide}>
        {t('continue')}
      </Button>
    </Modal>
  );
};

SecureFundsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default SecureFundsModal;
