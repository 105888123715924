import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getExternalId,
  getTransactionId,
} from 'helpers/transactionTemplateReturnUrl';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector((state) => state.persistent.meReducer.loggedIn);

  const externalId = getExternalId(window.location.href);

  return (
    <Route
      {...rest}
      render={(props) => (loggedIn ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              referer: props.location,
              externalId,
              transactionId: getTransactionId(props.location.pathname),
            },
          }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
