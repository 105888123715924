import React, { useEffect, useState } from 'react';
import { getBase64FromBlob, getBase64FromPdfFile } from 'helpers/decode';
import { Spinner } from 'react-bootstrap';
import classes from 'assets/style/documentPreview.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/close-cross.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/downloadIcon.svg';

const DocumentPreview = ({ file, onDelete, onDownload }) => {
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (file) {
      setLoading(true);

      if (file.type === 'application/pdf') {
        getBase64FromPdfFile(file)
          .then((url) => {
            setUrl(url);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        getBase64FromBlob(file)
          .then((url) => {
            setUrl(url);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [file]);

  return (
    <div className={classes.container}>
      {loading ? (
        <Spinner variant="secondary" animation="grow" />
      ) : (
        <>
          {onDelete && (
            <button
              className={classes.deleteBtn}
              type="button"
              onClick={() => {
                onDelete(file);
              }}
            >
              <CloseIcon />
            </button>
          )}
          {onDownload && (
            <button
              className={classes.downloadBtn}
              type="button"
              onClick={() => {
                onDownload(file);
              }}
            >
              <div>
                <DownloadIcon />
              </div>
            </button>
          )}
          <img src={url} title={file.name} alt={file.name} />
        </>
      )}
    </div>
  );
};

export default DocumentPreview;
