import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import IconPackage from 'assets/svg/IconPackage';
import 'assets/style/trackingNumberModal.scss';

const SHIPPING_PROVIDERS = [
  {
    label: 'Colissimo',
    value: 'colissimo',
  },
  {
    label: 'TNT',
    value: 'tnt',
  },
  {
    label: 'Chronopost',
    value: 'chronopost-france',
  },
  {
    label: 'Swiss Post',
    value: 'swiss-post',
  },
  {
    label: 'DPD',
    value: 'dpd',
  },
  {
    label: 'UPS',
    value: 'ups',
  },
  {
    label: 'DHL',
    value: 'dhl',
  },
  {
    label: 'FedEx',
    value: 'fedex',
  },
];

const TrackingNumberModal = ({ visible, onHide, onSubmit }) => {
  const { t } = useTranslation('trackingNumberModal');

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        shippingProvider: yup.string().required(t('emptyShippingProvider')),
        trackingNumber: yup.string().required(t('emptyTrackingNumber')),
      })}
      onSubmit={onSubmit}
      initialValues={{
        shippingProvider: 'colissimo',
        trackingNumber: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        resetForm,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={visible}
            onHide={resetAndHide}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton />
              <Modal.Body className="pt-0">
                <div className="tracking-number-modal__text text-center">
                  <div>
                    <IconPackage width="80px" />
                  </div>
                  <h4 className="mt-3 mb-3 checkout__title">{t('title')}</h4>
                  <p>{t('msg')}</p>
                </div>
                <div>
                  <div>
                    <InputGroup className="tracking-number-modal__input-group mb-3 pb-1">
                      <Form.Label className="text-sub">
                        {t('carrier')}
                      </Form.Label>
                      <FormControl
                        className="tracking-number-modal__select"
                        as="select"
                        name="shippingProvider"
                        value={values.shippingProvider}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.shippingProvider && !!errors.shippingProvider
                        }
                        autoFocus
                      >
                        {SHIPPING_PROVIDERS.map((provider) => (
                          <option key={provider.value} value={provider.value}>
                            {provider.label}
                          </option>
                        ))}
                      </FormControl>
                      <Form.Control.Feedback type="invalid">
                        {errors.shippingProvider}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="tracking-number-modal__input-group mb-3 pb-1">
                      <Form.Label className="text-sub">
                        {t('trackingNumber')}
                      </Form.Label>
                      <FormControl
                        className="tracking-number-modal__input"
                        placeholder={t('placeholder')}
                        name="trackingNumber"
                        value={values.trackingNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.trackingNumber && !!errors.trackingNumber
                        }
                        autoFocus
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.trackingNumber}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      className="btn-block"
                    >
                      {t('submit')}
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default TrackingNumberModal;
