import localforage from 'localforage';

class ItemsStorage {
  constructor() {
    this.storage = localforage.createInstance({
      name: 'parcel',
      storeName: 'media',
    });
  }

  add(id, mediaFile) {
    return this.storage
      .setItem(id, mediaFile)
      .then(() => this.storage.getItem('ids'))
      .then((ids) => {
        if (!ids) {
          return this.storage.setItem('ids', [id]);
        }
        return this.storage.setItem('ids', [...ids, id]);
      });
  }

  remove(deletedId) {
    return this.storage
      .removeItem(deletedId)
      .then(() => this.storage.getItem('ids'))
      .then((ids) => this.storage.setItem(
        'ids',
        ids?.filter((id) => id !== deletedId),
      ));
  }

  getAll() {
    const byIds = {};
    const ids = [];
    return this.storage
      .getItem('ids')
      .then((currentIds) => {
        if (currentIds) {
          return Promise.allSettled(
            currentIds.map((id) => this.storage.getItem(id).then((media) => {
              byIds[id] = media;
              ids.push(id);
            })),
          );
        }
        return Promise.resolve();
      })
      .then(() => ({ byIds, ids }));
  }

  clear() {
    return this.storage.clear();
  }
}

export default new ItemsStorage();
