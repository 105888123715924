import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/takePictures/takePicturesForItems.module.scss';
import PreviewCarousel from 'components/takePictures/PreviewCarousel';
import PageTitle from 'components/takePictures/PageTitle';
import ButtonWithCheckedIcon from 'components/takePictures/ButtonWithCheckedIcon';
import Controls from 'components/takePictures/Controls';
import Slider from 'components/takePictures/Slider';
import ProcessNewMediaButtons from 'components/takePictures/ProcessNewMediaButtons';
import ProcessCurrentMediaButtons from 'components/takePictures/ProcessCurrentMediaButtons';
import ConfirmModal from 'components/takePictures/ConfirmModal';
import useModal from 'hooks/useModal';
import MediaContext from 'components/takePictures/mediaContext';
import Loader from 'components/takePictures/Loader';
import { SELLER, TAKE_PICTURES_ROUTES } from 'helpers/constants';
import InformModal from 'components/takePictures/InformModal';
import exclamation from 'assets/images/exclamation-icon-blue.svg';
import classNames from 'classnames';

const TakePicturesForItems = () => {
  const {
    modalState: confirmModal,
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
  } = useModal(false);

  const {
    modalState: prevStepModal,
    openModal: openPrevStepModal,
    closeModal: closePrevStepModal,
  } = useModal(false);

  const {
    modalState: limitModal,
    openModal: openLimitModal,
    closeModal: closeLimitModal,
  } = useModal(false);

  const history = useHistory();
  const location = useLocation();

  const { state: { role } = {} } = location;

  const { t } = useTranslation('takePictures');

  const {
    loading,
    isSteamActive,
    items,
    stopStream,
    photoPreview,
    deletePhoto,
    deleteVideo,
    showDeleteButton,
    recording,
    video,
    replay,
    handleClosePhotoPreview,
    savePhoto,
    handleCloseVideoPreview,
    saveVideo,
  } = useContext(MediaContext);

  const deletePhotoAndCloseModal = useCallback(() => {
    deletePhoto();
    closeConfirmModal();
  }, [deletePhoto, closeConfirmModal]);

  const deleteVideoAndCloseModal = useCallback(() => {
    deleteVideo();
    closeConfirmModal();
  }, [deleteVideo, closeConfirmModal]);

  const deleteConfirmCallback = photoPreview
    ? deletePhotoAndCloseModal
    : deleteVideoAndCloseModal;

  const goBackToParcel = useCallback(() => {
    stopStream();
    history.push({
      pathname: TAKE_PICTURES_ROUTES.PARCEL_SCREEN,
      state: location.state,
    });
  }, [
    history,
    location.state,
    stopStream,
  ]);

  const goBackToSecond = useCallback(() => {
    stopStream();
    history.push({
      pathname: TAKE_PICTURES_ROUTES.SECOND_SCREEN,
      state: location.state,
    });
  }, [
    stopStream,
    history,
    location.state,
  ]);

  const goNextButtonVisible = useMemo(
    () => !recording && !photoPreview && items.current.ids.length > 0,
    [
      recording,
      photoPreview,
      items,
    ],
  );

  const controlsVisible = useMemo(
    () => items.current.ids.length < 5,
    [items],
  );

  const goToNextStep = useCallback(() => {
    stopStream();
    if (role === SELLER) {
      history.push({
        pathname: TAKE_PICTURES_ROUTES.PARCEL_SCREEN,
        state: location.state,
      });
    } else {
      history.push({
        pathname: TAKE_PICTURES_ROUTES.RESULT_SCREEN,
        state: location.state,
      });
    }
  }, [
    stopStream,
    role,
    history,
    location.state,
  ]);

  useEffect(() => {
    if (items.current.ids.length > 4) {
      openLimitModal();
    }
  }, [
    items,
    openLimitModal,
  ]);

  const renderTitle = useMemo(
    () => (role === SELLER ? (
      <PageTitle
        title={t('itemsTitle')}
        step={1}
        goBack={openPrevStepModal}
      />
    ) : (
      <PageTitle title={t('itemsTitle')} step={2} goBack={goBackToParcel} />
    )),
    [
      role,
      openPrevStepModal,
      goBackToParcel,
      t,
    ],
  );

  return (
    <div className={classes.takePicturesForItems}>
      <InformModal
        show={limitModal}
        confirmCallback={closeLimitModal}
        title={t('limitModal.text')}
        confirmText={t('limitModal.confirm')}
        image={<img src={exclamation} alt="exclamation" className="mb-2" />}
      />
      <ConfirmModal
        show={confirmModal}
        onHide={closeConfirmModal}
        onConfirm={deleteConfirmCallback}
        onReject={closeConfirmModal}
        title={t('deleteModal.title')}
        confirmText={t('deleteModal.confirm')}
        rejectText={t('deleteModal.reject')}
      />
      <ConfirmModal
        show={prevStepModal}
        onHide={closePrevStepModal}
        onConfirm={goBackToSecond}
        onReject={closePrevStepModal}
        title={t('previousStepModal.title')}
        confirmText={t('previousStepModal.confirm')}
        rejectText={t('previousStepModal.reject')}
      />
      <div className={classes.takePicturesForItems__upperContainer}>
        <div className={classes.takePicturesForItems__headerContainer}>
          {!showDeleteButton && renderTitle}
          {goNextButtonVisible && (
            <ButtonWithCheckedIcon handleClick={goToNextStep} />
          )}
        </div>

        {loading && <Loader />}

        {!recording && !photoPreview && (
          <div className={classes.takePicturesForItems__footerContainer}>
            {controlsVisible && <Controls />}
            <PreviewCarousel
              ids={items.current.ids}
              byIds={items.current.byIds}
            />
            <Slider rulesType="itemRules" />
          </div>
        )}

        {photoPreview && (
          <div className={classes.takePicturesForItems__previewContainer}>
            <div
              className={classes.takePicturesForItems__previewButtonsContainer}
            >
              {showDeleteButton ? (
                <ProcessCurrentMediaButtons
                  handleDelete={openConfirmModal}
                  handleClose={handleClosePhotoPreview}
                />
              ) : (
                <ProcessNewMediaButtons
                  handleSave={savePhoto}
                  handleRetry={handleClosePhotoPreview}
                />
              )}
            </div>
          </div>
        )}

        {recording && (
          <div className={classes.takePicturesForItems__previewContainer}>
            <div
              className={classes.takePicturesForItems__previewButtonsContainer}
            >
              {showDeleteButton ? (
                <ProcessCurrentMediaButtons
                  handleDelete={openConfirmModal}
                  handleClose={handleCloseVideoPreview}
                />
              ) : (
                <ProcessNewMediaButtons
                  handleSave={saveVideo}
                  handleRetry={handleCloseVideoPreview}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className={classes.takePicturesForItems__content}>
        {!isSteamActive && items.current.ids?.length === 0 && (
          <div className={classes.takePicturesForItems__disclaimer}>
            {t('disclaimer')}
          </div>
        )}
        {photoPreview && (
          <img
            src={photoPreview}
            className={classes.takePicturesForItems__imagePreview}
            alt="preview"
          />
        )}
        <video
          autoPlay
          playsInline
          muted
          className={classNames(
            classes.takePicturesForItems__replayVideo,
            `h-auto ${replay.current ? 'visible' : 'invisible'}`,
          )}
          ref={replay}
        />
        <video
          autoPlay
          playsInline
          muted
          ref={video}
          className={classes.takePicturesForItems__streamVideo}
        />
      </div>
    </div>
  );
};

export default TakePicturesForItems;
