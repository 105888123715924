import React, { useCallback } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { baseUrl } from 'helpers/env';
import classes from 'assets/style/loginForm.module.scss';
import { ROUTES } from 'helpers/constants';
import ErrorMessagesBlock from 'components/ErrorMessagesBlock';
import FormReset from 'components/FormReset';
import PasswordInput from 'components/PasswordInput';

const LoginForm = ({
  onSubmit,
  isCheckoutPage,
  setShowSignup,
  externalId,
  transactionId,
  referer,
}) => {
  const { t } = useTranslation('loginForm');
  const history = useHistory();

  const gotoSignup = useCallback(() => {
    if (isCheckoutPage) {
      setShowSignup(true);
    } else {
      history.push({
        pathname: '/signup',
        state: {
          referer: externalId ? referer : undefined,
          externalId,
          transactionId,
        },
      });
    }
  }, [
    isCheckoutPage,
    setShowSignup,
    externalId,
    history,
    referer,
    transactionId,
  ]);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        email: yup
          .string()
          .required(t('emptyEmail'))
          .email(t('invalidEmail')),
        password: yup.string().required(t('emptyPassword')),
      })}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form className={classes.container} noValidate onSubmit={handleSubmit}>
          <FormReset
            defaults={{
              email: '',
              password: '',
            }}
          />
          <div>
            <Form.Row>
              <Form.Group md="4" controlId="email">
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                  placeholder={t('email')}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group md="4" controlId="password">
                <PasswordInput
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                  placeholder={t('password')}
                />
              </Form.Group>
            </Form.Row>
          </div>
          {!!Object.keys(errors).length && <ErrorMessagesBlock />}
          <a
            className={classes.forgotPassword}
            href={`${baseUrl}${ROUTES.RESET_PASSWORD.REQUEST}?return-to=${window.location.href}`}
          >
            {t('forgotPassword')}
          </a>
          <Form.Row>
            <Button type="submit" isLoading={isSubmitting}>
              {t('submit')}
            </Button>
          </Form.Row>
          <div className={classes.noAccountText}>
            <span />
            <span>{t('dontHaveAccount')}</span>
            <span />
          </div>
          <Form.Row>
            <Button variant="outline-primary" onClick={gotoSignup}>
              {t('signup')}
            </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
