import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { requestResetPassword } from 'logic/actions/resetPasswordActions';
import { loggedIn } from 'logic/actions/meActions';
import Button from 'components/Button';
import classes from 'assets/style/resetPassword.module.scss';
import GoBackCheckoutLink from 'components/GoBackCheckoutLink';
import LogoTripartie from 'assets/svg/LogoTripartie';
import { ERRORS_FROM_SERVER, ROUTES } from 'helpers/constants';
import queryString from 'query-string';
import Footer from 'components/Footer';

const RequestResetPassword = ({
  history,
  location: { state: { referer = '/' } = {}, search = '' } = {},
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(loggedIn);

  const { t } = useTranslation('requestResetPassword');

  const { 'return-to': returnTo } = queryString.parse(search);

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t('emptyEmail'))
      .email(t('invalidEmail')),
  });

  const onSubmit = useCallback(
    ({ email }, { setSubmitting, setFieldError }) => {
      dispatch(requestResetPassword(email, returnTo))
        .then(() => {
          history.push(ROUTES.RESET_PASSWORD.MAIL_INFO);
        })
        .catch(({ error }) => {
          if (error.response.data?.errors === ERRORS_FROM_SERVER.NO_USER) {
            setFieldError('email', t('noUser'));
          }
          setSubmitting(false);
        });
    },
    [
      dispatch,
      history,
      returnTo,
      t,
    ],
  );

  if (isAuthenticated) {
    return <Redirect to={referer} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <LogoTripartie fill="#004BB4" className={classes.logo} />
          <p className={classes.title}>{t('title')}</p>
          <p className={classes.description}>{t('description')}</p>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{ email: '' }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group
                    className={classes.formGroup}
                    md="4"
                    controlId="email"
                  >
                    <Form.Label className={classes.label}>
                      {t('email')}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!touched.email && !!errors.email}
                      placeholder={t('email')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Button
                    type="submit"
                    className={classes.button}
                    isLoading={isSubmitting}
                  >
                    {t('submit')}
                  </Button>
                </Form.Row>
              </Form>
            )}
          </Formik>
          <GoBackCheckoutLink
            handleClick={() => {
              window.location.replace(returnTo);
            }}
            text={t('goBack')}
            className={classes.goBackLink}
          />
        </div>
        <Footer className={classes.footer} />
      </div>
    </>
  );
};

export default RequestResetPassword;
