import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import PlusIcon from 'assets/svg/PlusIcon';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/promocode.module.scss';
import { Accordion } from 'react-bootstrap';
import { Formik } from 'formik';
import AddPromocodeButton from 'components/AddPromocodeButton';
import useWindowSize from 'helpers/useWindowSize';
import PromocodeForm from 'components/PromocodeForm';
import { useSelector } from 'react-redux';
import { loggedIn } from 'logic/actions/meActions';
import CheckoutPromocodesContext from 'pages/checkout/CheckoutPromocodesContext';

const AddPromocode = ({ currency, discount }) => {
  const {
    promocode,
    setPromocode,
    applyPromocode,
    clearDiscount,
    resetToTransactionTemplate,
    loading,
  } = useContext(CheckoutPromocodesContext);

  const { isNarrow } = useWindowSize();

  const isLoggedIn = useSelector(loggedIn);

  useEffect(() => {
    if (!isLoggedIn && promocode) {
      setPromocode(undefined);
      resetToTransactionTemplate();
    }
  }, [
    isLoggedIn,
    promocode,
    resetToTransactionTemplate,
    setPromocode,
  ]);

  const [open, setOpen] = useState(false);

  const { t } = useTranslation('checkoutRecap');

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const showInput = useMemo(() => open || isNarrow, [open, isNarrow]);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={{
        promocode: '',
      }}
      onSubmit={applyPromocode}
    >
      {({ resetForm }) => (
        <div className={classes.promocode}>
          <Accordion className={classes.promocode__openButtonContainer}>
            {showInput ? (
              !promocode && (
                <div>
                  <span className={classes['promocode__label--open']}>
                    {t('promocode')}
                  </span>
                  {!isNarrow && (
                    <AddPromocodeButton
                      eventKey="add_promocode"
                      cb={() => {
                        clearDiscount();
                        handleOpen();
                        resetForm();
                        setPromocode(undefined);
                      }}
                      className={classes.promocode__cancelBtn}
                    >
                      {t('cancelPromocode')}
                    </AddPromocodeButton>
                  )}
                </div>
              )
            ) : (
              <AddPromocodeButton
                eventKey="add_promocode"
                cb={handleOpen}
                className={classes.promocode__openButton}
              >
                <PlusIcon
                  height={16}
                  width={16}
                  className={classes.promocode__icon}
                />
                <span className={classes['promocode__label--closed']}>
                  {t('promocode')}
                </span>
              </AddPromocodeButton>
            )}
            {isNarrow ? (
              <div className={classes.mobile}>
                <PromocodeForm
                  currency={currency}
                  promocode={promocode}
                  discount={discount}
                  clearDiscount={clearDiscount}
                  loading={loading}
                />
              </div>
            ) : (
              <Accordion.Collapse eventKey="add_promocode">
                <PromocodeForm
                  currency={currency}
                  promocode={promocode}
                  discount={discount}
                  clearDiscount={clearDiscount}
                  loading={loading}
                />
              </Accordion.Collapse>
            )}
          </Accordion>
        </div>
      )}
    </Formik>
  );
};

AddPromocode.propTypes = {
  currency: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
};

export default AddPromocode;
